import React from 'react';

export class AetoliaFarmMap extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.SUMMER = 0;
        this.WINTER = 1;

        this.MAX_WIDTH = 17;
        this.MAX_HEIGHT = 11;
        
        this.tilesPath = '/games/aetolia/farmmap-';

        this.tiles = {
            'fenceTL':      ['fencecornerTL.png',    'fencecornersnowTL.png'],
            'fenceTR':      ['fencecornerTR.png',    'fencecornersnowTR.png'],
            'fenceBL':      ['fencecornerBL.png',    'fencecornersnowBL.png'],
            'fenceBR':      ['fencecornerBR.png',    'fencecornersnowBR.png'],
            'fenceH':       ['fenceH.png',           'fencesnowH.png'],
            'fenceV':       ['fenceV.png',           'fencesnowV.png'],
            'gapTL':        ['gapTL.png',            'gapsnowTL.png'],
            'gapTR':        ['gapTR.png',            'gapsnowTR.png'],
            'gapBL':        ['gapBL.png',            'gapsnowBL.png'],
            'gapBR':        ['gapBR.png',            'gapsnowBR.png'],
            'gapLeft':      ['gapL.png',             'gapsnowL.png'],
            'gapRight':     ['gapR.png',             'gapsnowR.png'],
            'gapTop':       ['gapT.png',             'gapsnowT.png'],
            'gapBot':       ['gapB.png',             'gapsnowB.png'],
            'gate':         ['gate.png',             'gatesnow.png'],
            'dirt':         ['dirt.png',             'dirtsnow.png'],
            'tilled':       ['tilled.png',           'tilledsnow.png'],
            'plant':        ['plantnew.png',         'plantnew.png'],
            'land':         ['land.png',             'landsnow.png'],
            'wateredplant': ['wateredplant.png',     'wateredplant.png'],
            'watereddirt':  ['watereddirt.png',      ''],

            'plant1':       ['plantyoung1.png',      ''],
            'plant2':       ['plantyoung2.png',      ''],
            'plant3':       ['plantyoung3.png',      '',                        16,  21],
            'plant4':       ['plantmature.png',      '',                        16,  32],
            'plant5':       ['plantmaturecrop.png',  '',                        16,  32],

            'trellis0':     ['trellis0.png',         '',                        16,  19],
            'trellis1':     ['trellis1.png',         '',                        16,  19],
            'trellis2':     ['trellis2.png',         '',                        16,  19],
            'trellis3':     ['trellis3.png',         '',                        16,  19],
            'trellis4':     ['trellis4.png',         '',                        16,  19],

            'grass':        ['grass.png',            'grassSnow.png'],
            'preserves':    ['preservingbin.png',    ''],
            'shedL':        ['shedL.png',            'shedsnowL.png',           20,  48],
            'shedR':        ['shedR.png',            'shedsnowR.png',           20,  48],
            'well':         ['stonewell.png',        '',                        16,  32],
            'coop':         ['chickencoop.png',      'chickencoopsnow.png',     16,  32],

            'cellar':       ['cellar.png',           'cellarsnow.png'],
            'log':          ['woodlog.png',          ''],
            'meteorite':    ['meteorite.png',        ''],
            'evilcrow':     ['evilcrow.png',         ''],
            'bandit':       ['bandit.png',           ''],
            'badfox':       ['badfox.png',           ''],
            
            'uncaredCalf':  ['uncaredcowbaby.png',  ''],
            'uncaredChick': ['uncaredchickenbaby.png', ''],
            'uncaredLamb':  ['uncaredsheepbaby.png',  ''],
            'uncaredKid':   ['uncaredgoatbaby.png',  ''],
            'uncaredCow':   ['uncaredcow.png',      ''],
            'uncaredChicken': ['uncaredchicken.png',  ''],
            'uncaredSheep': ['uncaredsheep.png',    ''],
            'uncaredGoat':  ['uncaredgoat.png',    ''],

            'treeSmall':    ['treesmall.png',        ''],
            'treeLarge':    ['treelarge.png',        '',                        22,  32],

            'playerM':      ['playerM.png',          ''],
            'playerF':      ['playerF.png',          ''],
            'scarecrow':    ['scarecrow.png',        ''],
            'watersprite':  ['wsprite.png',          ''],
            'calf':         ['cowbaby.png',          ''],
            'chick':        ['chickenbaby.png',      ''],
            'lamb':         ['sheepbaby.png',        ''],
            'cow':          ['cow.png',              ''],
            'chicken':      ['chicken.png',          ''],
            'sheep':        ['sheep.png',            ''],
            'merchant':     ['traderPeiry.png',      ''],
        };

        this.initTiles();
    }

    mapGround (v) {
        if (v === 0) return 'dirt';
        if (v === 19) return 'tilled';
        if (v >= 6 && v <= 11) return 'plant';
        if (v >= 25 && v <= 30) return 'wateredplant';
        if (v >= 31 && v <= 37) return 'watereddirt';
        return 'dirt';
    }

    mapObject(v) {
        v = parseInt(v);
        switch (v) {
            case 1:     return 'shedL';
            case 2:     return 'well';
            case 3:     return 'scarecrow';
            case 4:     return 'preserves';
            case 5:     return 'grass';
            case 6:     return '';
            case 7:     return 'plant1';
            case 8:     return 'plant2';
            case 9:     return 'plant3';
            case 10:    return 'plant4';
            case 11:    return 'plant5';
            case 12:    return 'trellis0';
            case 13:    return 'trellis1';
            case 14:    return 'trellis2';
            case 15:    return 'trellis3';
            case 16:    return 'trellis4';
            case 17:    return 'treeSmall';
            case 18:    return 'treeLarge';
            case 20:    return 'coop';
            case 21:    return 'cellar';
            case 22:    return 'meteorite';
            case 23:    return 'shedR';
            case 24:    return 'evilcrow';
            case 25:    return '';
            case 26:    return 'plant1';
            case 27:    return 'plant2';
            case 28:    return 'plant3';
            case 29:    return 'plant4';
            case 30:    return 'plant5';
            case 31:    return 'trellis0';
            case 32:    return 'trellis1';
            case 33:    return 'trellis2';
            case 34:    return 'trellis3';
            case 35:    return 'trellis4';
            case 36:    return 'treeSmall';
            case 37:    return 'treeLarge';
            default:    break;
        }
        return '';
    }

    mapCreature(v) {
        v = parseInt(v);
        switch (v) {
            case 1:     return (this.props.datahandler.current_gender() === 'male') ? 'playerM' : 'playerF';
            case 2:     return 'merchant';
            case 3:     return 'watersprite';
            case 4:     return 'calf';
            case 5:     return 'chick';
            case 6:     return 'lamb';
            case 7:     return 'cow';
            case 8:     return 'chicken';
            case 9:     return 'sheep';
            case 10:    return 'bandit';
            case 11:    return 'badfox';
            case 12:    return 'log';
            case 13:    return 'uncaredCalf';
            case 14:    return 'uncaredChick';
            case 15:    return 'uncaredLamb';
            case 16:    return 'uncaredCow';
            case 17:    return 'uncaredChicken';
            case 18:    return 'uncaredSheep';
            case 19:    return 'kid';
            case 20:    return 'goat';
            case 21:    return 'uncaredKid';
            case 22:    return 'uncaredGoat';
            default:    break;
        }
        return '';
    }

    // for the next two functions, x and y are 0-indexed, first and last two rows/columns are the fence, hence that -4
    gmcpBg(x, y) {
        let idx = y * (this.tilesWidth() - 4) + x;
        return this.props.data.farmbg[idx];
    }

    gmcpFg(x, y) {
        let idx = y * (this.tilesWidth() - 4) + x;
        return this.props.data.farmfg[idx];
    }

    getRowGround(r) {
        if ((r < 1) || (r > this.tilesHeight())) return;

        let row = [];
        if (r === 1) {
            row.push ('fenceTL');
            for (let x = 1; x < this.tilesWidth() - 1; x++)
                row.push ('fenceH');
            row.push ('fenceTR');
        }
        else if (r === 2) {
            row.push('fenceV');
            row.push('gapTL');
            for (let x = 2; x < this.tilesWidth() - 2; x++)
                row.push ('gapTop');
            row.push('gapTR');
            row.push('fenceV');
        }
        else if (r === this.tilesHeight() - 1) {
            row.push('fenceV');
            row.push('gapBL');
            for (let x = 2; x < this.tilesWidth() - 2; x++)
                row.push ('gapBot');
            row.push('gapBR');
            row.push('fenceV');
        }
        else if (r === this.tilesHeight()) {
            row.push('fenceBL');
            for (let x = 1; x < this.tilesWidth() - 1; x++)
                row.push ('fenceH');
            row.push('fenceBR');
            var mid = Math.trunc (this.tilesWidth() / 2);
            row[mid] = 'gate';
        }
        else
        {
            row.push('fenceV');
            row.push('gapLeft');
            for (let x = 2; x < this.tilesWidth() - 2; x++)
                row.push (this.mapGround (this.gmcpBg(x - 2, r - 3)));
            row.push('gapRight');
            row.push('fenceV');
        }
        return row;
    }

    getRowObject(r) {
        if (r <= 2 || r >= this.tilesHeight() - 1) return;

        let row = [];
        row.push('');
        row.push('');
        for (let x = 2; x < this.tilesWidth() - 2; x++)
            row.push (this.mapObject (this.gmcpBg(x - 2, r - 3)));
        row.push('');
        row.push('');

        return row;
    }

    getRowCreature(r) {
        if (r <= 2 || r >= this.tilesHeight() - 1) return;

        let row = [];
        row.push('');
        row.push('');
        for (let x = 2; x < this.tilesWidth() - 2; x++)
            row.push (this.mapCreature (this.gmcpFg(x - 2, r - 3)));
        row.push('');
        row.push('');

        return row;
    }


    tilesWidth() {
        return this.MAX_WIDTH;
    }

    tilesHeight() {
        return this.MAX_HEIGHT;
    }

    // Returns path of image for a given tile
    imagePath(tile, tileSize) {
        if (!(tile in this.tiles)) return null;

        let path = this.tilesPath + tileSize + 'x' + tileSize + '/';
        let fname = this.tiles[tile][this.season];
        if (!fname) fname = this.tiles[tile][0];
        return path + fname;
    }

    // Initialize tiles.
    initTiles() {
        this.images = {};

        let last;
        for (let t in this.tiles) {
            if (!this.tiles.hasOwnProperty(t)) continue;
            this.images[t + '16'] = new Image();
            this.images[t + '16'].src = this.imagePath(t, 16);
            this.images[t + '32'] = new Image();
            this.images[t + '32'].src = this.imagePath(t, 32);
            last = t;
        }

        let t = this;
        this.images[last + '32'].onload = () => { t.setState({'update':1}); }
    }   

    componentDidUpdate() {
        // this updates the canvas
        if (this.props.data.season && (this.props.data.season === 'winter'))
            this.season = this.WINTER;
        else
            this.season = this.SUMMER;        

        let canvas = this.canvasRef.current;
        if (!canvas) return;

        this.renderMap();
    }

    renderMapParts(funcs) {
        let ctx = this.canvasRef.current.getContext('2d');
        let shed = false;
        for (let y = 0; y < this.tilesHeight(); y++) {
            for (let funcid = 0; funcid < funcs.length; ++funcid) {
                let func = funcs[funcid];
                let row = this[func](y+1);
                if (!row) continue;
                for (let x = 0; x < this.tilesWidth(); x++) {
                    if (x >= row.length) break;
                    if (!row[x]) continue;
                    if (row[x] === 'shed') {
                        if (shed) continue;
                        shed = true;
                    }

                    let img = this.images[row[x] + this.tileSize];
                    let xs = x * this.tileSize;
                    let ys = y * this.tileSize;
                    // if the image dimensions do not match the tile dimensions, we need to adjust it to be properly centered
                    if (img.width !== this.tileSize) xs += Math.trunc ((this.tileSize - img.width) / 2);
                    if (img.height !== this.tileSize) ys += (this.tileSize - img.height);
                    ctx.drawImage (img, xs, ys);
                }
            }
        }
    }

    renderMap() {
        this.renderMapParts(['getRowGround']);
        this.renderMapParts(['getRowObject', 'getRowCreature']);
    }

    render() {
        this.tileSize = this.props.settings.display_largemap ? 32 : 16;
        this.width = this.MAX_WIDTH * this.tileSize;
        this.height = this.MAX_HEIGHT * this.tileSize;

        let passProps = {};
        for (let key in this.props) {
            if (key === 'settings') continue;
            if (key === 'datahandler') continue;
            if (key === 'data') continue;
            passProps[key] = this.props[key];
        }
        return (<canvas id='map_canvas' height={this.height} width={this.width} ref={this.canvasRef} {...passProps} />);
    }
}
