
import { Util } from '../base/util.js'

export class Log {
    constructor(nexus) {
        this.nexus = nexus;
        this._active = false;
        this._start = 0;
    }

    active() {
        return this._active;
    }

    enable() {
        let buffer = this.nexus.ui().buffer();
        this._start = buffer.count();
        this._active = true;
    }
    
    // workaround to keep 3rdparty packages working
    append() {}
    
    disable() {
        this._start = 0;
        this._active = false;
    }

    lineToLog(line) {
        if (line.gag) return '';   // not if the line is gagged

        let timestamp = '';
        timestamp = line.timestamp_ms;
        timestamp = '<span class="timestamp">' + timestamp + '&nbsp;</span>';

        let lineclass = line.monospace ? 'mono' : '';
        // prompt?
        if (line.is_prompt)
            lineclass = 'prompt ' + lineclass;

        let message;
        let txt = line.parsed_line;
        if (txt) message = '<div class="' + lineclass + '">' + timestamp + txt.formatted() + '</div>';
        else if (line.html_line) message = '<div class="' + lineclass + '">' + timestamp + line.html_text + '</div>';
        return message;
    }

    logFromBuffer(fromMarker=false) {
        let buffer = this.nexus.ui().buffer();
        let res = '';
        let from = 0;
        if (fromMarker && this._start) from = this._start;
        for (let i = from; i < buffer.count(); ++i)
            res += this.lineToLog(buffer.get_line_by_idx(i));
        return res;
    }

    downloadLog(data) {
        let nex = this.nexus;
        let now = new Date();
        let date = now.getFullYear() + '-' + (now.getMonth()+1) + '-' + now.getDate();
        let title = 'Nexus log - ' + nex.charname + '@' + nex.gameinfo().game_short() + ' - ' + date;
        let style = 'body { background-color: black; color: #ccc; } div { line-height: 120%; font-family: Arimo, Verdana, Geneva, sans-serif; font-size: 13px; white-space: pre-wrap; word-wrap: break-word;  } .mono { font-family: monospace; } .timestamp { font-family: monospace; color: #555; }';
        data = '<html><head><title>' + Util.escape_html(title) + '</title><style>' + style + '</style></head><body>' + data + '</body></html>';
        let fname = 'log-' + nex.charname + '-' + date + '.html';
        nex.platform().do_download(data, fname);
    }

}
