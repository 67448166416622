
// Custom tabs page

import React from 'react';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

import { SettingsTabs_Base } from './tabs_base'

export class SettingsTabs extends SettingsTabs_Base {
    constructor(props) {
        super(props);
        this.state = { 'newtab' : '' };
    }

    render() {
        let el_tabname = (<TextField key='add-tab' value={this.state.newtab} onChange={(e)=>this.setState({newtab:e.target.value})} placeholder='Tab Name' />);
        let el_addbutton = (<Button onClick={()=>this.newTab(this.state.newtab)}>Add Custom Tab</Button>);
        let newtab = (<div key='newtab' style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{paddingRight:'15px'}}>Add a new tab: </span>{el_tabname}&nbsp;{el_addbutton}</div>);
        let section1 = this.formSection ('Add a Tab', newtab);
        
        let desc = (<div key='desc'><p>Custom tabs can be used to display various content using scripting. After adding a new tab, you can use the main interface to position it where you want.</p></div>);

        let layout = this.props.layout;
        
        let rows = [];
        let tlist = Object.keys(layout.custom_tabs());
        tlist.sort();
        for (let idx = 0; idx < tlist.length; ++idx) {
            let tabname = tlist[idx];

            let namecell = (<TableCell><TextField defaultValue={tabname} onBlur={(e)=>this.tabRename(tabname,e.target.value)} /></TableCell>);
            let removecell = (<TableCell><Button size='small' onClick={()=>this.tabRemove(tabname)}>Remove</Button></TableCell>);
            
            let row = (<TableRow key={'tab-'+tabname}>{namecell}{removecell}</TableRow>);
            rows.push(row);
        }

        let tabheader = (<TableHead><TableRow><TableCell>Name</TableCell><TableCell></TableCell></TableRow></TableHead>);
        let tabtable = (<TableContainer component={Paper}><Table size="small" aria-label="tabs">{tabheader}<TableBody>{rows}</TableBody></Table></TableContainer>);
        if (!tlist.length) tabtable = (<div>You do not have any custom tabs defined.</div>);
        let tabs_el = (<div>{desc}<hr />{tabtable}</div>);

        let section2 = this.formSection ('Custom Tab List', tabs_el);
        return (<div>{section1}{section2}</div>);
    }

}
