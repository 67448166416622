// CodeMirror wrapper

import React from 'react';

import CodeMirror from '@uiw/react-codemirror';
import { EditorView } from 'codemirror';
import { completeFromList } from '@codemirror/autocomplete';
import { javascript, esLint } from '@codemirror/lang-javascript';
import { material } from '@uiw/codemirror-theme-material';
import { linter, lintGutter } from '@codemirror/lint'
import * as eslint from "eslint-linter-browserify";


export class JSEditor extends React.Component {
    constructor(props) {
        super(props);
        
        let nex = this.props.nex;

        let linterConfig = {
            // eslint configuration
            'parserOptions': {
                ecmaVersion: 6,
                sourceType: "module",
                ecmaFeatures: {
                    impliedStrict: true
                }
            },
            'env': {
                browser: true,
                es6: true
            },
            'rules': {
              // these are the eslint:recommended rules. extends:'eslint:recommended' doesn't work for whatever weird reason, so I'm just copying them here
              "constructor-super": "error",
              "for-direction": "error",
              "getter-return": "error",
              "no-async-promise-executor": "error",
              "no-case-declarations": "error",
              "no-class-assign": "error",
              "no-compare-neg-zero": "error",
              "no-cond-assign": "error",
              "no-const-assign": "error",
              "no-constant-condition": "error",
              "no-control-regex": "error",
              "no-debugger": "error",
              "no-delete-var": "error",
              "no-dupe-args": "error",
              "no-dupe-class-members": "error",
              "no-dupe-else-if": "error",
              "no-dupe-keys": "error",
              "no-duplicate-case": "error",
              "no-empty": "error",
              "no-empty-character-class": "error",
              "no-empty-pattern": "error",
              "no-ex-assign": "error",
              "no-extra-boolean-cast": "error",
              "no-extra-semi": "error",
              "no-fallthrough": "error",
              "no-func-assign": "error",
              "no-global-assign": "error",
              "no-import-assign": "error",
              "no-inner-declarations": "error",
              "no-invalid-regexp": "error",
              "no-irregular-whitespace": "error",
              "no-loss-of-precision": "error",
              "no-misleading-character-class": "error",
              "no-mixed-spaces-and-tabs": "error",
              "no-new-symbol": "error",
              "no-nonoctal-decimal-escape": "error",
              "no-obj-calls": "error",
              "no-octal": "error",
              "no-prototype-builtins": "error",
              "no-redeclare": "error",
              "no-regex-spaces": "error",
              "no-self-assign": "error",
              "no-setter-return": "error",
              "no-shadow-restricted-names": "error",
              "no-sparse-arrays": "error",
              "no-this-before-super": "error",
              "no-undef": "error",
              "no-unexpected-multiline": "error",
              "no-unreachable": "error",
              "no-unsafe-finally": "error",
              "no-unsafe-negation": "error",
              "no-unsafe-optional-chaining": "error",
              "no-unused-labels": "error",
              "no-unused-vars": "error",
              "no-useless-backreference": "error",
              "no-useless-catch": "error",
              "no-useless-escape": "error",
              "no-with": "error",
              "require-yield": "error",
              "use-isnan": "error",
              "valid-typeof": "error"
            },
        };

        let glist = nex.api_funcs();
        glist.push('nexus');
        glist.push('client');
        glist.push('args');
        let globals = {};
        for (let idx = 0; idx < glist.length; ++idx)
            globals[glist[idx]] = 'readonly';
        linterConfig.globals = globals;

        let linterSource = esLint(new eslint.Linter(), linterConfig);
        let js = javascript({ jsx: false })

        // Add the globals to the completion list.
        let globalCompletion = [];
        for (let idx = 0; idx < glist.length; ++idx)
            globalCompletion.push({ label: glist[idx], type: "function" });
        let globalSupport = js.language.data.of({autocomplete: completeFromList(globalCompletion)});
        js.support.push(globalSupport);

        this.exts = [js, EditorView.lineWrapping, lintGutter(), linter(linterSource)];
        this.cstyle = {height:'250px', width:'100%'};
        this.onChange = (value)=>this.props.onChange(value);
    }

    render() {
        let val = this.props.val;
        let key = this.props.keyval;

        return (<CodeMirror key={key} height='250px' style={this.cstyle} labelPlacement='top' extensions={this.exts} theme={material} value={val} onChange={this.onChange} />);        
    }
}

