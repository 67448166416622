// the avatar component

import React from 'react'

export class AvatarBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = { avatar: null, dialog: false, opentab: 0 };
        this.charname = null;   // keeping this off-state so I can update it immediately in the avatar fetching handler
        this.fetchingchar = null;
    }

    componentDidMount() {
        this.request();
    }

    componentDidUpdate() {
        this.request();
    }

    url()
    {
        return 'https://www.' + this.props.gameinfo.server_name() + '/wp-content/plugins/ironrealms/avatars.php';
    }

    request()
    {
        let gmcp = this.props.gmcp;
        let char = gmcp.Character;
        if (!char) return;
        if (char.name === this.fetchingchar) return;  // already fetching this
        if (char.name === this.charname) return;   // got that avatar already
        this.fetchingchar = char.name;
        let url = this.url() + '?action=get&name=' + encodeURIComponent(char.name);
        fetch(url)
            .then(res => res.json())
            .then((res) => {
                let ms = new Date().getTime();
                let a = null;
                if (res.avatar) a = res.avatar + '?stamp=' + ms;
                this.fetchingchar = null;
                this.charname = char.name;
                this.setState({avatar:a});
            })
            .catch((error) => {
                this.fetchingchar = null;
                this.charname = char.name;
                this.setState({avatar:null}); })
    }

    toggleDialog() {
        this.setState({dialog: !this.state.dialog});
    }

    avatarChanged() {
        this.fetchingchar = null;
        this.charname = null;
        this.request();        
    }

    avatarPick(aid) {
        let dh = this.props.dh;
        let platform = this.props.platform;
        dh.request_onetime_password ((pwd) => {
            let url = this.url() + '?action=set&name=' + encodeURIComponent(this.props.gmcp.Character.name)+'&pass=' + encodeURIComponent(pwd)+'&preset='+aid;
            fetch(url)
                .then(res => res.json())
                .then((res) => {
                    if (res.error) {
                        platform.alert('Activation failed', 'Something went wrong when attempting to activate the avatar. Please try again later.');
                        return;
                    }
                    this.avatarChanged();
                })
                .catch(() => {
                    platform.alert('Activation failed', 'Something went wrong when attempting to activate the avatar. Please try again later.');
                })
        });
        this.setState({dialog: false});
    }

    uploadFail(error) {
        this.props.platform.alert('Loading error', 'We are sorry, but something went wrong while trying to load the avatar. ' + error);
        this.setState({dialog: false});
    }

    getUploader() {
        let t = this;
        let dh = this.props.dh;
        let platform = this.props.platform;
        let uploader = platform.setup_uploader((name, data) =>
            {
                dh.request_onetime_password ((pwd) => {
                    let gmcp = t.props.gmcp;
                    let char = gmcp.Character;
                    let url = this.url() + '?action=set&name=' + encodeURIComponent(char.name)+'&pass=' + encodeURIComponent(pwd);
                    let formData = new FormData();
                    formData.append('avatar', new Blob([data]), name);
                    let params = {method: 'POST', mode: 'cors', body: formData};
                    fetch(url, params).then(response => {
                        if (!response.ok) {
                            t.uploadFail();
                            return;
                        }
                        response.json().then(data => {
                            if (data.error) {
                                t.uploadFail(data.error);
                                return;
                            }
                            // the avatar is fine
                            t.avatarChanged();
                            t.setState({dialog: false});
                        });
                    })
                    .catch(error => t.uploadFail(error));
                });
            }, (error) => {
                this.uploadFail(error);
            }, true
        );
        return uploader;
    }

    handleTabChange(val) {
        this.setState({opentab: val});
    }

}
