
import React from 'react'
 
// Basic tab functionality
export class Tab extends React.Component {

    id() {
        return '';
    }

    render() {
        return '';
    }

}
