
// game info - Starmourn
import React from 'react'

export class GameInfoStarmourn {
    constructor() {
        this._gauges = [];
        this._gauges.push ({ name : 'health', 'desc' : 'Health', curvar : 'hp', maxvar : 'maxhp', percentage: true, color : '#ff0000', coloroff: '#7f7f7f', textcolor: '#ffffff',
            tooltip : 'Health represents your physical well-being. When your health reaches 0 you will die.'
        });
        this._gauges.push ({ name : 'overcharge', 'desc' : 'Overcharge', curvar : 'oc', maxvar : 'maxoc', percentage: true, color : '#ffff00', coloroff: '#9f9f9f', textcolor: '#000000',
            tooltip : 'Overcharge is generated by the B.E.A.S.T. suit and used by various abilities.'
        });
        this._gauges.push ({ name : 'plasma', 'desc' : 'Plasma', curvar : 'pl', maxvar : 'maxpl', percentage: true, color : '#ff7f50', coloroff: '#9f9f9f', textcolor: '#000000',
            tooltip : 'Plasma is used to power most Plasmacasting abilities. Excess plasma can cause burning.'
        });
        this._gauges.push ({ name : 'bullets', 'desc' : 'Bullets', curvar : 'bl', maxvar : 'maxbl', percentage: false, color : '#e0e0e0', coloroff: '#9f9f9f', textcolor: '#000000',
            tooltip : 'Bullets are required to shoot your P.I.E.C.E.'
        });
        this._gauges.push ({ name : 'nanites', 'desc' : 'Nanites', curvar : 'nn', maxvar : 'maxnn', percentage: true, color : 'cyan', coloroff: '#9f9f9f', textcolor: '#000000',
            tooltip : 'Nanites are miniature machines used to perform all the Nanotech abilities.'
        });
        this._gauges.push ({ name : 'sanity', 'desc' : 'Sanity', curvar : 'sa', maxvar : 'maxsa', percentage: true, color : '#00bf00', coloroff: '#9f9f9f', textcolor: '#000000',
            tooltip : 'Your Sanity is reduced whenever you attempt to channel Empyreal abilities, and regained over time. Low sanity has various negative effects on your character.'
        });
        this._gauges.push ({ name : 'rage', 'desc' : 'Rage', curvar : 'rg', maxvar : 'maxrg', percentage: true, color : '#ffffff', coloroff: '#9f9f9f', textcolor: '#000000',
            tooltip : 'Rage allows using various abilities in the skill of the same name. It is generated by using Kithblade abilities.'
        });
        this._gauges.push ({ name : 'parts', 'desc' : 'Parts', curvar : 'pt', maxvar : 'maxpt', percentage: false, color : '#87b8e9', coloroff: '#9f9f9f', textcolor: '#000000',
            tooltip : 'Parts are used to construct a wide array of mechanical devices, including bots, gadgets, and turrets.'
        });
        
        this._balances = [];
        this._balances.push ({name : 'balance', class : 'bal', desc : 'Balance', curvar : 'bal',
            tooltip : 'Most physical abilities require that you have Balance and will take away your Balance for a few seconds after you use the ability.'
        });
        // no eq on starmourn - need the element still so layouting works (for now)
        this._balances.push ({name : 'equilibrium', class : 'eq', desc : '', curvar : 'nonexistant', tooltip : '' });
    }

    server_name(secondary = false) {
        if (secondary) return 'ironrealms.com';
        return 'starmourn.com';
    }

    server_port(encrypted, secondary = false) {
        if (secondary) return 12006;
        if (encrypted) return 443;
        return 80;
    }
    
    is_ire_game() { return true; }
    websock_dir() { return '/socket/'; }
    game_short() { return 'Starmourn'; }
    game_name() { return 'starmourn'; }
    game_long() { return 'Starmourn'; };
    root_url() { return 'https://www.'+this.server_name(false)+'/' };
    vote_url() { return this.root_url()+'vote' };
    password_url() { return this.root_url()+'game/recover' };
    script_url() { return 'https://client.'+this.server_name(false)+'/' };
    files_url() { return '/games/' + this.game_name(); }
    text_creation() { return 'new'; }
    button_count() { return 6; }
    css_style() { return 'space'; }
    gauges() { return this._gauges; }
    balances() { return this._balances; }
    rift_name() { return ''; }
    command_rift_in() { return ''; }
    command_rift_out() { return ''; }
    money() { return 'Marks'; }
    intro_sound() { return 'starmourn'; }
    avatar_count() { return 55; }
    discord_url() { return 'https://discord.gg/H8m7pFV'; }
    facebook_url() { return 'https://www.facebook.com/Starmourners'; }
    twitter_url() { return 'https://twitter.com/starmourner'; }
    show_undefined_gauges() { return false; }

    google_analytics() { return ''; }
    
    default_tabs() {
        return {
            container_1: [ 'map' ],
            container_2: [ 'affdef', 'skills', 'inventory' ],
            container_3: [ 'room', 'who', 'news' ],
            container_4: [ 'quests', 'all_comm' ],
            disabled: [ 'gauges', 'buttons' ],
            main_container: [ "output_main" ]
        };
    }


    // creation
    creation_stages() { return 4; }
    creation_valid_choice(type, id, creation_dlg) { return true; }
    
    creation_step(step) {
        if (step === 1) return 'race';
        if (step === 2) return 'class';
        if (step === 3) return 'faction';
        if (step === 4) return 'name';
        return '';
    }

    creation_short_title(step) {
        if (step === 1) return 'Race';
        if (step === 2) return 'Class';
        if (step === 3) return 'Faction';
        if (step === 4) return 'Description';
        return '';
    }

    creation_title(step) {
        if (step === 1) return 'Select Your Race';
        if (step === 2) return 'Select Your Class';
        if (step === 3) return 'Select Your Faction';
        if (step === 4) return 'Describe Your Character';
        return '';
    }

    creation_prepare_data(data) {
        let url = 'https://www.starmourn.com/local/nexus_data/';
        let baseurl = url;

        data['class'] = data['classes'];

        for (let r in data['race']) {
            let img = data['race'][r].imageURL;
            data['race'][r].imageURL = url + 'races/' + img;
            data['race'][r].mobileImageURL = url + 'mobile/races/' + img;
            data['race'][r].thumbImageURL = baseurl + 'races/thumbnails/' + data['race'][r].thumbImageURL;
        }
        for (let c in data['class']) {
            let img = data['class'][c].imageURL;
            data['class'][c].imageURL = url + 'classes/' + img;
            data['class'][c].mobileImageURL = url + 'mobile/classes/' + img;
        }
        for (let c in data['faction']) {
            let img = data['faction'][c].imageURL;
            data['faction'][c].imageURL = url + 'factions/' + img;
            data['faction'][c].mobileImageURL = url + 'mobile/factions/' + img;
        }

        data['class'].sort(function compare(a,b) {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });
        return data;
    }

    genders() { return [ 'Male', 'Female', 'Non-Binary' ]; }
    show_age() { return true; }
    age_limits(data, creation_dlg) {
        let race = creation_dlg.selected_obj('race');
        if (!race) return [ 18, 18, 18 ];  // this should never happen
        return [ parseInt(race.ageMin), parseInt(race.ageMax), parseInt(race.ageMin) ];
    }

    creation_genderreq (type, e) {
        if (type !== 'race') return null;
        if (e.noGender) return 'Non-Binary';
        return null;
    }
    creation_genderreq_text(type, e) {
        let g = this.creation_genderreq(type, e);
        if (!g) return '';
        return 'This race is genderless.'
    }

    creation_stage_info(type) {
        if (type === 'race') return 'The race has no mechanical effect on game play.';
        return 'You will be able to change your ' + type + ' selection later.';
    }

    creation_data(creation_dlg) {
        let race = creation_dlg.selected_obj('race');
        let cl = creation_dlg.selected_obj('class');
        let faction = creation_dlg.selected_obj('faction');
        if ((!race) || (!cl) || (!faction)) return null;
        let age = creation_dlg.state.selection['age'];

        return "raceID\n" + race.id + "\nclassID\n" + cl.id + "\nfactionID\n" + faction.id + "\nage\n" + age;
    }

    creation_name_hint() {
        return 'A good name feels sci-fi, but isn\'t from a book or movie.';
    }

    creation_nameform_image(data, creation_dlg) {
        let race = creation_dlg.selected_obj('race');
        if (!race) return null;
        return race.imageURL;
    }

    // this is used on the web version only
    login_extras() {
        let link = (<a style={{position: 'absolute', left: '48px', bottom: '50px', height: '29px', width: '159px', backgroundImage: 'url(\'/games/starmourn/images/starmourn-learn-more.png\')',  backgroundRepeat: 'no-repeat'}} target='_blank' rel='noopener noreferrer' href='https://www.starmourn.com' />);
        let add1 = (<div style={{position: 'absolute', left: '-285px', marginTop: '-237px', top: '50%', width: '257px', height: '486px', zIndex: 2, backgroundImage: 'url(\'/games/starmourn/images/starmourn-info.png\')', backgroundRepeat: 'no-repeat'}}>{link}</div>);
        let add2 = (<div style={{position: 'absolute', right: '-489px', bottom: '-97px', width: '688px', height: '500px', zIndex: 1, backgroundImage: 'url(\'/games/starmourn/images/cruiser.png\')' }}></div>);
        return [ add1, add2 ];
    }

    statusbar_hide_field(field) {
        if (field === 'bank') return true;
        return false;
    }

    update_default_settings(settings) {
        settings.show_last_prompt = true;  // we want the last prompt shown
    }

    backgroundImage() {
        return this.files_url() + '/images/background.jpg';
    }

    creationImage() {
        return this.files_url() + '/images/creation.jpg';
    }

    blurb1() {
        return 'Starmourn invites explorers into a galaxy of uncharted worlds, fierce rivalries, and epic battles that shape the fate of entire systems.';
    }
    
    blurb2() {
        return 'Adventurers explore alien worlds, combat hostile species, trade high-tech resources, and seek fame across the galaxy’s edge.';
    }
    
    blurb3() {
        return 'Whether you’re a soldier, smuggler, or diplomat, Starmourn empowers you to build a legacy that aligns with your ambitions.';
    }
}
