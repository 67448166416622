// Input line-related functionality

import React from 'react'
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Menu, Item, useContextMenu } from 'react-contexify';
import "react-contexify/dist/ReactContexify.css";

import { Util } from '../base/util.js'

export class InputLine extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { };
        this.commands = [];
        this.historyPos = -1;
        this.partialCommand = '';  // when we press up to go back in history, we store the original command here, so we can return to it
        this.ref = React.createRef();
        this.historyRef = React.createRef();
        this.menuID = 'input-context';
        this.menu = useContextMenu({ id: this.menuID });
    }

    componentDidMount() {
        this.props.nexus.platform().inputRef = this.ref;
        this.props.nexus.platform().inputComponent = this;
    }

    componentWillUnmount() {
        this.props.nexus.platform().inputRef = null;
        this.props.nexus.platform().inputComponent = null;
    }

    handleContextMenu(e) {
        this.menu.show({event:e});
    }

    renderCommandHistory() {
        let t = this;
        if (!t.commands[0]) return null;  // no history - nothing to do

        let items = [];
        let count = t.commands.length;
        if (count > 10) count = 10;  // show up to 10 commands
        for (let idx = count - 1; idx >= 0; --idx) {   // show these backwards so the most recent one is closest to the mouse
            let cmd = t.commands[idx];
            let el = (<Item key={'historyitem-'+idx} onClick={()=>this.props.onsubmit (cmd)}>{cmd}</Item>);
            items.push (el);
        }

        let menu = (<Menu id={this.menuID}>{items}</Menu>);
        return menu;
    }


    render() {
        let settings = this.props.settings;
        let btn_submit = (<FontAwesomeIcon style={{fontSize:'2.1em',cursor:'pointer',paddingLeft:'5px'}} icon={['fad', 'circle-caret-right']} aria-label="submit" onClick={() => this.handleSubmit()} />);
        let btn_history = (<FontAwesomeIcon style={{fontSize:'1.8em',cursor:'pointer',paddingLeft:'5px'}} icon={['fad', 'circle-caret-down']} aria-label="history" onClick={(e) => this.handleContextMenu(e)} />);
        let history_menu = this.renderCommandHistory();
        let history = (<InputAdornment key="adornSubmit" position="start">{history_menu}{btn_history}</InputAdornment>);
        let font = settings.get_font_family();
        let fontsize = settings.calc_fontsize();
        let st = { fontFamily: font, fontSize: fontsize };
        let notch = {
            "& .MuiOutlinedInput-notchedOutline" : { borderWidth : 1, borderColor: '#333' },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline" : { borderWidth : 1, borderColor: '#666' },
            "&:hover .MuiOutlinedInput-notchedOutline" : { borderWidth : 1, borderColor: '#999' },
        };
        let input = (<TextField placeholder="Enter commands here" className='inputLine' inputRef={this.ref} variant='outlined' size='small' multiline={this.props.singleline ? false : true} minRows="1" maxRows="5" InputProps={{disableUnderline:true, style:st, sx:notch, startAdornment:[history]}} onKeyPress={(e) => this.onKeyPress(e)} onKeyDown={(e) => this.onKeyDown(e)} />);
        return (<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>{input}{btn_submit}</div>);
    }

    onKeyPress(e) {
        let t = this;
        if (e.charCode === 13) {
            e.stopPropagation();
            e.preventDefault();
            t.handleSubmit();
            return false;
        }
    }

    historyMove(dir) {
        let t = this;

        let current_val = t.ref.current.value;

        if (dir === 1) {   // up arrow
            if (!t.commands[0]) return;  // no history - nothing to do
            if (t.historyPos >= 99) return;   // we are at the top

            // store what we have, so that it is not lost
            if ((t.historyPos === -1) && current_val.length && (current_val !== t.commands[0]))
                t.partialCommand = current_val;

            if (t.historyPos + 1 >= t.commands.length) return;

            // move to the previous *different* command
            do {
                t.historyPos++;
            } while ((t.historyPos < t.commands.length - 1) && (t.commands[t.historyPos] === current_val));
        }
        
        if (dir === -1) {   // down arrow
            if (t.historyPos === -1) return;

            // move to the next *different* command
            do {
                t.historyPos--;
            } while ((t.historyPos > 0) && (t.commands[t.historyPos] === current_val));

            if (t.historyPos === -1) {
                if (t.partialCommand.length) {  // if we have a partial command stored, restore it here
                    t.ref.current.value = t.partialCommand;
                    t.partialCommand = '';
                }
                // nothing more if we are already at the bottom
                return;
            }
        }
        t.ref.current.value = t.commands[t.historyPos];
    }

    // arrow keys are only captured by keydown, not by keypress
    onKeyDown(e) {
        let t = this;
        let plainKey = true;
        if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) plainKey = false;
        if ((e.keyCode === 38) && plainKey) {  // up arrow
            e.stopPropagation();
            e.preventDefault();
            t.historyMove(1);
            return false;
        }

        if ((e.keyCode === 40) && plainKey) {  // down arrow
            e.stopPropagation();
            e.preventDefault();
            t.historyMove(-1);
            return false;            
        }
        
        if ((e.keyCode === 9) && e.shiftKey) {   // Shift+Tab is tab-completion
            t.do_tab_expansion(this);
            e.stopPropagation();
            e.preventDefault();
            return;
        } else
            t.clear_tab_expansion();  // this means that expansion stops as soon as they release the modifier key

    }

    handleSubmit() {
        let cmd = this.ref.current.value;
        
        this.props.onsubmit (cmd);

        if (this.commands[0] !== cmd)
        {
            this.commands.unshift(cmd);
            if (this.commands.length > 100)
                this.commands.pop();
            // Remove duplicate copies of this command from earlier.
            for (let idx = 1; idx < this.commands.length; ++idx) {
                if (this.commands[idx] != cmd) continue;
                this.commands.splice(idx, 1);
            }
        }
        this.historyPos = -1;
        this.partialCommand = '';

        if (this.props.settings.clear_input)
            this.ref.current.value = '';
        else
            this.ref.current.select();
    }
    
    handleSettings() {
        this.props.on_feature('settings');
    }

    do_tab_expansion() {
        var input = this.ref.current;
        var pos = input.selectionStart;
        var txt = input.value;
        // if tab-expansion isn't ongoing, do that now
        if (input.tabexp_start === undefined) {
            // find the beginning of the word and grab the prefix
            var start = pos;
            while ((start > 0) && Util.is_alphanumeric(txt.substr(start - 1, 1))) start--;
            var prefix = txt.substr(start, pos - start);
            var words = this.props.lbuffer.words_for_tab_completion(prefix, 40);
            if (!words.length) return;
            input.tabexp_start = pos;
            input.tabexp_choices = words;
            input.tabexp_choice = 0;
            input.tabexp_prefix = prefix;
            input.tabexp_line_pre = txt.substr(0, start);
            input.tabexp_line_post = txt.substr(pos);
        }

        // put in the new word
        var idx = input.tabexp_choice;
        var choice = input.tabexp_choices[idx];

        idx++;
        if (idx >= input.tabexp_choices.length) idx = 0;
        input.tabexp_choice = idx;

        txt = input.tabexp_line_pre + choice + input.tabexp_line_post;
        input.value = txt;
        input.selectionStart = input.selectionEnd = input.tabexp_line_pre.length + choice.length;
    }

    // clear stored tab expansion related info
    clear_tab_expansion() {
        var input = this.ref.current;
        if (!input) return;
        delete input.tabexp_start;
        delete input.tabexp_choices;
        delete input.tabexp_choice;
        delete input.tabexp_prefix;
        delete input.tabexp_line_pre;
        delete input.tabexp_line_post;
    }
/*

        // variable auto-completion
        var t = this;
        t._platform.set_timeout(function() {
            t._platform.setup_autocomplete(t.el, { my: "left bottom", at: "left top", collision: "none" }, false, true);
        }, 10);




*/
}


