
import React from 'react';

export class IntroBaseDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

}
