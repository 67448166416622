import { Tab } from './tab.js'
import { Util } from '../../base/util.js'

import React from 'react'
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export class TabTasks extends Tab {
    constructor(props) {
        super(props);
        this.state = { selection : null };
        this.ref = React.createRef();
    }

    id() {
        return this.props.tasktype;
    }

    render() {
        var list = this.props.gmcp.TaskList[this.props.tasktype];
        
        // generate the list, including the headers
        var entries = [];
        var groups = list ? list['groups'] : [];
        for (let g = 0; g < groups.length; ++g) {
            var groupname = groups[g]['name'];
            var items = groups[g]['items'];
            if (items.length === 0) continue;
            
            // add the subgroup
            let heading = (<ListSubheader disableSticky={true} key={'group-'+this.props.tasktype+'-'+groupname}>{groupname}</ListSubheader>);
            entries.push (heading);
            
            // and the entries
            for (let idx = 0; idx < items.length; ++idx) {
                let task = items[idx];
                let checked = (task.status === "1");
                let selected = (this.state.selection === task.id);
                let task_id = this.props.tasktype + task.id;
                let color = checked ? '#00cc00' : '#606060';
                let iconname = checked ? 'square-check' : 'square';
                let st = { fontSize: '1.8em', paddingRight: '5px' };
                let icon = (<FontAwesomeIcon key={'checkbox'} color={color} icon={['fas', iconname]} style={st} />);
                let entry = (<ListItem key={task_id} dense button ref={selected ? this.ref : null} onClick={() => this.setSelection(task.id)}>{icon}<ListItemText>{task.name}</ListItemText></ListItem>);
                entries.push (entry);
                
                if (selected) {
                    let desc = task.desc || '';
/* TODO - figure out how to do these links with react!
                    desc = desc.replace(/\^([^\^]*)\^/g,
                                    function (match, $1) {
                                        return "<a href=\"" + $1 + "\">" + $1 + "</a>";
                                    });
*/
                    desc = desc.replace(/\|Type\|/g, "Click")
                                .replace(/\|type\|/g, "click")
                                .replace(/\|typing\|/g, "clicking");
                    // and add this list to a Popper
                    let key = this.props.tasktype+'-popup';
                    let popper = (<Popper style={{zIndex:2}} open={true} anchorEl={() => this.ref.current} key={key} ><Paper style={{maxWidth:'400px',padding:'5px',border:'1px solid #777777'}}>{desc}</Paper></Popper>);
                    entries.push(popper);
                }
            }                
        }
        
        return (
            <Box style={this.props.style}>
                <List component="nav" dense disablePadding={true} key={'list-'+this.props.tasktype} aria-label={Util.ucfirst (this.props.tasktype)}>
                    {entries}
                </List>
            </Box>
        );
    }

    setSelection(id) {
        if (this.state.selection === id)
            this.setState ({'selection' : null});
        else
            this.setState ({'selection' : id});
    }

}
 
