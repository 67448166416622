import { Tab } from './tab.js'
import { Util } from '../../base/util.js'

import React from 'react'

import { Gauges } from '../gauges'


export class TabGauges extends Tab {
    constructor(props) {
        super(props);
        this.state = {};
    }

    id() {
        return 'gauges';
    }

    render() {
        let nex = this.props.nexus;
        let gmcp = this.props.gmcp;
        let gauges = this.props.gauges;
        let sett = nex.settings();
        let tooltips = nex.platform().tooltips_shown();
        let gi = nex.gameinfo();
        let res = (<Gauges vitals={gmcp.Vitals} gauges={gauges} variables={nex.variables()} settings={sett} exact={sett.show_exact_gauges} tooltips={tooltips} alter_gauge_var={this.props.alter_gauge_var} gameinfo={gi} vertical={true} />);
        return res;
    }

}
 
