import { Tab } from './tab.js'

import React from 'react'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class TabSkills extends Tab {

    constructor(props) {
        super(props);
        this.state = { 'selgroup' : '', 'selskill' : '' };
    }

    id() {
        return 'skills';
    }

    renderGroupEntries(gname, skills) {
        // we add a nested list containing the skill entries
        let skill_entries = [];
        for (let i = 0; i < skills.list.length; ++i) {
            let sname = skills.list[i];
            let desc = '';
            if (skills.descs && (skills.descs.length > i)) desc = skills.descs[i];
            let keyname = 'skillentry-'+sname;
            let issel = (this.state.selskill === sname);
            let li = (<ListItem key={keyname} style={{paddingLeft:'30px',cursor:'pointer'}} selected={issel} dense onClick={() => this.skillClick(gname, sname)}><ListItemText primary={sname} secondary={desc} /></ListItem>);
            skill_entries.push (li);
        }
        return skill_entries;
    }
    
    render() {
        let skills = this.props.gmcp.SkillGroups;

        let entries = [];
        for (let i = 0; i < skills.length; ++i) {
            let gname = skills[i].name;
            let rank = skills[i].rank;
            let list = this.props.gmcp.SkillInfo.List;
            let issel = (this.state.selgroup === gname);
            let have_skills = (issel && list && (list.group.toLowerCase() === gname.toLowerCase()) && (this.state.selgroup.toLowerCase() === list.group.toLowerCase()));

            let icon_name = have_skills ? 'circle-minus' : 'circle-plus';
            let icon = (<FontAwesomeIcon icon={['fad', icon_name]} onClick={()=>this.groupClick(gname)} style={{fontSize:'1.5em', paddingRight:'5px'}} />);

            let text = gname + ' (' + rank + ')';
            let keyname = 'skillgroup-'+gname+'-'+i;
            let li = (<ListItem dense key={keyname} style={{cursor:'pointer'}} selected={issel} onClick={() => this.groupClick(gname)}>{icon}<ListItemText primary={text} className='highlightText' /></ListItem>)
            entries.push (li);
            if (have_skills) {
                let lst = this.renderGroupEntries (gname, list);
                for (let i = 0; i < lst.length; ++i)
                    entries.push (lst[i]);
            }
        }
        
        return (
            <Box style={this.props.style}>
                <List component="nav" aria-label="Skill groups">
                    {entries}
                </List>
            </Box>
        );
    }

    groupClick(name) {
        if (name === this.state.selgroup) {
            this.setState ({'selgroup' : ''});
            return;
        }

        this.setState ({'selgroup' : name}, () => this.props.ongroupfetch(name) );
        
    }

    skillClick(gname, sname) {
        if (sname === this.state.selskill) {
            this.setState ({'selskill' : ''});
            return;
        }
        this.setState ({'selskill' : sname}, () => this.props.onskillfetch(gname, sname) );
    }
    
}
 
