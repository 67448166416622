 
// Packages page

import React from 'react';

import { Util } from '../../base/util.js'
import { FormSection } from './settingcomps'

export class SettingsPackages_Base extends React.Component {
    constructor(props) {
        super(props);
        this.state = { filter: '', newpkg: '' };
    }

    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    newPackage(name) {
        if (!name.length) return;
        
        let packages = this.props.packages;
        packages.create(name, '');
        this.setState({packages:packages});  // this will update the display
    }

    onActivate(idx, active) {
        let packages = this.props.packages;
        let pkg = packages.get_idx (idx);
        if (!pkg) return;
        packages.set_enabled(idx, active);
        this.setState({packages:packages});  // this will update the display
    }

    renderDeleteQuestion(name) {
        return null;
    }
    
    onDelete(idx) {
        let t = this;
        let nexus = this.props.nexus;
        let packages = this.props.packages;
        let platform = this.props.platform;

        let pkg = packages.get_idx (idx);
        if (!pkg) return;
        let name = pkg.name;
        let q = this.renderDeleteQuestion(name);
        platform.confirm('Really Delete Reflex Package?', q, function() {
            let pkg = packages.get_idx (idx);
            if (!pkg) return;
            if (pkg.name !== name) return;   // do nothing if the package at that position is called differently now
            nexus.reflexes().run_function("onUninstall", undefined, pkg.name);
            packages.remove_idx(idx);
            t.setState({packages:packages});  // this will update the display
        });
    }

    onDownload(idx) {
        let nex = this.props.nexus;
        let packages = this.props.packages;
        let pkg = packages.get_idx (idx);
        if (!pkg) return;
        let name = pkg.name;
        var now = new Date();
        var date = now.getFullYear() + '-' + (now.getMonth()+1) + '-' + now.getDate();
        let fname = 'Reflex Package ' + Util.ucfirst(name) + ' ' + date + ".nxs";
        let data = JSON.stringify (pkg.encode());
        nex.platform().do_download(data, fname);
    }

    packageChange(idx, what, val) {
        let packages = this.props.packages;
        let pkg = packages.get_idx (idx);
        if (!pkg) return;
        if (what === 'name') pkg.name = val;
        else if (what === 'desc') pkg.description = val;
        this.setState({packages:packages});  // this will update the display
    }

    movePackageUp(idx) {
        if (idx <= 0) return;
        this.moveAction(idx, idx - 1);
    }
    
    movePackageDown(idx) {
        let packages = this.props.packages;
        let pkgs = packages.list();
        if (idx >= pkgs.length - 1) return;
        this.moveAction(idx, idx + 1);
    }

    moveAction(fromIdx, toIdx) {
        let packages = this.props.packages;
        packages.move(fromIdx, toIdx);
        this.setState({packages:packages});  // this will update the display
    }

    try_import_reflex_package(data) {
        let nexus = this.props.nexus;
        let packages = this.props.packages;

        try {
            var pkg = JSON.parse(data);
        } catch (e) {
            nexus.platform().alert('This does not seem to be a correct reflex package file, or it has been corrupted.');
            return;
        }
        if ((!pkg.name) || (!pkg.items) || (pkg.type !== 'group')) {
            nexus.platform().alert('This does not look like a valid reflex package file.');
            return;
        }
        let newpkg = packages.create (pkg.name, pkg.description);
        newpkg.apply (pkg, nexus.reflexes());
        nexus.reflexes().run_function("onInstall", undefined, pkg.name);

        this.setState({packages:packages});  // this will update the display
        nexus.platform().alert('The package ' + Util.ucfirst(pkg.name) + ' has been imported.');
    }

    render() {
        return null;
    }
}

