import { SoundsBase } from './soundsbase'

var buzz = require('buzz');

export class Sounds extends SoundsBase {

    constructor(nexus) {
        super(nexus);
        this.buzz = buzz;
        this.cache = {};
    }

    _sound_object(path, preload) {
        if (this.no_sounds()) return undefined;

        if (this.cache[path]) return this.cache[path];

        let sound = new buzz.sound("/sounds/" + path,
            {
                formats: ["ogg","mp3"],
                autoplay: false,
                preload: preload
            });
        this.cache[path] = sound;
        return sound;
    }

    _do_stop_sound(sound, fadeout) {
        if (fadeout !== false)
            sound.fadeOut(fadeout, function () {
                this.stop();
            });
        else {
            sound.stop();
        }
    }

    _do_play_sound(name, fadein, fadeout, end_callback) {
        let t = this;
        let sound = t._sound_object(name, false);
        sound.name = name;
        sound.setVolume(t.volume);
//        if (fadein !== false) sound.fadeIn(fadein)
//        if (fadeout !== false) sound.fadeOut(fadeout)

        sound.bind("ended", end_callback);

        sound.play();
        sound.setVolume(t.volume);
        return sound;
    }


    // music starts here

    _do_play_background_music(name) {
        let t = this;
        let music = t.background_music[name];
        if (music === undefined) {
            music = t._sound_object('games/' + name, false);
            music.bind("ended", function() { t.play_next_music(); });
            // music.fadeIn(3000);  // disabling this as it messes up volumes
            // music.fadeOut(3000);
            t.background_music[name] = music;
        }
        music.play();
        music.setVolume(t.volume);
        return music;
    }

    stop_music() {
        for (let m in this.background_music)
            this.background_music[m].stop();
    }

    is_music_playing() {
        for (let m in this.background_music)
            if (!this.background_music[m].isPaused())
                return true;
        return false;
    }

    _do_set_volume(volume) {
        // mute/unmute keeps the sound allocated, replacing with pause() / play()
        // the getPercent check ensures that only sounds that are actually playing get suspended/resumed
        // isPaused returns true for stopped sounds as well, so relying on that causes all loaded songs to start playing at once
        if (volume > 0) {
            for (let i in buzz.sounds) if (buzz.sounds[i].getPercent() && (buzz.sounds[i].getPercent() < 100))
                buzz.sounds[i].play();
            buzz.all().setVolume(volume);
        }
        else {
            for (let i in buzz.sounds) // if (buzz.sounds[i].getPercent() && (buzz.sounds[i].getPercent() < 100))
                buzz.sounds[i].pause();
        }
    }

}



