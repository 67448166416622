
import { Util } from '../base/util.js'

// one match
export class Match {
    constructor(text, type) {
        this.text = text;
        this.type = type;
        this.onchange = null;
    }

    set_match(pos, match, backrefs, variables) {
        this.index = pos;
        this.match = match;
        this.prefix = this.text.substr(0, pos);
        var posend = pos + match.length;
        this.suffix = this.text.substr(posend);
        this.backrefs = backrefs;
        this.variables = variables;
    }

    get_position(type, backref)
    {
        if (!this.text) return null;

        var res = {};
        if (type === 'line') {
            res.start = 0;
            res.len = this.text.length;
            return res;
        }
        if (type === 'match') {
            res.start = this.index;
            res.length = this.match.length;
            return res;
        }
        if (type === 'prefix') {
            res.start = 0;
            res.length = this.prefix.length;
            return res;
        }
        if (type === 'suffix') {
            res.start = this.index + this.match.length;
            res.length = this.suffix.length;
            return res;
        }
        // regular expressions
        if (type === 'backref') {  // these are capture groups, not backrefs, but whatever
            // capture groups; [0] is the matching text, 1+ are captured groups
            if (typeof backref === 'string') backref = parseInt(backref);
            if (this.backrefs === undefined) return null;
            if (isNaN(backref)) backref = 0;
            if (this.backrefs[backref].length <= backref) return null;   // yes, '<=' is correct; need length 4 for 3 backrefs
            // find the matched text in the regexp ... this sucks big-time and will often be wrong, but javascript
            // doesn't support backref indexes
            res.start = this.index + this.match.indexOf(this.backrefs[backref]);
            if (res.start < this.index) return null;
            res.length = this.backrefs[backref].length;
            return res;
        }
        return null;
    }

    // helper for some trigger actions
    update_positions(pos, txt, type)
    {
        this.text = this.text.substring(0, pos.start) + txt + this.text.substring(pos.start + pos.length);
        if (type === 'prefix') {
            this.index = txt.length;
            this.prefix = txt;
        }
        if (type === 'suffix')
            this.suffix = txt;
        if (type === 'match') {
            this.match = txt;
            if (this.backrefs) this.backrefs[0] = txt;
        }
        // nothing with backrefs right now, as we don't know their indexes
    }

    do_replacements(replacement, prefix_suffix, variables_obj)
    {
        if (!this.text) return replacement;

        var res = replacement;
        var replace = {};
        replace['match'] = this.match;
        replace['line'] = this.text;
        replace['prefix'] = this.prefix;
        replace['suffix'] = this.suffix;
        if (this.backrefs) {
            var ref = 1;
            while (this.backrefs[ref] != null) {
               replace[ref] = this.backrefs[ref];
                ref++;
            }
        }
        res = variables_obj.expand(res, replace);

        if (this.variables)
            res = variables_obj.expand(res, this.variables);

        if (prefix_suffix) {
            if (this.prefix) res = this.prefix + res;
            if (this.suffix) res = res + this.suffix;
        }
        return res;
    }
}


// base class for all the reflexes
export class Reflex {
    constructor(name, nexus) {
        this._nexus = nexus;
        this.type = 'none';
        this.name = name;
        this.enabled = true;
        this.id = 0;
        this.p = null;
        
        // cached regexp
        this.regexp = null;
        this.regexp_idx = '';
    }

    encode() {
        var res = {};
        res['type'] = this.type;
        res['name'] = this.name;
        res['enabled'] = this.enabled;
        if (this.actions) {
            var acts = [];
            for (var idx = 0; idx < this.actions.length; idx++)
            {
                var a = this.actions[idx].encode();
                acts.push (a);
            }
            res['actions'] = acts;
        }
        return res;
    }

    apply (e, reflexes) {
        if (e['type'] !== this.type) return;  // sanity check
        this.name = e['name'];
        this.enabled = e['enabled'];
        if (e['actions']) {
            var acts = [];
            for (let idx = 0; idx < e['actions'].length; idx++)
            {
                let adata = e['actions'][idx];
                let atype = adata['type'];
                if (!atype) {
                    atype = adata['action'];   // for older settings
                    if (atype) adata['type'] = atype;
                }
                if (!atype) {
                    atype = 'command';
                    adata['type'] = atype;
                }
                let a = reflexes.make_action (atype);
                if (!a) continue;
                a.apply (adata);
                acts.push (a);
            }
            this.actions = acts;
        }
    }

    copy_actions() {
        if (!this.actions) return null;
        let reflexes = this._nexus.reflexes();
        let res = [];
        for (var i = 0; i < this.actions.length; ++i) {
            let a = this.actions[i].encode();
            let newa = reflexes.make_action (a.type);
            newa.apply (a);
            res.push (newa);
        }
        return res;
    }

    parent() {
        return this.p;
    }

    // used for filtering reflexes
    matches(txt) {
        if ((!txt) || (txt.length === 0)) return true;

        txt = txt.toLowerCase();
        if (this.type === txt) return true;   // type must match exactly to qualify
        // name
        if (this.name && this.name.toLowerCase().indexOf(txt) >= 0) return true;

        // actions - there are several object types with these, so I just put it here
        if (this.actions) {
            for (var idx = 0; idx < this.actions.length; ++idx) {
                let a = this.actions[idx];
                if (a.matches(txt)) return true;
            }
        }
        return false;
    }

    // This returns a cached regexp object, or creates a new one. The goal is to cache the regex parsing.
    get_regexp(re, case_sensitive) {
        var idx = 'RE' + re + (case_sensitive ? 'S' : 'I');
        if (this.regexp_idx === idx) return this.regexp;

        var exp = null;
        var opts = '';
        if (!case_sensitive) opts = 'i';
        try {
            exp = new RegExp(re, opts);
        } catch (msg) {
            this._nexus.display_notice("RegEx Error in Pattern [" + re + "]:", 'red');
            this._nexus.display_notice(msg);
            return null;
        }
        if (exp)
        {
            this.regexp = exp;
            this.regexp_idx = idx;
        }
        return exp;
    }

    // exact, begins, substring, regexp
    // used by aliases and triggers
    do_matching(text, pattern, type, whole_words, case_sensitive) {
        if ((pattern == null) || (pattern.length === 0)) return null;
        let txt = text;
        let res = new Match (txt, type);
        let regex;
        if (type === 'regexp')
            regex = true;
        else if ((type === 'exact') || (type === 'begins') || (type === 'substring') || (type === 'ends'))
            regex = false;
        else return;

        // Are we using simplified patterns?
        this.simplified_vars = undefined;

        if (!regex) {
            // check if they are using a simplified pattern
            // simplified patterns contain the string <varname>, which stores a matching subtext (single word only) into a variable of the given name. E.g. 'You take <gold> gold sovereigns. will match on "You take 45 gold sovereigns.", setting @gold to 45.
            let simplified = true;
            if (pattern.indexOf('<') < 0) simplified = false;
            if (pattern.indexOf('>') < 0) simplified = false;
            if (simplified && (!pattern.match(/<\w+>/))) simplified = false;
            if (simplified) {
                // turn the text into a regular expression
                regex = true;
                pattern = this._parse_simplified_pattern (pattern, type);
            }
        }

        if (!regex) {
            if (!case_sensitive) {
                pattern = pattern.toLowerCase();
                txt = txt.toLowerCase();
            }
            let pos = txt.indexOf(pattern);
            if (pos === -1) return null;
            let posend = pos + pattern.length;
            if (((type === 'begins') || (type === 'exact')) && (pos > 0)) return null;
            if (((type === 'ends') || (type === 'exact')) && (posend !== txt.length)) return null;
            // whole words?
            if (whole_words) {
                let pre = ' ', post = ' ';
                if (pos > 0) pre = txt.substr(pos - 1, 1);
                if (posend < txt.length) post = txt.substr(posend, 1);
                // doing whole-words using spaces only, otherwise we get weird things like 't.something' triggering targeting
                if ((pre !== ' ') || (post !== ' ')) return null;
                // if (Util.is_alphanumeric(pre) || Util.is_alphanumeric(post)) return null;
            }
            res.set_match(pos, text.substr(pos, pattern.length), null, null);
            return res;
        }
        
        let re = pattern;
        // triggers ending in | match everything, and for some weird reason didn't in 2.1, adding a workaround
        while ((re.length >= 2) && (re.substr(re.length-1) === '|') && (re.substr(re.length-2,1) !== '\\'))
            re = re.substr(0, re.length - 1);

        // fetch a RegExp object, caching the compiled expression
        let exp = this.get_regexp(re, case_sensitive);
        // if exp is null, the regexp had an error, and it was already reported, so just bail out
        if (!exp) return null;

        let result = exp.exec(text);
        if (result == null) return null;
        let pos = result.index;
        let posend = pos + result[0].length;
        if (whole_words) {
            let pre = ' ', post = ' ';
            if (pos > 0) pre = txt.substr(pos - 1, 1);
            if (posend < txt.length) post = txt.substr(posend, 1);
            // doing whole-words using spaces only, otherwise we get weird things like 't.something' triggering targeting
            if ((pre !== ' ') || (post !== ' ')) return null;
            // if (Util.is_alphanumeric(pre) || Util.is_alphanumeric(post)) return null;
        }
        let vars = undefined;
        if (this.simplified_vars !== undefined) {
            // need to convert the backrefs into variables for the simplified matching
            vars = {};
            for (let i = 0; i < this.simplified_vars.length; ++i)
                vars[this.simplified_vars[i]] = result[i + 1];
        }
        res.set_match(pos, result[0], result, vars);

        return res;
    }

    _parse_simplified_pattern(pattern, type) {
        this.simplified_vars = [];
        var pp = pattern;
        pattern = '';
        var invar = false;
        var varname = '';
        // a simple parser that produces a regexp and remembers the desired variable names
        for (var i = 0; i < pp.length; ++i) {
            var letter = pp[i];
            if (invar) {
                if (Util.is_alphanumeric(letter))
                    varname += letter;
                else if (letter === '>') {
                    invar = false;
                    pattern += '(\\S+)';
                    this.simplified_vars.push(varname);
                    varname = '';
                } else {
                    invar = false;
                    pattern += '<' + varname + letter;
                    varname = '';
                }
            } else {
                if (letter === '<') {
                    invar = true;
                    varname = '';
                }
                else
                {
                    if ((letter === '\\') || (letter === '*') || (letter === '+') || (letter === '.') || (letter === '(') || (letter === ')') || (letter === '[') || (letter === ']') || (letter === '{') || (letter === '}') || (letter === '|') || (letter === '^') || (letter === '$'))
                        pattern += '\\';
                    pattern += letter;
                }
            }
        }
        if ((type === 'exact') || (type === 'begins')) pattern = '^' + pattern;
        if ((type === 'exact') || (type === 'ends')) pattern = pattern + '$';

        return pattern;
    }
}

export class ReflexGroup extends Reflex {
    constructor(name, nexus) {
        super(name, nexus);
        this.type = 'group';
        this.items = [];
    }

    encode() {
        var res = super.encode();
        res.items = [];
        for (var i = 0; i < this.items.length; ++i) {
            res.items.push (this.items[i].encode());
        }
        return res;
    }

    apply (e, reflexes) {
        if (e['type'] !== this.type) return;  // sanity check
        super.apply (e, reflexes);
        this.items = [];
        for (var idx = 0; idx < e['items'].length; ++idx) {
            var ei = e['items'][idx];
            var item = reflexes.create (ei['type'], ei['name']);
            reflexes.append (item, this);
            item.apply (ei, reflexes);
        }
    }

    duplicate() {
        var res = new ReflexGroup(this.name, this._nexus);
        return res;
    }
}
