
// Feedback page

import React from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { SettingsFeedback_Base } from './feedback_base'

export class SettingsFeedback extends SettingsFeedback_Base {

    render() {
        let rows = [];
        let name_el = (<TextField label='Your Name' value={this.state.name} onChange={(e)=>this.setState({name:e.target.value})} />);
        let email_el = (<TextField label='E-mail Address' value={this.state.email} onChange={(e)=>this.setState({email:e.target.value})} />);
        let istyle = {width: '98%', height: '200px'};
        let feedback_el = (<TextField label='Your Feedback' multiline={true} rows={6} style={{width:'100%'}} inputProps={{style:istyle}} value={this.state.feedback} onChange={(e)=>this.setState({feedback:e.target.value})} />);
        let btn_send = (<Button variant='contained' color='primary' onClick={()=>this.sendFeedback()}>Send Feedback</Button>);

        if (this.state.thank) rows.push ((<div key='header' style={{fontWeight: 'bold', fontSize: '1.3em'}}>Thank you for your feedback!</div>));
        rows.push ((<div key='name'>{name_el}</div>));
        rows.push ((<div key='email'>{email_el}</div>));
        rows.push ((<div key='feedback'>{feedback_el}</div>));
        rows.push ((<div key='send'>{btn_send}</div>));
        return this.formSection ('Send Us Your Feedback', rows, 'section_feedback');
    }
}

 
 
