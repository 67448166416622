
import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export class Gauges extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
        this.reflist = {};
        this._alter_gauge_var = this.props.alter_gauge_var;
    }

    componentDidUpdate() {
        let gauges = this.props.gauges;
        let updates = {};
        let update = false;
        for (let i = 0; i < gauges.length; i++) {
            if (!this.reflist['gauge'+i]) continue;
            let el = this.reflist['gauge'+i].current;
            let text = this.reflist['gaugetext'+i].current;
            if (!el) continue;

            let limit = el.offsetWidth - 40;
            let hide = (text.offsetWidth >= limit);
            let hidden = this.state['hidetext'+i] ? true : false;
            // Is there a change?
            if (hide === hidden) continue;   // No, nothing to do here.

            update = true;
            updates['hidetext'+i] = hide;
        }
        if (update) this.setState(updates);
    }

    render() {
        let vitals = this.props.vitals;
        if (!vitals) vitals = {};
        let gauges = this.props.gauges;
        let variables = this.props.variables;
        let gi = this.props.gameinfo;
        let show_undefined = true;
        if (gi.show_undefined_gauges) show_undefined = gi.show_undefined_gauges();
        let sett = this.props.settings;
        let vertical = this.props.vertical;

        let list = [];
        let pos = 0;
        for (let i = 0; i < gauges.length; i++) {
            let g = gauges[i];
            let isvar = g['is_variable'];
            let max = undefined, cur = undefined;
            if (isvar) {
                max = variables.get(g['maxvar']);
                cur = variables.get(g['curvar']);
            } else {
                max = vitals[g['maxvar']];
                cur = vitals[g['curvar']];
            }
            if (!this.reflist['gauge'+i]) {
                this.reflist['gauge'+i] = React.createRef();
                this.reflist['gaugetext'+i] = React.createRef();
            }

            if ((!show_undefined) && (cur === undefined)) continue;  // this gauge should not be shown
            let noval = (cur === undefined);
            try {
                max = parseInt(max);
                cur = parseInt(cur);
            } catch (e) {
                cur = 0;
                max = 100;
            }

            if (this._alter_gauge_var) {
                max = this._alter_gauge_var (g['maxvar'], max);
                cur = this._alter_gauge_var (g['maxvar'], cur);
            }
            let percent = g.percentage;
            if (this.props.exact) percent = false;
            let val = noval ? 0 : Math.round((cur / max) * 100);
            if (percent) cur = val;
            if (val > 100) val = 100;

            pos++;
            let fontSize = parseInt(sett.calc_fontsize()) + 1;
            let top = Math.floor(fontSize/3);
            let gaugeSize = fontSize * 2;
            let gaugeColors = { backgroundColor: g.coloroff, "& .MuiLinearProgress-bar": { backgroundColor: g.color }};
            let inner = (<LinearProgress variant="determinate" value={val} style={{height:gaugeSize+'px', borderRadius:'5px', border: '1px #aaaaaa solid'}} sx={gaugeColors} />);
            let tstyle = {position:'absolute', left: '50%', transform: 'translate(-50%, 0)', top: top+'px', fontSize: fontSize+'px', textTransform: 'uppercase', whiteSpace: 'nowrap'};
            if (g.textcolor) tstyle['color'] = g.textcolor;

            let desc = g.desc + ': ';
            if (noval) desc += '---';
            else desc += cur + ( percent ? '%' : '' );
            if (this.state['hidetext'+i]) tstyle['visibility'] = 'hidden';  // need to do it like this as we still want the width
            let text = (<span ref={this.reflist['gaugetext'+i]} style={tstyle}>{desc}</span>);

            let st = {paddingLeft:'3px', paddingRight:'3px', position: 'relative'};
            if (vertical) {
                st['gridRow'] = pos+'/'+(pos+1);
                st['marginTop'] ='6px';
                st['marginBottom'] ='6px';
            }
            else st['gridColumn'] = pos+'/'+(pos+1);
            let el = (<div key={'gauge'+pos} ref={this.reflist['gauge'+i]} style={st}>{inner}{text}</div>);
            if (this.props.tooltips) el = (<Tooltip title={<React.Fragment><Typography>{desc}<br /><br />{g.tooltip}</Typography></React.Fragment>}>{el}</Tooltip>);
            list.push (el);
        }
        let key = vertical ? 'gauges_vertical' : 'gauges';
        return (<div key={key} id={key} style={{display:'grid'}}>{list}</div>);
    }

}

