
// various random utility functions
export class Util {

    static is_alphanumeric(ch) {
        if ((!ch) | (ch.length === 0)) return false;
        if ((ch >= 'a') && (ch <= 'z')) return true;
        if ((ch >= 'A') && (ch <= 'Z')) return true;
        if ((ch >= '0') && (ch <= '9')) return true;
        if (ch === '_') return true;
        return false;
    }

    static arrayToString(a) {
        let len = a.length;
        let res = '';
        for (let i = 0; i < len; ++i)
            res += String.fromCharCode(a[i]);
        return res;
    }

    static stringToArray(str) {
        let len = str.length;
        let res = new Uint8Array(len);
        for (let i = 0; i < len; ++i)
            res[i] = str.charCodeAt(i);
        return res;
    }

    static is_number(n) {
        if (isNaN(parseFloat(n))) return false;
        if (!isFinite(n)) return false;
        return true;
    }

    // similar to parseFloat, but anything not numeric is represented as 0
    static to_number(val)
    {
        val = parseFloat(val);
        if (isNaN(val)) return 0;
        return val;
    }

    static random_element(a) {
        if ((a == null) || (a.length === 0)) return null;
        return a[Math.floor(Math.random() * a.length)];
    }

    static ucfirst (str) {
        str += '';
        var f = str.charAt(0).toUpperCase();
        return f + str.substr(1);
    }

    static leftpad(str, len, ch)
    {
        if (!str) str = '';
        str = str.toString();
        while (str.length < len)
            str = ch + str;
        return str;
    }

    // milliseconds since epoch
    static timestamp()
    {
        return (new Date()).getTime();
    }

    static get_time(include_ms = false)
    {
        var today = new Date();
        var h = today.getHours();
        var m = today.getMinutes();
        var s = today.getSeconds();

        h = Util.leftpad (h, 2, '0');
        m = Util.leftpad (m, 2, '0');
        s = Util.leftpad (s, 2, '0');
        var res = h + ':' + m + ':' + s;

        if (include_ms) {
            var ms = today.getMilliseconds();
            ms = Util.leftpad (ms, 3, '0');
            res += '.' + ms;
        }
        return res;
    }

    // A very simple html tag stripper. Used by the mobile version.
    static strip_html_tags (str) {
        return str.replace(/(<([^>]+)>)/g, '');
    }

    static escape_html (str) {
        if (!str) return str;

        return str
             .replace(/&/g, "&amp;")
             .replace(/</g, "&lt;")
             .replace(/>/g, "&gt;")
             .replace(/"/g, "&quot;")
             .replace(/'/g, "&#039;");
    }


    static fisherYatesShuffle ( myArray ) {
        var i = myArray.length, j, tempi, tempj;
        if ( i === 0 ) return false;
        while ( --i ) {
            j = Math.floor( Math.random() * ( i + 1 ) );
            tempi = myArray[i];
            tempj = myArray[j];
            myArray[i] = tempj;
            myArray[j] = tempi;
        }
    }

    static escapeRegExp(str) {
        return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
    }

    // these need to remain in this order! We use them for angle calculations in the mapper
    static directions() {
        return ["n", "ne", "e", "se", "s", "sw", "w", "nw", "u", "d", "i", "o"];
    }

    // some static functions
    static get_brief_direction_name (ab) {
        if (ab === "northwest") return "nw";
        if (ab === "north") return "n";
        if (ab === "northeast") return "ne";
        if (ab === "east") return "e";
        if (ab === "southeast") return "se";
        if (ab === "south") return "s";
        if (ab === "southwest") return "sw";
        if (ab === "west") return "w";
        if (ab === "up") return "u";
        if (ab === "down") return "d";
        if (ab === "in") return "i";
        if (ab === "out") return "o";
        return ab;
    }

    static get_full_direction_name (ab) {
        if (ab === "nw") return "northwest";
        if (ab === "n") return "north";
        if (ab === "ne") return "northeast";
        if (ab === "e") return "east";
        if (ab === "se") return "southeast";
        if (ab === "s") return "south";
        if (ab === "sw") return "southwest";
        if (ab === "w") return "west";
        if (ab === "u") return "up";
        if (ab === "d") return "down";
        if (ab === "i") return "in";
        if (ab === "o") return "out";
        return ab;
    }

    static valid_email(email) {
        if (!email.match(/^([a-zA-Z0-9])+([a-zA-Z0-9+._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9._-]+)+$/)) return false;
        return true;
    }

    // rather simplistic, but it'll do
    static add_article(txt) {
        if (!txt.length) return txt;
        var ch = txt.charAt(0).toLowerCase();
        if ((ch === 'a') || (ch === 'e') || (ch === 'i') || (ch === 'o') || (ch === 'y')) return 'an ' + txt;
        return 'a ' + txt;
    }

}
