
// components used on setting pages

import React from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Popover from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

import { ChromePicker } from 'react-color'


export class SettingBool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return this.renderSetting (this.props.keyname, this.props.text, this.props.tooltip, this.props.inline);
    }

    renderSetting(key, text, tooltip, inline=false) {
        let s = this.props.settings;

        let el = (<Switch checked={s[key]?true:false} onChange={(ev) => this.props.onChange(key, ev.target.checked)} name={key} />);
        let label = (<FormControlLabel key={'settings-'+key} control={el} label={text} />);
        if (tooltip) label = (<Tooltip key={'settings-'+key} title={tooltip} PopperProps={{disablePortal:true}}>{label}</Tooltip>);
        if (!inline) label = (<div key={'settings-'+key}>{label}</div>);
        return label;
    }

}

export class SettingColor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.ref = React.createRef();
    }

    openColor(open) {
        this.setState({open:open});
    }

    render() {
        return this.renderSettingWithColor(this.props.keyname, this.props.text, this.props.tooltip, this.props.colorkey, this.props.colortext)
    }

    renderSettingWithColor(key, text, tooltip, colorkey, colortext) {
        let s = this.props.settings;
        let setting = (<SettingBool key={key} keyname={key} text={text} tooltip={tooltip} inline={true} settings={s} onChange={(key, val)=>this.props.onChange(key, val)} />);

        let open = this.state.open ? true : false;
        let divstyle = { display: 'flex-inline', flexDirection: 'row' };
        let bstyle = { backgroundColor: s[colorkey] };
        
        let color = (<Button key={key+'sett_button'} ref={this.ref} style={bstyle} onClick={()=>{ if (!open) this.openColor(!open) } }>{colortext}</Button>);
        let picker = (<ChromePicker color={s[colorkey]} onChange={(color)=>this.props.onChange(colorkey, color.hex)} disableAlpha={true} />);
        let anchorOrigin={ vertical: 'bottom', horizontal: 'center' };
        let transformOrigin = { vertical: 'top', horizontal: 'center' };
        let popup = (<Popover style={{zIndex:8300}} key={key+'sett_popup'} open={open} anchorEl={this.ref.current} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} onClose={()=>this.openColor(false)}>{picker}</Popover>);
        let res = (<div key={key+'-main'} style={divstyle}>{setting}{color}{popup}</div>);
        return res;
    }
}

export class FormSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    
    render() {
        let header = null;
        if (this.props.name && this.props.name.length) header = (<h4 className='formSectionHeader'>{this.props.name}</h4>);
        else header = (<div style={{marginTop:'10px'}} />);
        let content = (<Card key={this.props.keyname}><CardContent>{this.props.content}</CardContent></Card>);
        return (<div className='formSection'>{header}{content}</div>);
    }
}
