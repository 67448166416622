
import { TextFormatter } from './textformatter'

// line chunks
class BaseChunk {
    clone() { return new BaseChunk(); }

    type() { return 'base' }

    length() { return 0; }

    text() { return ''; }

    formatted(f) { return f.formatBase (this); }

    resize(start, len) { }

    update_state(state) { return state; }
}

export class TextChunk extends BaseChunk {
    constructor(text) {
        super();

        this._txt = text;
    }

    clone() { return new TextChunk(this._txt); }

    type() { return 'text'; }

    length() { return this._txt ? this._txt.length : 0; }

    text() { return this._txt; }

    formatted(f) { return f.formatText (this); }

    resize(start, len) {
        this._txt = this._txt.substr(start, len);
    }
}

export class ColorChunk extends BaseChunk {
    constructor(fg, bg) {
        super();
        this._fg = fg;
        this._bg = bg;
    }

    fg() { return this._fg; }
    bg() { return this._bg; }

    clone() { return new ColorChunk (this._fg, this._bg); }

    type() { return 'color'; }

    formatted(f) { return f.formatColor (this); }

    update_state(state) {
        state.fg = this._fg;
        if (this._fg === 'reset')
            delete state.bg;
        else
            state.bg = this._bg;
        return state;
    }
}

export class LinkChunk extends TextChunk {
    constructor(color, commands, text, hint, isprompt, reverted) {
        super(text)
        if (reverted && color) {
            let intcolor = parseInt(color.substr(1), 16);
            if (!isNaN(intcolor)) {
                let b = color % 256; color = Math.floor(color / 256);
                let g = color % 256; color = Math.floor(color / 256);
                let r = color % 256;
                intcolor = 256 * 256 * (255 - r) + 256 * (255 - g) + (255 - b);  // reverted color
                color = '#' + intcolor.toString(16);
            }
        }
        this._color = color;
        this._commands = commands;
        this._hint = hint;
        this._prompt = isprompt;
    }

    color() { return this._color; }
    commands() { return this._commands; }
    hint() { return this._hint; }
    prompt() { return this._prompt; }

    clone() { return new LinkChunk (this._color, this._commands, this._txt, this._hint, this._prompt, false); }

    type() { return 'link'; }

    formatted(f) { return f.formatLink (this); }
}

export class TextLine {
    constructor(chunks, allow_mxp=true) {
        this.chunks = chunks;
        this.allow_mxp = allow_mxp;
    }

    // helper function for colorize / replace. Ensures that there exists no chunk that spans this position.
    // Chunks starting -at- this position are fine. Chunks -ending- at this position are not (last letter will be split off).
    split_chunk_at_pos(pos)
    {
        if (pos <= 0) return;  // nothing to do

        let at = 0;
        for (var idx = 0; idx < this.chunks.length; ++idx) {
            // if we got exactly to the desired position, there is no need to split anything; bail out
            if (at === pos) break;
            let len = this.chunks[idx].length();
            if (at + len <= pos) {
                at += len;
                continue;
            }

            // If we got here, at < pos and at + len > pos, meaning we need to split
            let splitlen = pos - at;
            let chunk2 = this.chunks[idx].clone();
            this.chunks[idx].resize(0, splitlen);
            chunk2.resize(splitlen, len);
            this.chunks.splice(idx + 1, 0, chunk2);   // insert chunk2 after the current chunk
            break;
        }
    }

    colorize(start, end, color, bgcolor) {
        if ((this.chunks == null) || (this.chunks.length === 0))
            return;
        if (end < start) end = start;
        // ensure that no chunk spans the thresholds (simplifies the logic)
        this.split_chunk_at_pos(start);
        if (end !== start) this.split_chunk_at_pos(end);

        // grab all the chunks between start and end, except colorization ones
        let chunks = [];
        chunks.push(new ColorChunk(color, bgcolor));

        let pos = 0;
        let copying = false;
        for (var idx = 0; idx < this.chunks.length; ++idx) {
            if (pos >= end) break;
            if (pos >= start) copying = true;
            if (copying && this.chunks[idx].type() !== 'color') chunks.push(this.chunks[idx]);
            let len = this.chunks[idx].length();
            pos += len;
        }

        return this.replace_with_linechunks(start, end, chunks);
    }

    replace(start, end, replacement, color, bgcolor) {
        let chunks = [];
        if ((replacement != null) && replacement.length) {
            if ((color !== undefined) || (bgcolor !== undefined))
                chunks.push(new ColorChunk(color, bgcolor));
            chunks.push(new TextChunk(replacement));
        }
        return this.replace_with_linechunks(start, end, chunks);
    }

    linkify(start, end, color, link_command, link_text, reverted) {
        if (!link_command) return;
        if (!link_text)
            link_text = this.text().substr(start, end - start + 1);
        let chunks = [];
        chunks.push(new LinkChunk(color, link_command, link_text, undefined, false, reverted));
        return this.replace_with_linechunks(start, end, chunks);
    }

    // returns chunks, does not actually alter anything
    static apply_line_state(state) {
        let res = [];
        if ((state.fg != null) || (state.bg != null))
            res.push(new ColorChunk(state.fg, state.bg));
        else
            res.push(new ColorChunk('reset', null));
        return res;
    }

    // start = first index to replace, end = first index to -not- replace (i.e. end-start = length to replace)
    replace_with_linechunks(start, end, linechunks)
    {
        if ((this.chunks == null) || (this.chunks.length === 0)) {
            this.chunks = linechunks;
            return;
        }
        if (end < start) end = start;
        // ensure that no chunk spans the thresholds (simplifies the logic)
        this.split_chunk_at_pos(start);
        if (end !== start) this.split_chunk_at_pos(end);

        // remove old chunks, remember state at the end (so that colors match)
        let pos = 0;
        let removeidx = -1;
        let removecount = 0;
        let state = {};
        for (var idx = 0; idx < this.chunks.length; ++idx) {
            if (pos >= end) break;
            if ((pos >= start) && (removeidx < 0)) removeidx = idx;
            if (removeidx >= 0) removecount += 1;

            let len = this.chunks[idx].length();
            pos += len;
            state = this.chunks[idx].update_state(state);
        }
        if (removeidx < 0) removeidx = 0;

        linechunks = linechunks || [];  // linechunks must exist, as we'll be adding the new state
        let state_chunks = TextLine.apply_line_state(state);
        linechunks.push(new ColorChunk('reset', null));   // reset the color -- fixes background color leakage
        for (var s = 0; s < state_chunks.length; ++s)
            linechunks.push(state_chunks[s]);
        // this calls splice with (removeidx, removecount, (contents of linechunks)) as params
        Array.prototype.splice.apply(this.chunks, [removeidx, removecount].concat(linechunks));
    }

    remove(start, end) {
        return this.replace_with_linechunks(start, end, null);
    }

    formatted(params=null) {
        let formatter = new TextFormatter(params, this.allow_mxp);
        return formatter.format (this.chunks);
    }

    text() {
        let res = '';
        for (var i = 0; i < this.chunks.length; ++i)
            res += this.chunks[i].text(this);
        return res;
    }
}


export class LineParser {
    constructor(settings, datahandler) {
        this._settings = settings;
        this._datahandler = datahandler;
        this.allow_mxp = /*datahandler ? datahandler.mxp_enabled :*/ false;
        this._state = {}
        this._chunks = {}
    }

    reset() {
        this._state = {}
        this._chunks = {}
    }


    _handle_ansi256_color(color) {  // sequences are 38;5;X and 48;5;X
        let c, fg, bg;
        if (this._state.fg256 === 2) {
            this._state.fg256 = 0;
            this._state.ansi_fg = color;
            fg = this._settings.get_ansi_color(color, false);
            c = new ColorChunk(fg, null);
            this._state = c.update_state(this._state);
            this._chunks.push(c);
            return true;
        }
        if (this._state.bg256 === 2) {
            this._state.bg256 = 0;
            this._state.ansi_bg = color;
            bg = this._settings.get_ansi_color(color, true);
            c = new ColorChunk(null, bg);
            this._state = c.update_state(this._state);
            this._chunks.push(c);
            return true;
        }
        if (this._state.fg256 === 1) {
            if (color === 5) this._state.fg256++;
            else this._state.fg256 = 0;
            return true;
        }
        if (this._state.bg256 === 1) {
            if (color === 5) this._state.bg256++;
            else this._state.bg256 = 0;
            return true;
        }
        if (color === 38) {
            this._state.fg256 = 1;
            return true;
        }
        if (color === 48) {
            this._state.bg256 = 1;
            return true;
        }

        return false;
    }

    _handle_ansi_color(color) {
        let fg = null;
        let bg = null;

        // 256 color support
        if (this._handle_ansi256_color(color)) return;

        if ((color >= 30) && (color <= 37)) {  // fg
            this._state.ansi_fg = color - 30;
            fg = this._settings.get_ansi_color(this._state.ansi_fg + this._state.bold * 8, false);
        }
        if ((color >= 40) && (color <= 47)) {  // bg
            this._state.ansi_bg = color - 40;
            bg = this._settings.get_ansi_color(this._state.ansi_bg, true);
        }
        if (color === 1) {  // bold on
            this._state.bold = 1;
            let fgc = this._state.ansi_fg;
            if (fgc === undefined) fgc = 7;
            fg = this._settings.get_ansi_color(fgc + this._state.bold * 8, false);
        }
        if (color === 22) {  // bold off
            this._state.bold = 0;
            let fgc = this._state.ansi_fg;
            if (fgc === undefined) fgc = 7;
            fg = this._settings.get_ansi_color(fgc + this._state.bold * 8, false);
        }
        if (color === 0) {  // reset
            this._state.bold = 0;
            delete this._state.ansi_fg;
            delete this._state.ansi_bg;
            fg = 'reset';
        }

        if ((fg !== null) || (bg !== null)) {
            let c = new ColorChunk(fg, bg);
            this._state = c.update_state(this._state);
            this._chunks.push(c);
        }
    }

    _handle_regular_text(text) {
        this._chunks.push(new TextChunk(text));
    }

    _handle_mxp_tag(tag) {
        tag = tag.replace (/\x1B\[\dz/g, '');  // strip ANSI tags
        tag = tag.replace (/\x1B\[[\d;]+m/g, '');  // strip color ANSI tags - there shouldn't be any ideally, but games sometimes send them
        // swap out the Rapture markers for real tags (GMCP only)
        tag = tag.replace (/\x03/g, '<');
        tag = tag.replace (/\x04/g, '>');
        if (tag.toLowerCase() === '<version>') {
            if (this._datahandler) this._datahandler.send_mxp_info();
            return;
        }
        if (tag.toLowerCase() === '<support>') {
            if (this._datahandler) this._datahandler.send_mxp_supports();
            return;
        }

        let match = tag.match (/send href="([^"]*)"/i);
        if (match == null) return;
        let cmd = match[1];
        match = tag.match(/" (prompt)\b/i);
        let isprompt = (match == null) ? undefined : match[1];
        match = tag.match(/hint="([^"]*)"/i);
        let hint = (match == null) ? undefined : match[1];
        match = tag.match (/>([^>]+)<\/send>/i);
        let text = (match == null) ? '' : match[1];
        match = tag.match (/<color ([^>]+)>/i);
        let color = (match == null) ? undefined : match[1];
        this._chunks.push(new LinkChunk(color, cmd, text, hint, isprompt, this._settings.reverted));
    }

    // parses a line - assumes a complete line (no buffering here)
    parse_line(line) {
        this._chunks = TextLine.apply_line_state(this._state);

        if (!this._state.bold) this._state.bold = 0;

        let buffer = '';
        // Alright, parse the line character by character and handle all the special cases
        while (line.length) {
            let ch = line.charAt(0);
            let c = line.charCodeAt(0);
            line = line.substr(1);
            if (c === 27) {
                if (buffer.length) {
                    this._handle_regular_text(buffer);
                    buffer = '';
                }
                // ANSI sequence
                let nums = [];
                let n = null;
                while (line.length) {
                    let ch = line.charAt(0);
                    line = line.substr(1);
                    if (ch === '[') continue;
                    if ((ch >= '0') && (ch <= '9'))
                        n = 10 * n + (ch.charCodeAt(0) - '0'.charCodeAt(0));
                    else if (ch === ';') {
                        if (n !== null) nums.push(n);
                        n = null;
                    }
                    else if (ch === 'm') {
                        if (n != null) nums.push(n);
                        for (var i = 0; i < nums.length; ++i)
                            this._handle_ansi_color(nums[i]);
                        break;
                    }
                    else if ((ch === 'z') && (n === 4)) {
                        // MXP TEMP SECURE tag -- IRE games only ever send these, we do not support the rest for now
                        let tagend = -1;
                        let endchar = '>';
                        if (line.substr(0, 1) === '<') {
                            if (line.substr(0, 5).toLowerCase() === '<send') {
                                tagend = line.search(/<\/send>/i);
                                if (tagend >= 0) tagend += 7;
                            }
                            else if (line.substr(0, 6).toLowerCase() === '<color') {
                                tagend = line.search(/<\/color>/i);
                                if (tagend >= 0) tagend += 8;
                            }
                            else if (line.substr(0, 8).toLowerCase() === '<support') {
                                tagend = line.search(/>/i);
                                if (tagend >= 0) tagend += 1;
                            }
                            else if (line.substr(0, 8).toLowerCase() === '<version') {
                                tagend = line.search(/>/i);
                                if (tagend >= 0) tagend += 1;
                            }

                        } else if (line.charCodeAt(0) === 3) {   // Rapture ecodes tags like this when they come in GMCP
                            endchar = '\x04';
                            if (line.substr(0, 5).toLowerCase() === '\x03send') {
                                tagend = line.search(/\x03\/send\x04/i);
                                if (tagend >= 0) tagend += 7;
                            }
                            else if (line.substr(0, 6).toLowerCase() === '\x03color') {
                                tagend = line.search(/\x03\/color\x04/i);
                                if (tagend >= 0) tagend += 8;
                            }
                        }
                        if (tagend >= 0) {
                            let tag = line.substr(0, tagend);
                            line = line.substr(tagend);
                            this._handle_mxp_tag(tag);
                        } else {
                            // we got some other tag, strip it out
                            tagend = line.search(endchar);
                            if (tagend >= 0) line = line.substr(tagend + 1);
                        }

                        break;
                    }
                    else
                        break;   // unsupported ANSI sequence, ignore it
                }
                continue;
            }
            // anything else - just copy it to the buffer
            buffer += ch;
        }
        if (buffer.length)
            this._handle_regular_text(buffer);

        return new TextLine (this._chunks, this.allow_mxp);
    }

    // text,fg,bg trios
    colorLine (params) {
        let chunk = [];
        let idx = 0;
        while (idx < params.length) {
            let text = params[idx];
            let fg = params[idx + 1];
            let bg = params[idx + 2];
            chunk.push(new ColorChunk(fg, bg));
            chunk.push(new TextChunk(text));
            idx += 3;
        }
        return new TextLine(chunk, this.allow_mxp);
    }

}
