
import { Reflex } from './reflex.js'

export class ReflexKeybind extends Reflex {
    constructor(name, nexus) {
        super(name, nexus);
        this.type = 'keybind';
        this.key = 0;
        this.key_alt = false;
        this.key_ctrl = false;
        this.key_shift = false;
        this.actions = [];
    }

    encode() {
        var res = super.encode();
        res.key = this.key;
        res.key_alt = this.key_alt;
        res.key_ctrl = this.key_ctrl;
        res.key_shift = this.key_shift;
        return res;
    }

    apply (e, reflexes) {
        if (e['type'] !== this.type) return;  // sanity check
        super.apply (e, reflexes);
        this.key = e.key;
        this.key_alt = e.key_alt;
        this.key_ctrl = e.key_ctrl;
        this.key_shift = e.key_shift;
    }
    
    duplicate() {
        var res = new ReflexKeybind(this.name, this._nexus);
        res.key = this.key;
        res.key_alt = this.key_alt;
        res.key_ctrl = this.key_ctrl;
        res.key_shift = this.key_shift;
        res.actions = this.copy_actions();
        return res;
    }

    do_matching(input) {
        let key = ReflexKeybind.get_plaintext_key (this.key);
        return super.do_matching(key, key, 'exact', true, true);
    }

    static find_binding(key, isShift, isCtrl, isAlt, reflexes) {
        var list = reflexes.get_active_list('keybind');
        for (var id = 0; id < list.length; ++id) {
            // do not replace these '==' with '===', the types can differ here
            if ((key == list[id].key) &&
                (Boolean(list[id].key_alt) == isAlt) && (Boolean(list[id].key_ctrl) == isCtrl) && (Boolean(list[id].key_shift) == isShift))
            {
                if (list[id].actions == null) continue;
                return list[id];
            }
        }
        return null;
    }

    static get_plaintext_key (code)
    {
        // Special Keys map gratefully borrowed from https://github.com/tzuryby/jquery.hotkeys/blob/master/jquery.hotkeys.js //
        var specialKeys = {
            8: "backspace", 9: "tab", 13: "return", 16: "shift", 17: "ctrl", 18: "alt", 19: "pause",
            20: "capslock", 27: "esc", 32: "space", 33: "pageup", 34: "pagedown", 35: "end", 36: "home",
            37: "left", 38: "up", 39: "right", 40: "down", 45: "insert", 46: "del",
            96: "0", 97: "1", 98: "2", 99: "3", 100: "4", 101: "5", 102: "6", 103: "7",
            104: "8", 105: "9", 106: "*", 107: "+", 109: "-", 110: ".", 111 : "/",
            112: "f1", 113: "f2", 114: "f3", 115: "f4", 116: "f5", 117: "f6", 118: "f7", 119: "f8",
            120: "f9", 121: "f10", 122: "f11", 123: "f12", 144: "numlock", 145: "scroll", 191: "/", 224: "meta"
        };

        if (specialKeys[code] !== undefined)
            return specialKeys[code];
        else
        {
            if (code < 32) return '???';
            return String.fromCharCode(code);
        }
    }


}



