
// this class holds information about custom buttons - it's passed to the react component for drawing

export class ButtonInfo {
    constructor(nexus) {
        this._nexus = nexus;
        this.count = 1;
        this._buttons = {};
        this._default_buttons = {};
        this.onchange = function(){};
    }

    set_defaults(list) {
        if (!list) return;
        this._default_buttons = list;
    }

    default_button(i) {
        i = this._button_num(i);
        if (i === undefined) return;
        let b = this._default_buttons[i];
        if (b === undefined)
            b = this._default_buttons['button' + i];
        if (b === undefined)
            return { text: '', commands: '', highlight: false, target_picker: true };

        var e = {};
        e.text = b.text;
        e.commands = b.commands;
        e.highlight = b.highlight;
        e.target_picker = true;   // use the target picker
        return e;
    }

    clear() {
        this.count = 1;
        this._buttons = {};
        this.onchange();
    }

    clear_button(id) {
        id = this._button_num(id);
        if (id === undefined) return;
        delete this._buttons[id];
        delete this._buttons['button'+id];
        this.onchange();
    }

    _button_num(id) {
        if (id.startsWith && id.startsWith('button'))
            id = id.substr(6);
        id = Number(id);
        if (isNaN(id)) return undefined;
        if ((id <= 0) || id > this.count) return undefined;
        return id;
    }

    get(id) {
        id = this._button_num(id);
        if (id === undefined) return undefined;

        let b = this._buttons[id];
        if (b === null) b = undefined;
        if (b !== undefined) return b;

        return this.default_button(id);
    }

    set_count(amount) {
        if (isNaN(amount)) amount = 4;
        if (amount < 4) amount = 4;
        if (amount > 12) amount = 12;
        this.count = amount;
        this.onchange();
    }

    set(id, cmds, script, text, target_picker) {
        id = this._button_num(id);
        if (id === undefined) return;

        var e = {};
        e.id = id;
        e.text = text;
        e.commands = cmds;
        e.script = script;
        e.target_picker = target_picker;
        e.highlight = false;
        this._buttons[id] = e;
        this.onchange();
    }

    text(id) {
        var text = 'F' + id;
        var b = this.get(id);
        if (b) {
            text += ': ' + b.text;
            if (this._nexus.platform().narrow_display()) text = b.text;  // on mobile, do not show Fx if we have some text, too. (because not much space)
        }
        return text;
    }

    highlight_button(id, highlight) {
        var b = this.get(id);
        if (!b) return;
        b.highlight = highlight;
        this._buttons[id] = b;
        this.onchange();
    }

    is_highlighted (id) {
        let b = this.get(id);
        if (!b) return false;
        // if it's the default button flagged as highlighted, return true
        if ((b.highlight === true) || (b.highlight === 1)) return true;
        // if it's not a default button, or not flagged as one, we need to check the default buttons
        // if we find one with the same command as ours, we follow its highlighted status
        let cmd = b.commands;
        if (!cmd) return false;
        cmd = cmd.toLowerCase();
        for (let i = 1; i <= this.count; ++i) {
            var btn = this.default_button(i);
            if (!btn) continue;
            let bcmd = btn.commands;
            if (!bcmd) continue;
            // if this default button contains the same command as this button, we highlight
            // this allows players to retain the highlight functionality, while also adding custom echoes or whatnot to their buttons
            if (bcmd.toLowerCase().indexOf(cmd) >= 0)
                return ((btn.highlight === true) || (btn.highlight === 1));
        }
        return false;
    }

    encode() {
        var res = {};
        res['list'] = this._buttons;
        res['count'] = this.count;
        return res;
    }
    
    apply(list) {
        this._buttons = list['list'];
        this.count = list['count'];
    }


}
