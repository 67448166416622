
// Gauges page

import React from 'react';

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import { ChromePicker } from 'react-color';

import { SettingsGauges_Base } from './gauges_base'

export class SettingsGauges extends SettingsGauges_Base {
    constructor(props) {
        super(props);
        this.state = { sel: 0 };
    }

    openColor(name, open) {
        let st = {};
        st['color-' + name + '-open'] = open;
        this.setState(st);
    }

    renderColorSetting(id, name, value) {
        if (!this['ref-'+name]) this['ref-'+name] = React.createRef();
        let open = Boolean(this.state['color-' + name + '-open']);
        let picker = (<ChromePicker color={value} onChange={(color,e)=>{ this.setGaugeValue(id, name, color.hex); this.openColor(name, false); e.stopPropagation(); }} disableAlpha={true} />);
        let anchorOrigin={ vertical: 'bottom', horizontal: 'center' };
        let transformOrigin = { vertical: 'top', horizontal: 'center' };
        let popup = (<Popover style={{zIndex:6300}} key={name+'_popup'} open={open} anchorEl={this['ref-'+name].current} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} onClose={()=>this.openColor(name, false)}>{picker}</Popover>);
        let bstyle = { backgroundColor: value, width: '100px' };
        return (<Button key={name+'-color'} ref={this['ref-'+name]} style={bstyle} onClick={ ()=>{ this.openColor(name, !open); } }><span style={{mixBlendMode:'difference'}}>{name}</span>{popup}</Button>);
    }
    
    renderGauge(id, data) {
        let rows = [];

        let el = (<TextField key='gauges_name' label='Gauge name' value={data.name} onChange={(e)=>this.setGaugeValue(id, 'name', e.target.value)} />);
        rows.push(el);

        el = (<TextField key='gauges_desc' label='Description' value={data.desc} onChange={(e)=>this.setGaugeValue(id, 'desc', e.target.value)} />);
        rows.push(el);

        el = (<TextField key='gauges_curvar' label='Current variable' value={data.curvar} onChange={(e)=>this.setGaugeValue(id, 'curvar', e.target.value)} />);
        rows.push(el);

        el = (<TextField key='gauges_maxvar' label='Maximum variable' value={data.maxvar} onChange={(e)=>this.setGaugeValue(id, 'maxvar', e.target.value)} />);
        rows.push(el);

        el = (<TextField key='gauges_tooltip' label='Tooltip' value={data.tooltip} onChange={(e)=>this.setGaugeValue(id, 'tooltip', e.target.value)} />);
        rows.push(el);

        el = (<Switch key='gauges_percentage' checked={Boolean(data.percentage)} onChange={(ev) => this.setGaugeValue(id, 'percentage', ev.target.checked)} />);
        let label = (<span>Show as percentage</span>);
        el = (<FormControlLabel key='gauges_percentage_label' control={el} label={label} />);
        rows.push(el);

        el = (<Switch key='gauges_variable' checked={Boolean(data.is_variable)} onChange={(ev) => this.setGaugeValue(id, 'is_variable', ev.target.checked)} />);
        label = (<span>Fetch values from variables instead of GMCP</span>);
        el = (<FormControlLabel key='gauges_variable_label' control={el} label={label} />);
        rows.push(el);

        el = this.renderColorSetting(id, 'color', data.color);
        label = (<span>Bar color</span>);
        el = (<FormControlLabel key='gauges_color_label' control={el} label={label} />);
        rows.push(el);

        el = this.renderColorSetting(id, 'coloroff', data.coloroff);
        label = (<span>Background color</span>);
        el = (<FormControlLabel key='gauges_coloroff_label' control={el} label={label} />);
        rows.push(el);

        let btnDel = (<Button key="btn_del" style={{width:'150px'}} onClick={()=>this.onDelete(id)}>Remove Gauge</Button>);
        rows.push(btnDel);
        
        rows = (<div className='gauges_editor' style={{display:'flex', flexDirection:'column'}}>{rows}</div>);

        let text = 'Gauge #' + (id+1) + ': ' + data.name;

        let section = this.formSection (text, rows);
        return section;
    }

    renderList() {
        let gauges = this.props.gauges;
        let els = [];

        let glist = gauges.get_gauges();
        // and the actual gauge list
        for (let i = 0; i < glist.length; ++i) {
            let idx = i;
            let g = glist[i];
            let text = g.name;
            let style = {padding: '3px'};
            let el = (<ListItem key={'gauge_'+i} style={style} button onClick={()=>this.onSelect(idx)} selected={this.state.sel === i}><ListItemText>{text}</ListItemText></ListItem>);
            els.push (el);
        }
        els = (<List key='gauges_list'>{els}</List>);
        let bstyle = {alignSelf:'center', width: '150px', marginTop: '10px'};
        let btnAdd = (<Button key="btn_add" style={bstyle} onClick={()=>this.onAdd()}>Add Gauge</Button>);
        let btnDefaults = (<Button key="btn_defaults" style={bstyle} onClick={()=>this.onDefaults()}>Reset to Defaults</Button>);

        let buttons = (<div style={{display:'flex', flexDirection: 'column', marginTop: '30px'}}>{btnAdd}{btnDefaults}</div>);
        els = (<div>{els}{buttons}</div>);
        return els;
    }

    render() {
        let left = this.renderList();

        let gauges = this.props.gauges;
        let glist = gauges.get_gauges();
        let id = this.state.sel;
        let data = glist[id];
        let right = data ? this.renderGauge(id, data) : (<div>&nbsp;</div>);
        
        let res = (<div style={{display:'flex', width: '100%'}}><div style={{width: '250px', marginRight:'30px'}}>{left}</div><div style={{flexGrow:1}}>{right}</div></div>);
        return res;
    }
}

