import { Tab } from './tab.js'
import { Util } from '../../base/util.js'

import React from 'react'

import { Buttons } from '../buttons'


export class TabButtons extends Tab {
    constructor(props) {
        super(props);
        this.state = {};
    }

    id() {
        return 'buttons';
    }

    render() {
        let nex = this.props.nexus;
        let gmcp = this.props.gmcp;
        let buttons = this.props.buttons;
        let res = (<Buttons gmcp={gmcp} buttons={this.props.buttons} nexus={nex} dh={nex.datahandler()} oncommand={this.props.oncommand} onscript={this.props.onscript} onnotice={this.props.onnotice} vertical={true} />);
        return res;
    }

}
 
