// The API

import { Util } from './base/util.js'

export var NexusAPI = {

send_command:
    function(input, no_expansion) {
        NexusAPI.nexus.send_commands(input, no_expansion);
    },

display_notice:
    function(...params) {
        NexusAPI.nexus.display_notice.apply(NexusAPI.nexus, params);
    },

display_html_line:
    function(text, channel=null) {
        NexusAPI.nexus.add_html_line(text, channel);
    },

get_variable:
    function(name) {
        return NexusAPI.nexus.variables().get(name);
    },

set_variable:
    function(name, val) {
        NexusAPI.nexus.variables().set(name, val);
    },

delete_variable:
    function(name) {
        NexusAPI.nexus.variables().del(name);
    },

inc_variable:
    function(name, by) {
        NexusAPI.nexus.variables().inc(name, by);
    },

dec_variable:
    function(name, by) {
        NexusAPI.nexus.variables().dec(name, by);
    },

mul_variable:
    function(name, by) {
        NexusAPI.nexus.variables().mul(name, by);
    },

div_variable:
    function(name, by) {
        NexusAPI.nexus.variables().div(name, by);
    },

send_GMCP:
    function send_GMCP(message_type, message) {
        NexusAPI.nexus.datahandler().send_GMCP(message_type, message);
    },

run_function:
    function run_function (name, args, pkg) { 
        NexusAPI.nexus.reflexes().run_function (name, args, pkg);
    },

// similar to parseFloat, but anything not numeric is represented as 0
to_number:
    function (val) {
        return Util.to_number(val);
    },

// Returns a reflex matching the specified parameters.
// type: 'alias', 'trigger', 'keybind', ...
// name: the name
// case_sensitive: whether the name is case sensitive
// enabled_only: only search anomgst currently enabled reflexes
// pkg: package name to search in; omit if searching in the base one
reflex_find_by_name:
    function(type, name, case_sensitive, enabled_only, pkgname) {
        return NexusAPI.nexus.reflexes().find_by_name(type, name, case_sensitive, enabled_only, pkgname);
    },


reflex_enable:
    function(el) {
        return NexusAPI.nexus.reflexes().enable_reflex(el);
    },

reflex_disable:
    function(el) {
        return NexusAPI.nexus.reflexes().disable_reflex(el);
    },

// text that fired this trigger, unformatted
current_text:
    function() {
        if (!NexusAPI.nexus.current_line) return '';
        return NexusAPI.nexus.current_line.parsed_line.text();
    },

gag_current_line:
    function() {
        if (!NexusAPI.nexus.current_line) return;
        NexusAPI.nexus.current_line.gag = true;
    },

colorize_current_line:
    function(start, len, fgcolor, bgcolor) {
        if (!NexusAPI.nexus.current_actions) return;
        var text = NexusAPI.nexus.current_actions.text;
        if (!text) return;
        if (bgcolor) bgcolor = NexusAPI.nexus.settings().convert_bgcolor(bgcolor);
        text.colorize(start, start + len, fgcolor, bgcolor);
    },

replace_current_line:
    function(start, len, newtext, fgcolor, bgcolor) {
        if (!NexusAPI.nexus.current_actions) return;
        var text = NexusAPI.nexus.current_actions.text;
        if (!text) return;
        if (bgcolor) bgcolor = NexusAPI.nexus.settings().convert_bgcolor(bgcolor);
        text.replace(start, start + len, newtext, fgcolor, bgcolor);
    },

// desktop notification
notification_show:
    function(heading, text) {
        NexusAPI.nexus.ui().notifications().notify(heading, text);
    },

buttons_set_label:
    function(id, text) {
        var b = NexusAPI.nexus.ui().buttons().get (id);
        if (!b) return;
        NexusAPI.nexus.ui().buttons().set (id, b.commands, b.script, text, b.target_picker);
    },

buttons_set_commands:
    function(id, commands) {
        var b = NexusAPI.nexus.ui().buttons().get (id);
        if (!b) return;
        NexusAPI.nexus.ui().buttons().set (id, commands, b.script, b.text, b.target_picker);
    },

buttons_set_highlight:
    function(id, highlight) {
        NexusAPI.nexus.ui().buttons().highlight_button (id, highlight);
    },

buttons_set_default:
    function(id) {
        NexusAPI.nexus.ui().buttons().clear_button (id);
    },

gmcp:
    function() {
        return NexusAPI.nexus.datahandler().GMCP;
    },

open_channel:
    function(name) {
        NexusAPI.nexus.ui().layout().open_channel(name, name, name);
    },

close_channel:
    function(name) {
        NexusAPI.nexus.ui().layout().close_channel(name);
    },
    
current_layout:
    function() {
        return NexusAPI.nexus.ui().layout().encode();
    },
    
set_current_layout:
    function(l) {
        NexusAPI.nexus.ui().layout().apply(l);
        NexusAPI.nexus.ui().redraw();
    },

register_custom_tab:
    function(name, component=null) {
        NexusAPI.nexus.ui().layout().register_custom_tab(name, component);
    },

set_custom_tab_html:
    function(name, html) {
        NexusAPI.nexus.ui().layout().set_custom_tab_html(name, html);
    },

append_custom_tab_html:
    function(name, html) {
        NexusAPI.nexus.ui().layout().append_custom_tab_html(name, html);
    }


};

// compatibility alias
NexusAPI.send_direct = NexusAPI.send_command;
NexusAPI.print = NexusAPI.display_notice;
