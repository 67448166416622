
// this class holds information about custom gauges - it's passed to the react component for drawing

// name, desc, curvar, maxvar, percentage, is_variable, color, coloroff, tooltip

export class GaugeInfo {
    constructor(nexus) {
        this._nexus = nexus;
        this._gauges = undefined;
        this.onchange = function(){};
    }

    reset_to_defaults() {
        this._gauges = undefined;
    }

    copy_defaults(trigger_event = true) {
        if (this._gauges !== undefined) return;
        let gi = this._nexus.gameinfo();
        this._gauges = JSON.parse(JSON.stringify(gi.gauges()));  // deepcopy
        if (trigger_event) this.onchange();
    }

    get_gauges() {
        if (this._gauges !== undefined) return this._gauges;
        let gi = this._nexus.gameinfo();
        return gi.gauges();
    }
    
    get(id) {
        if (id < 0) return;
        let glist = this.get_gauges();
        if (id >= glist.length) return;
        return {...glist[id]};   // shallow copy
    }

    add(name, desc, curvar, maxvar, percentage, is_variable, color, coloroff, tooltip)
    {
        this.copy_defaults(false);
        let e = { 'name' : name, 'desc' : desc, 'curvar' : curvar, 'maxvar' : maxvar, 'percentage' : percentage, 'is_variable' : is_variable, 'color' : color, 'coloroff' : coloroff, 'tooltip' : tooltip };
        this._gauges.push(e);
        this.onchange();
        return this._gauges.length - 1;
    }

    change(id, name, desc, curvar, maxvar, percentage, is_variable, color, coloroff, tooltip) {
        let lst = this.get_gauges();
        if (id < 0) return;
        if (id >= lst.length) return;
        this.copy_defaults(false);

        let e = { 'name' : name, 'desc' : desc, 'curvar' : curvar, 'maxvar' : maxvar, 'percentage' : percentage, 'is_variable' : is_variable, 'color' : color, 'coloroff' : coloroff, 'tooltip' : tooltip };
        this._gauges[id] = e;
        this.onchange();
    }

    remove(id) {
        let lst = this.get_gauges();
        if (id < 0) return;
        if (id >= lst.length) return;
        this.copy_defaults(false);

        this._gauges.splice(id, 1);
        this.onchange();
    }


    encode() {
        var res = {};
        res['list'] = this._gauges;
        return res;
    }
    
    apply(list) {
        this._gauges = list['list'];
    }


}
