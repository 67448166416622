
// settings dialog - we want this non-modal, and so we use Popper instead of Dialog

import { SettingsBaseDialog } from './settingsbase.js'

import { useTheme } from '@mui/material';
import React from 'react';
import Popper from '@mui/material/Popper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import CancelIcon from '@mui/icons-material/Cancel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NexusDraggable } from '../nexusdraggable';
import NewWindow from '../lib/NewWindow';

const styles = (theme) => ({
    root: {
        [theme.breakpoints.up("xs")]: {
            minWidth: '40px'
        }
    }
});

export class RealSettingsDialog extends SettingsBaseDialog {
    constructor(props) {
        super(props);
        this.extrasRef = React.createRef();
    }

    renderContent() {
        let opentab = this.state.opentab;
        if (opentab >= this.tabs.length) opentab = this.tabs.length - 1;

        if (opentab >= 0) {
            let tabid = this.tabs[opentab];
            let btn_back = (<div style={{display:'flex', flexDirection: 'column', height: '40px', marginBottom:'5px', position: 'relative'}}><Button key="btn_close" style={{alignSelf:'center', width: '150px'}} onClick={()=>this.onBack()}>Back</Button><div style={{position: 'absolute', right: 0, top: 0}} ref={this.extrasRef}></div></div>);
            let content = this.renderTab (tabid, this.extrasRef);
            content = (<div style={{display:'flex', flexDirection: 'column', width: '100%', height: 'calc(100% - 45px)'}}>{content}</div>);
            content = (<div style={{display:'flex', flexDirection: 'column', width: '100%', height: '100%'}}>{btn_back}{content}</div>);
            return content;
        }

        let btns = [];
        let btnstyle = {width: '100%', marginTop: '3px', marginBottom: '3px'};
        for (let idx = 0; idx < this.tabs.length; ++idx) {
            let tabidx = idx;
            let tabid = this.tabs[idx];
            let label = this.tabName(tabid);
            let btn = (<Button style={btnstyle} onClick={()=>this.handleTabChange(tabidx)}>{label}</Button>);
            btns.push (<div key={'btn_settings_'+tabid}>{btn}</div>);
        }
        let btn_close = (<Button style={btnstyle} onClick={()=>this.props.onCancel()}>{'Exit Settings'}</Button>);
        btns.push (btn_close);
        return (<div style={{display:'flex', flexDirection: 'column', width: '100%'}}>{btns}</div>);
    }

    toggleWindow() {
        let w = this.state.newwindow ? false : true;
        this.setState({newwindow: w});
    }

    render() {
        let nex = this.props.nexus;
        let theme = this.props.theme;

        let opentab = this.state.opentab;

        let closeButton = (<IconButton aria-label="close" size='small' onClick={() => this.props.onCancel()} ><CancelIcon /></IconButton>);
        let content = this.renderContent();
        if (this.props.modal && (opentab === -1)) {
            let cstyle1 = {display:'flex', flexDirection:'row', flex: 1, width: '25%', alignSelf: 'center' };
            content = (<div style={cstyle1}>{content}</div>);
        }
        let cstyle2 = { display: 'flex', flexDirection: 'column', margin: '10px', height: 'calc(100% - 20px)' };
        content = (<div className='settingsContent nexusDialogContent' style={cstyle2}>{content}</div>);
        
        let tabid = this.tabs[opentab];
        let title = (opentab >= 0) ? this.tabName(tabid) : 'Character Settings';

        if (this.props.modal) {
            let backdropStyle={backgroundSize: 'cover', backgroundImage: 'url("/images/login/login-background.jpg")'};
            let actions = null;
            let btn_save = (<Button key="btn_save" onClick={()=>this.onSave()}>Save</Button>);
            let btn_close = (<Button key="btn_close" onClick={()=>this.props.onCancel()}>Save and Close</Button>);
            let btn_back = (<Button key="btn_back" onClick={()=>this.onBack()}>Back</Button>);
            if (opentab < 0) actions = (<DialogActions>{btn_save}{btn_close}</DialogActions>);
            else actions = (<DialogActions>{btn_save}{btn_back}</DialogActions>);
            return (
                <Dialog
                    className='settings_dialog settingsDialog'
                    fullScreen
                    ref={this.props.settingsRef}
                    open={this.props.open}
                    aria-labelledby="settings-dialog"
                    PaperProps={{style: {backgroundColor: 'rgba(0,0,0,0.4)'} }}
                    disableEscapeKeyDown={true}
                    BackdropProps={{style:backdropStyle}}>
                <DialogTitle id="settings-dialog">{title}</DialogTitle>
                <DialogContent>{content}</DialogContent>
                {actions}
                </Dialog>
            );
        } else
        {
            let wh = nex.platform().window_height();
            let ww = nex.platform().window_width();
            let dh = Math.floor (wh * 4 / 5);
            let dw = Math.floor (ww * 4 / 5);
            let w = ((opentab === -1) ? dw / 2 : dw)+'px';
            let h = (opentab === -1) ? 'auto' : dh+'px';
            if ((opentab == -1) && (dh > 4)) dh = 4;
            let fontsize = this.props.nexus.settings().calc_fontsize();
            let style = {backgroundColor: theme.palette.background.default, width: w, height: h, position:'relative', fontSize:fontsize};

            let window_icon = this.state.newwindow ? 'window-maximize' : 'window-restore';
            let windowButton = (<FontAwesomeIcon aria-label="window" style={{marginRight:'5px'}} color={'white'} onClick={()=>this.toggleWindow()} icon={['fad', window_icon]} />);
            let cstyle = { position:'absolute', top:0, right:0, display: 'inline-block' };
            let buttons = (<div style={cstyle}>{windowButton}{closeButton}</div>);

            if (this.state.newwindow) {
                style.width = '100%';
                style.height = '100%';
            }
            content = (<div style={style} ref={this.props.settingsRef} className='settingsDialog nexusDialog'><div className='handle nexusDialogHeader settingsHeader'>{title}{buttons}</div>{content}</div>);
            if (this.state.newwindow) {
                content = (<NewWindow title='Nexus Settings' features={{width:dw, height:h, menubar:0, toolbar:0, status:0, chrome:0}} onUnload={()=>this.setState({newwindow:false})} closeOnUnmount={true} >{content}</NewWindow>);
                return content;
            } else {
                content = (<NexusDraggable platform={nex.platform()} handle='.handle' position={{x: 'center', y: 'center'}}>{content}</NexusDraggable>);
                let dlg = (<Popper aria-labelledby="settings-dialog" open={this.state.open} placement='top-end' >{content}</Popper>);
                return dlg;
            }
        }
    }
}

export function SettingsDialog(props) {
  const theme = useTheme();
  return <RealSettingsDialog {...props} theme={theme} />;
}
