
import { Reflex } from './reflex.js'
import { ActionList } from './actions.js'

export class ReflexTrigger extends Reflex {
    constructor(name, nexus) {
        super(name, nexus);
        this.type = 'trigger';
        this.text = '';
        this.matching = 'substring';
        this.whole_words = true;
        this.case_sensitive = true;
        this.actions = [];
    }

    encode() {
        var res = super.encode();
        res.text = this.text;
        res.matching = this.matching;
        res.whole_words = this.whole_words;
        res.case_sensitive = this.case_sensitive;
        return res;
    }

    apply (e, reflexes) {
        if (e['type'] !== this.type) return;  // sanity check
        super.apply (e, reflexes);
        this.text = e.text;
        this.matching = e.matching;
        this.whole_words = e.whole_words;
        this.case_sensitive = e.case_sensitive;
    }

    duplicate() {
        var res = new ReflexTrigger(this.name, this._nexus);
        res.text = this.text;
        res.matching = this.matching;
        res.whole_words = this.whole_words;
        res.case_sensitive = this.case_sensitive;
        res.actions = this.copy_actions();
        return res;
    }

    matches(txt) {
        if ((!txt) || (txt.length === 0)) return true;

        txt = txt.toLowerCase();

        if (this.text && this.text.toLowerCase().indexOf(txt) >= 0) return true;

        return super.matches(txt);
    }

    do_matching(input) {
        return super.do_matching(input, this.text, this.matching, this.whole_words, this.case_sensitive);
    }


    static benchmark_triggers(nexus) {
        let t1 = performance.now();
        // generate a list of triggers
        let trigs = [];
        let trigcount = 2000;
        for (let i = 1; i <= trigcount; ++i) {
            let tr = nexus.reflexes().create('trigger', 't'+i);
            tr.matching = 'regexp';
            let block1 = Math.random().toString(36).slice(-5);
            let block2 = Math.random().toString(36).slice(-5);
            let block3 = Math.random().toString(36).slice(-5);
            let block4 = Math.random().toString(36).slice(-5);
            tr.text = 'text1('+block1+')['+block2+']+text2'+block3+'('+block4+')*';
            trigs.push(tr);
        }
        let t2 = performance.now();
        nexus.display_notice('Creation took '+(t2 - t1) + 'ms');
        // and run them on random strings
        t1 = t2;
        for (let i = 1; i <= 100000; ++i) {
            let txt = 'text1';
            txt += Math.random().toString(36).slice(-5);
            txt += Math.random().toString(36).slice(-5);
            txt += Math.random().toString(36).slice(-5);
            txt += Math.random().toString(36).slice(-5);
            for (let j = 0; j <= trigcount.length; ++j) {
                trigs[j].do_matching(txt);
            }
        }
        t2 = performance.now();
        nexus.display_notice('The benchmark took '+(t2 - t1) + 'ms');
    }

}



