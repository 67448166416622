
// Packages page

import React from 'react';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Util } from '../../base/util.js'

import { SettingsPackages_Base } from './packages_base'

export class SettingsPackages extends SettingsPackages_Base {
    constructor(props) {
        super(props);
        this.state = { filter: '', newpkg: '' };
    }

    renderDeleteQuestion(name) {
        return (<div><p>Do you really want to delete package <b>{Util.ucfirst(Util.escape_html(name))}</b>?</p><p><b>WARNING: This will delete all reflexes in the package!</b></p></div>);
    }
    
    renderPackage(pkg, idx) {
        let tips = this.props.settings.tooltips_enabled;
        let elActive = (<Switch checked={pkg.enabled} onChange={(ev) => this.onActivate(idx, ev.target.checked)} />);
        if (tips) elActive = (<Tooltip title={pkg.enabled?'Enabled':'Disabled'} PopperProps={{disablePortal:true}}>{elActive}</Tooltip>);
        let btnDownload = (<span style={{cursor:'pointer', paddingLeft: '10px'}}><FontAwesomeIcon icon={['fad', 'download']} size={20} onClick={() => this.onDownload(idx)} /></span>);
        if (tips) btnDownload = (<Tooltip title='Download' PopperProps={{disablePortal:true}}>{btnDownload}</Tooltip>);
        let btnDelete = (<span style={{cursor:'pointer', paddingLeft: '10px'}}><FontAwesomeIcon icon={['fad', 'trash-can']} size={20} onClick={() => this.onDelete(idx)} /></span>);
        if (tips) btnDelete = (<Tooltip title='Delete' PopperProps={{disablePortal:true}}>{btnDelete}</Tooltip>);
        let btnUp = (<span style={{cursor:'pointer'}}><FontAwesomeIcon icon={['fad', 'angle-up']} size={20} onClick={() => this.movePackageUp(idx)} /></span>);
        if (tips) btnUp = (<Tooltip title='Move up' PopperProps={{disablePortal:true}}>{btnUp}</Tooltip>);
        let btnDown = (<span style={{cursor:'pointer', paddingLeft: '10px'}}><FontAwesomeIcon icon={['fad', 'angle-down']} size={20} onClick={() => this.movePackageDown(idx)} /></span>);
        if (tips) btnDown = (<Tooltip title='Move down' PopperProps={{disablePortal:true}}>{btnDown}</Tooltip>);
        let elName = (<TextField style={{width:'100%'}} value={pkg.name} onChange={(e)=>this.packageChange(idx, 'name', e.target.value)} label='Package Name' />);
        let elDesc = (<TextField style={{width:'100%'}} value={pkg.description} onChange={(e)=>this.packageChange(idx, 'desc', e.target.value)} label='Description' />);

        let style = {};
        if (this.state.filter && (!pkg.name.includes(this.state.filter))) style.display='none';
        let cells = [];
        cells.push ((<TableCell key={'cell_enabled_'+idx}>{elActive}</TableCell>));
        cells.push ((<TableCell key={'cell_name_'+idx}>{elName}</TableCell>));
        cells.push ((<TableCell key={'cell_desc_'+idx}>{elDesc}</TableCell>));
        cells.push ((<TableCell key={'cell_actions_'+idx} style={{textAlign:'right', whiteSpace:'nowrap'}}>{btnUp}{btnDown}{btnDownload}{btnDelete}</TableCell>));
        return (<TableRow style={style} key={'package-'+idx}>{cells}</TableRow>);
    }

    renderPackages() {
        let packages = this.props.packages;
        // take all the packages and place them into the list
        let pkgs = packages.list();
        let pkg_els = [];

        for (var p = 0; p < pkgs.length; ++p) {
            let pkg = packages.get_idx (p);
            if (pkg) pkg_els.push (this.renderPackage(pkg, p));
        }

        let pkgheader = (<TableHead><TableRow><TableCell style={{width:'8%'}}>Active</TableCell><TableCell style={{width:'20%'}} >Name</TableCell><TableCell style={{width:'64%'}}>Description</TableCell><TableCell style={{width:'8%'}}></TableCell></TableRow></TableHead>);
        let pkgtable = (<TableContainer component={Paper}><Table size="small" aria-label="packages">{pkgheader}<TableBody>{pkg_els}</TableBody></Table></TableContainer>);

        return pkgtable;
    }

    renderPackageLoader() {
        let platform = this.props.platform;
        let uploader = platform.setup_uploader((name, data) => {
                this.try_import_reflex_package(data);
            }, (error) => {
                window.alert('We are sorry, but something went wrong while trying to load the file. ' + error);
            }
        );
        let res = (<div>Select or drag the exported package file:<br />{uploader}</div>);
        return res;
    }

    render() {
        let res = [];

        let header = (<p key='header'>Reflex packages can be used to easily group your various settings, and optionally share them with other players.</p>);
        let newpkg = (<div key='newpkg'><TextField key='add-package' value={this.state.newpkg} onChange={(e)=>this.setState({newpkg:e.target.value})} label='Package Name' />&nbsp;<Button onClick={()=>this.newPackage(this.state.newpkg)} style={{verticalAlign:'bottom'}}>Add Package</Button></div>);
        let pkg_els = this.renderPackages();
        let section = this.formSection('Reflex Packages', [ header, newpkg, pkg_els ], 'reflex_pkgs');
        res.push (section);

        header = (<p key='import_header'>You can also import a previously exported package. The package will be added as a new one, it will not overwrite any existng ones.</p>);
        let loader = this.renderPackageLoader();
        section = this.formSection('Import Reflex Package', [ header, loader ], 'pkgs_import');
        res.push (section);

        return res;
    }
}

