
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { CharactersBaseDialog } from './charactersbase'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Util } from '../../base/util.js'


export class CharactersDialog extends CharactersBaseDialog {
    constructor(props) {
        super(props);
        this.state.newemail = '';        
        this.state.newpass = '';
        this.state.newpass2 = '';
    }

    onFirstLoad() {
        this.selectInitialGame();
    }

    socialIcon(key, url) {
        let icon = '/images/login/' + key + '.png';
        return (<a href={url} key={key} target='_blank' rel='noopener noreferrer' style={{display: 'inline-block', padding: '10px', verticalAlign: 'top'}}><img src={icon} alt='' /></a>);
    }

    renderGameLogo() {
        let nex = this.props.nexus;
        let gi = nex.gameinfo();
        let url = gi.files_url();
        let server = gi.root_url();

        let logo;
        if (gi.is_ire_game()) {
            logo = (<img src={url + '/images/logo.png?v=2'} alt='' height={250} />);
            if (server) logo = (<a href={server} rel='noopener noreferrer' target='_blank'>{logo}</a>);
        } else {
            let name = gi.game_short();
            let server = gi.server + ':' + gi.port;
            if (!gi.port) server = '';
            logo = (<div style={{position:'absolute',width:'100%',bottom:0,left:0,textAlign:'center'}}><div key='name' className='character_list_gameinfo_name'>{name}</div><div key='server' className='character_list_gameinfo_server'>{server}</div></div>);
        }
        logo = (<div style={{height:'250px', width:'100%', position:'relative', textAlign: 'center'}}>{logo}</div>);
        return logo;
    }

    renderCharList() {
        if (this.state.loading) return 'Loading ...';
        if (this.state.loadError) return this.state.loadError.toString();
        let chars = this.state.chars;

        let nex = this.props.nexus;
        let gid = nex.active_game_id();
        let gi = nex.gameinfo();

        let elRightButton = null;
        let elBottomButton = null;
        let buttonClass = 'charactersButton '+gi.game_name()+'Button';
        if (gid >= 0) buttonClass = 'greenButton';
        if (gi.is_ire_game())
            elRightButton = (<div key='btn_create' style={{margin:10}}><Button key='btn_create' variant='contained' className={buttonClass} onClick={ ()=>this.props.oncreation() }>Create a New Character</Button></div>);
        if (nex.logged_in_nexus()) {
            elBottomButton = (<div key='btn_add' style={{margin:10}}><Button key='btn_add' variant='contained' className={buttonClass} onClick={ ()=>this.addChar() }>Add Existing Character</Button></div>);
        } else {
            elBottomButton = (<div key='btn_play' style={{margin:10}}><Button key='btn_add' variant='contained' style={{backgroundColor:'black'}} onClick={ ()=>this.handleGameLogin(0) }>Play an Existing Character</Button></div>);
        }
        let socials = this.renderSocialIcons();

        let cname = 'character_entry';
        let char_els = [];
        if (chars) {
            for (let idx = 0; idx < chars.length; ++idx) {
                let ch = chars[idx];
                if (ch.game !== gid) continue;  // only show the currently selected game
                let cid = ch.cid;
                let btn_play = (<Button key='btn_play' className={buttonClass} variant='contained' onClick={ ()=>this.handleGameLogin(cid) }>Play</Button>);
                let btn_settings = (<FontAwesomeIcon className='character_settings' icon={['far', 'gear']} aria-label="Settings" onClick={() => this.openSettings(cid)} />);
                btn_settings = (<Tooltip title={'Edit Settings'}><span>{btn_settings}</span></Tooltip>);
                let btn_delete = (<FontAwesomeIcon className='character_delete' icon={['far', 'trash-can']} aria-label="Remove" onClick={() => this.removeChar(cid)} />);
                btn_delete = (<Tooltip title={'Delete'}><span>{btn_delete}</span></Tooltip>);
                let btns = (<ListItemSecondaryAction>{btn_play}<span>&nbsp;&nbsp;</span>{btn_settings}{btn_delete}</ListItemSecondaryAction>);
                let second = '';
                if (ch.level) second = 'Level ' + ch.level;
                if (ch.class) second += ' ' + Util.ucfirst (ch.class);

                char_els.push ((<ListItem className={cname} button key={'char-'+ch.cid} onClick={ ()=>this.handleGameLogin(cid) }><ListItemText style={{color:'black'}} primary={Util.ucfirst(ch.name)} secondary={second} />{btns}</ListItem>));
            }
        }
        if (!char_els.length) {
            let btns = (<div style={{display:'flex', flexDirection:'row',justifyContent:'center'}}>{elRightButton}{elBottomButton}</div>);
            let blurb1 = this.gameBlurb(1);
            let blurb2 = this.gameBlurb(2);
            let blurb3 = this.gameBlurb(3);
            if (blurb1) blurb1 = (<div className='gameBlurb1'>{blurb1}</div>);
            if (blurb2) blurb2 = (<div className='gameBlurb2'>{blurb2}</div>);
            if (blurb3) blurb3 = (<div className={'gameBlurb3 '+gi.game_name()+'Blurb3'}>{blurb3}</div>);
            let blurbs = (<div className='gameBlurbs'>{blurb1}{blurb2}{blurb3}</div>);
            let explain = null;
            if (!nex.logged_in_nexus()) {
                let st = { cursor: 'pointer', fontWeight: 'bold' };
                let linkRegister = (<a key='btn_create' style={st} onClick={()=>this.props.onregister()}>{'Create an Account'}</a>);
                let linkLogin = (<a key='btn_login' style={st} onClick={()=>this.props.onlogin()}>{'Log into Nexus in'}</a>);
                explain = (<div style={{marginTop:'50px'}} className='gameNotLogged'>{'You are currently using Nexus as a guest. To same your character list, '}{linkLogin}{' or '}{linkRegister}{'.'}</div>);
            }
            let res = (<div className='character_list_empty'>{btns}{blurbs}{socials}{explain}</div>);
            return res;
        }

        let gname = gi.game_long();
        let titleTag = (<div className='character_list_title' >Your Characters on {gname}</div>);
        let header = (<div className='character_list_header' >{titleTag}{elRightButton}</div>);
        let extras = (<div className='character_list_footer'>{elBottomButton}</div>);
        let res = (<div className='character_list_entries' key='character_list_entries'>{header}<List key='characters_list'>{char_els}</List>{extras}</div>);
        res = (<div>{res}{socials}</div>);
        return res;
    }

    renderAccount() {
        let nex = this.props.nexus;
        let btnStyle = {marginTop:'10px'};

        let gid = nex.active_game_id();
        let gi = nex.gameinfo();

        let buttonClass = 'charactersButton '+gi.game_name()+'Button';
        if (gid >= 0) buttonClass = 'greenButton';

        // Character count
        let chars = this.state.chars;
        let charcount = chars ? chars.length : 0;
        let chars_line = (<div>{'Characters: '}<span style={{fontWeight:'bold'}}>{charcount}</span></div>);
        
        // E-mail + ability to change it
        let email = nex.nexus_email();
        let email_field = (<TextField key='email' label='New e-mail' value={this.state.newemail} onChange={(e)=>{ this.setState({newemail: e.target.value}) } } />);
        let email_button = (<Button key='btn_email' variant='contained' style={btnStyle} className={buttonClass} onClick={ ()=>this.props.onacctchange(this.state.newemail, null, null) }>Change e-mail</Button>);
        let email_line = (<div>{'Account e-mail: '}<span style={{fontWeight:'bold'}}>{email}</span></div>);
        let email_change = (<div><div>{email_field}</div><div>{email_button}</div></div>);

        // Ability to set a new password
        let pass_field = (<TextField key='pwd' label='New password' type='password' value={this.state.newpass} onChange={(e)=>{ this.setState({newpass: e.target.value}) } } />);
        let pass2_field = (<TextField key='pwd' label='Confirm password' type='password' value={this.state.newpass2} onChange={(e)=>{ this.setState({newpass2: e.target.value}) } } />);
        let pass_button = (<Button key='btn_pwd' variant='contained' style={btnStyle} className={buttonClass} onClick={ ()=>this.props.onacctchange(null, this.state.newpass, this.state.newpass2) }>Change password</Button>);
        let pass_change = (<div><div>{pass_field}</div><div>{pass2_field}</div><div>{pass_button}</div></div>);

        // Ability to delete the account
        let delete_button = (<Button key='btn_del' variant='contained' style={btnStyle} className={buttonClass} onClick={ ()=>this.props.onacctremove() }>Delete Account</Button>);
        let delete_msg1 = 'Click below if you would like to delete your Nexus account. This will not delete your actual characters, but all the Nexus settings and reflexes associated with your characters WILL BE LOST.';
        let delete_msg2 = 'You will be prompted to enter your current Nexus password.';
        let acct_delete = (<div><div>{delete_msg1}</div><div>{delete_button}</div><div style={{marginTop:'10px'}}>{delete_msg2}</div></div>);

        let btn_back = (<Button key='btn_back1' variant='contained' style={btnStyle} className={buttonClass} onClick={ ()=>this.onCloseAccount() }>Back to Character List</Button>);
        let btn_back2 = (<Button key='btn_back2' variant='contained' style={btnStyle} className={buttonClass} onClick={ ()=>this.onCloseAccount() }>Back to Character List</Button>);

        let main = (<div className='account_info' style={{textAlign:'center'}}>{chars_line}{email_line}{btn_back}<hr /><h4>Change Account E-mail</h4>{email_change}<hr /><h4>Change Account Password</h4>{pass_change}<hr /><h4>Delete Nexus Account</h4>{acct_delete}<hr /><div>{btn_back2}</div></div>);
        return main;
    }

    renderSocialIcons() {
        let nex = this.props.nexus;
        let gi = nex.gameinfo();
        let icons = [];
        if (gi.discord_url) icons.push (this.socialIcon ('discord', gi.discord_url()));
        if (gi.facebook_url) icons.push (this.socialIcon ('facebook', gi.facebook_url()));
        if (gi.twitter_url) icons.push (this.socialIcon ('x', gi.twitter_url()));
            
        let socials = (<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20px' }}>{icons}</div>);
        return socials;
    }
    
    renderContent() {
        let games = this.renderGameSelector();
        let lst, list_header;
        
        if (this.state.showAccount) {
            lst = this.renderAccount();
            list_header = (<h4>Account Information</h4>);
        } else {
            lst =  this.renderCharList();
            let logo = this.renderGameLogo();
            lst = (<div style={{position:'relative'}}>{logo}{lst}</div>);
            list_header = null;
        }

        let games_el = (<div id='character_list_games' className='character_list_games'>{games}</div>);
        let right = (<div id={'character_list_right'} className='character_list_right'>{games_el}{list_header}{lst}</div>);
        let main = (<div className='character_list'>{right}</div>);
        return main;
    }

    renderBottomBar() {
        let nex = this.props.nexus;
        let version = 'Version: ' + nex.client_version();
        return (<div className='characters_bottom'>{version}</div>);
    }

    renderHeader()
    {
        let nex = this.props.nexus;
        let buttons = [];
        let style = {color:'black', backgroundColor: '#d7d7d7', marginRight:'10px', fontSize:'0.6em'};
        if (nex.logged_in_nexus()) {
            let logged = (<span className='account_name'>{this.loggedInText()}</span>);
            buttons.push(logged);
            buttons.push ((<Button key='btn_account' variant='contained' style={style} onClick={()=>this.onAccount()}>{this.state.showAccount ? 'Characters' : 'Account'}</Button>));
            buttons.push ((<Button key='btn_logout' variant='contained' style={style} onClick={()=>this.props.onlogout()}>{'Logout'}</Button>));
        } else {
            buttons.push ((<Button key='btn_create' variant='contained' style={style} onClick={()=>this.props.onregister()}>{'Create a Nexus account'}</Button>));
            buttons.push ((<Button key='btn_login' variant='contained' style={style} onClick={()=>this.props.onlogin()}>{'Sign in'}</Button>));
        }

        let res = (<div style={{display:'flex', flexDirection:'row'}}><span style={{flex:1}}>{'NEXUS'}</span><span>{buttons}</span></div>);
        return res;
    }

    render() {
        if (this.state.settingsdlg) return this.renderSettings();

        let nex = this.props.nexus;

        let maincontent = this.renderContent();
        let bottom = this.renderBottomBar();
        let content = (<div key="characters_wrapper" style={{display:'flex',flexDirection:'column',height:'100%'}}>{maincontent}{bottom}</div>);

        let background = nex.ui().layout().backgroundImage();
        let backdropStyle={backgroundSize: 'auto 100%', backgroundColor: 'black', backgroundRepeat: 'no-repeat', backgroundPosition: 'top right', backgroundImage: 'url("'+background+'")'};

        let header = this.renderHeader();

        return (
            <Dialog
                fullScreen
                open={this.props.open}
                className='characters_dialog'
                PaperProps={{style: {overflow:'visible', minHeight:'500px', backgroundColor: 'rgba(0,0,0,0.2)'} } }
                aria-labelledby="characters-dialog"
                disableEscapeKeyDown={true}
                BackdropProps={{style:backdropStyle, className:'characters-backdrop'} } >
            <DialogTitle id="character-dialog-title">{header}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            </Dialog>
        );
    }
}
