
// A platform-independent class that holds most of the client logic, as well as all the objects, settings and the like.

import { Util } from './base/util.js'
import { ClientSettings } from './base/settings.js'
import { LineParser } from './base/line.js'
import { ServerAPI } from './base/serverapi.js'
import { ReflexAlias } from './reflexes/aliases.js'
import { ReflexEvent } from './reflexes/events.js'
import { ReflexFunction } from './reflexes/functions.js'
import { ReflexPackages } from './reflexes/packages.js'
import { Reflexes } from './reflexes/reflexes.js'
// import { ReflexTrigger } from './reflexes/triggers.js'
import { Variables } from './reflexes/variables.js'
import { DataHandler } from './telnet/datahandler.js'
import { Interface } from './ui/interface.js'
import { Passwords } from './base/passwords'
import { Log } from './ui/log'
import { NexusPlatform } from './ui/platform'
import { NexusAPI } from './api.js'

// games
import { GameInfoAchaea } from './games/achaea/gameinfo.js'
import { GameInfoAetolia } from './games/aetolia/gameinfo.js'
import { GameInfoImperian } from './games/imperian/gameinfo.js'
import { GameInfoLusternia } from './games/lusternia/gameinfo.js'
import { GameInfoStarmourn } from './games/starmourn/gameinfo.js'
import { GameInfoAchaeaTest } from './games/achaeatest/gameinfo.js'
import { GameInfoGeneric } from './games/generic/gameinfo.js'

// base64 - needed for react-native; browsers have this, but easier to just use the same implementation on all platforms
import { Base64 } from 'js-base64';

var pako = require('pako');

export class NexusClient {
    constructor(startup) {
        this.startup = startup;
        this.version = startup.version;
        this.allowtest = startup.allowtest;   // this must be on the top (before set_current_game)
        this._api = startup.api;    // this is the Nexus server API, not the client scripting API
        this._gameinfo = null;
        this._gameid = 0;
        this.set_current_game (0, null, false);
        this.charname = '';
        this.last_charname = '';

        this.gagged = true;
        this.fullstop = false;
        this.command_counter = 0;
        this.current_system = null;
        this.settingsId = 0;
        this.logged_in = false;
        
        // these are for user scripts
        this.current_line = undefined;
        this.current_block = undefined;

        this.set_default_settings();
    }

    set_current_game(gameID, info, remember) {
        let gi = this.game_settings (gameID, info);
        if (!gi) return false;
        this.set_game_info (gi, gameID);
        this.clear_buffers();
        // Let's remember the setting too
        if (remember) this.platform().set_local_setting('IRE.Game', gameID);
        return true;
    }

    set_game_info(gameinfo, gameID) {
        if (this.datahandler().is_connected()) return;  // nothing if we're currently connected

        this._gameid = gameID;
        this._gameinfo = gameinfo;
        this.settings().set_gameinfo(gameinfo);
        this.set_default_settings();
        this.platform().handle_gameinfo_changed();
        this.ui().layout().game_changed();
    }

    // *** OBJECT DECLARATIONS START HERE ***

    gameinfo() {
        return this._gameinfo;
    }

    active_game_id() {
        return this._gameid;
    }

    settings() {
        if (!this._settings) this._settings = new ClientSettings (this.gameinfo());
        return this._settings;
    }

    ui() {
        if (!this._ui) this._ui = new Interface(this);
        return this._ui;
    }

    platform() {
        if (!this._platform) this._platform = new NexusPlatform(this);
        return this._platform;
    }

    passwords() {
        if (!this._passwords) this._passwords = new Passwords(this);
        return this._passwords;
    }

    log() {
        if (!this._log) {
            this._log = new Log(this);
        }
        return this._log;
    }

    variables() {
        if (!this._variables) {
            let handler = (varname)=>this.on_var_changed(varname);
            this._variables = new Variables(handler);
        }
        return this._variables;
    }

    datahandler() {
        if (!this._datahandler) {
            var t = this;
            var h = new DataHandler(this);
            this._datahandler = h;
            
            // callbacks
            h.on_block = function(block) { t.process_lines (block); };
            h.on_rawdata = function(s){ if (t.settings().debug_log_raw === true) t.platform().log_raw(s); };
            h.on_connected = function(){ t.handle_connected(); };
            h.on_disconnected = function(){ t.handle_disconnected(); };
            h.on_gmcp_feature = function(feature, data) { t.handle_gmcp_feature (feature, data); };
            h.on_gmcp_updated = function() { t.ui().layout().gmcp_updated(); };
            h.on_special_display = function(type, lines, params) {
                if (type === 'ohmap')
                    t.ui().layout().set_overhead_map(lines);
                if (type === 'help')
                    t.ui().layout().help_window(lines);
                if (type === 'window')
                    t.ui().layout().command_window(lines, params['cmd']);
            };

        }
        return this._datahandler;
    }

    reflexes() {
        let t = this;
        if (!this._reflexes) {
            this._reflexes = new Reflexes(this);
            this._reflexes.set_packages (this.packages());
            this.packages().set_reflexes (this._reflexes);
            this._reflexes.onchange = function()
            {
                t.ui().layout().update_settings();
            }
        }
        return this._reflexes;
    }

    packages() {
        var t = this;
        if (!this._packages) {
            this._packages = new ReflexPackages(this);
            this._packages.onenabled = function(pkg)
            {
                t.reflexes().run_function("onLoad", undefined, pkg.name);
            }
            this._packages.ondisabled = function(pkg)
            {
                t.reflexes().run_function("onUnload", undefined, pkg.name);
            }

        }
        return this._packages;
    }

    serverapi() {
        if (!this._serverapi)
        {
            this._serverapi = new ServerAPI(this);
        }
        return this._serverapi;
    }

    // *** OBJECT DECLARATIONS END HERE ***

    client_name() {
        return "IRE-Nexus";
    }
    
    client_version() {
        return this.version;
    }

    gameIDs(includeLegacy=true) {
        let res = [ -1, -2 ];
        if (includeLegacy) res.push(-3);
        if (includeLegacy) res.push(-4);
        if (includeLegacy) res.push(-5);
        if (this.allowtest) res.push(-15);
        return res;
    }

    legacyGameIDs() {
        let res = [ -3, -4, -5 ];
        return res;
    }

    game_settings(gameID, info) {
        if (!this.__gamesettings) {
            this.__gamesettings = [];
            this.__gamesettings[-1] = new GameInfoAchaea();
            this.__gamesettings[-2] = new GameInfoAetolia();
            this.__gamesettings[-3] = new GameInfoImperian();
            this.__gamesettings[-4] = new GameInfoLusternia();
            this.__gamesettings[-5] = new GameInfoStarmourn();
            if (this.allowtest) this.__gamesettings[-15] = new GameInfoAchaeaTest();
        }

        // Override these even if they exist, in case the player updated them.
        if ((gameID > 0) && info) {
            this.__gamesettings[gameID] = new GameInfoGeneric (info);
        }        
        if (this.__gamesettings[gameID]) return this.__gamesettings[gameID];

        return new GameInfoGeneric(null);
    }

    logged_in_nexus() {
        let api = this.serverapi();
        return api.aid() ? true : false;
    }

    // If IRE.Game is set, they started Nexus previously. Same if they're logged in.
    new_player() {
        let t = this;
        if (t.logged_in_nexus()) return false;
        let existing = t.platform().local_setting('IRE.Game');
        if (existing) return false;
        return true;
    }

    nexus_email() {
        let api = this.serverapi();
        if (!api.aid()) return null;
        return api.email();
    }

    setup_autosave() {
        let t = this;
        if (t._autosave) return;
        t._autosave = t.platform().set_interval(function() {
            if (!t.settings().autosave) return;
            let sys = t.encode_system(false);
            if (!t.system_changed(sys)) return;
            
            // The system is not compressed yet, so let's do that.
            let csys = t.compress_system (sys);
            // Save it. Once that succeeds, set the current system to that.
            // We can't do that earlier, as we want to try again in case the saving fails.
            t.save_system(csys, ()=>{ t.current_system = sys; });
        }, 90 * 1000);
    }

    setup() {
        let t = this;
        t.set_default_settings();

        t.ui().setup();
        t.platform().setup();

        t.setup_command_counter();

        t.ui().show_intro();

        // Did we remember the name/password? If so, let's try to log in.
        let pwds = t.passwords();
        pwds.fetch(null, null, 'name').then((name) => {
            if (!name) return;
            pwds.fetch(null, null, 'password').then((pass) => {
                t.ui().layout().on_login(name, pass, false);
            }).catch(()=>{});
        }).catch(()=>{});
    }

    store_login(name, pass) {
        let pwds = this.passwords();
        pwds.store(null, null, 'name', name);
        pwds.store(null, null, 'password', pass);
    }

    store_game_password(gid, name, pass) {
        let pwds = this.passwords();
        pwds.store(gid, name, 'password', pass);
    }
    
    clear_game_password(gid, name) {
        let pwds = this.passwords();
        pwds.clear(gid, name, 'password');
    }

    // This returns a promise.
    get_game_password(gid, name) {
        let pwds = this.passwords();
        return pwds.fetch(gid, name, 'password');
    }

    set_default_settings()
    {
        var s = this.settings();
        var gi = this.gameinfo();
        
        s.reset();

        var style = this.platform().local_setting("IRE.Style");
        if (style && style.length) s.css_style = style;
        else if (gi) s.css_style = gi.css_style();
        else s.css_style = 'standard';

        this.variables().clear();
        this.reflexes().clear();
        this.packages().clear();
        this.ui().buttons().clear();
        this.ui().buttons().set_count(gi ? gi.button_count() : 6);

        this.ui().gauges().reset_to_defaults();

        this.packages().create_default_reflex_packages();
        ReflexFunction.create_default_functions (this);

        // so we can override defaults on individual games
        // the check needs to be there, as we call this function before everything is loaded
        if (gi && gi.update_default_settings) gi.update_default_settings(s);
    }

    on_activated() {
        this.ui().on_activated();
    }
    
    on_deactivated() {
        this.ui().on_deactivated();
    }

    // Shared code between create_char and login.
    _prepare_login(char_name, password, load_settings, remember) {
        let t = this;
        let gi = t.gameinfo();

        if (char_name != null) char_name = char_name.trim();
        if ((!char_name) || (!char_name.length)) return false;
        if (gi.is_ire_game() && ((!password) || (!password.length))) return false;
        t.charname = char_name;
        t.current_game_id = this.active_game_id();
        // If we are using guest mode, we'll need to retain this password to load/save settings. Otherwise we won't need it.
        t.load_settings = load_settings;
        // If we aren't logged into nexus, we need the password to auth against the API. The password will be removed after logging in.
        t.password = t.logged_in_nexus() ? null : password;

        if (remember)
            t.store_game_password(t.current_game_id, char_name, password);
        else
            // If they unchecked the remember box, delete the stored password, if any.
            t.clear_game_password(t.current_game_id, char_name);

        let gname = gi.game_short();
        this.platform().set_title (Util.ucfirst (char_name) + " - "  + Util.ucfirst (gname));
        
        return true;
    }

    create_char(char_name, password, gameID, data) {
        let t = this;
        if (!t._prepare_login(char_name, password, false, true)) return;

        data.client = this.startup.adsource;
        this.datahandler().on_gmcp_enabled = (datahandler) => {
            this.gagged = false;
            this.datahandler().send_GMCP("IRE.Misc.CharCreate", data);
        };

        t.connect_to_game();
        t.ui().close_dialogs();
    }

    login(char_name, password, load_settings, remember)
    {
        let t = this;
        if (!t._prepare_login(char_name, password, load_settings, remember)) return;

        let login = {"name" : char_name, "password" : password};

        let gi = t.gameinfo();
        t.datahandler().on_gmcp_enabled = function (datahandler) {
            if (!gi.is_ire_game()) return;

            t.platform().set_timeout(function () {
                t.datahandler().send_GMCP("Char.Login", login);
            }, 500);
        };

        t.connect_to_game(false);
        t.ui().close_dialogs();
    }


    connect_to_game() {
        this.ui().close_dialogs();

        this.datahandler().connect();
        this.display_notice('Connecting ...');
    }

    clear_buffers() {
        this.ui().buffer().clear();
        this.ui().comm_buffer().clear();
        this.ui().layout().close_channels();
    }

    handle_connected() {
        let t = this;
        
        let gi = t.gameinfo();
        // If this is not an IRE game, it will now need to connect to the real game.
        if (gi.is_ire_game()) {
            t.display_notice('Connected to the game.');
        } else {
            t.display_notice('Connected to the Iron Realms server, attempting connection to the game.');
            t.datahandler().send_command('connect ' + gi.server + ' ' + gi.port);
        }
        this.platform().set_local_setting('IRE.Game', this._gameid);

        t.set_default_settings();  // this also clears reflexes and such
        // We set the current system to the cleared one - this is so that we don't try to save something before the real system is loaded.
        t.current_system = t.encode_system();

        // Load the system if requested, but only if we're using a Nexus account. If we are not, we'll load after the name/password is accepted,
        // so that we don't load someone else's settings. We approve the password for registered accounts when adding the char, so it's not a concern there.
        if (t.load_settings && t.logged_in_nexus()) t.load_system();

        // clean scrollback if we are not relogging with the same character
        if ((!t.last_charname.length) || (t.charname !== t.last_charname)) {
            t.clear_buffers();
        }

        if (!gi.is_ire_game()) t.gagged = false;
        t.last_charname = t.charname;
        this.logged_in = false;
        t.platform().focus_input();

        let vol = t.platform().get_volume();
        t.ui().sounds().set_volume(Number(vol));
        // start music in 5 seconds
        // if settings load before that, music will start when they do as needed
        t.platform().set_timeout(function() {
            if (!t.datahandler().is_connected()) return;
            t.ui().sounds().start_music (t.gameinfo().intro_sound());
        }, 5000);
    }
    
    handle_disconnected() {
        var t = this;
        let platform = this.platform();

        if (t._autosave) {
            platform.clear_interval (t._autosave);
            // Save their settings on disconnect, but only if loading went through correctly.
            // Loading goes through correctly for new profiles (it just returns an empty system), so this should be safe to do.
            t.save_system();
        }
        
        this._autosave = null;
        platform.leave_fullscreen();
        this.current_system = null;
        this.gagged = true;
        this.logged_in = false;
        this.remember_password = false;
        this.ui().sounds().stop_music();
        this.ui().close_dialogs();
        t.serverapi().on_disconnect();
        t.password = null;

        // This disables logging and downloads the log.
        if (t.log().active())
            t.ui().statusbar_feature('logging-stop');

        if (t.old_loader) {  // don't try to load old settings anymore
            platform.clear_interval(t.old_loader);
            t.old_loader = undefined;
        }

        let reason = this.datahandler().disconnect_reason;
        if (this.datahandler().connect_error) {
            t.display_notice('Connection failed');
            platform.alert('Connection failed', 'Unable to connect to the game. Please check your network configuration, or try again later.', function() { t.ui().show_characters(); });
            delete this.datahandler().connect_error;
            if (!platform.is_active()) t.ui().show_characters();
            return;
        }
        t.display_notice('Connection closed.');
        t.set_default_settings();

        let onDownload = () => t.ui().statusbar_feature('logging-download');
        t.ui().layout().show_disconnect_screen(t.charname, reason, onDownload);
//        t.ui().show_characters();
    }

    on_var_changed(varname) {
        let t = this;
        if (!t.settings_dlg) return;
        // Don't trigger this very often.
        if (t.variableUpdater) return;
        t.variableUpdater = this.platform().set_timeout(function() {
            delete t.variableUpdater;
            if (!t.datahandler().is_connected()) return;
            t.settings_dlg.setState({varchange:1});   // this updates the variable list
        }, 200);        
    }

    // load/save

    compress_system(sys)
    {
        sys = unescape(encodeURIComponent(sys));
        if (sys.length > 100000)   // big system files get compressed
            sys = 'CHROMUD-INFLATE:' + Util.arrayToString(pako.deflate(sys));
        return Base64.btoa(sys);
    }
    
    decompress_system(data) {
        data = data.replace(/\s/g, '');  // remove whitespace, atob chokes on it
        var sys = Base64.atob(data);
        if (sys.substr(0, 16) === 'CHROMUD-INFLATE:') {  // compressed?
            sys = sys.substr(16);
            sys = Util.stringToArray(sys);
            sys = pako.inflate(sys, { to: 'string' });
        }

        return decodeURIComponent(escape(sys));
    }

    encode_system(compress=true) {
        var sys = {};
        sys.settings = this.settings().encode();
        sys.variables = this.variables().encode();
        sys.reflexes = this.reflexes().encode();
        sys.packages = this.packages().encode();
        sys.buttons = this.ui().buttons().encode();
        sys.gauges = this.ui().gauges().encode();
        sys.layout = this.ui().layout().encode();

        sys = JSON.stringify (sys);
        if (compress) sys = this.compress_system (sys);
        return sys;
    };

    save_system(sys=null, on_completion=null)
    {
        let t = this;
        if (!sys) sys = t.encode_system();
        t.serverapi().save_system(t.charname, t.current_game_id, sys).then(() => {
            if (on_completion) on_completion();
        }).catch((err) => t.display_notice('Error saving system: ' + String(err)));
    }

    available_systems()
    {
        let t = this;
        return t.serverapi().system_available(t.charname, t.current_game_id);
    }

    system_changed(sys) {
        if (!this.datahandler().is_connected()) return false;
        if (sys == null) sys = this.encode_system();
        if (!this.current_system) return true;
        if (sys !== this.current_system) return true;
        return false;
    }

    // fetches the system(settings) and loads them up
    load_system(age=null, msg=false, onload=null) {
        let t = this;
        t.serverapi().load_system(t.charname, t.current_game_id, age).then((sys) => {

            // Once load succeeds, enable autosave.
            t.setup_autosave();

            if ((!sys) || (!sys.length)) {
                // No data exists? Try to load the old system - but only if it's the IRE game.
                let gi = this.gameinfo();
                if (t.datahandler().is_connected() && gi.is_ire_game()) t.load_old_system();
                if (onload) onload(sys);
                return;
            }
            if (t.import_system(sys)) {
                // show a message if requested
                if (msg) t.platform().alert('Settings', 'The settings have been loaded.');
                if (onload) onload(sys);
            }
        }).catch((err) => t.platform().alert('Error loading system', String(err)));
    }

    // loads the old-version system
    load_old_system() {
        let t = this;
        t.old_loader = t.platform().set_interval(() => {                
            if (!t.logged_in) return;
            t.datahandler().request_old_system();
            t.platform().clear_interval(t.old_loader);
            t.old_loader = undefined;
        }, 500);
        // Here we need to wait until we actually connect and log in.
    }

    import_system(system, decompress=true) {
        let sys;
        let t = this;
        if (decompress) system = this.decompress_system(system);
        
        // Are these the old settings? Need to do it like this, as a player-supplied file could contain either type of settings.
        if (system.startsWith ('// +++++ GENERAL OPTIONS +++++')) {
            console.log('Old settings detected - converting.');
            sys = this.convert_old_settings(system);
        } else {
            try {
                sys = JSON.parse (system);
            } catch (e) {
                t.platform().alert('The settings seem to be corrupted.');
                return false;
            }
        }
        
        if (!sys.settings) {
            t.platform().alert('This does not look like a Nexus settings file. If you are trying to import a Nexus package, please use the Packages screen to do so.');
            return false;
        }

        if (sys.settings) t.settings().apply (sys.settings);
        if (sys.variables) t.variables().apply (sys.variables);
        if (sys.reflexes) t.reflexes().apply (sys.reflexes);
        if (sys.packages) t.packages().apply (sys.packages);
        if (sys.buttons) t.ui().buttons().apply (sys.buttons);
        if (sys.gauges) t.ui().gauges().apply (sys.gauges);
        if (sys.layout) t.ui().layout().apply (sys.layout);

        t.packages().create_default_reflex_packages();
        ReflexFunction.create_default_functions (t);

        t.reflexes().run_function("onLoad", undefined, 'ALL');
        t.current_system = t.encode_system();

        t.apply_settings();

        return true;
    }

    convert_old_settings(s) {
        // converts old settings to the new system
        // 's' is javascript code, so we need to execute that - it will fill in the 'client' variable
        let client = { load_all_reflex_packages: ()=>{}, reflexes_convert: ()=>{}, reflexes_fix_parents: ()=>{}, bottom_buttons_set_count: (num)=>{client.button_count=num;} };
        let fn = new Function('client', s);
        fn(client);

        let sys = {};
        // SETTINGS
        sys.settings = {};
        for (let idx = 0; idx < this.settings().settings_keys.length; ++idx) {
            let key = this.settings().settings_keys[idx];
            if (typeof client[key] != 'undefined')
                sys.settings[key] = client[key];
        }
        // some settings are reset
        sys.settings['scrollback_msg_limit'] = 1000;

        // VARIABLES
        sys.variables = client.variables;  // this should also load as-is ... hopefully

        // REFLEXES
        sys.reflexes = client.reflexes;  // this should load as-is ... hopefully
        if (sys.reflexes['name'] == 'MASTER') sys.reflexes['name'] = 'ROOT';

        // PACKAGES
        sys.packages = client.packages;  // this should also load as-is ... hopefully

        // BUTTONS
        sys.buttons = { list: client.buttons, count: client.button_count };

        return sys;
    }

    // notifies objects about changed settings
    apply_settings()
    {
        this.settingsId++;   // The current iteration ID of the settings. Used to determine whether the settings changed and we need to redraw everything.
        this.ui().apply_settings(this.settings());
        this.ui().sounds().update_music (this.gameinfo().intro_sound(), this.datahandler().is_connected());
        if (this.gameinfo().apply_game_settings) this.gameinfo().apply_game_settings();
    }

    
    setup_command_counter()
    {
        var t = this;
        if (t.command_counter_interval) t.platform().clear_interval(t.command_counter_interval);
        t.command_counter_interval = t.platform().set_interval(function() { t.command_counter -= 100; if (t.command_counter < 0) t.command_counter = 0; }, 1000);
    }

    send_commands(input, no_expansion)
    {
        if (typeof input == 'undefined')
            return false;

        var do_expansion = !no_expansion;

        if (typeof input != "string")
            input = input.toString();

        // for testing, comment out for production
/*
        if (input === 'nexbenchmark') {
            ReflexTrigger.benchmark_triggers(this);
            return;
        }
*/

        this.command_counter++;
        if (this.command_counter >= 200) {
            if (this.command_counter === 200)
                this.display_notice('You seem to have sent more than 200 commands within a second. You probably have some runaway trigger or an endless alias loop - disabling commands for a while.', '#FF8080');
            this.setup_command_counter();  // just in case -- had the interval disappear at one point
            return;
        }

        var real_cmds = [];
        // Do we want to expand? Empty enters are sent as-is.
        if (do_expansion && input.length) {
            var commands = [];
            var split_regex = new RegExp(Util.escapeRegExp(this.settings().stack_delimiter), 'gm');
            var parts = input.split(split_regex);

            // Delimiter split
            for (let i = 0; i < parts.length; ++i) {
                let cmd = parts[i];
                if (cmd === "") continue;
                var cmds = [];
                // Aliases
                cmds = ReflexAlias.handle_aliases(cmd, this);

                for (var j = 0; j < cmds.length; ++j)
                    commands.push(cmds[j]);
            }

            // Now process internal commands, expand variables and execute functions.
            for (let i = 0; i < commands.length; ++i) {
                let cmd = commands[i];

                if (cmd.indexOf("@set") === 0)
                {
                    var temp = cmd.split(/ /);
                    if (temp[1] !== "" && temp[2] !== "")
                    {
                        if (this.variables().set(temp[1], temp[2]))
                            this.display_notice("Set " + temp[1] + " to " + temp[2]);
                        continue;
                    }
                }

                cmd = this.variables().expand(cmd);

                if (!this.fullstop)
                {
                    cmd = this.reflexes().handle_functions(cmd);
                    if (!cmd) continue;
                }

                // This is a real command - add it to the queue
                real_cmds.push(cmd);
            }
        } else
            real_cmds.push(input);   // skip the cmds loop entirely if we don't expand anything

        if (!real_cmds.length) return;

        for (let i = 0; i < real_cmds.length; ++i) {
            var s = real_cmds[i];
            if (this.settings().echo_input)
                this.display_notice(s, this.settings().color_inputecho);
            this.datahandler().send_command(s);
        }
    }


    process_lines(lines) {
        if (this.gagged) return;
        // Nothing to do if there are no lines. Happens when we receive a GMCP message.
        if (!lines.length) return;

        this.current_block = lines;
        let reflexes = this.reflexes();

        for (var idx = 0; idx < lines.length; ++idx) {
            if (idx >= 1000) break;   // just in case we somehow hit an infinite loop (notifications mainly)

            // this is for custom functions/scripts
            this.current_line = lines[idx];

            if (lines[idx].line && (lines[idx].line.indexOf(String.fromCharCode(7)) >= 0))  // line contains the beep char
                this.platform().beep();
            
            if (!this.fullstop)
                lines = reflexes.handle_triggers(lines, idx);
        }

        reflexes.run_function("onBlock", lines, 'ALL');

        let block = this.get_displayed_block(lines);
        this.ui().buffer().add_block (block);

        this.current_line = undefined;
        this.current_block = undefined;
    }

    get_displayed_block(block) {
        let res = [];
        let count = 0;
        for (let idx = 0; idx < block.length; ++idx)
        {
            var l = block[idx];

            if (l.gag) continue;
            if ((!l.parsed_line) && (!l.html_line)) continue;
            // no prompt if we gagged everything (so far)
            if (l.is_prompt && (!count)) continue;
            // empty line? include if it's not the first/last one
            if (l.parsed_line) {
                let text = l.parsed_line.text();
                if ((!text) && ((count === 0) || (idx === block.length - 1))) continue;
            }

            res.push(l);
            count++;
        }
        return res;
    }

    // text,fg,bg triplets, ended with an optional channel param
    display_notice(...params)
    {
        let channel = null;
        let args = [];
        for (let idx = 0; idx < params.length; idx += 3) {
            if ((idx > 0) && (params.length < idx + 3)) {  // incomplete trio, not the first one
                channel = params[idx];
                break;
            }
            let text = params[idx];
            if (!text) text = '';
            let fg = params[idx + 1];
            let bg = params[idx + 2];
            if (fg === undefined) fg = this.settings().get_ansi_color(7, false);
            bg = this.settings().convert_bgcolor(bg);
            args.push (text.toString());
            args.push (fg);
            args.push (bg);
        }
        let parser = new LineParser (this.settings(), this.datahandler());
        var line = {};
        line.parsed_line = parser.colorLine(args);
        line.timestamp_ms = Util.get_time(true);
        line.timestamp = Util.get_time(false);
        line.no_triggers = true;

        this._add_line(line, channel);
    }

    add_html_line(html_line, channel=null) {
        let line = {};
        line.html_text = html_line;
        line.html_line = true;
        line.timestamp_ms = Util.get_time(true);
        line.timestamp = Util.get_time(false);

        this._add_line(line, channel);
    }

    _add_line(line, channel) {
        // if this is called inside a trigger, append the notice to the currently processed block
        // otherwise show it right away
        if (this.current_block) {
            let idx = this.current_block.length;
            if (this.current_line) idx = this.current_block.indexOf(this.current_line) + 1;
            this.current_block.splice(idx, 0, line);
        } else {
            let lines = [];
            lines.push(line);
            let buffer = null;
            if ((channel === 'all') || (channel === 'all_comm'))
                buffer = this.ui().comm_buffer();
            else if (channel)
                buffer = this.ui().layout().channel_buffer(channel);
            if (!buffer) buffer = this.ui().buffer();
            buffer.add_block (lines);
        }
    }

    api_funcs() {
        let res = [];
        for (let fn in NexusAPI) res.push(fn);
        return res;
    }

    exec_script(script, args, current_package, id) {
        try {
            // add the API to the scope
            let fn;
            let api = '';
            NexusAPI.nexus = this;
            NexusAPI.client = this;  // older version compatibility
            NexusAPI.GMCP = NexusAPI.gmcp();   // older version compatibility
            for (fn in NexusAPI) api += 'var '+fn+' = NexusAPI.'+fn+'; ';
            fn = new Function('args', 'current_package', 'NexusAPI', api + script);
            fn(args, current_package, NexusAPI);
        } catch(err)
        {
            let error = 'Error in ' + id;
            if (current_package) error += ' (package ' + current_package + ')';
            if (err.lineNumber) error += ' on line ' + err.lineNumber;
            error += ': ' + err;
            this.display_notice(error, 'red');
        }
    }

    handle_gmcp_feature(feature, data) {
        let t = this;
        if (feature === 'gmcp') {
            if (this.settings().echo_gmcp) {
                let msg = data.args;
                try {
                    msg = JSON.stringify(msg);
                } catch (e) {};
                this.display_notice('[GMCP]: ' + data.method + ' ' + msg, this.settings().color_gmcpecho);
            }
            this.reflexes().run_function("onGMCP", {"gmcp_method": data.method, "gmcp_args": data.args}, 'ALL');
            if (data.fire_event) ReflexEvent.handle_event('GMCP', data.method, data.event_param, this);
            return;
        }
        
        if (feature === 'gmcp-send') {
            if (this.settings().echo_gmcp) {
                let msg = data.message;
                try {
                    msg = JSON.stringify(msg);
                } catch (e) {};
                this.display_notice('[Sent GMCP]: ' + data.type + ' ' + msg, this.settings().color_gmcpecho);
            }
            return;
        }

        if (feature === 'charname') {
            this.logged_in = true;
            this.gagged = false;
            // If we're using a guest account, we load the settings at this point. See handle_connected for explanation.
            if (t.password && (!t.logged_in_nexus())) {
                // But first, we request an auth token so that we don't need to store the password any longer.
                t.serverapi().anon_login (t.current_game_id, t.charname, t.password).then( () => {
                    t.password = null;
                    if (t.load_settings) t.load_system();
                }).catch((err) => t.platform().alert('Error obtaining token', String(err)));
            }

            t.datahandler().send_GMCP('Char.Items.Room', '');
            t.datahandler().send_GMCP('Char.Items.Inv', '');
            return;
        }

        if (feature === 'file-content')
        {
            var file = data;
            if (file.name && file.name === "raw") {
                if (file.text !== "")
                    this.import_system(file.text);
            }
            return;
        }

        if (feature === 'input-command') {
            this.platform().set_input(data.command);
            if (data.highlight) this.platform().select_input();
            this.platform().focus_input();
            return;
        }        

        if (feature === 'channel-text') {
            this.ui().layout().write_channel(data.channel, data.line);
            this.ui().notifications().handle_channel_text(data.channel, data.line.text(), data.talker);
            return;
        }

        if (feature === 'target-changed') {
            var tg = data.target;
            var name = data.name;
            if (!name) name = tg;

            this.variables().set('tar', tg);
            this.ui().layout().gmcp_updated();

            if (name && name.length && this.settings().echo_target)
                this.display_notice('Targeting: ' + name, this.settings().color_targetecho);
            return;
        }

        if (feature === 'edit-text') {
            this.ui().layout().open_editor (data.text, data.title);
            return;
        }
        
        if (feature === 'sound-preload') {
            this.ui().sounds().preload_sound('library/' + data.name);
            return;
        }

        if (feature === 'sound-play') {
            this.ui().sounds().play_sound('library/' + data.name, data.fadein, data.fadeout);
            return;
        }
        
        if (feature === 'sound-stop') {
            if (data.name === 'ALL')
                this.ui().sounds().stop_all_sounds(data.fadeout);
            else
                this.ui().sounds().stop_sound(data.name, data.fadeout);
            return;
        }
        if (feature === 'popup') {
            t.current_popup = data.id;
            t.ui().layout().open_popup(data.id, data.commands, data.allownoshow, data.src, data.content);
            let opts = data.options;
            if (opts && opts.time) {
                let time = Util.to_number(opts.time);
                if (time) t.platform().set_timeout (function() {
                    if (t.current_popup !== data.id) return;  // another popup showed meanwhile, don't close that
                    t.current_popup = null;
                    t.ui().layout().hide_popup();
                }, time * 1000);
            }
            return;
        }
        if (feature === 'popup-hide') {
            this.current_popup = null;
            this.ui().layout().hide_popup();
            return;
        }
        if (feature === 'content-window') {
            this.ui().layout().create_content_window (data.id, data.name, data.type, data.content, data.width, data.height);
        }
        if (feature === 'content-window-close') {
            this.ui().layout().close_content_window (data.id);
        }
        if (feature === 'skill-info') {
            let info = data.info;
            if (info) info = info.split("\n");
            else info = [ 'You have not yet learned that ability.' ];
            this.ui().layout().text_window(data.group + ': ' + data.skill, info);
        }
        if (feature === 'news-article') {
            let txt = data.article;
            if (txt) txt = txt.split("\n");
            else txt = [ 'That article does not exist or has been lost.' ];
            this.ui().layout().text_window('News Article: ' + data.section + ' ' + data.id, txt);
        }

    }


};
