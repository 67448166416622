// the affdef panel

import { TabAffDef_Base } from './affdef_base'
import { Util } from '../../base/util.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from 'react'
import Tooltip from '@mui/material/Tooltip';

export class TabAffDef extends TabAffDef_Base {
    
    constructor(props) {
        super(props);
        this.state = { };

        this.ref_affsel = React.createRef();
        this.ref_defsel = React.createRef();
        this.ref_afflist = React.createRef();
        this.ref_deflist = React.createRef();
    }

    render_deflist() {
        let gmcp = this.props.gmcp;
        let defs = gmcp.Defences;
        
        let drawn_defs = {};
        let deflist = [];
        let cats = this.def_categories();
        for (let idx = 0; idx < cats.length; ++idx) {
            let cat = cats[idx];
            let dlist = this.shown_defs_in_category(cat);
            if (!dlist) continue;
            let catdeflist = [];
            for (let defname in dlist) {
                if (!dlist.hasOwnProperty(defname)) continue;
                catdeflist.push (this.draw_defence(defs, defname, dlist[defname]));
                drawn_defs[defname] = true;
            }
            deflist.push((<div key={'defcat-'+cat}>{catdeflist}</div>));
        }
        return { 'drawn' : drawn_defs, 'list' : deflist };
    }

    render() {
        let gmcp = this.props.gmcp;
        let affs = gmcp.Afflictions;
        let definfo = gmcp.DefencesList;
        let defs = gmcp.Defences;
        let tips = this.props.tooltips;

        let rendered = this.render_deflist();
        let drawn_defs = rendered['drawn'];
        let deflist = rendered['list'];

        let stats = gmcp.CharStats;
        let statlist = [];
        for (let i = 0; i < stats.length; ++i) {
            let line = stats[i];
            let pos = line.indexOf(': ');
            let entry = '';
            if (pos >= 0) entry = (<span><b>{line.substr(0, pos)}</b>{line.substr(pos)}</span>);
            else entry = line;
            statlist.push (<div>{entry}</div>);
        }

        let info = (<div id="tab_affdef_info"><p id="tab_affdef_charname">{gmcp.Status.name?gmcp.Status.name:''}</p><div id="tab_affdef_stats">{statlist}</div></div>);
        deflist = (<div id="tab_affdef_defs" className={'onlydefs'}>{deflist}</div>);
        let top = (<div id="tab_affdef_top_right">{deflist}{info}</div>);

        // affs and defs - list
        let list = [];
        for (let aff in affs) {
            if (!this.aff_shown(aff)) continue;
            let a = affs[aff];
            let tip = a.desc+((a.cure && a.cure.length) ? ' - ' + a.cure : '');
            let l = (<span>{a.name}</span>);
            if (tips) l = (<Tooltip title={tip}>{l}</Tooltip>);
            if (list.length) list.push (', ');
            list.push(l);
        }
        if (!list.length) list.push (('(no afflictions)'));
        let alist = (<div className='tab_affdef_list tab_affdef_list_aff' ref={this.ref_afflist} >&nbsp;{list}</div>);

        list = [];
        for (let def in defs) {
//            if (drawn_defs[def]) continue;    // already in the icon list
            let d = defs[def];
            let l = (<span>{d.name}</span>)
            if (tips) l = (<Tooltip title={d.desc}>{l}</Tooltip>)
            if (list.length) list.push (', ');
            list.push(l);
        }
        if (!list.length) list.push (('(no defences)'));
        let dlist = (<div className='tab_affdef_list tab_affdef_list_def' ref={this.ref_deflist}>&nbsp;{list}</div>);

        let affswitch = (<a id="tab_affdef_affswitch" className="tab_affdef_heading_sel" ref={this.ref_affsel} onClick={()=>this.toggle_affs()} >Afflictions</a>);
        let defswitch = (<a id="tab_affdef_defswitch" className="tab_affdef_heading_sel" ref={this.ref_defsel} onClick={()=>this.toggle_defs()} >Defences</a>);
        affswitch = (<div className="tab_affdef_heading">{affswitch}</div>);
        defswitch = (<div className="tab_affdef_heading">{defswitch}</div>);
        let res = (<div id="tab_affdef_main" className="affdef_normal">{top}{affswitch}{alist}{defswitch}{dlist}</div>);
        return res;
    }

    toggle_affs() {
        let el = this.ref_affsel.current;
        let lst = this.ref_afflist.current;
        if (el.classList.contains('tab_affdef_heading_sel')) {
            el.classList.remove('tab_affdef_heading_sel');
            lst.style.display = 'none';
        } else {
            el.classList.add('tab_affdef_heading_sel');
            lst.style.display = 'block';
        }        
    }
    
    toggle_defs() {
        let el = this.ref_defsel.current;
        let lst = this.ref_deflist.current;
        if (el.classList.contains('tab_affdef_heading_sel')) {
            el.classList.remove('tab_affdef_heading_sel');
            lst.style.display = 'none';
        } else {
            el.classList.add('tab_affdef_heading_sel');
            lst.style.display = 'block';
        }        
    }

    draw_defence(defs, name, definfo)
    {
        let defentry = defs[name];
        let active = defentry ? true : false;

        let desc = name;
        if (definfo.desc && definfo.desc.length) {
            if (definfo.desc.toLowerCase().substr(0, desc.length) != desc.toLowerCase())
                desc += ' - ' + definfo.desc;
            else
                desc = definfo.desc;
        }
        let title = Util.ucfirst(desc);
        
        let icon = definfo.icon;
        let color = definfo.color;
        let color2 = definfo.color2;
        if (!color) color = 'green';
        let st = { 'color' : color, '--fa-primary-color' : color };
        if (color2) {
            st['--fa-secondary-color'] = color2;
            st['--fa-secondary-opacity'] = 1.0;
        }
        if (!active) { color = '#505050'; st = { 'color' : color }; }
        let res = (<FontAwesomeIcon key={'def-'+name} color={color} style={st} icon={['fad', icon]} />);
        if (title) res = (<Tooltip key={'def-'+name} title={title}><span>{res}</span></Tooltip>);
        return res;
    }


}


