
// TODO need to figure out dragging, too

import { TabItemList_Base } from './itemlist_base'

import React from 'react'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class TabItemList extends TabItemList_Base {
    constructor(props) {
        super(props);
        // type is 'room' or 'inv'
        this.ref = React.createRef();
        this.roomdescRef = React.createRef();
        this.expandRef = React.createRef();
        this.descLines = 2;
    }

    componentDidUpdate() {
        let elDesc = this.roomdescRef.current;
        let elLink = this.expandRef.current;
        if (!elLink) return;
        // If we have more lines than this.descLines, we're in the expanded state and the collapse link needs to be shown.
        let style = window.getComputedStyle (elDesc);
        let lineHeight = parseInt(style.getPropertyValue('line-height'));
        let expanded = (Math.floor(elDesc.scrollHeight / lineHeight) > this.descLines);
        // Seems scrollHeight can be 1px more, so adding 5px as a reserve
        if (expanded || (elDesc.scrollHeight > elDesc.clientHeight + 5)) elLink.style.display = 'block';
        else elLink.style.display = 'none';
    }

    render_buttons(item, loc) {
        let buttons = [];
        let button_defs = this.item_buttons (item, loc);
        for (let idx = 0; idx < button_defs.length; ++idx) {
            let b = button_defs[idx];
            buttons.push ((<ListItem button key={b.command} onClick={() => this.entry_click (item, loc, b.command)}><ListItemText>{b.text}</ListItemText></ListItem>));
        }
        if (buttons.length) {
            let fontsize = this.props.settings.calc_fontsize();
            let pkey = 'menuitem' + ((loc === 'players') ? item.name : item.id);
            let popper = (<Popper style={{zIndex:2000,fontSize:fontsize}} open={true} anchorEl={() => this.ref.current} key={pkey} ><Paper style={{border: 'solid #333 3px', borderRadius: '6px'}}><List dense disablePadding={true} key="items-buttons" aria-label={this.props.title}>{buttons}</List></Paper></Popper>);
            return popper;
        }
        return null;
    }

    render_icon (iconname, color, color2) {
        if (!iconname) return null;
        let st = { fontSize:'1.5em', paddingRight:'5px' };
        if (color && color.length) {
            st['color'] = color;
            st['--fa-primary-color'] = color;
        }
        if (color2) {
            st['--fa-secondary-color'] = color2;
            st['--fa-secondary-opacity'] = 1.0;
        }

        let icon = (<span><FontAwesomeIcon icon={['fas', iconname]} style={st} /></span>);
        return icon;
    }

    render_entry(item, loc, depth=0) {
        let attr = item.attrib;
        if (attr == null) attr = "";
        let icon = item.icon;
        let color = item.color;
        let color2 = item.color2;
        let iid = parseInt(item.id);

        let container = ((depth === 0) && (attr.indexOf ('c') >= 0)); // container at top depth
        let selected = (this.state.selection === item.id);
        let key = 'item' + item.id;
        let icon_el = this.render_icon (icon, color, color2);
        let textStyle = {};
        if (this.is_current_target(item.id, false))
            textStyle.color = 'yellow';
        let eid = null;
        if (this.props.settings.show_item_vnums) eid = (<span className="item_vnum">{item.id}</span>);
        let etext = (<ListItemText primaryTypographyProps={{ style: textStyle }}>{item.name} {eid}</ListItemText>);
        let container_icon = null;
        if (container) {
            let icon_name = (this.state.expanded[iid]) ? 'square-minus' : 'square-plus';
            container_icon = (<FontAwesomeIcon icon={['fad', icon_name]} id={'container_'+item.id} onClick={(e)=>this.toggleExpand(e,iid)} style={{height:'16px', width:'16px', paddingRight:'5px'}} />);
        }
        let style = { paddingLeft: (15+depth*15)+'px' };
        let entry = (<ListItem style={style} key={key} dense button ref={selected ? this.ref : null} onClick={() => this.setSelection(item, loc)}>{container_icon}{icon_el}{etext}</ListItem>);

        let res = [ entry ];
        
        // buttons?
        if (selected) {
            let buttons = this.render_buttons (item, loc);
            if (buttons) res.push (buttons);
        }

        if (container && item.items && item.items.length && this.state.expanded[iid])  // items inside?
        {
            let items = item.items;
            for (let idx = 0; idx < items.length; ++idx)
            {
                let ires = this.render_entry(items[idx], 'rep'+item.id, depth + 1);
                if (ires && ires.length)
                    for (let idx2 = 0; idx2 < ires.length; ++idx2)
                        res.push (ires[idx2]);
            }
        }

        return res;
    }

    render_player_entry(player) {
        let pname = player.name;
        let selected = (this.state.selection === 'player-' + pname);
        let key = 'player-' + pname;
        let textStyle = {};
        if (this.is_current_target(pname, true))
            textStyle.color = 'yellow';
        let etext = (<ListItemText primaryTypographyProps={{ style: textStyle }}>{player.fullname}</ListItemText>);
        let entry = (<ListItem key={key} dense button ref={selected ? this.ref : null} onClick={() => this.setSelection(player, 'players')}>{etext}</ListItem>);

        let res = [ entry ];
        
        if (selected) {
            let buttons = this.render_buttons (player, 'players');
            if (buttons) res.push (buttons);
        }

        return res;
    }

    render() {
        let items = this.props.gmcp.Items[this.props.type];
        let entries = {};
        let slist = this.sections();
        for (let idx = 0; idx < slist.length; ++idx)
            entries[slist[idx]] = [];

        this.items = {};
        for (let idx = 0; idx < items.length; ++idx) {
            let item = items[idx];
            this.items[item.id] = true;
            let loc = this.props.datahandler.item_section (this.props.type, item.attrib);
            let entryset = this.render_entry (item, loc);
            for (let idx2 = 0; idx2 < entryset.length; ++idx2)
                entries[loc].push (entryset[idx2]);
        }
        
        // player list?
        if (slist.includes ('players')) {
            let players = this.props.gmcp.RoomPlayers;
            for (let idx = 0; idx < players.length; ++idx) {
                let entryset = this.render_player_entry (players[idx]);
                for (let idx2 = 0; idx2 < entryset.length; ++idx2)
                    entries['players'].push (entryset[idx2]);
            }
        }

        let content = [];
        for (let idx = 0; idx < slist.length; ++idx)
        {
            let loc = slist[idx];
            let heading = (<ListSubheader key={'section-'+loc} disableSticky={true}>{this.section_desc(loc)}</ListSubheader>);
            content.push (heading);
            for (let idx2 = 0; idx2 < entries[loc].length; ++idx2)
                content.push (entries[loc][idx2]);
        }

        let roomdesc = '';
        if (this.props.type === 'room') {
            let nametext = this.props.gmcp.Location.roomname;
            let rname = (<span className="s_room_name" onClick={() => this.toggleDesc()}>{nametext}</span>);
            let rdesc = null, moreless = null;

            if (this.props.settings.show_rdescs) {
                moreless = 'Hide';
                let desctext = this.props.gmcp.Location.desc;
                let style = { lineHeight: '1.2em' };
                if (!this.state.desc) {
                    style['height'] = (1.2 * this.descLines) + 'em';
                    style['overflow'] = 'hidden';
                    moreless = 'Expand';
                }

                rdesc = (<div className='rdesc' key="rdesc" ref={this.roomdescRef} style={style}>{desctext}</div>);
                moreless = (<a ref={this.expandRef} href='#' style={{fontSize:'0.8em'}} onClick={() => this.toggleDesc()}>{moreless}</a>);
                if (!desctext) moreless = null;
            }

            roomdesc = (<div className="room_name">{rname}{rdesc}{moreless}</div>);
        }

        return (
            <Box style={this.props.style}>
                {roomdesc}
                <List component="nav" dense disablePadding={true} key={'items-'+this.props.type} aria-label={this.props.title}>
                    {content}
                </List>
            </Box>
        );
    }


}
