
import { Tab } from './tab.js'
import { Util } from '../../base/util.js'
import { MapData, SingleRoomData } from './mapdata.js'

import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faSwords, faColumns, faChessRook, faBuilding, faPeoplePantsSimple, faStore, faBrush, faSpaceShuttle, faFort, faAnchor, faHouseTurret, faFileSignature, faDog, faBooks, faElevator, faNewspaper, faGalaxy, faPortalEnter, faMailbox, faIndustry, faTools, faStoreAlt, faHorse, faTransporter1, faHouse, faWarehouse, faTrees } from '@fortawesome/pro-solid-svg-icons'


import MouseTooltip from '../lib/MouseTooltip.js';

// the canvas
class MapCanvas extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.canvasRef2 = React.createRef();
        this.state = {};
        this.loc = {};
        this.lineColor = "#a0a0a0";
        this.positionColor = "#808080";
        this.roomIcons = {};  // cache for the room icon paths
    }

    componentDidMount() {
        this.loc = {};
        this.componentDidUpdate();
    }

    // we render the canvas changes here
    componentDidUpdate() {
        let loc = this.props.loc;

        let newloc = {};  // we need a deep copy
        for (let key in loc) newloc[key] = loc[key];
        this.loc = newloc;
        
        if (this.props.parent.data.loading) return;

        // This needs to be called on every update, because we may have updated the center when the mapMarker was not ready yet - this happens on the initial load and causes the map to not get centered
        this.setCenter(loc);

        if ((loc.areaid !== this.drawnArea) || (loc.z !== this.drawnZ) || (loc.b !== this.drawnB) || loc.exploreChanged || (this.props.data.singleRoom && (this.drawnRoom != loc.id)))
        {
            this.drawMap();
            return;
        }
    }

    get_feature_icon(iconName) {
        if (iconName == 'swords') return faSwords;
        if (iconName == 'building-columns') return faColumns;
        if (iconName == 'chess-rook') return faChessRook;
        if (iconName == 'building') return faBuilding;
        if (iconName == 'people-pants-simple') return faPeoplePantsSimple;
        if (iconName == 'store') return faStore;
        if (iconName == 'brush') return faBrush;
        if (iconName == 'shuttle-space') return faSpaceShuttle;
        if (iconName == 'fort') return faFort;
        if (iconName == 'anchor') return faAnchor;
        if (iconName == 'house-turret') return faHouseTurret;
        if (iconName == 'file-signature') return faFileSignature;
        if (iconName == 'dog') return faDog;
        if (iconName == 'books') return faBooks;
        if (iconName == 'elevator') return faElevator;
        if (iconName == 'mailbox') return faMailbox;
        if (iconName == 'newspaper') return faNewspaper;
        if (iconName == 'galaxy') return faGalaxy;
        if (iconName == 'person-to-portal') return faPortalEnter;
        if (iconName == 'mailbox') return faMailbox;
        if (iconName == 'industry') return faIndustry;
        if (iconName == 'screwdriver-wrench') return faTools;
        if (iconName == 'shop') return faStoreAlt;
        if (iconName == 'horse') return faHorse;
        if (iconName == 'transporter-1') return faTransporter1;
        if (iconName == 'house') return faHouse;
        if (iconName == 'warehouse') return faWarehouse;
        if (iconName == 'trees') return faTrees;
        return null;
    }

    setCenter(loc) {
        this.drawLocation();
        // adjust the player room location
        this.centerRoom = loc.id;
        let proom = this.props.data.map_rooms[loc.id];
        let playerLoc = proom ? this.square_location(proom.x, proom.y) : { x : 0, y : 0 };
        this.props.onCenterChanged(loc.id, playerLoc.x, playerLoc.y, this.props.zoom);
    }

    get_map_linecolor() {
        return this.lineColor;
    }

    get_position_linecolor() {
        return this.positionColor;
    }

    square_location(x, y) {
        let res = {};
        if (x === null) return { x : 0, y : 0 };
        let data = this.props.data;
        res.x = (x - data.minX + 0.5) * this.props.zoom * 3;
        // the Y-coordinate is backwards
        res.y = (data.maxY - y - 0.5) * this.props.zoom * 3;
        return res;
    }

    get_offset_by_dir(dir) {
        let shift = 24 * this.props.zoom / 10;
        let xOffset = this.props.data.get_base_dir_offset(dir);
        if (xOffset === undefined) return xOffset;
        xOffset.top *= shift;
        xOffset.left *= shift;
        return xOffset;
    }

    // angle, north is 0
    get_rotation_by_dir(dir) {
        var full = Math.PI * 2;
        var dirs = Util.directions();
        for (var i = 0; i < dirs.length; ++i) {
            if (i >= 8) break;   // nothing for up/down/in/out
            if (dirs[i] === dir)
                return full * i / 8;
        }
        return 0;
    }

    is_drawn(from, to) {
        for (let i in this.exits_drawn)
        {
            if ((this.exits_drawn[i][0] === from && this.exits_drawn[i][1] === to) ||
                (this.exits_drawn[i][1] === from && this.exits_drawn[i][0] === to))
                return true;
        }
        return false;
    }

    centered_text(ctx, text, xx, yy, color) {
        ctx.font = (Math.floor (this.props.zoom * 11 / 10)) + "pt Arial,sans-serif";
        var textSize = ctx.measureText(text);
        xx -= textSize.width / 2;
        var stroke = ctx.strokeStyle;
        ctx.fillStyle = color;
        ctx.fillText(text, xx, yy);
        ctx.strokeStyle = stroke;
    }

    /** Should this room be displayed? */
    roomDisplayed(id) {
        let sett = this.props.settings;
        if (sett.reveal_map) return true;
        // The current room is always shown.
        let myloc = this.loc;
        if (myloc.id === id) return true;
        // Otherwise depends on exploration status.
        let area = myloc.areaid;
        if ((!this.props.gmcp.Explored) || (!this.props.gmcp.Explored[area])) return true;   // no explore info exists
        if (this.props.gmcp.Explored[area][id]) return true;
        return false;
    }

    /** Does this room have any special exits? */
    roomHasSpecials(id) {
        let gmcp = this.props.gmcp;

        // check the map
        let room = this.props.data.map_rooms[id];
        if (room && room.specialexits && room.specialexits.length)
            return true;

        // check GMCP
        if (gmcp.SpecialExits && gmcp.SpecialExits[id] && gmcp.SpecialExits[id].length)
            return true;

        return false;
    }

    drawMap() {
        const canvasEl = this.canvasRef.current;
        const canvasEl2 = this.canvasRef2.current;
        if (!canvasEl) return;
        const ctx = canvasEl.getContext('2d');

        let data = this.props.data;
        let myloc = this.loc;
        let unmapped = (myloc.x === undefined);

//        let start = Util.timestamp();

        data.onDraw();
        this.exits_drawn = [];
        this.myloc_set = false;

        let b = myloc['b'];
        let z = myloc['z'];
        if (!b) b = 0;
        if (!z) z = 0;
        let map_data = data.map_data[b];
        if (!map_data) map_data = [];

        let maxX = data.maxX;
        let maxY = data.maxY;
        let minX = data.minX;
        let minY = data.minY;

        canvasEl.width = (maxX - minX + 1) * this.props.zoom * 3;
        canvasEl.height = (maxY - minY + 1) * this.props.zoom * 3;
        canvasEl2.width = canvasEl.width;
        canvasEl2.height = canvasEl.height;

        ctx.clearRect(0, 0, canvasEl.width, canvasEl.height);

        // first pass - exits
        ctx.strokeStyle = this.get_map_linecolor();
        ctx.lineWidth = 2;
        ctx.beginPath();

        for (let y = maxY; y >= minY; y--)
        {
            for (let x = minX; x <= maxX; x++)
            {
                if ((!map_data[x]) || (!map_data[x][y]) || (!map_data[x][y][z]) || (typeof map_data[x][y][z] != "object")) continue;

                let room = map_data[x][y][z];
                let loc = this.square_location(x, y);
                let xx = loc.x;
                let yy = loc.y;

                // Draw exits. Ignore exits if this is a building entrance.
                if (room.exits && (b === room.b))
                {
                    for (let i = 0; i < room.exits.length; ++i)
                    {
                        let exit = room.exits[i];

                        if (!unmapped) {
                            if (this.is_drawn(room.id, exit.target) || (!exit.draw)) continue;
                            if (exit.hidden) continue;
                            // If -this- room is not displayed, we won't draw exits. If the target room is not displayed, we will.
                            if (!this.roomDisplayed(room.id)) continue;
                        }

                        let xOffset = this.get_offset_by_dir(exit.dir);
                        if (!xOffset) continue;

                        let tgx, tgy;
                        let tgroom = unmapped ? undefined : data.map_rooms[exit.target];
                        if (tgroom && (tgroom.z !== room.z)) tgroom = undefined;
                        if (tgroom && (tgroom.b !== room.b)) tgroom = undefined;
                        // If the target room is not displayed, we'll show a halved length exit there.
                        if (tgroom && (!this.roomDisplayed(exit.target))) tgroom = undefined;
                        if (tgroom)
                        {
                            this.exits_drawn.push ([room.id, exit.target]);
                            let loc = this.square_location(tgroom.x, tgroom.y);
                            tgx = loc.x;
                            tgy = loc.y;
                        } else {
                            tgx = xx - xOffset.left * 2 / 3;
                            tgy = yy - xOffset.top * 2 / 3;
                        }
                        ctx.moveTo(xx, yy);
                        ctx.lineTo(tgx, tgy);

                        if (exit.door) {
                            let centerx, centery;
                            if (tgroom) {
                                centerx = xx + (tgx - xx) / 2;
                                centery = yy + (tgy - yy) / 2;
                            }
                            else
                            {
                                centerx = tgx;
                                centery = tgy;
                            }
                            ctx.save();
                            ctx.translate(centerx, centery);
                            ctx.rotate(this.get_rotation_by_dir(exit.dir));

                            let desired = 4;
                            desired = Math.ceil(desired * this.props.zoom / 10);
                            ctx.moveTo (-1 * desired, 0);
                            ctx.lineTo (desired, 0);

                            ctx.restore();
                        }
                        if (exit.tgarea) {
                            ctx.save();
                            ctx.translate(tgx, tgy);
                            ctx.rotate(this.get_rotation_by_dir(exit.dir));

                            let sz = 3;
                            sz = Math.ceil(sz * this.props.zoom / 10);
                            ctx.moveTo (0, 0);
                            ctx.lineTo (-1 * sz, sz);
                            ctx.moveTo (0, 0);
                            ctx.lineTo (sz, sz);

                            ctx.restore();

                            let areaName = data.get_area_name(parseInt(exit.tgarea));
                            if (areaName)
                            {
                                if (areaName.substr(0, 4) === "the ")
                                    areaName = areaName.substr(4);
                                data.add_map_texts(xx, yy, tgx, tgy, areaName, 'path track ' + exit.target);
                            }
                        }

                        // check if the exit leads out of some building (entrances are handled elsewhere)
                        let tgb = unmapped ? null : data.map_buildings[exit.target];
                        if ((tgb != null) && (tgb === 0) && (data.map_buildings[room.id] !== 0))
                            data.add_map_texts(xx, yy, tgx, tgy, '(leave)', 'path track ' + exit.target);
                    }
                }

                ctx.strokeStyle = this.get_map_linecolor();
                ctx.lineWidth = 2;

                // marker
                if (room.marker) {
                    let xOffset = this.get_offset_by_dir(Util.get_brief_direction_name(room.markerdir));
                    let modifier = 0.9;
                    if (room.important) { xOffset.top *= 1.3; xOffset.left *= 1.3; }
                    this.text_marker(ctx, room.marker, xx, yy, xOffset, modifier);
                }
            }
        }
        ctx.stroke();
        ctx.closePath();

        // second pass - room rects
        for (let y = maxY; y >= minY; y--)
        {
            for (let x = minX; x <= maxX; x++)
            {
                if ((!map_data[x]) || (!map_data[x][y]) || (!map_data[x][y][z]) || (typeof map_data[x][y][z] != "object")) continue;

                let room = map_data[x][y][z];
                let loc = this.square_location(x, y);
                let xx = loc.x;
                let yy = loc.y;
                if ((!unmapped) && (!this.roomDisplayed(room.id))) continue;

                let color = room.color;
                if ((!color) && data.env_color)  // no room color, using the environment
                {
                    let env = room.environment;
                    color = data.env_color[env];
                }
                if (!color) color = '#80ff80';

                ctx.strokeStyle = this.get_map_linecolor(); // "#000000";
                ctx.lineWidth = room.important ? 2 : 1;
                ctx.fillStyle = color;
                let width = room.important ? 22 : 16;
                width = Math.ceil(width * this.props.zoom / 10);
                ctx.fillRect(xx - width / 2, yy - width / 2, width, width);
                ctx.strokeRect(xx - width / 2, yy - width / 2, width, width);

                // Is this our location?
                if ((myloc['x'] === x) && (myloc['y'] === y)) {
                    this.myloc_set = true;
                    this.myloc_x = xx;
                    this.myloc_y = yy;
                    this.myloc_w = width;
                    this.myloc_lw = ctx.lineWidth;
                    this.myloc_color = color;
                }

                let txt = room.title;
                let features = data.get_room_features(room.id);
                if (features && features.length)
                    txt += "  -  " + features.join(', ');
                data.add_map_texts(xx - width / 2, yy - width / 2, xx + width / 2, yy + width / 2, txt, 'path track ' + room.id);

                // special features icon
                let iconName = data.get_room_icon(room.id);
                if (iconName && iconName.length) {
                    let iconDef = this.get_feature_icon(iconName);
                    let w, h, p;
                    // Parsing these paths is very slow, so we need to cache it.
                    if (this.roomIcons[iconName]) {
                        w = this.roomIcons[iconName].w;
                        h = this.roomIcons[iconName].h;
                        p = this.roomIcons[iconName].p;
                    } else {
                        let iconData = icon(iconDef).icon;
                        w = iconData[0];
                        h = iconData[1];
                        let path = iconData[4];
                        p = new Path2D(path);
                        this.roomIcons[iconName] = { p : p, w : w, h : h };
                    }
                    ctx.strokeStyle = "#000000";
                    ctx.fillStyle = "#ffffff";
                    let scale1 = w ? width * 0.8 / w : 0;
                    let scale2 = h ? width / 0.8 / h : 0;
                    let scale = (scale1 > scale2) ? scale2 : scale1;
                    ctx.lineWidth = 1 / scale;
                    let xstart, ystart;
                    if (w >= h) {
                        xstart = xx - width * 0.4;
                        let diff = (w - h) / h;
                        ystart = yy - width * 0.4 * (1 - diff);
                    } else {
                        ystart = yy - width * 0.4;
                        let diff = (h - w) / w;
                        xstart = xx - width * 0.4 * (1 - diff);
                    }
                    ctx.save();
                    ctx.translate(xstart, ystart);
                    ctx.scale(scale, scale);
                    ctx.fill(p);
                    ctx.stroke(p);
                    ctx.restore();
                }

                if (room.exits && (room.b === b))
                {
                    ctx.strokeStyle = "#000000";
                    ctx.fillStyle = "#ffffff";
                    ctx.lineWidth = 1;
                    for (let i = 0; i < room.exits.length; ++i)
                    {
                        let exit = room.exits[i];

                        if (exit.hidden) continue;

                        let sz = 5;
                        sz = Math.floor(sz * this.props.zoom / 10);
                        if (exit.dir === "d") {
                            let startx = xx - sz;
                            let starty = yy - width / 2 + (ctx.lineWidth + 2);
                            ctx.beginPath();
                            ctx.moveTo (startx, starty);
                            ctx.lineTo (startx + 2 * sz, starty);
                            ctx.lineTo (startx + sz, starty + sz);
                            ctx.lineTo (startx, starty);
                            ctx.fill();
                            ctx.stroke();
                            ctx.closePath();
                        }
                        if (exit.dir === "u") {
                            let startx = xx - sz;
                            let starty = yy + width / 2 - (ctx.lineWidth + 2);
                            ctx.beginPath();
                            ctx.moveTo (startx, starty);
                            ctx.lineTo (startx + 2 * sz, starty);
                            ctx.lineTo (startx + sz, starty - sz);
                            ctx.lineTo (startx, starty);
                            ctx.fill();
                            ctx.stroke();
                            ctx.closePath();
                        }
                        if (exit.dir === "i") {
                            let startx = xx - width / 2 + (ctx.lineWidth + 2);
                            let starty = yy - sz;
                            ctx.beginPath();
                            ctx.moveTo (startx, starty);
                            ctx.lineTo (startx, starty + 2 * sz);
                            ctx.lineTo (startx + sz, starty + sz);
                            ctx.lineTo (startx, starty);
                            ctx.fill();
                            ctx.stroke();
                            ctx.closePath();
                        }
                        if (exit.dir === "o") {
                            let startx = xx + width / 2 - (ctx.lineWidth + 2);
                            let starty = yy - sz;
                            ctx.beginPath();
                            ctx.moveTo (startx, starty);
                            ctx.lineTo (startx, starty + 2 * sz);
                            ctx.lineTo (startx - sz, starty + sz);
                            ctx.lineTo (startx, starty);
                            ctx.fill();
                            ctx.stroke();
                            ctx.closePath();
                        }
                    }
                }
                // building entrance - write a B in the center
                if ((b === 0) && (room.b !== 0))
                    this.centered_text(ctx, "B", xx, yy + width / 3, "#000000");
                
                // special exits?
                if (this.roomHasSpecials (room.id)) {
                    ctx.beginPath();
                    ctx.strokeStyle = "#000000";
                    ctx.fillStyle = "#ffff00";
                    ctx.lineWidth = 1;
                    ctx.arc(xx + width / 2, yy - width / 2, width / 5, 0, 2 * Math.PI);
                    ctx.fill();
                    ctx.stroke();
                    ctx.closePath();
                }
            }
        }

        // adjust the player room location
        this.setCenter(myloc);

        this.drawnArea = myloc.areaid;
        this.drawnZ = myloc.z;
        this.drawnB = myloc.b;
        this.drawnRoom = unmapped ? myloc.id : null;

//        let end = Util.timestamp();
    }

    padZero(x) {
        while (x.length < 2) x = '0' + x;
        return x;
    }

    reverseColor(color) {
        if (color[0] === '#') color = color.slice(1);
        let r = parseInt(color.slice(0, 2), 16);
        let g = parseInt(color.slice(2, 4), 16);
        let b = parseInt(color.slice(4, 6), 16);

        // reverse
        r = (r > 160) ? 0 : 255;
        g = (g > 160) ? 0 : 255;
        b = (b > 160) ? 0 : 255;

        return 'rgba('+r+','+g+','+b+',+0.6)';
//        return '#' + this.padZero(r.toString(16)) + this.padZero(g.toString(16)) + this.padZero(b.toString(16));
    }

    drawLocation() {
        const canvasEl2 = this.canvasRef2.current;
        if (!canvasEl2) return;
        const ctx = canvasEl2.getContext('2d');
        ctx.clearRect(0, 0, canvasEl2.width, canvasEl2.height);

        if (!this.myloc_set) return;
        
        ctx.strokeStyle = this.get_position_linecolor();
        ctx.fillStyle = this.reverseColor(this.myloc_color);
        ctx.lineWidth = this.myloc_lw;

        let xx = this.myloc_x;
        let yy = this.myloc_y;
        let width = this.myloc_w;
        ctx.strokeRect(xx - width / 2, yy - width / 2, width, width);
        ctx.fillRect(xx - width / 2, yy - width / 2, width, width);
    }

    text_marker(ctx, text, xx, yy, xOffset, modifier) {
        // figure out where to place the text
        // N: Y + height, X - width / 2
        // NE: Y + height, X - width / 2
        // NW: Y + height, X - width / 2
        // E: as-is, CENTER Y
        // W: X - width, CENTER Y
        // SE: X - width / 2
        // S: X - width / 2
        // SW: X - width / 2
        ctx.font = (Math.floor (this.props.zoom * 11 / 10)) + "pt Arial,sans-serif";
        var nx = xx;
        var ny = yy;
        if (xOffset != null) {
            var textSize = ctx.measureText(text);
            nx -= xOffset.left * modifier;
            ny -= xOffset.top * modifier;
            if (xOffset.top > 0) ny += this.props.zoom;
            if (xOffset.top !== 0)
                nx -= textSize.width / 2;
            else
            {
                ny += 5;
                if (xOffset.left > 0) nx -= textSize.width;
            }
        }

        ctx.fillStyle = this.get_map_linecolor();
        ctx.fillText(text, nx, ny);
    }


    onWheel(e) {
        e.preventDefault();
        let dY = e.deltaY / 50;
        let z = this.props.zoom - dY;
        if (z < 6) z = 6;
        if (z > 24) z = 24;
        this.props.onZoom(z);
        this.drawMap();
    }

    render() {
        let passProps = {};
        for (let key in this.props) {
            if (key === 'onCenterChanged') continue;
            if (key === 'loc') continue;
            if (key === 'data') continue;
            passProps[key] = this.props[key];
        }
        let canvas1 = (<canvas id='map_canvas' ref={this.canvasRef} onWheel={(e)=>this.onWheel(e)} {...passProps} />);
        let canvas2 = (<canvas id='map_canvas2' ref={this.canvasRef2} style={{ pointerEvents: 'none', position:'absolute', left:0, top:0 }} />);
        return (<div>{canvas1}{canvas2}</div>);
    }

}


export class TabMap extends Tab {
    constructor(props) {
        super(props);
        this.state = { tooltip: '', zoom: 10 };
        this.gi = this.props.gameinfo;
        this.setupData();
        this.ref = React.createRef();
        this.centerX = null;
        this.centerY = null;
        this.areaid = null;
    }

    setupData() {
        this.data = new MapData(this.gi);
        this.props.nexus.map = this.data;  // for scripting
        this.data.onload = ((id) => this.mapLoaded(id));
        this.data.onloading = ((id) => this.mapLoading());
    }

    componentDidMount() {
        this.reset();
    }

    componentDidUpdate() {
        // if the game changes, we need to reload the gameinfo
        if (this.gi !== this.props.gameinfo) {
            this.gi = this.props.gameinfo;
            this.setupData();
        }

        let gmcp = this.props.gmcp || {};
        let loc = gmcp.Location || {};
        if (loc.areaid && (loc.areaid !== this.areaid))  // we have changed areas
        {
            this.areaid = loc.areaid;
            this.data.load_map_data();
            return;
        }

        this.centerMap();
    }

    // called when a new map is loaded
    reset(delayed=true) {
        let st = { panX: 0, panY: 0, tooltip: '', tooltipX: null, tooltipY: null };
        if (delayed) this.props.platform.set_timeout(() => this.setState(st), 5);
        else this.setState(st);
    }

    scrollMaxX() {
        let container = this.ref.current;
        if (!container) return;
        let res = container.scrollWidth - container.clientWidth;
        if (res < 0) res = 0;
        return res;
    }
    
    scrollMaxY() {
        let container = this.ref.current;
        if (!container) return;
        let res = container.scrollHeight - container.clientHeight;
        if (res < 0) res = 0;
        return res;
    }

    mapLoaded(id) {
        this.reset(false);  // this will trigger a redraw
    }

    mapLoading(id) {
        this.reset(false);  // this will trigger a redraw
    }

    centerMap() {
        let container = this.ref.current;
        if (!container) return;

        let cx = this.centerX;
        let cy = this.centerY;
        // this is for overhead map
        if (cx === null) cx = Math.floor(container.scrollWidth / 2);
        if (cy === null) cy = Math.floor(container.scrollHeight / 2);
        
        let x = Math.floor (cx - container.clientWidth / 2);
        let y = Math.floor (cy - container.clientHeight / 2);
        x += this.state.panX;
        y += this.state.panY;
        if (x < 0) x = 0;
        if (x >= this.scrollMaxX()) x = this.scrollMaxX();
        if (y < 0) y = 0;
        if (y >= this.scrollMaxY()) y = this.scrollMaxY();
        container.scroll({left: x, top: y, behavior: 'smooth'});
        //container.scrollLeft = x;
        //container.scrollTop = y;
    }

    renderOverhead(lines) {
        if (!lines) return null;
        let output = [];
        this.centerX = null;
        this.centerY = null;
        for (let idx = 0; idx < lines.length; ++idx) {
            let txt = lines[idx].parsed_line;
            let lineData = (txt ? txt.formatted() : '');
            if (lines[idx].html_line) lineData = lines[idx].html_text;
            let lineclass = 'mono';
            let ldata = (<div key={'overhead-'+idx} className={lineclass} dangerouslySetInnerHTML={{__html: lineData }}></div>);
            output.push (ldata);
        }
        let h = 12 * this.state.zoom / 8;
        let w = 12 * this.state.zoom / 8;
        let st = { fontSize: w+'px', lineHeight: h+'px', transform: 'scaleY(0.7)' };
        
        return (<div id='map_overhead'><div id='map_overhead_content' style={st} onWheel={(e)=>this.onOverheadWheel(e)}>{output}</div></div>);
    }

    onOverheadWheel(e) {
        e.preventDefault();
        let dY = e.deltaY / 50;
        let z = this.state.zoom - dY;
        if (z < 6) z = 6;
        if (z > 24) z = 24;
        this.setState({zoom:z});
    }


    renderExits(loc) {
        let exits = [];
        let lst = loc['roomexits'];
        if (lst) {
            exits.push ((<b key='exits_header'>Exits: </b>));
            let short_exits = (Object.keys(lst).length > 3);
            for (let ex in lst)
            {
                let exit = short_exits ? ex.toUpperCase() : Util.ucfirst(Util.get_full_direction_name(ex));
                let el = (<a key={'exit_'+exit} href='#' onClick={()=>{ this.props.oncommand(ex); }}>{exit}</a>);
                if (exits.length > 1) exits.push(', ');
                exits.push (el);
            }
        }
        return (<div id='map_room_exits'>{exits}</div>);
    }

    canvasPanStart(e) {
        this.panning = true;
        this.panningX = e.screenX;
        this.panningY = e.screenY;
    }

    canvasMove(e) {
        let x = e.nativeEvent.offsetX;
        let y = e.nativeEvent.offsetY;

        if (this.panning) {
            let diffX = this.panningX - e.screenX;
            let diffY = this.panningY - e.screenY;
            this.panningX = e.screenX;
            this.panningY = e.screenY;
            this.setState({panX: this.state.panX + diffX, panY: this.state.panY + diffY});
        }
        
        let tooltip = this.data.get_map_text (x, y, 'tooltip');
        if (this.panning) tooltip = '';
        if (tooltip !== this.state.tooltip) {
            this.setState({ tooltip: tooltip, tooltipX: x, tooltipY: y });
        }
    }

    canvasMouseOut(e) {
        this.panning = false;
        this.setState({ tooltip: '' });
    }

    canvasPanEnd(e) {
        this.panning = false;
    }

    canvasDoubleClick(e) {
        let x = e.nativeEvent.offsetX;
        let y = e.nativeEvent.offsetY;
        let command = this.data.get_map_text (x, y, 'command');
        if (command) this.props.oncommand (command);
    }

    onCenterChanged(roomid, x, y, zoom) {

        // Nothing if we have the correct data already.
        if ((this.roomid === roomid) && (this.centerX === x) && (this.centerY === y) && (this.state.zoom === zoom)) return;
        this.roomid = roomid;
        this.centerX = x;
        this.centerY = y;
        if (this.state.zoom !== zoom) this.setState({zoom:zoom});
        this.reset(false);  // this is here to reset panning

        this.centerMap();
    }

    render() {
        let gmcp = this.props.gmcp || {};
        let loc = gmcp.Location || {};
        this.data.set_location (loc);
        let data = this.data;
        if (loc.x === undefined)   // unmapped room
            data = new SingleRoomData(loc);
        else if (this.data.failed)   // no map exists
            data = new SingleRoomData(loc);
        else if (!this.data.map_data)  // not loaded yet
            data = new SingleRoomData(loc);
        if (this.data.loading) data = null;

        let noStyle = { textAlign: 'center', position: 'absolute', left : 0, top: 'calc(50% - 10px)', right: 0 };
        let mainMap, el_area_name;
        if (this.props.ohmap && loc.ohmap) {
            mainMap = this.renderOverhead (this.props.ohmap);
        } else if (this.data.loading) {
            mainMap = (<div key='map_nomap' id='map_nomap' style={noStyle}>LOADING ...</div>);
        } else if (!data) {
            mainMap = (<div key='map_nomap' id='map_nomap' style={noStyle}>NO MAP AVAILABLE</div>);   // shouldn't really happen
        } else {
            let areaName = this.data.get_area_name(loc.areaid);
            if (!areaName)
                areaName = loc['areaname'] ? loc['areaname'] : '(unknown area)';
            el_area_name = (<div id='map_area_name'>{areaName}</div>);
            let el_canvas = (<MapCanvas
                key='map_canvas'
                loc={loc}
                parent={this}
                data={data}
                zoom={this.state.zoom}
                settings={this.props.settings}
                gmcp={this.props.gmcp}
                onZoom={(z)=>this.setState({zoom:z})}
                onMouseDown={(e)=>this.canvasPanStart(e)}
                onMouseMove={(e)=>this.canvasMove(e)}
                onMouseUp={(e)=>this.canvasPanEnd(e)}
                onMouseOut={(e)=>this.canvasMouseOut(e)}
                onDoubleClick={(e)=>this.canvasDoubleClick(e)}
                onCenterChanged={(rid,x,y,zoom) => this.onCenterChanged(rid,x,y,zoom)}
            />);
            let mstyle = {};
            if (loc.x === null) mstyle['display'] = 'none';
            let tooltipStyle = { borderColor:'black', borderStyle:'solid', borderWidth:1, color: 'white', backgroundColor: '#444444', zIndex: 50000, padding: '2px' };
            let el_tooltip = (<MouseTooltip key='map_tooltip' offsetX={10} offsetY={5} style={tooltipStyle} visible={this.state.tooltip?true:false}><span>{this.state.tooltip}</span></MouseTooltip>);
            mainMap = [ el_canvas, el_tooltip ];
        }

        let style = {overflow:'hidden', position: 'relative', flexGrow: 1};
        mainMap = (<div id='map_container' key='map_container' style={style} ref={this.ref}>{mainMap}</div>);
        let el_exits = this.renderExits (loc);

        let res = (<div id='map_main' style={{height:'100%', display: 'flex', flexDirection: 'column'}}>{el_area_name}{mainMap}{el_exits}</div>);
        return res;
    }
}
