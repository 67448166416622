
// settings - the "general" and "advanced" pages
// keeping both here because they use very similar functionality
// TODO: font stack, font size!

import React from 'react';

import { SettingBool, SettingColor, FormSection } from './settingcomps'


export class SettingsGeneral_Base extends React.Component {
    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    renderBoolSetting(key, text, tooltip, inline=false) {
        let tips = this.props.platform.tooltips_shown();
        if (!tips) tooltip = null;
        return (<SettingBool key={key} keyname={key} text={text} tooltip={tooltip} inline={inline} settings={this.props.settings} onChange={(key, val)=>this.props.onChange(key, val)} />);
    }

    renderSettingWithColor(key, text, tooltip, colorkey, colortext) {
        let tips = this.props.platform.tooltips_shown();
        if (!tips) tooltip = null;
        return (<SettingColor key={key} keyname={key} text={text} tooltip={tooltip} inline={false} colorkey={colorkey} colortext={colortext} settings={this.props.settings} onChange={(key, val)=>this.props.onChange(key, val)} />);
    }

    renderFontSetting() {
        let s = this.props.settings;
        let font = s.font_stack;
        let fstacks = s.font_stacks();
        let vals = [];
        for (let i = 0; i < fstacks.length; i++) {
            vals.push ({ value: fstacks[i].name, text: fstacks[i].name });
        }
        return this.renderSelectSetting('font_stack', vals, font, 'Display Font', 'Specifies the font used for the main output.');
    }
    
    renderFontSizeSetting() {
        let s = this.props.settings;
        let vals = [];
        for (let i = 7; i <= 20; ++i) {
            let val = i + 'px';
            let txt = val;
            if (i === 13) txt += ' (Default)';
            vals.push({value: val, text: txt});
        }
        return this.renderSelectSetting('font_size', vals, s.font_size, 'Font Size', 'Specifies the font size used for the main output.');
    }

    render() {
        return null;
    }
}

export class SettingsAdvanced_Base extends React.Component {
    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    setScrollbackLimit(l) {
        l = parseInt(l);
        if (isNaN(l)) return;
        this.props.onChange('scrollback_msg_limit', l);
    }

    setDelimiter(d) {
        if (d.match(/[A-Za-z0-9\s]/)) return;  // wrong delim
        if (d.length > 2) d = d.substr(0, 2);
        this.props.onChange('stack_delimiter', d);
    }

    renderBoolSetting(key, text, tooltip, inline=false) {
        let tips = this.props.platform.tooltips_shown();
        if (!tips) tooltip = null;
        return (<SettingBool key={key} keyname={key} text={text} tooltip={tooltip} inline={inline} settings={this.props.settings} onChange={(key, val)=>this.props.onChange(key, val)} />);
    }

    renderSettingWithColor(key, text, tooltip, colorkey, colortext) {
        let tips = this.props.platform.tooltips_shown();
        if (!tips) tooltip = null;
        return (<SettingColor key={key} keyname={key} text={text} tooltip={tooltip} inline={false} colorkey={colorkey} colortext={colortext} settings={this.props.settings} onChange={(key, val)=>this.props.onChange(key, val)} />);
    }

    render() {
        return null;
    }    
}

 
