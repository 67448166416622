// item list - base class

import { Tab } from './tab.js'
import { Util } from '../../base/util.js'

export class TabItemList_Base extends Tab {
    constructor(props) {
        super(props);
        this.state = { selection : null, desc : false, expanded: {} };
        this.items = {};
        
        this.icons = { 
            abomination: 'skull-cow',
            angel: 'angel',
            animal: 'paw',
            armor: 'shield',
            book: 'book',
            bone: 'bone',
            clothing: 'shirt',
            coin: 'coin',
            commodity: 'cubes',
            container: 'box',
            crystal: 'gem',
            curative: 'prescription-bottle-medical',
            deadbody: 'skull',
            door: 'door-open',
            feather: 'feather-pointed',
            fiend: 'spider',
            food: 'meat',
            guard: 'user-shield',
            humanoid: 'person',
            jewelry: 'ring',
            key: 'key-skeleton',
            lamp: 'candle-holder',
            lyre: 'violin',
            magical: 'sparkles',
            mineral: 'cube',
            monster: 'dragon',
            mug: 'beer-mug-empty',
            party: 'party',
            pet: 'paw',
            pipe: 'smoking',
            plant: 'seedling',
            potion: 'flask-round-potion',
            profile: 'circle-user',
            rune: 'ankh',
            scroll: 'scroll-old',
            seacreature: 'fish',
            shrine: 'place-of-worship',
            target: 'bullseye',
            top: 'top',
            weapon: 'sword'
        };
    }

    id() {
        return this.props.type;
    }

    sections() {
        if (this.props.type === 'room')
            return ['players', 'mobs', 'items'];
        return ['wielded', 'worn', 'items'];
    }

    section_desc(section) {
        return Util.ucfirst (section);
    }

    icon_name(type) {
        if (this.icons[type]) return this.icons[type];
        return null;
    }

    is_current_target(id, is_player) {
        let dh = this.props.datahandler;
        if (is_player) {
            if (!dh.current_target_is_player()) return false;
            return (id.toLowerCase() === dh.current_target().toLowerCase());
        }

        if (dh.current_target_is_player()) return false;
        return (parseInt(id) === parseInt(dh.current_target()));
    }

    item_button(text, command) {
        return { 'text' : text, 'command' : command };
    }

    item_buttons(item, loc) {
        let container = null;
        if (loc.substr (0, 3) === 'rep') container = loc.substr (3);

        let buttons = [];
        if (loc === 'players') {
            buttons.push (this.item_button ('Look', 'look ' + item.name));
            buttons.push (this.item_button ('Honours', 'honours ' + item.name));
            return buttons;
        }

        var id = item.id;
        var attrib = item.attrib;
        if (attrib == null) attrib = "";
        let gi = this.props.settings.gameinfo();

        // In container?
        if (container) {
            buttons.push (this.item_button ('Take out', 'get ' + id + ' from ' + container));
        }
        else if (this.props.type === 'inv') {
            buttons.push (this.item_button ('Look', 'probe ' + id));
            if (attrib.indexOf ('w') >= 0)  // worn
                buttons.push (this.item_button ('Remove', 'remove ' + id));
            else if (attrib.indexOf ('l') >= 0)  // wielded
                buttons.push (this.item_button ('Unwield', 'unwield ' + id));
            else {
                buttons.push (this.item_button ('Drop', 'drop ' + id));
                buttons.push (this.item_button ('Wield', 'wield ' + id));
                if (attrib.indexOf ('W') >= 0)  // wearable
                    buttons.push (this.item_button ('Wear', 'wear ' + id));
            }
            if (attrib.indexOf ('g') >= 0)  // groupable
                buttons.push (this.item_button ('Group All', 'combine ' + id));
            if (attrib.indexOf ('r') >= 0)  // riftable
                buttons.push (this.item_button ('To ' + gi.rift_name(), gi.command_rift_in() + ' ' + id));
            if (attrib.indexOf ('f') >= 0)  // fluid
                buttons.push (this.item_button ('Drink', 'drink ' + id));
            if (attrib.indexOf ('e') >= 0)  // edible
                buttons.push (this.item_button ('Eat', 'eat ' + id));
        }
        else {
            // on the ground
            buttons.push (this.item_button ('Look', 'probe ' + id));
            if ((attrib.indexOf ('t') >= 0) || (attrib.indexOf ('d') >= 0))  // takeable or dead
                buttons.push (this.item_button ('Get', 'get ' + id));
            if ((attrib.indexOf ('m') >= 0) && (attrib.indexOf ('d') < 0)) {  // mob and not dead
                buttons.push (this.item_button ('Greet', 'greet ' + id));
                buttons.push (this.item_button ('Attack', 'attack ' + id));
            }
        }

        if (attrib.indexOf('c') >= 0)
            buttons.push (this.item_button ('Contents', 'look in ' + id));

        return buttons;
    }

    toggleExpand(e,id) {
        e.stopPropagation();
        let orig = this.state.expanded;
        let exp = {};
        // reset state.expanded to not include things that are no longer valid
        for (let key in orig) {
            if (this.items[key]) exp[key] = orig[key]
        }
        exp[id] = exp[id] ? false : true;
        this.setState({expanded: exp});

        if (exp) this.props.fetchItems(id);
    }

    entry_click(e, loc, cmd) {
        this.setState ({'selection' : null});
        this.props.oncommand (cmd);
    }

    toggleDesc() {
        this.setState ({desc : this.state.desc ? false : true});
    }
    
    setSelection(item, loc) {
        let attrib = item.attrib;
        if (!attrib) attrib = '';
        if (loc === 'players')  // player target
        {
            this.props.datahandler.set_current_target(item.name, false);
            if (this.state.selection === 'player-' + item.name)
                this.setState ({'selection' : null});
            else
                this.setState ({'selection' : 'player-' + item.name});
            return;
        }
        
        // if we clicked on a mob, also set it as a target
        if ((loc !== 'inv') && (attrib.indexOf ('m') >= 0) && (attrib.indexOf ('d') < 0))
            this.props.datahandler.set_current_target(item.id, true);

        if (this.state.selection === item.id)
            this.setState ({'selection' : null});
        else
            this.setState ({'selection' : item.id});
    }

}
