

import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class Balances extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
    
    iconName(type) {
        if (type === 'bal') return 'gavel';
        if (type === 'eq') return 'star';
        return undefined;
    }

    iconColor(type, has) {
        if (!has) return '#777777';
        if (type === 'bal') return '#40ff40';
        if (type === 'eq') return '#ffff40';
        return '#eaac15';
    }

    render() {
        let vitals = this.props.vitals;
        let balances = this.props.balances;
        let sett = this.props.settings;

        let els = [];
        for (let idx = 0; idx < balances.length; ++idx) {
            let b = balances[idx];
            let cur = vitals ? parseInt(vitals[b['curvar']]) : true;
            let name = this.iconName(b.class);
            let color = this.iconColor(b.class, cur);
            let fsize = parseInt(sett.calc_fontsize());
            let w = 16 * fsize / 13;
            let margin = 6 * fsize / 13;
            if (idx) margin = 7 * fsize / 13;
            let st = {width : w+'px', marginLeft: margin+'px'};
            let img = (<FontAwesomeIcon key={b.class} style={{fontSize: '1.5em'}} color={color} icon={['fad', name]} />);
            let el = (<div style={st} key={'bal_'+b['name']} id={'balance_' + b.name} className={'balance '+b.class+(cur?'':' lost')}>{img}</div>);
            if (this.props.tooltips) el = (<Tooltip key={'tip_'+b['name']} title={b.tooltip}>{el}</Tooltip>);
            els.push(el);
        }

        return (<div id="component_balances" style={{display:'flex', flexDirection: 'row', height: '100%', alignItems: 'center'}}>{els}</div>);
    }
}

 
