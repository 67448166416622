
import React from 'react';

import { SettingBool, FormSection } from './settingcomps'

export class SettingsButtons_Base extends React.Component {
    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    getButton(id) {
        let buttons = this.props.buttons;
        let b = buttons.get(id);
        if (!b) b = {text:'', commands:'', highlight:'',target_picker:true};
        return b;
    }

    setButtonValue(id, which, value) {
        let buttons = this.props.buttons;
        let b = this.getButton(id);
        b[which] = value;
        buttons.set(id, b.commands, b.script, b.text, b.target_picker);
        this.setState({updated:1});
    }

    setDefault(id) {
        let buttons = this.props.buttons;
        buttons.clear_button(id);
        this.setState({updated:1});
    }

    renderBoolSetting(key, text, tooltip, inline=false) {
        let tips = this.props.platform.tooltips_shown();
        if (!tips) tooltip = null;
        return (<SettingBool key={key} keyname={key} text={text} tooltip={tooltip} inline={inline} settings={this.props.settings} onChange={(key, val)=>this.props.onChange(key, val)} />);
    }

    onCountChange(val) {
        let buttons = this.props.buttons;
        buttons.set_count(val);
        this.setState({updated:1});
    }

    onSelect(idx) {
        this.setState({sel:idx});
    }

    render() {
        return null;
    }
}

 
 
 
 
