
import { Reflex, Match } from './reflex.js'
import { ActionList } from './actions.js'

export class ReflexEvent extends Reflex {
    constructor(name, nexus) {
        super(name, nexus);
        this.type = 'event';
        this.evtype = 'GMCP';
        this.evsubtype = 'Char.Vitals';
        this.actions = [];
    }

    encode() {
        var res = super.encode();
        res.evtype = this.evtype;
        res.evsubtype = this.evsubtype;
        return res;
    }

    apply (e, reflexes) {
        if (e['type'] !== this.type) return;  // sanity check
        super.apply (e, reflexes);
        this.evtype = e.evtype;
        this.evsubtype = e.evsubtype;
    }

    duplicate() {
        var res = new ReflexEvent(this.name, this._nexus);
        res.evtype = this.evtype;
        res.evsubtype = this.evsubtype;
        res.actions = this.copy_actions();
        return res;
    }

    matches(txt) {
        if ((!txt) || (txt.length === 0)) return true;

        txt = txt.toLowerCase();

        if (this.evtype && this.evtype.toLowerCase().indexOf(txt) >= 0) return true;
        if (this.evsubtype && this.evsubtype.toLowerCase().indexOf(txt) >= 0) return true;

        return super.matches(txt);
    }

    static event_types() {
        return [ 'GMCP' ];
    }

    static event_subtypes(type) {
        if (type === 'GMCP')
            return [ 'Char.Vitals', 'Char.Afflictions.Add', 'Char.Afflictions.Remove', 'Char.Defences.Add', 'Char.Defences.Remove', 'Room.AddPlayer', 'Room.RemovePlayer', 'Room.Info', 'IRE.Target.Set' ];
        return [];
    }

    static event_heading (type, subtype) {
        if (type === 'GMCP') {
            if (subtype === 'Char.Vitals') return 'Block of text received';
            if (subtype === 'Char.Afflictions.Add') return 'Affliction received';
            if (subtype === 'Char.Afflictions.Remove') return 'Affliction cured';
            if (subtype === 'Char.Defences.Add') return 'Defence activated';
            if (subtype === 'Char.Defences.Remove') return 'Defence lost';
            if (subtype === 'Room.AddPlayer') return 'Player arrived';
            if (subtype === 'Room.RemovePlayer') return 'Player left';
            if (subtype === 'Room.Info') return 'Room changed';
            if (subtype === 'IRE.Target.Set') return 'Target changed';
        }
        return undefined;
    }

    static event_description (type, subtype) {
        if (type === 'GMCP') {
            if (subtype === 'Char.Vitals') return 'The client has received a block of text. <b>Note that unconditionally sending commands from this event is a VERY bad idea.</b>';
            if (subtype === 'Char.Afflictions.Add') return 'Affliction received, name is in <b>@match</b>.';
            if (subtype === 'Char.Afflictions.Remove') return 'Affliction cured, name is in <b>@match</b>.';
            if (subtype === 'Char.Defences.Add') return 'Defence activated, name is in <b>@match</b>.';
            if (subtype === 'Char.Defences.Remove') return 'Defence lost, name is in <b>@match</b>.';
            if (subtype === 'Room.AddPlayer') return 'A player has entered your room, name is in <b>@match</b>.';
            if (subtype === 'Room.RemovePlayer') return 'A player has left your room, name is in <b>@match</b>.';
            if (subtype === 'Room.Info') return 'Moved to another room, the room ID is in <b>@match</b>.';
            if (subtype === 'IRE.Target.Set') return 'Current target changed, the name is in <b>@match</b>.';
        }
        return undefined;
    }

    static handle_event(type, subtype, args, nexus) {
        let match = new Match ('', 'exact');
        match.set_match(0, args, [ args ], null);

        var list = nexus.reflexes().get_active_list('event');
        for (var id = 0; id < list.length; ++id) {
            if ((list[id].evtype !== type) || (list[id].evsubtype !== subtype)) continue;

            var actions = new ActionList(nexus);
            actions.prepare ('event', list[id], match);
            actions.execute (0);
        }
    }


}



