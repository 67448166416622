
// Buttons page

import React from 'react';

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import { SettingsButtons_Base } from './buttons_base'

export class SettingsButtons extends SettingsButtons_Base {
    constructor(props) {
        super(props);
        this.state = { sel: 1 };
    }

    renderButton(id) {
        let b = this.getButton(id);

        let rows = [];

        let el = (<TextField key='buttons_text' label='Button text' value={b.text} onChange={(e)=>this.setButtonValue(id, 'text', e.target.value)} />);
        rows.push(el);

        el = (<TextField key='buttons_commands' multiline={true} label='Commands' value={b.commands} onChange={(e)=>this.setButtonValue(id, 'commands', e.target.value)} />);
        rows.push(el);

        el = (<TextField key='buttons_script' multiline={true} label='Script' value={b.script} onChange={(e)=>this.setButtonValue(id, 'script', e.target.value)} />);
        rows.push(el);

        el = (<Switch key='buttons_target' checked={b.target_picker} onChange={(ev) => this.setButtonValue(id, 'target_picker', ev.target.checked)} />);
        let label = (<span>Expand <b>@tar</b> to the current target</span>);
        el = (<FormControlLabel key='buttons_target_label' control={el} label={label} />);
        rows.push(el);

        el = (<div key='buttons_default_div'><Button key='buttons_default' onClick={()=>this.setDefault(id)}>Restore Defaults</Button></div>);
        rows.push(el);
        rows = (<div className='buttons_editor' style={{display:'flex', flexDirection:'column'}}>{rows}</div>);

        let text = 'Button #' + id + ': ' + b.text;

        let section = this.formSection (text, rows);
        return section;
    }

    renderList() {
        let buttons = this.props.buttons;
        let els = [];

        els.push ((<div key='buttons_caption'>Button count:</div>));
        let bcount = (<Slider key='btn_count' style={{marginTop:'40px'}} value={buttons.count} className='creation_age_slider' min={4} max={12} step={1} valueLabelDisplay='on' onChange={(e,val)=>this.onCountChange(val)} />);
        els.push (bcount);
        
        let fkeys = this.renderBoolSetting('allow_button_keys', 'Enable F-keys', 'If enabled, The F1,F2,... keys can be used to trigger button actions.');
        fkeys = (<div key='allow_button_keys' style={{marginTop:'10px', marginBottom: '10px'}}>{fkeys}</div>);
        els.push(fkeys);

        // and the actual key list
        for (let i = 1; i <= buttons.count; ++i) {
            let idx = i;
            let b = this.getButton(idx);
            let text = 'F' + idx + ': ' + b.text;
            let style = {padding: '3px'};
            let el = (<ListItem key={'button_'+i} style={style} button onClick={()=>this.onSelect(idx)} selected={this.state.sel === i}><ListItemText>{text}</ListItemText></ListItem>);
            els.push (el);
        }
        els = (<List key='buttons_list'>{els}</List>);
        return els;
    }

    render() {
        let left = this.renderList();
        let right = this.state.sel ? this.renderButton(this.state.sel) : (<div>&nbsp;</div>);
        
        let res = (<div style={{display:'flex', width: '100%'}}><div style={{width: '250px', marginRight:'30px'}}>{left}</div><div style={{flexGrow:1}}>{right}</div></div>);
        return res;
    }
}

