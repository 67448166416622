
// game info - non-IRE

import { Util } from '../..//base/util.js'

export class GameInfoGeneric {
    constructor(info) {
        this._info = info;
        this.name = info ? info['name'] : 'No game selected';
        this.server = info ? info['server'] : '';
        this.port = info ? info['port'] : 0;
        this._gauges = [];
        this._balances = [];
        this._gmcp = false;
    }

    set_using_gmcp(gmcp) {
        this._gmcp = gmcp;
        this._gauges = [];
        if (gmcp) {
            this._gauges.push ({ name : 'health', 'desc' : 'Health', curvar : 'hp', maxvar : 'maxhp', percentage: true, color : '#ff0000', coloroff: '#7f7f7f',
                tooltip : 'Health represents your physical well-being. When your health reaches 0 you will die.'
            });
            this._gauges.push ({ name : 'mana', 'desc' : 'Mana', curvar : 'mp', maxvar : 'maxmp', percentage: true, color : '#5050ff', coloroff: '#7f7f7f',
                tooltip : 'Mana represents mental strength. When you are out of mana, you will not be able to perform most mental actions.'
            });
        }

    }
    
    server_name(secondary = false) {
        if (secondary) return 'ironrealms.com';
        return 'anvil.ironrealms.com';
    }

    server_port(encrypted, secondary = false) {
        if (secondary) return 12010;
        if (encrypted) return 443;
        return 80;
    }

    is_ire_game() { return false; }
    using_gmcp() { return this._gmcp; }
    websock_dir() { return '/socket/'; }
    game_short() { return Util.ucfirst (this.name); }
    game_name() { return this.game_short().toLowerCase(); }
    game_long() { return this.game_short(); };
    files_url() { return '/games/others/' }
    vote_url() { return null; };
    password_url() { return null; };
    script_url() { return null };
    root_url() { return null; }
    text_creation() { return null; }
    button_count() { return 0; }
    css_style() { return 'standard'; }
    gauges() { return this._gauges; }
    balances() { return this._balances; }
    rift_name() { return null; }
    command_rift_in() { return null; }
    command_rift_out() { return null; }
    money() { return 'Money'; }
    intro_sound() { return 'ironrealms'; }
    avatar_count() { return 0; }

    google_analytics() { return null; }
    
    default_tabs() {
        if (this._gmcp) {
            return {
                el_version: 2,
                container_1: [],
                container_2: [ 'inventory' ],
                container_3: [ 'room', 'who' ],
                container_4: [ 'all_comm' ],
                disabled: [ 'gauges', 'buttons' ],
                main_container: [ "output_main" ]
            };
        }
        return {
            el_version: 2,
            main_container: [ "output_main" ]
        };
    }


    // creation
    creation_stages() { return 0; }
    creation_valid_choice(type, id, creation_dlg) { return false; }
    creation_step(step) { return ''; }
    creation_title(step) { return ''; }

    creation_prepare_data(data) { return data; }

    genders() { return [ 'Male', 'Female' ]; }
    show_age() { return false; }

    creation_genderreq (type, e) { return null; }
    creation_genderreq_text(type, e) { return null; }
    creation_data(creation_dlg) { return null; }
    creation_nameform_image(data, creation_dlg) { return null; }

}
