
// Import/export page

import React from 'react';

import { FormSection } from './settingcomps'

export class SettingsImport_Base extends React.Component {
    constructor(props) {
        super(props);
        this.state = { oldSettings: [] };
    }

    componentDidMount() {
        let nex = this.props.nexus;
        nex.available_systems().then((list) => {
            let lst = [];
            for (let idx = 0; idx < list.length; ++idx)
                if (list[idx]) lst.push (list[idx]);
            this.setState({oldSettings: lst});
        });
    }

    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    onDownload() {
        let nex = this.props.nexus;
        var now = new Date();
        var fname = 'reflexes-' + now.getFullYear() + '-' + (now.getMonth()+1) + '-' + now.getDate() + ".nxs";
        let data = nex.encode_system(false);
        nex.platform().do_download(data, fname);
    }

    onRequestOld(age) {
        let nex = this.props.nexus;
        nex.platform().confirm('Load Older Settings', 'Loading settings from ' + age + ' days ago will overwrite your current ones. Do you want to proceed?', () => {
            nex.load_system (age, true);
        });
    }

    try_import_system(data) {
        let nex = this.props.nexus;
        if (data.length === 0) {
            nex.platform().alert('Import', 'This does not seem to be a valid settings file.');
            return;
        }
        if (!nex.import_system(data, false)) return;
        nex.platform().alert('Import', 'The settings have been imported.');
    }

    render() {
        return null;
    }
}

 
