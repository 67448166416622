
// wrapper around the platform's XML parser
export class XmlParser {
    constructor() {
        this.parsed = null;
        this.domparser = new DOMParser();
    }

    parse(text) {
        this.parsed = this.domparser.parseFromString(text, 'text/xml');
        let rel = this.parsed.documentElement;
        if (rel.nodeName === 'parsererror') {
            this.parsed = null;
            this.error = rel.textContent;
            return false;
        }
        return true;
    }

    error() {
        return this.error;
    }

    mainElement() {
        if (!this.parsed) return null;
        return this.parsed.documentElement;
    }

    elementName(el) {
        return el.nodeName;
    }

    elementAttrib(el, name) {
        return el.getAttribute (name);
    }

    elementChildren(el) {
        let res = [];
        for (let idx = 0; idx < el.childNodes.length; ++idx) {
            let child = el.childNodes[idx];
            if (child.nodeType !== 1) continue;   // elements only
            res.push (child);
        }
        return res;
    }
}

 
