
// game popups

import React from 'react';

import Popper from '@mui/material/Popper';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

import { NexusDraggable }  from '../nexusdraggable'

export class TextWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    mxpLink (e)
    {
        let link = e.target.closest('a');
        if(!link) return;
        e.preventDefault();

        let command = link.rel;
        if (!command.length) return;

        let isprompt = link.classList.contains ('mxp_prompt');
        let split = command.split(/\|/g);
        if (split.length === 1)
        {
            this.props.on_command (command, isprompt);
            return;
        }

        // no MXP menus here
    }

    renderLines(lines) {
        if (!lines) return null;
        let output = [];
        for (let idx = 0; idx < lines.length; ++idx) {
            let txt = lines[idx].parsed_line;
            let lineData = (txt ? txt.formatted() : '');
            if (lines[idx].html_line) lineData = lines[idx].html_text;
            let lineclass = 'mono';
            let ldata = (<div key={'dlglines-'+idx} className={lineclass} onClick={(e) => this.mxpLink(e)} dangerouslySetInnerHTML={{__html: lineData }}></div>);
            output.push (ldata);
        }
        return (<div className='content textwindow_content'>{output}</div>);
    }

    render() {
        let id = this.props.id;
        let nex = this.props.nexus;
        let offsetId = this.props.offsetId;
        let fontsize = nex.settings().calc_fontsize();
        let style = { fontSize: fontsize };
        let hstyle = {};
        let cstyle = { position:'absolute', top:0, right:0 };
        let closeButton = (<IconButton aria-label="close" size='small' style={cstyle} onClick={() => this.props.onClose()} ><CancelIcon /></IconButton>);

        let header = this.props.header;
        let content = this.renderLines(this.props.lines);
        
        content = (<div style={style} id={'textwindow-'+id} key={'textwindow-'+id} className='textWindow nexusDialog'><div style={hstyle} className='handle nexusDialogHeader'>{header}{closeButton}</div>{content}</div>);
        content = (<NexusDraggable handle='.handle' platform={nex.platform()} key={'textwindow-handle-'+id} position={{x: 'center', y: 'center'}} offsetId={offsetId}>{content}</NexusDraggable>);
        let dlg = (<Popper aria-labelledby="text-window" key={'textwindow-popper-'+id} open={this.props.open} placement='top-end' >{content}</Popper>);
        return dlg;
    }

}
 
