
// game popups

import React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

export class PopupDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { noshowChecked: false };
    }

    noShowClick(s) {
        this.setState({noshowChecked:s});
    }

    onDone() {
        if (this.state.noshowChecked) this.props.handleNoShow(this.props.id);
        this.props.onDone();
        this.props.setOpen(false);
    }
    
    onContinue() {
        if (this.state.noshowChecked) this.props.handleNoShow(this.props.id);
        this.props.onContinue();
        this.props.setOpen(false);
    }
    
    onBack() {
        if (this.state.noshowChecked) this.props.handleNoShow(this.props.id);
        this.props.onBack();
        this.props.setOpen(false);
    }

    render() {
        let id = this.props.id;
        if (!id) id = 'ire_popup';
        let commands = this.props.commands;
        if (!commands) commands = ['done'];
        let noshow = this.props.noshow;
        let content = this.props.content;
        let image = this.props.image;
        if (image)
            content = (<div key={id} id={id}><img src={image} alt='' height={400} width={600} /></div>);
        else
            content = (<div key={id} id={id} dangerouslySetInnerHTML={content} style={{width:'600px', height:'400px'}} />);
        if (noshow) {
            let checkbox = (<Checkbox checked={this.state.noshowChecked} onChange={(e)=>this.noShowClick(e.target.checked)} />);
            let el_noshow = (<div key='noshow'><FormControlLabel control={checkbox} label='Do not show this popup again' /></div>);
            content = [ content, el_noshow ];
        }

        let actions = [];
        // buttons
        if (commands.indexOf ("done") > -1)
        {
            actions.push (<Button key="btn_done" onClick={()=>this.onDone()} color="primary">Done</Button>);
        } else if (commands.indexOf ("continue") > -1)
        {
            actions.push (<Button key="btn_continue" onClick={()=>this.onContinue()} color="primary">Continue</Button>);
        }
        if (commands.indexOf ("back") > -1)
        {
            actions.push (<Button key="btn_back" onClick={()=>this.onBack()} color="primary">Back</Button>);
        }

        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.props.setOpen(false)}
                maxWidth={'md'}
                aria-labelledby="editor-dialog"
                disableEscapeKeyDown={true}>
            <DialogContent>{content}</DialogContent>
            <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }
}
 
