import React from 'react';

import { SettingBool, FormSection } from './settingcomps'


export class SettingsColors_Base extends React.Component {
    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    renderBoolSetting(key, text, tooltip, inline=false) {
        let tips = this.props.platform.tooltips_shown();
        if (!tips) tooltip = null;
        return (<SettingBool key={key} keyname={key} text={text} tooltip={tooltip} inline={inline} settings={this.props.settings} onChange={(key, val)=>this.props.onChange(key, val)} />);
    }

    colorKey(id) {
        return 'color-'+id;
    }

    openColor(colorkey, open) {
        let st = {};
        st[colorkey+'-open'] = open;
        this.setState(st);
    }

    colorChanged(colorid, color) {
        this.props.onColorChange(colorid, color);
        this.openColor(this.colorKey(colorid), false);
    }

    render() {
        return null;
    }
}

 
