
// editor functionality

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

export class EditorDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { onSave: this.props.onSave, onCancel: this.props.onCancel };
        this.ref = React.createRef();
    }

    onSave() {
        let text = this.ref.current.value;
        this.state.onSave(text);
    }

    render() {
        let caption = this.props.caption;
        if (!caption) caption = 'Composer';
        let editor = (<TextField inputRef={this.ref} multiline={true} minRows={15} maxRows={30} fullWidth={true} inputProps={{className:'mono', style:{fontFamily:'LiberationMono'} }} defaultValue={this.props.text} />);
        let content = (<div className='paddedContent'>{editor}</div>);
        let actions = [];
        actions.push (<Button key="btn_cancel" onClick={this.state.onCancel}>Cancel</Button>);
        actions.push (<Button key="btn_save" onClick={()=>this.onSave()}>Save Composition</Button>);

        let setOpen = this.props.setOpen;
        return (
            <Dialog
                fullWidth={true}
                open={this.props.open}
                onClose={() => setOpen(false)}
                aria-labelledby="editor-dialog"
                maxWidth={'md'}
                disableEscapeKeyDown={true}>
            <DialogTitle id="editor-dialog">{caption}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }
}
