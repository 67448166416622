
import { Reflex } from './reflex.js'

export class ReflexFunction extends Reflex {
    constructor(name, nexus) {
        super(name, nexus);
        this.type = 'function';
        this.code = '';
    }

    encode() {
        var res = super.encode();
        res.code = this.code;
        return res;
    }

    apply (e, reflexes) {
        if (e['type'] !== this.type) return;  // sanity check
        super.apply (e, reflexes);
        this.code = e.code;
    }

    duplicate() {
        var res = new ReflexFunction(this.name, this._nexus);
        res.code = this.code;
        return res;
    }

    matches(txt) {
        if ((!txt) || (txt.length === 0)) return true;

        txt = txt.toLowerCase();

        if (this.code && this.code.toLowerCase().indexOf(txt) >= 0) return true;

        return super.matches(txt);
    }

    // We receive the package name as a param, instead of querying for it ourselves, because it's faster that way.
    exec(args, pkgname=undefined)
    {
        if ((!this.code) || (!this.code.length)) return false;

        this._nexus.exec_script(this.code, args, pkgname, 'Function [' + this.name + ']');
        return true;
    }

    static create_default_functions (nexus)
    {
        var reflexes = nexus.reflexes();
        // create functions that always exist (unless deleted in settings)
        if (!reflexes.find_function_by_name ('onLoad', false, null)) {
            let obj = new ReflexFunction ('onLoad', nexus);
            reflexes.append (obj, reflexes.master_group());
            obj.code = "// Place any code here you'd like to autorun when the system is loaded";
        }
        if (!reflexes.find_function_by_name ('onGMCP', false, null)) {
            let obj = new ReflexFunction ('onGMCP', nexus);
            reflexes.append (obj, reflexes.master_group());
            obj.code = "// Place any code here you'd like to run when a GMCP message is received\n" +
                       "// - The GMCP message is received as \"args.gmcp_method\" and \"args.gmcp_args\"";
        }
        if (!reflexes.find_function_by_name ('onBlock', false, null)) {
            let obj = new ReflexFunction ('onBlock', nexus);
            reflexes.append (obj, reflexes.master_group());
            obj.code = "// Place any code here you'd like to run when a text block is received\n" +
                       "// - Use current_block[0], current_block[1], ... to access the lines";
        }
    }


}




