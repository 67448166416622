
import React from 'react';

import { SettingBool, FormSection } from './settingcomps'

// name, desc, curvar, maxvar, percentage, is_variable, color, coloroff, tooltip

export class SettingsGauges_Base extends React.Component {
    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    setGaugeValue(id, which, value) {
        let gauges = this.props.gauges;
        let g = gauges.get(id);
        if (!g) return;
        g[which] = value;
        gauges.change(id, g.name, g.desc, g.curvar, g.maxvar, g.percentage, g.is_variable, g.color, g.coloroff, g.tooltip);
        this.setState({updated:1});
    }

    renderBoolSetting(key, text, tooltip, inline=false) {
        let tips = this.props.platform.tooltips_shown();
        if (!tips) tooltip = null;
        return (<SettingBool key={key} keyname={key} text={text} tooltip={tooltip} inline={inline} settings={this.props.settings} onChange={(key, val)=>this.props.onChange(key, val)} />);
    }

    onSelect(idx) {
        this.setState({sel:idx});
    }

    onDefaults() {        
        let gauges = this.props.gauges;
        gauges.reset_to_defaults();
        this.setState({updated:1});
    }

    onAdd() {        
        let gauges = this.props.gauges;
        let id = gauges.add('New gauge', '', '', '', true, true, '#ffffff', '#808080', '');
        this.setState({updated:1, sel:id});
    }

    onDelete(id) {
        let gauges = this.props.gauges;
        gauges.remove(id);
        this.setState({updated:1});
    }

    render() {
        return null;
    }
}

 
 
 
 
