
// login screen functionality

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LoginBaseDialog } from './loginbase'

export class LoginDialog extends LoginBaseDialog {

    onKeyPress(e) {
        if (e.charCode !== 13) return;
        // submitting the currently displayed form
        if (this.state.page === 0) this.onLogin();
        if (this.state.page === 1) this.onCreate();
        if (this.state.page === 2) this.onForgot();
        e.preventDefault();
    }

    renderLoginPage() {
        let iemail = (<TextField autoFocus key="iemail" style={{width:'240px'}} value={this.state.login_email} onChange={(e)=>this.setState({login_email:e.target.value})} label="E-mail" />);

        let ptype = this.state.showpass ? 'text' : 'password';
        let picon = this.state.showpass ? 'eye-slash' : 'eye';
        let btn_showpass = (<FontAwesomeIcon style={{height:'24px',width:'24px',cursor:'pointer',paddingLeft:'5px'}} icon={['fad', picon]} onClick={() => this.toggleShowPass()} />);
        let ptoggle = (<InputAdornment key="adornPassword" position="end">{btn_showpass}</InputAdornment>);
        let ipass = (<TextField key="ipass" style={{width:'240px'}} value={this.state.login_pass} onChange={(e)=>this.setState({login_pass:e.target.value})} label="Password" type={ptype} InputProps={{endAdornment:[ptoggle]}} />);

        let iremember = (<Switch key='iremember' checked={this.state.remember} onChange={(e) => this.setState({remember: e.target.checked}) } />);
        iremember = (<FormControlLabel key='iremember' control={iremember} label={'Remember me'} />);
        let login = (<Button variant="contained" style={{margin:'3px'}} onClick={() => { this.onLogin() }} color="secondary">Login to Nexus</Button>);
        let back = (<Button variant="contained" style={{margin:'3px'}} onClick={() => { this.props.setOpen(false) }}>Close</Button>);
        let buttons = (<div style={{display:'flex',flexDirection:'column'}}>{login}{back}</div>);
        let show_forgot = (<span style={{cursor:'pointer'}} onClick={()=>this.showForgot()}>Forgot Password</span>);
        let show_creation = (<span style={{cursor:'pointer'}} onClick={()=>this.showCreation()}>New to Nexus? Create Account</span>);
        
        return [ iemail, ipass, iremember, buttons, show_forgot, show_creation ];
    }
    
    renderCreatePage() {
        let icemail = (<TextField key="cemail" value={this.state.new_email} onChange={(e)=>this.setState({new_email:e.target.value})} label="E-mail" />);
        let icpass = (<TextField key="cpass" value={this.state.new_pass} onChange={(e)=>this.setState({new_pass:e.target.value})} label="Password" type='password' />);
        let icpass2 = (<TextField key="cpass2" value={this.state.new_pass2} onChange={(e)=>this.setState({new_pass2:e.target.value})} label="Confirm Password" type='password' />);
        let create = (<Button variant="contained" style={{margin:'3px'}} onClick={() => { this.onCreate(); }} color="secondary">Create Account</Button>);
        let btn_back;
        
        if (this.props.showregister) btn_back = (<Button variant="contained" style={{margin:'3px'}} onClick={() => { this.props.setOpen(false) }}>Close</Button>);
        else btn_back = (<Button variant="contained" style={{margin:'3px'}} onClick={() => { this.showLogin() }}>Back to Login</Button>);

        return [ icemail, icpass, icpass2, create, btn_back ];
    }
    
    renderForgotPage() {
        let finfo = 'Please enter the e-mail address you registered with, and we will send you instructions to reset the password.'
        let femail = (<TextField autoFocus key="femail" value={this.state.forgot_email} onChange={(e)=>this.setState({forgot_email:e.target.value})} label="E-mail" />);
        let forgot = (<Button variant="contained" onClick={() => { this.onForgot(); }} color="secondary">Submit</Button>);
        let show_login = (<Button variant="contained" style={{margin:'3px'}} onClick={() => { this.showLogin() }}>Back to Login</Button>);
        
        return [ finfo, femail, forgot, show_login ];
    }

    render() {
        let maincontent;
        if (this.state.page === 0) maincontent = this.renderLoginPage();
        if (this.state.page === 1) maincontent = this.renderCreatePage();
        if (this.state.page === 2) maincontent = this.renderForgotPage();
        let content = [];
        // wrap the elements in div's
        for (let i = 0; i < maincontent.length; ++i)
            content.push ((<div key={'el-'+this.state.page+'-'+i}>{maincontent[i]}</div>));
        
        content = (<div key="login_main" className='login_main' onKeyPress={(e) => this.onKeyPress(e)} >{content}</div>);
        let backdropStyle={backgroundSize: 'contain', backgroundColor: 'rgb(0,0,0)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: 'url("images/background_login.jpg")' };
//        let backdropStyle={backgroundSize: 'cover', backgroundImage: 'url("/images/login/login-background.jpg")'};

        let dlg_caption = this.dialogCaption();

        return (
            <Dialog
                className='login_dialog'
                open={this.props.open}
                onClose={() => this.props.setOpen(false)}
                PaperProps={{style: {overflow:'visible', backgroundColor: 'rgba(0,0,0,0.4)'} } }
                aria-labelledby="login-dialog"
                disableEscapeKeyDown={false}
                BackdropProps={{style:backdropStyle}}>
            <DialogTitle id="login-dialog">{dlg_caption}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
            </DialogActions>
            </Dialog>
        );
    }
}
