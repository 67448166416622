
// settings - the "color" page

import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';

import { ChromePicker } from 'react-color';

import { SettingsColors_Base } from './colors_base'

export class SettingsColors extends SettingsColors_Base {
    constructor(props) {
        super(props);
        this.state = {};
        this.reflist = {};
    }

    renderColorEntry(i) {
        let s = this.props.settings;
        let color = s.ansi_color_code(i);
        let defcolor = s.ansi_color_default(i);

        let colorkey = this.colorKey(i);
        let open = this.state[colorkey+'-open'] ? true : false;
        let bstyle = { backgroundColor: color };
        let defstyle = { backgroundColor: defcolor, whiteSpace:'nowrap' };
        if (!this.reflist[i]) this.reflist[i] = React.createRef();
        
        let colortext = s.get_ansi_color_name(i);
        let label = (<span>{colortext}</span>);
        let button = (<Button key={colorkey+'-button'} ref={this.reflist[i]} style={bstyle} onClick={()=>{ if (!open) this.openColor(colorkey, !open)} }>Change Color</Button>);
        let reset = (<Button key={colorkey+'-default'} style={defstyle} onClick={()=>this.colorChanged(i, defcolor)}>Reset to Default</Button>);

        let picker = (<ChromePicker color={color} onChange={(color)=>this.colorChanged(i, color.hex)} disableAlpha={true} />);
        let anchorOrigin={ vertical: 'bottom', horizontal: 'center' };
        let transformOrigin = { vertical: 'top', horizontal: 'center' };
        let popup = (<Popover style={{zIndex:8300}} key={colorkey+'-popup'} open={open} anchorEl={this.reflist[i].current} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} onClose={()=>this.openColor(colorkey,false)}>{picker}</Popover>);
        let res = (<Grid container key={colorkey+'-main'}><Grid item xs={3}>{label}</Grid><Grid item xs={3}>{button}</Grid><Grid item xs={3}>{reset}</Grid>{popup}</Grid>);
        return res;
    }

    render() {
        let sett = this.props.settings;

        let hint = (<div key='hint'>This screen allows you to configure the display colors. The purpose of this screen is to make colors lighter or darker as needed - if you want to change the color of the various game elements, use the <b>CONFIG COLOR</b> command in the game.</div>);

        let reverse = this.renderBoolSetting('reverted', 'Show inverted colors', 'If checked, the background color for the main output and communication windows will be inverted from black to white. This does not change the text color. Text colors may be edited in color settings or with the CONFIG COLOR command.');

        let reverse_warning = null;
        if (sett.reverted) {
            let rstyle = {color: '#ff0000'};
            reverse_warning = (<div key='warning' style={rstyle}>You are using <b>reverted colors</b> - the displayed colors will not match their names. Existing game output will not be affected.</div>);
        }
        let colors1 = [];
        let colors2 = [];
        for (let i = 0; i < 8; ++i) {
            colors1.push (this.renderColorEntry(i));
            colors2.push (this.renderColorEntry(i+8));
        }
        
        let cstyle = { display: 'flex', flexDirection: 'row' };
        let cinstyle = { flexGrow: 1, flexBasis: '50%', minWidth: '400px' };
        let colors = (<div style={cstyle}><div style={cinstyle}>{colors1}</div><div style={cinstyle}>{colors2}</div></div>);
        colors = this.formSection ('Colors', [ hint, reverse, reverse_warning, colors ], 'colors');
        return colors;
    }
    
}
