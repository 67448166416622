
import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export class ConfirmDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onKeyPress(e) {
        if (e.charCode !== 13) return;
        let setOpen = this.props.setOpen;
        let onConfirm = this.props.onConfirm;
        setOpen(false);
        if (onConfirm) onConfirm();
        e.preventDefault();
    }

    render() {
        let title = this.props.title;
        let open = this.props.open;
        let content = (<div className="confirm_content">{this.props.children}</div>);
        let setOpen = this.props.setOpen;
        let onConfirm = this.props.onConfirm;
        let overElement = this.props.overElement;
        let container = (overElement && overElement.current) ? (()=>overElement.current) : undefined;

        return (
            <Dialog open={open} onKeyPress={(e) => this.onKeyPress(e)} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog" style={{zIndex:8500}} container={container}>
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setOpen(false)}>No</Button>
                <Button variant="contained" onClick={() => { setOpen(false); if (onConfirm) onConfirm(); }}color="secondary" >Yes</Button>
            </DialogActions>
            </Dialog>
        );
    }
}

export class AlertDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onKeyPress(e) {
        if (e.charCode !== 13) return;
        let setOpen = this.props.setOpen;
        let onClose = this.props.onClose;
        setOpen(false);
        if (onClose) onClose();
        e.preventDefault();
    }

    render() {
        let title = this.props.title;
        let open = this.props.open;
        let content = (<div className="alert_content">{this.props.children}</div>);
        let setOpen = this.props.setOpen;
        let onClose = this.props.onClose;
        let overElement = this.props.overElement;
        let container = (overElement && overElement.current) ? (()=>overElement.current) : undefined;
        
        return (
            <Dialog
                open={open}
                onKeyPress={(e) => this.onKeyPress(e)}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog"
                style={{zIndex:8550}}
                container={container}>
            <DialogTitle id="alert-dialog">{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => { setOpen(false); if (onClose) onClose(); }} color="secondary">Close</Button>
            </DialogActions>
            </Dialog>
        );
    }
}

export class InputDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { input: this.props.defaultText };
    }

    onKeyPress(e) {
        if (e.charCode !== 13) return;
        let setOpen = this.props.setOpen;
        let onConfirm = this.props.onConfirm;
        setOpen(false);
        if (onConfirm) onConfirm(this.state.input);
        e.preventDefault();
    }

    renderInput() {
        let cinput = (<TextField key="cinput" autoFocus value={this.state.input} label={this.props.label} type='text' onChange={(e)=>{ this.setState({input: e.target.value})} } />);
        return cinput;
    }

    render() {
        let title = this.props.title;
        let open = this.props.open;
        let content = this.props.children;
        let setOpen = this.props.setOpen;
        let onConfirm = this.props.onConfirm;
        let oktext = this.props.okText;
        let input = this.renderInput();

        content = (<div className='input_content'><div>{content}</div><div>{input}</div></div>);
        
        return (
            <Dialog open={open} onKeyPress={(e) => this.onKeyPress(e)} onClose={() => setOpen(false)} aria-labelledby="input-dialog" style={{zIndex:8499}}>
            <DialogTitle id="input-dialog">{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setOpen(false)}>Cancel</Button>
                <Button variant="contained" onClick={() => { setOpen(false); if (onConfirm) onConfirm(this.state.input); }} color="secondary">{oktext}</Button>
            </DialogActions>
            </Dialog>
        );
    }
}

export class PasswordDialog extends React.Component {
    constructor(props) {
        super(props);
        let pass = this.props.pass;
        if (!pass) pass = '';
        this.state = { password: pass, remember: (pass.length > 0) };
    }

    onKeyPress(e) {
        if (e.charCode !== 13) return;
        let setOpen = this.props.setOpen;
        let onConfirm = this.props.onConfirm;
        setOpen(false);
        if (onConfirm) onConfirm(this.state.password);
        e.preventDefault();
    }

    renderPassword() {
        let cpass = (<TextField key="cpass" autoFocus value={this.state.password} label="Password" type='password' onChange={(e)=>{ this.setState({password: e.target.value})} } />);
        return cpass;
    }

    renderRemember() {
        let iremember = (<Switch key='iremember' checked={this.state.remember} onChange={(e) => this.setState({remember: e.target.checked}) } />);
        iremember = (<FormControlLabel key='iremember' control={iremember} label={'Store password'} />);
        return iremember;
    }

    render() {
        let title = this.props.title;
        let open = this.props.open;
        let content = this.props.children;
        let setOpen = this.props.setOpen;
        let onConfirm = this.props.onConfirm;
        let oktext = this.props.okText;
        let pwd = this.renderPassword();
        let remember = this.props.remember ? this.renderRemember() : null;

        content = (<div className='input_content'><div>{content}</div><div>{pwd}</div><div>{remember}</div></div>);
        
        return (
            <Dialog id="password-dialog" open={open} onKeyPress={(e) => this.onKeyPress(e)} onClose={() => setOpen(false)} aria-labelledby="password-dialog" style={{zIndex:8499}}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setOpen(false)}>Cancel</Button>
                <Button variant="contained" onClick={() => { setOpen(false); if (onConfirm) onConfirm(this.state.password, this.state.remember); }} color="secondary">{oktext}</Button>
            </DialogActions>
            </Dialog>
        );
    }
}


