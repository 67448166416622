
export class SoundsBase {
    constructor(nexus) {
        this._nexus = nexus;
        this._settings = nexus.settings();
        this.playing_sounds = [];
        this.background_music = {};
        this.last_play = [];
        this.music_names = ["achaea", "aetolia", "imperian", "lusternia", "starmourn", "ironrealms"];
        this.volume = 50;
        this.on_volume_changed = function(volume){};
        
        this.sounds_list = [
            'alarm beep',
            'alarm beep 2',
            'alarm clock beep 3',
            'alarm',
            'anvil hit',
            'arcade sound',
            'arrow impact wood',
            'baby laugh',
            'bang',
            'beep click',
            'beep long',
            'beep',
            'beep repeating',
            'beep 2',
            'bell',
            'bike horn double honk',
            'bleep',
            'bleep 2',
            'blip sonar',
            'boom',
            'bottle clang',
            'branch snap',
            'bullwhip',
            'button click',
            'button pop',
            'cartoon arpeggio',
            'cartoon female come on',
            'cartoon female help',
            'cartoon female idea',
            'cartoon female really',
            'cartoon female tyvm',
            'cartoon fting',
            'cartoon happy monster',
            'cartoon hello',
            'cartoon male ah hah',
            'cartoon male alright!',
            'cartoon male fiddle faddle',
            'cartoon male flying',
            'cartoon male oh man',
            'cartoon male uh oh',
            'cartoon male yeah',
            'cartoon slip',
            'cartoon squeak',
            'cat meow',
            'cat meow 2',
            'clang',
            'clank',
            'click mouse',
            'click',
            'click 10',
            'click 11',
            'click 2',
            'click 3',
            'click 4',
            'click 5',
            'click 6',
            'click 7',
            'click 8',
            'click 9',
            'clock ding',
            'clock chime',
            'clock chime 2',
            'clock chime 3',
            'cock pop',
            'coin drop',
            'coin drop 2',
            'coin drop 3',
            'comic sound',
            'cowbell',
            'cow moo',
            'crowd gasp',
            'crunch bite',
            'desk bell',
            'ding',
            'ding 2',
            'dog bark',
            'dog bark 2',
            'door close',
            'door close 2',
            'door close 3',
            'door knock',
            'door knock 2',
            'door lock',
            'drum',
            'drums 2',
            'eagle',
            'electric',
            'emergency',
            'emergency siren',
            'exhale',
            'explosion',
            'explosion 2',
            'finger snap',
            'firecracker',
            'game over',
            'gasp female',
            'girl giggle',
            'glass break',
            'glass clink',
            'glass jar break',
            'growl',
            'gruntasaurus',
            'horn',
            'horse neigh',
            'horse snort',
            'horse whinny',
            'jump',
            'kiss',
            'knifes',
            'knives 2',
            'lamb',
            'laugh evil male',
            'laugh evil male 2',
            'laugh evil male 3',
            'lion growl',
            'lion roar',
            'monkey',
            'monster roar',
            'monster roar 2',
            'pig',
            'pop',
            'punched male',
            'punched',
            'punched 2',
            'rewind',
            'shave and a haircut',
            'shimmer',
            'shimmer 2',
            'shimmer 3',
            'sigh male',
            'slide',
            'slide 2',
            'snake',
            'spell',
            'spell 2',
            'spell 3',
            'spell 4',
            'spell 5',
            'spell 6',
            'spell 7',
            'spell 8',
            'spring',
            'submarine diving',
            'suction',
            'swoosh deep',
            'swoosh deep 2',
            'swoosh',
            'swoosh 2',
            'swoosh 3',
            'thud',
            'violin ascending',
            'wet tongue',
            'whistle',
            'whistle 2',
            'whoo',
            'xylophone',
            'xylophone 3',
            'xylpophone 2',
            'yell girl',
            'yell male',
            'yell woman',
            'zip'
        ];

    }

    // These need to be overridden by the implementation class.
    _sound_object(path, preload) { return null; }
    _do_stop_sound(sound, fadeout) {}
    _do_play_sound(name, fadein, fadeout, end_callback) {}
    _do_play_background_music(name) {}
    stop_music() {}
    is_music_playing() { return false; }
    _do_set_volume(volume) {}


    no_sounds() {
        // TODO do we want music on mobile?
        return false;
    }

    _get_playing_sound(name) {
        for (var i = 0; i < this.playing_sounds.length; ++i)
            if (this.playing_sounds[i].name === name)
                return i;
        return false;
    }

    preload_sound(name) {
        return this._sound_object(name, true);
    }

    play_sound(name, fadein, fadeout) {
        if (this.no_sounds()) return;
        if (this.volume <= 0) return;

        let t = this;

        t._nexus.platform().set_timeout(function () {
            let sound_obj = t._do_play_sound (name, fadein, fadeout, function () {
                for (var i = 0; i < t.playing_sounds.length; ++i)
                    if (t.playing_sounds[i] === sound_obj)
                        t.playing_sounds.splice(i,1);
            });
            t.playing_sounds.push(sound_obj);
        },0);
    }

    stop_sound(name, fadeout) {
        if (this.no_sounds()) return;
        var i = this._get_playing_sound(name);
        if (i === false) return;

        this._do_stop_sound (this.playing_sounds[i], fadeout);
        this.playing_sounds.splice(i, 1);
    }

    stop_all_sounds(fadeout) {
        if (this.no_sounds()) return;
        for (var i = 0; i < this.playing_sounds.length; ++i)
            this._do_stop_sound (this.playing_sounds[i], fadeout);
        this.playing_sounds = [];
    }

    start_music(intro_sound) {
        if (!this._settings.play_music) return;
        
        this.last_play = [];
        this.last_play.push (intro_sound);
        this.play_background_music (intro_sound);
    }

    update_music(intro_sound, connected) {
        if ((!this._settings.play_music) || (!connected)) {
            this.stop_music();
            return;
        }
        if (this.is_music_playing()) return;
        this.start_music(intro_sound);
    }

    play_next_music() {
        if (this.no_sounds()) return;
        if (!this._settings.play_music) return;

        // stop everything first, for some reason we sometimes get 2+ playing at once
        this.stop_music();

        // let's pick amongst those that were not played recently
        if (this.last_play.length > 2) this.last_play.splice(0, this.last_play.length - 2);
        let picks = [];
        for (let m = 0; m < this.music_names.length; ++m) {
            let n = this.music_names[m];
            if (this.last_play.indexOf(n) === -1) picks.push(n);
        }
        let choice = Math.floor(Math.random() * picks.length);
        let name = picks[choice];
        this.last_play.push(name);
        // start the next one in a second
        let t = this;
        t._nexus.platform().set_timeout(function () {
            t.play_background_music(name);
        }, 1000);
    }

    set_volume(val) {
        if (this.no_sounds()) return;

        if (val > 100) val = 100;
        let volume = Math.floor(val);

        this._do_set_volume (volume);

        this.volume = volume;
        this.on_volume_changed (volume);
    }

    play_background_music(name) {
        if (this.no_sounds()) return;
        if (!this._settings.play_music) return;

        let t = this;
        t._do_play_background_music(name);
        t.set_volume(t.volume);   // set volume correctly, or pause if desired
    }


}



 
