
// Variables page

import React from 'react';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

import { SettingsVariables_Base } from './variables_base'

export class SettingsVariables extends SettingsVariables_Base {
    constructor(props) {
        super(props);
        this.state = { newvar: '', filter: '' };
    }

    render() {
        let el_varname = (<TextField key='add-variable' value={this.state.newvar} onChange={(e)=>this.setState({newvar:e.target.value})} placeholder='Variable Name' />);
        let el_addbutton = (<Button onClick={()=>this.varChange(this.state.newvar,'')}>Add Variable</Button>);
        let newvar = (<div key='newvar' style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}><span style={{paddingRight:'15px'}}>Add a new variable: </span>{el_varname}&nbsp;{el_addbutton}</div>);
        let section1 = this.formSection ('Add a Variable', newvar);
        
        let desc = (<div key='desc'><p>Variables are storage mechanisms that can be used in reflexes. &nbsp;They will be saved in their current states when you save or export your settings.</p><p>In addition to using the Variables tab in the settings window, there are two ways to manipulate variables: from the command line and in user-defined functions.</p><p>At the command line, you may set the value of a variable (which will also add it if it doesn&#39;t exist) by typing, &quot;@set &lt;variable_name&gt; &lt;variable_value&gt;&quot;</p></div>);

        let vars = this.props.vars;

        let filter = (<div key='filter'><TextField key='filter-variable' value={this.state.filter} onChange={(e)=>this.onFilter(e.target.value)} label='Filter' /></div>);
        
        let rows = [];
        let vlist = vars.defined_variables();
        vlist.sort();
        for (let idx = 0; idx < vlist.length; ++idx) {
            let varname = vlist[idx];
            let value = String(vars.get(varname));

            let namecell = (<TableCell>{varname}</TableCell>);
            let valuecell = (<TableCell><TextField value={value} onChange={(e)=>this.varChange(varname,e.target.value)} /></TableCell>);
            let removecell = (<TableCell><Button size='small' onClick={()=>this.varRemove(varname)}>Remove</Button></TableCell>);
            let style = {};
            if (this.state.filter && (!varname.includes(this.state.filter))) style.display='none';
            
            let row = (<TableRow style={style} key={'var-'+varname}>{namecell}{valuecell}{removecell}</TableRow>);
            rows.push(row);
        }

        let varheader = (<TableHead><TableRow><TableCell>Name</TableCell><TableCell>Value</TableCell><TableCell></TableCell></TableRow></TableHead>);
        let vartable = (<TableContainer component={Paper}><Table size="small" aria-label="variables">{varheader}<TableBody>{rows}</TableBody></Table></TableContainer>);
        let vars_el = (<div>{desc}<hr />{filter}{vartable}</div>);

        let section2 = this.formSection ('Variable List', vars_el);
        return (<div>{section1}{section2}</div>);
    }

}
