
import { Util } from '../base/util.js'

export class Variables {
    constructor(on_change) {
        this.vars = {};
        this.onChange = on_change;
    }

    clear() {
        this.vars = {};
    }

    encode() {
        return this.vars;
    }

    apply(data) {
        this.vars = data;
    }

    set(name, value)
    {
        if (typeof name != 'string') return;
        if (name.indexOf ('"') >= 0) return;
        if (name.indexOf ('\\') >= 0) return;
        name = name.trim();
        if (name[0] === '@') name = name.substr(1);

        this.vars[name] = value;
        this.onChange(name);
    }

    get(name)
    {
        if (name[0] === '@') name = name.substr(1);
        return this.vars[name];
    }

    defined_variables()
    {
        return Object.keys(this.vars);
    }

    del(name)
    {
        if (name[0] === '@') name = name.substr(1);
        delete this.vars[name];
        this.onChange(name);
    }

    inc(name, by)
    {
        by = parseFloat(by);
        if (isNaN(by)) return;
        var v = Util.to_number(this.get(name) + by);
        this.set(name, v);
        return v;
    }

    dec(name, by)
    {
        by = parseFloat(by);
        if (isNaN(by)) return;
        var v = Util.to_number(this.get(name)) - by;
        this.set(name, v);
        return v;
    }

    mul(name, by)
    {
        by = parseFloat(by);
        if (isNaN(by)) return;
        var v = Util.to_number(this.get(name)) * by;
        this.set(name, v);
        return v;
    }

    div(name, by)
    {
        by = parseFloat(by);
        if (isNaN(by)) return;
        if (by === 0) return;
        var v = Util.to_number(this.get(name) / by);
        this.set(name, v);
        return v;
    }

    expand(input, varobj)
    {
        if ((input == null) || (input.search('@') < 0)) return input;

        if (!varobj) varobj = this.vars;

        // parse variables
        var res = '';
        var state = 0;
        var variable = '';
        for (var i = 0; i < input.length; ++i) {
            var l = input[i];
            switch (state) {
                case 0: // not in a variable
                    if (l === '@') {
                        variable = '';
                        state = 1;
                    } else
                        res += l;
                    break;
                case 1:  // in simple variable (@name) or at the beginning of a @{name} block
                    if ((l === '{') && (variable.length === 0))
                        state = 2;
                    else if (Util.is_alphanumeric(l))
                        variable += l;
                    else
                    {
                        if (variable.length && varobj[variable] !== undefined)
                            res += varobj[variable];
                        else
                            res += '@' + variable;
                        state = 0;
                        variable = '';
                        if (l === '@') state = 1;
                        else res += l;
                    }
                    break;
                case 2:  // in @{blah} variable
                    if (l === '}') {
                        if (variable.length && varobj[variable] !== undefined)
                            res += varobj[variable];
                        else
                            res += '@{' + variable + '}';
                        state = 0;
                        variable = '';
                    } else if (Util.is_alphanumeric(l)) {
                        variable += l;
                    }
                    else {
                        res += '@{' + variable;
                        state = 0;
                        if (l === '@') state = 1;
                        else res += l;
                    }
                    break;
                default: break;
            };
        }
        if (state === 1) {
            if (variable.length && varobj[variable] !== undefined)
                res += varobj[variable];
            else
                res += '@' + variable;
        }
        if (state === 2) {
            res += '@{' + variable;
        }
        return res;
    }

    // Convert variables from 2.6 and older versions
    convert_variables(vars)
    {
        if (!vars) return;
        for (var i = 0; i < vars.length; ++i)
            this.set (vars[i].name, vars[i].value);
    }



};



