
// Import/export page

import React from 'react';

import Button from '@mui/material/Button';
import PublishIcon from '@mui/icons-material/Publish';
import SaveIcon from '@mui/icons-material/Save';

import { SettingsImport_Base } from './import_base'

export class SettingsImport extends SettingsImport_Base {
    renderOldSettings() {
        let list = this.state.oldSettings;
        if (list === undefined) return (<div>Loading...</div>);
        if (!list.length) return (<div>No older settings are available.</div>);
        let buttons = [];
        for (let idx = 0; idx < list.length; ++idx) {
            let days = list[idx];
            let txt = 'Revert to settings from '+days+' day'+((days===1)?'':'s')+' ago';
            let button = (<div key={'days-'+days} style={{marginBottom:'5px'}}><Button variant='contained' color='primary' onClick={()=>this.onRequestOld(days)} startIcon={<PublishIcon />}>{txt}</Button></div>);
            buttons.push (button);
        }
        return buttons;
    }

    render() {
        let nex = this.props.nexus;
        let sections = [];

        let exports = [];
        let downloadBtn = (<Button variant='contained' size='large' color='primary' onClick={()=>this.onDownload()} startIcon={<SaveIcon />}>Save Settings To Disk</Button>);
        exports.push ((<div key='export1'>You can save your settings to your computer:</div>));
        exports.push ((<div key='export2' id="download_settings">{downloadBtn}</div>));
        sections.push (this.formSection ('Export Settings', exports, 'section_export'));

        let imports = [];
        let uploader = nex.platform().setup_uploader((name, data) => {
                this.try_import_system(data);
            }, (error) => {
                window.alert('We are sorry, but something went wrong while trying to load the file. ' + error);
            }
        );
        imports.push ((<div key='imports1'>You can also load the saved settings back into the client. <b>WARNING: This will overwrite your current settings.</b></div>));
        imports.push ((<div key='imports2'id="settings_loader">{uploader}</div>));
        sections.push (this.formSection ('Import Settings', imports, 'section_import'));

        let restores = [];
        restores.push ((<div key='restores1'>You can restore an older version of your settings:</div>));
        restores.push ((<div key='restores2' id="restore_older_settings">{this.renderOldSettings()}</div>));
        sections.push (this.formSection ('Restore older settings', restores, 'section_restores'));
        
        return sections;
    }
}

 
