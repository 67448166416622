
import { PasswordsBase } from './passwords_base'

import { EncryptStorage } from 'encrypt-storage'

export class Passwords extends PasswordsBase {

    // Encrypted password storage. The key is right here, so it's not particularly secure, but that's okay - full security is impossible as it's all client-side.
    constructor(nexus) {
        super(nexus);
        this.KEY = 'NeXuS_3?iRE';
        this.storage = new EncryptStorage(this.KEY, {});
    }

    do_fetch(key, resolve, reject)
    {
        let res = this.storage.getItem(key);
        resolve(res);
    }

    do_store(key, password, resolve, reject)
    {
        if (password === undefined)
            this.storage.removeItem(key);
        else
            this.storage.setItem(key, password);
        resolve();
    }

};

