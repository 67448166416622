

import React from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ActionEditorList_Base } from './actions_base'

export class ActionEditorList extends ActionEditorList_Base {
    constructor(props) {
        super(props);
        this.state['newactiontype'] = 'pick';
    }

    render_new_action_ui(rtype) {
        // interface for adding a new action
        let choices = this.actions_for_element(rtype);
        let opts = [];
        opts.push((<MenuItem key={'menuadd-pick'} value={'pick'}>{'-- select the action to add --'}</MenuItem>));
        for (let idx = 0; idx < choices.length; ++idx) {
            let ch = choices[idx];
            let caption = this.action_type_caption(ch);
            opts.push((<MenuItem key={'menuadd-'+ch} value={ch}>{caption}</MenuItem>));
        }
        let txtadd = (<CardHeader style={{flexGrow:0}} className='actionsAdd' disableTypography={true} title={'Add New Action'} />);
        let choices_el = (<TextField key='select_add' value={this.state.newactiontype} SelectProps={{ MenuProps: { style: {zIndex:5500} } }} onChange={(e)=>this.onNewAction(e.target.value)} select>{opts}</TextField>);

        let newact = (<Card key={'action-pick'} className='actionsAddNew' style={{display:'flex'}}>{txtadd}<CardContent className='actionsAddContent' style={{flexGrow:1, display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>{choices_el}</CardContent></Card>);
        
        let alist = (<div style={{marginTop:'10px'}} />);
        return [ alist, newact ];
    }

    onNewAction(type) {
        if (type === 'pick') return;
        this.newAction(type);
        this.setState({newactiontype:'pick'});
        if (this.props.onActionAdded) this.props.onActionAdded();
    }

    renderActionElement(a, orig_a, idx, rtype) {

        let entry = (<Draggable key={idx} draggableId={''+idx} index={idx}>
            {(provided, snapshot) => {
                let el = this.render_action(a, orig_a, idx, rtype);

                let isize = 20;
                let clone = (<FontAwesomeIcon key={'clone-'+idx} size={isize} icon={['fas', 'clone']} className='settingsIcon' onClick={()=>this.cloneAction(idx)} />);
                clone = (<Tooltip key={'clone-'+idx} title='Clone'><span>{clone}</span></Tooltip>);
                let del = (<FontAwesomeIcon key={'delete-'+idx} size={isize} icon={['fas', 'trash-can']} className='settingsIcon' onClick={()=>this.removeAction(idx)} />);
                del = (<Tooltip key={'delete-'+idx} title='Delete'><span>{del}</span></Tooltip>);
                let upArrow = (<FontAwesomeIcon key={'delete-'+idx} size={isize} icon={['fas', 'caret-up']} className='settingsIcon' onClick={()=>this.moveActionUp(idx)} />);
                upArrow = (<Tooltip key={'up-'+idx} title='Move Up'><span>{upArrow}</span></Tooltip>);
                let downArrow = (<FontAwesomeIcon key={'delete-'+idx} size={isize} icon={['fas', 'caret-down']} className='settingsIcon' onClick={()=>this.moveActionDown(idx)} />);
                downArrow = (<Tooltip key={'down-'+idx} title='Move Down'><span>{downArrow}</span></Tooltip>);

                let icons = (<div key='icons' style={{display: 'flex', flexDirection:'row'}}>{upArrow}{downArrow}{clone}{del}</div>);

                let caption = this.action_type_caption(a.type);
                caption = (<div><div style={{paddingBottom:'3px'}}>{caption}</div>{icons}</div>);

                let header = (<CardHeader key='header' style={{flexGrow:0}} className='actionsHeader' {...provided.dragHandleProps} disableTypography={true} title={caption} />);
                let action_el = el;
                action_el = (<Card key={'action'+idx} style={{display:'flex'}}>{header}<CardContent className='actionsContent' style={{flexGrow:1, display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginRight:'10px', minHeight:'80px'}}>{action_el}</CardContent></Card>);

                let action_div = (<div style={{position:'relative', marginTop: '10px'}} key={'action'+idx} id={'action'+idx}>{action_el}</div>);

                return (<div key={'action-'+idx} ref={provided.innerRef} {...provided.draggableProps}>{action_div}</div>);
            }}
        </Draggable>);
        return entry;
    }

    render() {
        let reflex = this.props.reflex;
        let rtype = reflex.type;
        let actions = reflex.actions;
        if (!actions) actions = [];
        let orig_reflex = this.props.orig_reflex;
        let orig_actions = orig_reflex.actions;

        let newact = this.render_new_action_ui(rtype);

        let action_els = [];
        for (let i = 0; i < actions.length; ++i) {
            let idx = i;
            let a = actions[idx];
            let orig_a = orig_actions[idx];
            let entry = this.renderActionElement(a, orig_a, idx, rtype);
            action_els.push (entry);
        }
        let list = (<DragDropContext onDragEnd={(res)=>this.onDragEnd(res)}><Droppable droppableId='droppable'>
            {(provided, snapshot) => (<div {...provided.droppableProps} ref={provided.innerRef}>{action_els}</div>)}
        </Droppable></DragDropContext>);
        return (<div><div>{list}</div><div>{newact}</div></div>);
    }
    
}

