
import { Tab } from './tab.js'

export class TabNews_Base extends Tab {
    constructor(props) {
        super(props);
        this.state = { 'selsection' : '', 'selentry' : '' };
        this.pageLength = 20;
    }

    id() {
        return 'news';
    }

    render() {
        return null;
    }


    // returns an array with fields name, count, lastread
    sectionData(s) {
        s = s.toLowerCase();
        let gmcp = this.props.gmcp;
        let sections = gmcp.NewsSections;
        for (let i = 0; i < sections.length; ++i) {
            let name = sections[i].name.toLowerCase();
            if (name === s) return sections[i];
        }
        return null;
    }

    currentSectionData() {
        return sectionData(this.state.selsection);
    }

    backClick() {
        this.setState ({'selsection' : null });
    }

    sectionClick(name) {
        let data = this.sectionData(name);
        if (!data) return;
        let toID = data.count;
        let fromID = toID - this.pageLength + 1;
        if (fromID <= 0) fromID = 1;
        this.setState ({'selsection' : name, 'from' : fromID, 'to' : toID}, () => this.props.onsectionfetch(name, fromID, toID) );
    }

    nextClick() {
        let s = this.state.selsection;
        let data = this.sectionData(s);
        if (this.state.to >= data.count) return;   // already at the end
        if (data.count - this.state.to < this.pageLength) {   // We want to move to the last page.
            this.lastClick();
            return;
        }
        let fromID = this.state.to + 1;
        let toID = fromID + this.pageLength - 1;   // this is <= data.count thanks to the check above
        this.setState ({'from' : fromID, 'to' : toID}, () => this.props.onsectionfetch(s, fromID, toID) );
    }
    
    previousClick() {
        let s = this.state.selsection;
        let data = this.sectionData(s);
        if (this.state.from <= 1) return;   // already at beginning
        
        let toID = this.state.from - 1;
        let fromID = toID - this.pageLength + 1;
        if (fromID < 1) {  // first page
            fromID = 1;
            toID = this.pageLength;
            if (data.count < toID) toID = data.count;
        }
        this.setState ({'from' : fromID, 'to' : toID}, () => this.props.onsectionfetch(s, fromID, toID) );
    }

    lastClick() {
        let s = this.state.selsection;
        let data = this.sectionData(s);
        if (this.state.to >= data.count) return;   // already at the end

        let toID = data.count;
        let fromID = toID - this.pageLength + 1;
        if (fromID <= 0) fromID = 1;
        this.setState ({'selsection' : s, 'from' : fromID, 'to' : toID}, () => this.props.onsectionfetch(s, fromID, toID) );
    }

    entryClick(id) {
        this.setState ({'selentry' : id}, () => this.props.onentryfetch(this.state.selsection, id) );
    }

    
}
