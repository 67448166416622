
import { ReflexGroup } from './reflex.js'
import { ReflexFunction } from './functions.js'
import { ReflexKeybind } from './keys.js'
import { ActionList } from './actions.js'

// Reflex packages - these are basically groups with extra properties.

export class ReflexPackage extends ReflexGroup {
    constructor(name, nexus, description) {
        super(name, nexus);
        // we keep the "group" type so that we can treat packages and groups the same
        this.description = description;
        this.is_package = true;
        this.items = [];
        this.id = 1;
    }

    encode() {
        var res = super.encode();
        res['description'] = this.description;
        return res;
    }

    apply (e, reflexes) {
        if (e['type'] !== this.type) return;  // sanity check
        super.apply (e, reflexes);  // this creates child elements, too
        this.description = e['description'];
    }

    duplicate() {
        var res = new ReflexPackage(this.name, this._nexus, this.description);
        return res;
    }

}

export class ReflexPackages {
    constructor(nexus) {
        this._nexus = nexus;
        this._list = [];
    }

    set_reflexes(ref) {
        this._reflexes = ref;
    }

    list() {
        return this._list;
    }

    encode() {
        var list = [];
        for (var idx = 0; idx < this._list.length; ++idx)
            list.push (this._list[idx].encode());
        return list;
    }

    clear() {
        this._list = [];
    }

    apply (list) {
        this._list = [];
        for (var idx = 0; idx < list.length; ++idx) {
            var pkg = this.create (list[idx].name, list[idx].description);
            pkg.apply (list[idx], this._reflexes);
        }
    }

    create(name, description) {
        name = name.toLowerCase().trim();
//        if ((name === 'all') || (name === 'main')) return;  // these names are not allowed
//        if (this.exists(name)) this.remove(name);
        let pkg = new ReflexPackage (name, this._nexus, description);
        this._list.push(pkg);

        let obj = new ReflexFunction ('onInstall', this._nexus);
        this._reflexes.append (obj, pkg);
        obj.code = "// Place any code here you'd like to autorun when the package is installed";
        obj = new ReflexFunction ('onUninstall', this._nexus);
        this._reflexes.append (obj, pkg);
        obj.code = "// Place any code here you'd like to autorun when the package is uninstalled";

        return pkg;
    }

    add(pkg) {
        this._list.push(pkg);
    }

    replace(idx, pkg) {
        if ((idx < 0) || (idx >= this._list.length)) return;
        this._list[idx] = pkg;
    }

    move(from, to) {
        if ((from < 0) || (from >= this._list.length)) return;
        if ((to < 0) || (to >= this._list.length)) return;
        var el = this._list[from];
        this._list.splice(from, 1);
        this._list.splice(to, 0, el);
    }

    exists(name, case_sensitive=true) {
        name = name.trim();
        if (!case_sensitive) name = name.toLowerCase();
        for (var i = 0; i < this._list.length; ++i) {
            let pname = this._list[i].name;
            if (!case_sensitive) pname = pname.toLowerCase();
            if (pname === name)
                return i + 1;
        }
        return 0;
    }

    set_enabled(idx, enable) {
        var pkg = this.get_idx(idx);
        if (!pkg) return;
        if (pkg.enabled === enable) return;
        pkg.enabled = enable;

        if (enable && this.onenabled)
            this.onenabled (pkg);
        if ((!enable) && this.ondisabled)
            this.ondisabled (pkg);
    }

    get(name, case_sensitive=true) {
        var idx = this.exists(name, case_sensitive);
        if (idx) return this._list[idx-1];
        return undefined;
    }

    get_idx(idx) {
        if ((idx >= 0) && (idx < this._list.length)) return this._list[idx];
        return undefined;
    }

    remove(name) {
        var idx = this.exists(name) - 1;
        if (idx < 0) return;
        this._list.splice(idx, 1);
    }

    remove_idx(idx) {
        if ((idx < 0) || (idx >= this._list.length)) return;
        this._list.splice(idx, 1);
    }

    // we are merging 'ref' into 'group'. 'ref' is an exported reflex, not the object.
    reflex_package_merge_reflexes_recurs(group, ref, pkg) {
        if (!ref.items) return;
        if (!ref.items.length) return;

        // go over the items to import
        for (let i = 0; i < ref.items.length; ++i) {
            var item = ref.items[i];
            // if the item is a group, check if a group of the same name exists at this level
            if (item.type === 'group' && item.name.length) {
                var found;
                for (var j = 0; j < group.items.length; ++j) {
                    if (item.name === group.items[j].name) {
                        found = group.items[j];
                        break;
                    }
                }
                // if the group does exist, we need to merge it in, so do that
                if (found !== undefined) {
                    this.reflex_package_merge_reflexes_recurs(found, item, pkg);
                    continue;
                }
            }
            let newr = this._reflexes.create (item.type, item.name);
            this._reflexes.append (newr, group);
            newr.apply (item, this._reflexes);  // this imports the child reflexes too, if any
        }
    }

    // 'ref' is an exported reflex, not the object
    reflex_package_merge_reflexes(pkg, ref) {
        var group = pkg ? pkg : this._reflexes.master_group();

        // process everything recursively
        this.reflex_package_merge_reflexes_recurs(group, ref, pkg ? pkg.name : undefined);
    }

    create_default_reflex_packages() {
        if (!this.exists('numpad movement')) {
            var pkg = this.create('numpad movement', "Move around the world with your keyboard's number pad. 8=north, 9=northeast, etc. Use '/' for in, '*' for out, '-' for up and '+' for down. The 5 key will look at the room you're in.");
            var dirs = { 103: 'nw', 104: 'n', 105: 'ne', 100: 'w', 101: 'look', 102: 'e', 97: 'sw', 98: 's', 99: 'se', 111: 'in', 106: 'out', 109: 'up', 107: 'down'};
            for (var v in dirs) {
                var key = new ReflexKeybind (dirs[v], this._nexus);
                this._reflexes.append (key, pkg);
                key.key = v;
                key.key_alt = false; key.key_ctrl = false; key.key_shift = false;
                key.actions = [];
                var act = ActionList.makeAction ('command', this._nexus);
                act.command = dirs[v];
                key.actions.push(act);
            }
        }
/*
        if (!this.exists('targeting')) {
            var pkg = this.create('targeting', "This will allow you to set an enemy target by typing, in the game, for instance, \"t Epicurus\" to target Epicurus. Now the game knows who your target is, and you can just punch \"punch @tar\" to \"punch Epicurus.\"");
            var alias = new ReflexAlias ('', this._nexus);
            this._reflexes.append (key, pkg);
            alias.text = 't';
            alias.actions.push({command: '@set tar', prefix_suffix: true});
        }
*/
    }

}


