 
// Reflexes page - this is the big one

import React from 'react';

import { Util } from '../../base/util.js'
import { FormSection } from './settingcomps'
import { ReflexEvent } from '../../reflexes/events.js'
import { ReflexKeybind } from '../../reflexes/keys.js'
import { ReflexGroup } from '../../reflexes/reflex.js'



export class ReflexEditor_Base extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    componentDidMount() {
        let parent_el = this.props.parent_el;
        parent_el.reflex_editor = this;
    }

    componentWillUnmount() {
        let parent_el = this.props.parent_el;
        parent_el.reflex_editor = null;
    }

    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    attribList(type) {
        if (type === 'group') return [ 'name' ];
        if (type === 'alias') return [ 'name', 'text', 'whole_words', 'case_sensitive', 'matching' ];
        if (type === 'trigger') return [ 'name', 'text', 'whole_words', 'case_sensitive', 'matching' ];
        if (type === 'keybind') return [ 'key_alt', 'key_ctrl', 'key_shift', 'key' ];
        if (type === 'event') return [ 'evtype', 'evsubtype' ];
        if (type === 'function') return [ 'name', 'code' ];
        return [];
    }

    attribType(type, name) {
        if (type === 'group') {
            return 'string';
        }
        if (type === 'alias') {
            if (name === 'name') return 'string';
            if (name === 'text') return 'string';
            if (name === 'whole_words') return 'bool';
            if (name === 'case_sensitive') return 'bool';
            if (name === 'matching') return 'select';
        }
        if (type === 'trigger') {
            if (name === 'name') return 'string';
            if (name === 'text') return 'string';
            if (name === 'whole_words') return 'bool';
            if (name === 'case_sensitive') return 'bool';
            if (name === 'matching') return 'select';
        }
        if (type === 'keybind') {
            if (name === 'key') return 'int';
            return 'bool';
        }
        if (type === 'event') {
            return 'string';
        }
        if (type === 'function') {
            if (name === 'name') return 'string';
            if (name === 'code') return 'codemirror';
        }
        
    }

    attribCaption(type, name) {
        if ((type === 'alias') || (type === 'trigger')) {
            if (name === 'matching') return 'Match text that';
            if (name === 'name') return 'Name (optional)';
            if (name === 'text') return 'Value';
            if (name === 'whole_words') return 'Match whole words only';
            if (name === 'case_sensitive') return 'Match uppercase/lowercase exactly';
        }
        if (type === 'function') {
            if (name === 'name') return 'Name';
            if (name === 'code') return 'Provide the javascript code to execute:';
        }
        if (type === 'group') {
            if (name === 'name') return 'Group Name';
        }
        
        return null;  // others are done differently
    }

    attribTooltip(type, name) {
        if (type === 'alias') {
            if (name === 'text') return 'Substitutions: @match, @line, @prefix, @suffix, and variables; regular expressions also allow: @0, @1, @2, ...'
        }
        if (type === 'trigger') {
            if (name === 'text') return 'Substitutions: @match, @line, @prefix, @suffix, and variables; regular expressions also allow: @0, @1, @2, ...'
        }
        if (type === 'function') {
            if (name === 'name') return 'Provide the function name here. The name is used to call the function.';
            if (name === 'code') return 'Enter the actual javascript code to execute.';
        }
        
        return null;  // others are done differently
    }

    attribChoices(type, name) {
        if (type === 'alias') {
            if (name === 'matching') return [ 'begins', 'exact', 'regexp' ];
        }
        if (type === 'trigger') {
            if (name === 'matching') return [ 'substring', 'begins', 'exact', 'regexp' ];
        }
        return [];
    }
    
    attribChoiceCaption(type, name, ch) {
        if ((name === 'matching') && ((type === 'alias') || (type === 'trigger'))) {
            if (ch === 'substring') return 'Contains';
            if (ch === 'begins') return 'Begins with';
            if (ch === 'exact') return 'Exact match';
            if (ch === 'regexp') return 'Matches regex';
        }
        return null;
    }

    allowsActions(type) {
        if (type === 'function') return false;
        if (type === 'group') return false;
        return true;
    }


    updateField(rtype, name, val) {
        let type = this.attribType(rtype, name);
        if (val === undefined) return;
        if (val === null) return;

        if (type === 'int') {
            val = parseInt(val);
            if (isNaN(val)) return;
        }

        let r = this.props.reflexEdit;
        r[name] = val;
        this.setState({ reflexEdit: r });
    }

    setEnabled(en) {
        let r = this.props.reflexEdit;
        r.enabled = en;
        this.setState({ reflexEdit: r });
    }

    isModified() {
        let reflex = this.props.reflex;
        let reflexEdit = this.props.reflexEdit;
        if (!reflex) return false;
        let rtype = reflex.type;
        if (reflexEdit.type !== rtype) return true;
        let attrs = this.attribList (rtype);
        for (let idx = 0; idx < attrs.length; ++idx) {
            let aname = attrs[idx];
            if (reflex[aname] !== reflexEdit[aname]) return true;
        }
        
        if (this.allowsActions (reflex.type) && this.actions_el) {
            if (this.actions_el.isModified()) return true;
        }

        return false;
    }

    renderField(rtype, name) {
        return null;
    }

    renderGroup(fields) {
        return null;
    }

    renderAlias(fields) {
        return null;
    }

    renderTrigger(fields) {
        return null;
    }

    renderKeybind(fields) {
        return null;
    }

    updateEvent(evt) {
        let split = evt.search(' - ');
        let evtype = evt.substr(0, split);
        let evsubtype = evt.substr(split + 3);
        let el = this.props.reflexEdit;
        el.evtype = evtype;
        el.evsubtype = evsubtype;
        this.setState({ reflexEdit: el });
    }

    renderEvent(fields) {
        return null;
    }

    renderFunction(fields) {
        return null;
    }

    renderOptions() {
        let reflex = this.props.reflexEdit;
        let rtype = reflex.type;
        let lst = this.attribList(rtype);
        let fields = {};
        for (let idx = 0; idx < lst.length; ++idx) {
            let fname = lst[idx];
            fields[fname] = this.renderField (rtype, fname);
        }

        if (rtype === 'group') return this.renderGroup (fields);
        if (rtype === 'alias') return this.renderAlias (fields);
        if (rtype === 'trigger') return this.renderTrigger (fields);
        if (rtype === 'keybind') return this.renderKeybind (fields);
        if (rtype === 'event') return this.renderEvent (fields);
        if (rtype === 'function') return this.renderFunction (fields);
        return null;
    }

    save() {
        let nexus = this.props.nexus;
        let reflex = this.props.reflex;
        let reflexEdit = this.props.reflexEdit;
        reflex.apply (reflexEdit, nexus.reflexes());
        nexus.reflexes().reflexes_changed();
    }
    
    revert() {
        this.props.onRevert();
    }

    duplicate() {
        this.save();  // save the reflex first
        this.props.onDuplicate();
    }

    deleteReflex() {
        this.props.onDelete();
    }

    renderEnabled() {
        return null;
    }

    render() {
        return null;
    }

}



export class SettingsReflexes_Base extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selection: [], checked: [], expanded: [], package: -1, reflex: null, reflex_edit: null, newmenu: false, addmenu: false, filter: '' };
        this.rootId = -1;
    }

    componentDidUpdate() {
        let rReflex = this.current_root_reflex();
        if (!rReflex) return;

        if (rReflex.id === this.rootId) return;

        this.rootId = rReflex.id;
        this.onExpand([this.rootId]);        
    }

    reflex_visible_name(el) {
        if (el.type === 'group') return el.name ? el.name : '(unnamed)';
        if (el.type === 'alias') {
            if (el.name) return el.name;
            return el.text;
        }
        if (el.type === 'trigger') {
            if (el.name) return el.name;
            return el.text;
        }
        if (el.type === 'keybind') {
            let text = ReflexKeybind.get_plaintext_key(el.key);
            if (el.key_alt) text = "Alt + " + text;
            if (el.key_ctrl) text = "Ctrl + " + text;
            if (el.key_shift) text = "Shift + " + text;
            return text;
        }
        if (el.type === 'event') {
            return ReflexEvent.event_heading(el.evtype, el.evsubtype);
        }

        if (el.name) return el.name;
        return '(unnamed)';
    }

    isModified() {
        let editor = this.reflex_editor;
        if (!editor) return false;
        return editor.isModified();
    }

    onSelectConfirm(selectedKeys) {
        let reflexes = this.props.reflexes;
        let pkg = this.state.package;
        
        let st = {};
        st.selection = selectedKeys;
        if (selectedKeys.length === 1) {
            let key = selectedKeys[0];
            let reflex = reflexes.find_by_id(key, pkg);
            st.reflex = reflex;
            st.reflex_edit = reflex.encode();
        } else {
            st.reflex = null;
            st.reflex_edit = null;
        }
        this.setState(st);
    }

    onSelect(selectedKeys) {

        // ask whether to discard changes?
        if (this.isModified()) {
            let nex = this.props.nexus;
            nex.platform().confirm ('Discard Changes?', 'You have unsaved changes in this reflex. Do you want to discard them?', () => {
                this.onSelectConfirm (selectedKeys);
            });
            return;
        }

        this.onSelectConfirm (selectedKeys);
    }

    onRevert() {
        let reflex = this.state.reflex;
        let r = reflex.encode();
        this.setState({reflex_edit: r});
    }

    onDuplicate() {
        let nexus = this.props.nexus;
        let reflex = this.state.reflex;
        if (!reflex) return;

        let p = reflex.parent();
        if (!p) p = this.current_root_reflex();

        let newr = reflex.duplicate();
        nexus.reflexes().append(newr, p);

        this.onSelectConfirm ([ newr.id ]);
    }

    onDeleteConfirm() {
        let nex = this.props.nexus;
        let reflex = this.state.reflex;
        if (!reflex) return;

        nex.reflexes().remove(reflex);
        this.onSelectConfirm ([ ]);
    }

    onDelete() {
        let nex = this.props.nexus;
        let reflex = this.state.reflex;
        if (!reflex) return;
        
        let msg = 'Do you really want to delete this ' + Util.ucfirst (reflex.type) + '?';
        if ((reflex.type === 'group') && reflex.items && reflex.items.length) msg += ' THIS WILL DELETE ALL THE REFLEXES IN THIS GROUP!';
        nex.platform().confirm ('Really Delete?', msg, () => {
            this.onDeleteConfirm ();
        });
        
    }

    onCheck(checkedKeys) {
        this.setState({ checked: checkedKeys });
    }

    onExpand(expandedKeys) {
        this.setState({ expanded: expandedKeys });        
    }

    current_root_reflex()
    {
        let reflexes = this.props.reflexes;
        let packages = reflexes.packages();
        if (this.state.package === -1) return reflexes.master_group();
        return packages.get_idx(this.state.package);
    }

    allowDrop(info) {
//        let dropNode = info.dropNode;
//        let dropPos = info.dropPosition;
        return true;
    }

    onDrop(info) {
        let nex = this.props.nexus;
        let pkg = this.state.package;
        let node = info.node;
        let dragNode = info.dragNode;
        let src = nex.reflexes().find_by_id(dragNode.key, pkg);
        let dest = nex.reflexes().find_by_id(node.key, pkg);
        let dropPosArray = node.pos.split('-');
        let dropPos = info.dropPosition - Number(dropPosArray[dropPosArray.length - 1]);
        nex.reflexes().move(src, dest, dropPos);
    }

    filterElement(el) {
        let filter = this.state.filter;
        if (!filter) return false;
        if (el.matches(filter)) return false;
        
        if ((el.type === 'group') && (el.items)) {
            // groups are also shown if any of their children is shown
            for (let idx = 0; idx < el.items.length; ++idx) {
                if (!this.filterElement(el.items[idx])) return false;
            }
            return true;
        }
        return true;
    }

    renderTree(rootEl) {
        return null;
    }

    onPackageChange(pkg) {
        let rootEl = this.current_root_reflex();

        this.setState({ package: pkg, checked: [], expanded: [ rootEl.id ] })
        this.onSelect([]);
    }

    renderFilter() {
        return null;
    }

    renderPackageSelector() {
        return null;
    }

    selectedReflex() {
        let reflexes = this.props.reflexes;
        let pkg = this.state.package;
        let sel = this.state.selection;
        if (sel.length !== 1) return null;

        let key = sel[0];
        let reflex = reflexes.find_by_id(key, pkg);
        if (!reflex) return null;
        return reflex;
    }

    createNewReflex(rtype) {
        if (rtype === 'header') return;   // for native
        let reflexes = this.props.reflexes;
        let selected_reflex = this.selectedReflex();
        var p = null;
        // if there's a group selected, add it to it
        if (selected_reflex !== null) {
            if (selected_reflex.type === 'group') p = selected_reflex;
            else p = selected_reflex.parent();
        }
        if (!p) p = this.current_root_reflex();
        var el = reflexes.create (rtype, '');
        reflexes.append(el, p);
        this.setState({newmenu: false});
        this.onSelect ([ el.id ]);  // mark the newly created reflex as selected        
    }

    btnNew() {
        let sel = !this.state.newmenu;
        this.setState({newmenu: sel});
    }

    renderNewReflex() {
        return null;
    }

    getSelectedReflexesRecurs(el, selected) {
        var items = [];
        if (el.items && el.items.length) {
            for (var idx = 0; idx < el.items.length; ++idx) {
                var child = this.getSelectedReflexesRecurs(el.items[idx], selected);
                if (child !== null)
                    items.push(child);
            }
        }

        let sel = (selected.indexOf(el.id) >= 0);
        if ((!items.length) && (!sel)) return null;  // not selected, no children elements - we will not add this

        let res;
        if (items.length) {
            res = new ReflexGroup(el.name);
            res = res.encode();
            res.items = items;
        } else
            res = el.encode();
        return res;
    }

    getSelectedReflexes() {
        let rootEl = this.current_root_reflex();
        return this.getSelectedReflexesRecurs(rootEl, this.state.checked);
    }

    onExportSelected() {
        let lst = this.getSelectedReflexes();
        if (!lst) return;  // shouldn't happen as the button is hidden, but just in case

        let now = new Date();
        let date = now.getFullYear() + '-' + (now.getMonth()+1) + '-' + now.getDate();
        let fname = 'Exported Reflexes ' + date + ".nxs";

        let data = JSON.stringify (lst);
        let nex = this.props.nexus;
        nex.platform().do_download (data, fname);
    }

    onAddToPackage(idx) {
        let lst = this.getSelectedReflexes();
        if (!lst) return;  // shouldn't happen as the button is hidden, but just in case

        let nexus = this.props.nexus;
        let pkg = null;
        if (idx >= 0) {
            pkg = nexus.packages().get_idx (idx);
            if (!pkg) return;
        }

        nexus.packages().reflex_package_merge_reflexes(pkg, lst);
    }

    renderPackageButtons() {
        return null;
    }

    render() {
        return null;
    }
}
 
