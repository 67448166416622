
import { Util } from '../../base/util.js'
import { XmlParser } from '../../base/xmlparser'

export class MapData {
    constructor(gameinfo) {
        this.gameinfo = gameinfo;
        this.maxX = 6;
        this.minX = -6;
        this.maxY = 6;
        this.minY = -6;
        this.minZ = this.maxZ = 0;
        this.loc = null;
        this.loaded = false;
        this.loading = false;
        this.failed = false;
        this.onload = null;
        this.onloading = null;
        this.map_areas = [];
        this.map_data = [];
        this.map_rooms = [];
        this.map_cache = [];
        this.map_texts = [];
        this.parser = new XmlParser();
        this.load_area_list();
    }

    onDraw() {
        this.map_texts = [];
    }

    load_area_list() {
        let t = this;
        t.map_areas = [];
        let server = t.gameinfo.server_name();
        let dataurl = 'https://www.' + server + '/maps/v2/areas.xml';
        fetch(dataurl).then((result) => {
            result.text().then((text) => {
                let p = this.parser;
                if (!p.parse(text)) {
                    // TODO show some error?
                    console.log('error: '+p.error());
                    return;
                }
                let main = p.mainElement();
                let childs = p.elementChildren(main);
                for (let idx = 0; idx < childs.length; ++idx) {
                    let el = childs[idx];
                    if (p.elementName(el) !== 'area') continue;
                    let id = parseInt(p.elementAttrib (el, 'id'));
                    let name = p.elementAttrib (el, 'name');
                    t.map_areas[id] = name;
                }
            })
        }).catch((error) => {
            // TODO show some error?
            console.log('error: '+error);
        });
    }

    get_area_name(area) {
        if (this.map_areas && this.map_areas[area])
            return this.map_areas[area];
        return null;
    }

    set_location(loc) {
        let oldArea = this.loc ? this.loc.areaid : -1;
        let newArea = loc ? loc.areaid : -1;
        this.loc = loc;
        if (!loc) {
            this.loaded = false;
            this.failed = true;
            return;
        }
        if (newArea && (oldArea !== newArea))
            this.load_map_data();
    }

    get_map_text(x, y, type) {
        if ((type !== 'tooltip') && (type !== 'command')) return;
        for (let i = 0; i < this.map_texts.length; ++i) {
            let el = this.map_texts[i];
            if ((x < el.x1) || (x > el.x2)) continue;
            if ((y < el.y1) || (y > el.y2)) continue;

            // This one is a match.
            if ((type === 'tooltip') && el.tooltip && (el.tooltip.length > 0))
                return el.tooltip;
            if ((type === 'command') && el.command && (el.command.length > 0))
                return el.command;
        }
        return undefined;
    }

    add_map_texts(x1, y1, x2, y2, tooltip, cmd) {
        let tip = {};
        if (x1 > x2) { let xx = x1; x1 = x2; x2 = xx; }
        if (y1 > y2) { let yy = y1; y1 = y2; y2 = yy; }
        tip.x1 = x1;
        tip.y1 = y1;
        tip.x2 = x2;
        tip.y2 = y2;
        tip.tooltip = tooltip;
        tip.command = cmd;
        this.map_texts.push(tip);
    }

    get_base_dir_offset(dir) {
        if (dir === "n") return {top: 1, left: 0};
        else if (dir === "e")  return {top: 0, left: -1};
        else if (dir === "w")  return {top: 0, left: 1};
        else if (dir === "s")  return {top: -1, left: 0};
        else if (dir === "nw") return {top: 1, left: 1};
        else if (dir === "ne") return {top: 1, left: -1};
        else if (dir === "sw") return {top: -1, left: 1};
        else if (dir === "se") return {top: -1, left: -1};
        return undefined;
    }

    get_room_feature_icon(feature) {
        if (feature == 'arena') return 'swords';
        if (feature == 'bank') return 'building-columns';
        if (feature == 'barracks') return 'chess-rook';
        if (feature == 'building') return 'building';
        if (feature == 'cloning') return 'people-pants-simple';
        if (feature == 'commshop') return 'store';
        if (feature == 'customization') return 'brush';
        if (feature == 'docking') return 'shuttle-space';
        if (feature == 'guildhall') return 'fort';
        if (feature == 'harbour') return 'anchor';
        if (feature == 'house') return 'house-turret';
        if (feature == 'insurance') return 'file-signature';
        if (feature == 'kennel') return 'dog';
        if (feature == 'library') return 'books';
        if (feature == 'lift') return 'elevator';
        if (feature == 'mail') return 'mailbox';
        if (feature == 'news') return 'newspaper';
        if (feature == 'nexus') return 'galaxy';
        if (feature == 'portal') return 'person-to-portal';
        if (feature == 'post') return 'mailbox';
        if (feature == 'refinery') return 'industry';
        if (feature == 'repairshop') return 'screwdriver-wrench';
        if (feature == 'shop') return 'shop';
        if (feature == 'stable') return 'horse';
        if (feature == 'teleporter') return 'transporter-1';
        if (feature == 'villagehouse') return 'house';
        if (feature == 'warehouse') return 'warehouse';
        if (feature == 'wilderness') return 'trees';
        return null;
    }

    get_room_features(id) {
        let map = this;
        let r = map.map_rooms[id];
        if (!r) return [];
        if (!r.features) return [];
        return r.features;
    }

    get_room_icon(id) {
        let map = this;
        let r = map.map_rooms[id];
        if (!r) return '';
        if (!r.features) return '';
        if (!r.features.length) return '';
        for (let idx = 0; idx < r.features.length; ++idx) {
            let icon = map.get_room_feature_icon(r.features[idx]);
            if (icon && icon.length) return icon;
        }
        return '';
    }

    load_map_data() {
        let map = this;

        let loc = map.loc;
        if (map.loading && (map.loadingArea === loc.areaid)) return;   // nothing if we're already loading that area

        map.map_data = [];
        map.map_rooms = [];
        map.map_buildings = [];
        map.minX = map.maxX = map.minY = map.maxY = map.minZ = map.maxZ = 0;

        map.loadingArea = loc.areaid;      // remember what we are loading
        let loadArea = map.loadingArea;       // and remember it locally, in case we call this again before the fetch completes

        map.loaded = false;
        map.failed = false;
        map.loading = true;

        // if we already have the map in the cache, grab it from there
        if (map.map_cache[loadArea])
        {
           map.loadingArea = 0;
           map.load_xml_map(loadArea, map.map_cache[loadArea]);
           return;
        }

        let server = map.gameinfo.server_name();
        let dataurl = 'https://www.' + server + '/maps/v2/' + loadArea + '.xml';
        if (this.onloading) this.onloading();
        fetch(dataurl).then((data) => {
            data.text().then((text) => {
                // If we started loading something else in the meantime, bail out here.
                // Prevents the map from being corrupted on fast movement.
                if (map.loadingArea !== loadArea) return;
                map.loadingArea = 0;
                map.map_cache[loadArea] = text;

                map.load_xml_map(loadArea, text);
            });
        }).catch((error) => {
            this.loading = false;
            this.loaded = false;
            this.failed = true;
            this.error = error;
            if (this.onload) this.onload(loadArea);  // we call this even if we failed to load, so that the interface updates
        });
    }

    // returns the document element
    parse_xml_data(areaid, data) {
        let p = this.parser;
        if (!p.parse(data)) {
            this.loading = false;
            this.loaded = false;
            this.failed = true;
            this.error = p.error();
            return false;
        }
        return p.mainElement();
    }

    load_xml_map(areaid, data) {
        let map = this;

        let p = this.parser;
        let rootel = map.parse_xml_data(areaid, data);
        if (rootel === false) return false;

        map.map_rooms = [];
        map.map_buildings = [];
        map.env_color = [];
        map.minX = map.maxX = map.minY = map.maxY = map.minZ = map.maxZ = 0;

        let loc = this.loc;
        loc.areaname = p.elementAttrib (rootel, 'name');
        let childs = p.elementChildren (rootel);
        for (let idx = 0; idx < childs.length; ++idx) {
            let el = childs[idx];
            let name = p.elementName (el);
            if (name === 'rooms') {
                let rchilds = p.elementChildren (el);
                for (let rid = 0; rid < rchilds.length; ++rid) {
                    let x, y, z, b;
                    let roomel = rchilds[rid];

                    let roomid = parseInt(p.elementAttrib(roomel, 'id'));
                    let exits = [];
                    let specialexits = [];
                    let features = [];

                    let rchilds2 = p.elementChildren (roomel);
                    for (let idx2 = 0; idx2 < rchilds2.length; ++idx2) {
                        let el2 = rchilds2[idx2];
                        let name = p.elementName (el2);
                        if (name === 'coord') {
                            x = parseInt(p.elementAttrib (el2, 'x'));
                            y = parseInt(p.elementAttrib (el2, 'y'));
                            z = parseInt(p.elementAttrib (el2, 'z'));
                            b = parseInt(p.elementAttrib (el2, 'building'));
                            if (isNaN(x)) x = 0;
                            if (isNaN(y)) y = 0;
                            if (isNaN(z)) z = 0;
                            if (isNaN(b)) b = 0;
                              
                            if (x < map.minX) map.minX = x;
                            if (x > map.maxX) map.maxX = x;
                            if (y < map.minY) map.minY = y;
                            if (y > map.maxY) map.maxY = y;
                            if (z < map.minZ) map.minZ = z;
                            if (z > map.maxZ) map.maxZ = z;
                        }
                        if (name === 'exit')
                        {
                            let tg = parseInt(p.elementAttrib (el2, 'target'));

                            let spec = p.elementAttrib (el2, 'special');
                            if (spec) {
                                let cmd = p.elementAttrib (el2, 'command');
                                let e = { command: cmd, target: tg };
                                specialexits.push (e);
                                continue;
                            }

                            let eldir = p.elementAttrib (el2, 'direction');
                            let tgarea = p.elementAttrib (el2, 'tgarea');
                            if (tgarea) tgarea = parseInt(tgarea);
                            let hidden = p.elementAttrib (el2, 'hidden');
                            if (hidden) hidden = parseInt(hidden);
                            let door = p.elementAttrib (el2, 'door');
                            if (door) door = parseInt(door);

                            let dirs = Util.directions();
                            for (let didx = 0; didx < dirs.length; ++didx) {
                                let dirshort = dirs[didx];
                                if (eldir !== Util.get_full_direction_name(dirshort)) continue;
                                let e = { dir: dirshort, draw: true, target: tg, tgarea: tgarea, hidden: hidden, door: door };
                                exits.push (e);
                            }
                        }
                        if (name === 'features') {
                            let fchilds = p.elementChildren (el2);
                            for (let idx3 = 0; idx3 < fchilds.length; ++fchilds) {
                                let el3 = fchilds[idx3];
                                if (p.elementName(el3) === 'feature') features.push (p.elementAttrib (el3, 'type'));
                            }
                        }
                    }

                    map.map_rooms[roomid] = {
                        id: roomid,
                        title: p.elementAttrib (roomel, 'title'),
                        environment: parseInt(p.elementAttrib (roomel, 'environment')),
                        important: p.elementAttrib (roomel, 'important') ? parseInt(p.elementAttrib (roomel, 'important')) : null,
                        color: p.elementAttrib (roomel, 'color'),
                        marker: p.elementAttrib (roomel, 'marker'),
                        markerdir: p.elementAttrib (roomel, 'markerdir'),
                        x: x,
                        y: y,
                        z: z,
                        b: b,
                        exits: exits,
                        specialexits: specialexits,
                        features: features
                    };

                    map.map_buildings[roomid] = b;
                }
            }
            if (name === 'environments') {
                let echilds = p.elementChildren (el);
                for (let eid = 0; eid < echilds.length; ++eid) {
                    let envel = echilds[eid];
                    if (p.elementName (envel) !== 'environment') continue;
                    let id = parseInt(p.elementAttrib (envel, 'id'));
                    let htmlcolor = p.elementAttrib (envel, 'htmlcolor');
                    map.env_color[id] = htmlcolor;
                }
            }
        }

        // assign the rooms to coordinates
        for (let id in map.map_rooms) {
            let el = map.map_rooms[id];
            let x = el.x;
            let y = el.y;
            let z = el.z;
            let b = el.b;
            if (!map.map_data[b])
                map.map_data[b] = [];
            if (!map.map_data[b][x])
                map.map_data[b][x] = [];
            if (!map.map_data[b][x][y])
                map.map_data[b][x][y] = [];

            map.map_data[b][x][y][z] = el;

            // Is this a building entrance?
            if (b === 0) continue;
            if (el.exits == null) continue;
            let exitroom = 0;
            for (let e = 0; e < el.exits.length; ++e) {
                let tgr = el.exits[e].target;
                let tgroom = map.map_rooms[tgr];
                if (tgroom == null) continue;
                if (tgroom.b === 0) {
                    exitroom = tgr;
                    break;
                }
            }
            if (exitroom === 0) continue;

            // This is a building entrance. Determine coordinate shift.
            let eroom = map.map_rooms[exitroom];
            if (eroom == null) continue;
            let shift;
            for (let e = 0; e < eroom.exits.length; ++e) {
                if (eroom.exits[e].target === id)
                {
                    shift = map.get_base_dir_offset(eroom.exits[e].dir);
                    break;
                }
            }
            if (shift === undefined) continue;
            // and place this room on the base map
            x = eroom.x - shift.left;
            y = eroom.y + shift.top;

            if (!map.map_data[0])
                map.map_data[0] = [];
            if (!map.map_data[0][x])
                map.map_data[0][x] = [];
            if (!map.map_data[0][x][y])
                map.map_data[0][x][y] = [];

            map.map_data[0][x][y][z] = el;
        }

        // alter max/min X/Y to include borders. These allow the map to remain centered even when viewing the edges
        // the number is rather arbitrary there
        map.minX -= 6;
        map.minY -= 6;
        map.maxX += 6;
        map.maxY += 6;

        // and we're done, report success
        map.loading = false;
        map.loadingArea = 0;
        map.loadedArea = areaid;
        map.failed = false;
        map.loaded = true;
        map.onload(areaid);
    }

}

export class SingleRoomData {
    constructor(loc) {
        this.maxX = 6;
        this.minX = -6;
        this.maxY = 6;
        this.minY = -6;
        this.minZ = this.maxZ = 0;
        this.loc = loc;
        this.singleRoom = true;
        let exits = [];
        
        if (loc.roomexits) {
            for (let ex in loc.roomexits)
            {
                let exit = { dir: ex, draw: true, target: 0, tgarea: 0, hidden: 0, door: 0 };
                exits.push (exit);
            }
        }

        let el = {
            id: loc.id,
            title: loc.roomname,
            x: 0,
            y: 0,
            z: 0,
            b: 0,
            exits: exits
        };
        this.map_rooms = [];
        this.map_rooms[loc.id] = el;
        this.map_data = [];
        this.map_data[0] = [];
        this.map_data[0][0] = [];
        this.map_data[0][0][0] = [];
        this.map_data[0][0][0][0] = el;        
        this.map_buildings = [];
    }

    get_base_dir_offset(dir) {
        if (dir === "n") return {top: 1, left: 0};
        else if (dir === "e")  return {top: 0, left: -1};
        else if (dir === "w")  return {top: 0, left: 1};
        else if (dir === "s")  return {top: -1, left: 0};
        else if (dir === "nw") return {top: 1, left: 1};
        else if (dir === "ne") return {top: 1, left: -1};
        else if (dir === "sw") return {top: -1, left: 1};
        else if (dir === "se") return {top: -1, left: -1};
        return undefined;
    }

    onDraw() {}
    add_map_texts() {}
    get_map_text() {}
    
    get_room_icon(id) { return ''; }
    get_room_features(id) { return []; }

}

