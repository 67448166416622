import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


export class GameAddDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { title: this.props.title, server: this.props.server, port: this.props.port };
    }

    onKeyPress(e) {
        if (e.charCode !== 13) return;
        let setOpen = this.props.setOpen;
        let onConfirm = this.props.onConfirm;
        setOpen(false);
        if (onConfirm) onConfirm(this.state.title, this.state.server, this.state.port);
        e.preventDefault();
    }

    renderContent() {
        let st = {margin:3};
        let ctitle = (<TextField key="ctitle" autoFocus style={st} value={this.state.title} label={'Game Name'} type='text' onChange={(e)=>{ this.setState({title: e.target.value})} } />);
        let cserver = (<TextField key="cserver" style={st} value={this.state.server} label={'Game Server'} type='text' onChange={(e)=>{ this.setState({server: e.target.value})} } />);
        let cport = (<TextField key="cport" style={st} value={this.state.port} label={'Server Port'} type='text' onChange={(e)=>{ this.setState({port: e.target.value})} } />);
        return (<div className='paddedContent'><div>{ctitle}</div><div>{cserver}</div><div>{cport}</div></div>);
    }

    render() {
        let open = this.props.open;
        let setOpen = this.props.setOpen;
        let onConfirm = this.props.onConfirm;
        let oktext = this.props.isUpdate ? 'Update Game' : 'Add Game';
        let content = this.renderContent();

        return (
            <Dialog open={open} onKeyPress={(e) => this.onKeyPress(e)} onClose={() => setOpen(false)} aria-labelledby="game-add-dialog" style={{zIndex:8498}}>
            <DialogTitle id="game-add-dialog">{oktext}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setOpen(false)}>Cancel</Button>
                <Button variant="contained" onClick={() => { setOpen(false); if (onConfirm) onConfirm(this.state.title, this.state.server, this.state.port); }} color="secondary">{oktext}</Button>
            </DialogActions>
            </Dialog>
        );
    }
}
 
