
import { ReflexGroup } from './reflex.js'
import { ReflexAlias } from './aliases.js'
import { ReflexEvent } from './events.js'
import { ReflexFunction } from './functions.js'
import { ReflexKeybind } from './keys.js'
import { ReflexTrigger } from './triggers.js'
import { ActionList } from './actions.js'


export class Reflexes
{
    constructor(nexus)
    {
        this._nexus = nexus;
        this.clear();
    }

    clear()
    {
        this.reflexes = {};
        this.highest_id = 1;  // the root group has ID 1
        this.regexp_cache = {};
        this.waiting_actions = [];
    }

    set_packages(pkgs) {
        this._packages = pkgs;
    }

    packages() {
        return this._packages;
    }

    create(type, name) {
        if (type === "group") return new ReflexGroup (name, this._nexus);
        if (type === "alias") return new ReflexAlias (name, this._nexus);
        if (type === "trigger") return new ReflexTrigger (name, this._nexus);
        if (type === "keybind") return new ReflexKeybind (name, this._nexus);
        if (type === "event") return new ReflexEvent (name, this._nexus);
        if (type === "function") return new ReflexFunction (name, this._nexus);
        return null;
    }

    append(r, parent_group)
    {
        if (!parent_group) return;
        if (r.p === parent_group) return;  // already there

        let pkg = this.package_for_reflex (parent_group);
        this.highest_id++;
        r.id = this.highest_id;

        // remove from previous group, if any?
        this.remove(r);

        r.p = parent_group;
        parent_group.items.push(r);
    
        this.reflexes_changed();
    }

    remove(r)
    {
        let p = r.p;
        if (p == null) return;
        let idx = p.items.indexOf(r);
        if (idx >= 0) p.items.splice(idx, 1);

        this.reflexes_changed();
    }

    duplicate(r)
    {
        return r.duplicate();
    }

    // src: reflex that is being moved
    move(src, dest, pos)
    {
        if ((src == null) || (dest == null)) return;
        let p = src.p;
        if (p) {
            let idx = p.items.indexOf(src);
            if (idx >= 0) p.items.splice(idx, 1);
        }

        // Now let's place our item at the location of the dragged item. If the target is a group, we move the item inside.
        if (dest.type === 'group') {
            p = dest;
            pos = p.items.length;
        } else {
            p = dest.p;
            if (p) pos = p.items.indexOf(dest);   // the supplied 'pos' is wrong, we need to place it to where the dest is
        }
        p.items.splice(pos, 0, src);
        src.p = p;

        this.reflexes_changed();
    }

    enable_reflex(el) {
        if (!el) return;
        el.enabled = true;
        this.reflexes_changed();
    }

    disable_reflex(el) {
        if (!el) return;
        el.enabled = false;
        this.reflexes_changed();
    }

    reflexes_changed() {
        if (this.onchange) this.onchange(this);
    }

    master_group() {
        if (this.reflexes.type === "group") return this.reflexes;
        let group = new ReflexGroup ('ROOT', this._nexus);
        group.id = 1;
        this.reflexes = group;
        return this.reflexes;
    }

    package_for_reflex(r)
    {
        while (r.parent_group) r = r.parent_group;
        if (r.is_package) return r;
        return null;
    }

    package_highest_id(pkg)
    {
        let res = 0;
        this.traverse(function(r) { if (r.id > res) res = r.id; }, pkg, true);
        return res;
    }

    find_by_id(id, pkg_idx)
    {
        let group = null;
        if ((pkg_idx !== undefined) && (pkg_idx !== null) && (pkg_idx >= 0) && this._packages) group = this._packages.get_idx(pkg_idx);
        let res = null;
        this.traverse(function(r) { if ((res == null) && (r.id === id)) res = r; }, group, true);
        return res;
    }

    find_by_name(type, name, case_sensitive, enabled_only, pkg) {
        if (!name) return undefined;

        let group = null;
        if (pkg) {
            group = pkg;
            if (typeof pkg == 'string') group = this._packages.get(pkg, false);
            if (typeof pkg == 'number') group = this._packages.get_idx(pkg);
        }
        let list = [];
        this.traverse(function(r) { let allow = (r.enabled || (!enabled_only)); if (allow && (r.type === type)) list.push(r); if ((r.type === 'group') && (!allow)) return 'skip'; }, group, true, false);
        if (!case_sensitive) name = name.toLowerCase();
        for (var i = 0; i < list.length; ++i) {
            if (case_sensitive) {
                if (list[i].name === name) return list[i];
            } else {
                if (list[i].name.toLowerCase() === name) return list[i];
            }
        }
        return undefined;
    }

    // traverse the reflexes in order, calling fn on each
    traverse(fn, group, include_groups, include_packages)
    {
        let t = this;
        if (group == null) group = t.master_group();

        if (group.type !== "group") {
            fn(group);
            return;
        } else if (include_groups) {
            let res = fn(group);
            if (res && (res === 'skip')) return;   // used to disable entire groups
        }

        for (var i = 0; i < group.items.length; ++i) {
            if (group.items[i].type === "group")
                t.traverse(fn, group.items[i], include_groups, false);
            else
                fn(group.items[i]);
        }

        // add packages too if requested; recursive calls never have this set
        if (include_packages && (this._packages))
        {
            let pkgs = this._packages.list();
            for (var p = 0; p < pkgs.length; ++p) {
                let pkg = pkgs[p];
                if (!pkg.enabled) continue;
                this.traverse(fn, pkg, include_groups, false);
            }
        }
    }

    get_flat_list(type, active)
    {
        let res = [];
        this.traverse(function(r) { let allow = (r.enabled || (!active)); if (allow && (r.type === type)) res.push(r); if ((r.type === 'group') && (!allow)) return 'skip'; }, null, true, true);
        return res;
    }

    // Returns a flattened and ordered list of active reflexes. Used by processing functions
    get_active_list(type)
    {
        return this.get_flat_list(type, true);
    }

    get_package_list()
    {
        return this._packages.list();
    }

    determine_package_for_reflex(r) {
        while (r.p) r = r.p;
        let pkgs = this._packages.list();
        for (var p = 0; p < pkgs.length; ++p)
            if (r === pkgs[p]) return pkgs[p];
        return null;   // not in a package
    }


    encode(e) {
        let t = this;
        if (e == null) e = t.master_group();
        return e.encode();
    }

    apply(list) {
        let t = this;
        let onchange = this.onchange;
        this.onchange = null;  // don't fire off lots of change events
        t.clear();
        let master = t.master_group();
        master.apply (list, this);
        this.onchange = onchange;
        this.reflexes_changed();
    }

    add_waiting_action(action) {
        this.waiting_actions.push (action);
    }

    match_waiting_actions(text, block, idx)
    {
        let txt = text.text();
        let actions_to_run = [];
        // This goes in reverse order so that I can safely splice away matching elements without having to alter indexing
        for (let id = this.waiting_actions.length - 1; id >= 0; --id) {
            let wa = this.waiting_actions[id];

            let match = wa.do_matching(txt);
            if (match == null) {
                if (wa.expire < Date.now()) {
                    this._nexus.display_notice("Wait timer expired.", 'red');
                    this.waiting_actions.splice(id, 1);
                }

                continue;
            }

            let actions = new ActionList (this._nexus);
            actions.prepare ('trigger', wa.reflex, match, block, idx, txt, wa.state);
            actions.startfrom = wa.idx;
            actions_to_run.push(actions);
            this.waiting_actions.splice(id, 1);
        }

        for (let id = 0; id < actions_to_run.length; ++id) {
            actions_to_run[id].execute (actions_to_run[id].startfrom);
        }
    }

    // wrapper needed to prevent circular dependendies
    make_action(atype) {
        return ActionList.makeAction (atype, this._nexus);
    }

    find_function_by_name (name, enabled, pkg) {
        return this.find_by_name('function', name, true, enabled, pkg);
    }

    // Parse functions from the command line
    handle_functions (input)
    {
        let args = input.split(" ");

        // grab the first word, see if a function of that name exists
        let func = args[0];
        let list = [];
        let obj = this.find_function_by_name (func, true);
        if (obj) list.push({ 'obj' : obj, 'pkg' : undefined });
        let pkgs = this._packages.list();
        for (let i = 0; i < pkgs.length; ++i) {
            let obj = this.find_function_by_name (func, true, pkgs[i].name);
            if (obj) list.push({ 'obj' : obj, 'pkg' : pkgs[i].name });
        }
        if (!list.length) return input;

        args.shift();

        let success = false;
        for (let i = 0; i < list.length; ++i) {
            if (list[i]['obj'].exec(args, list[i]['pkg'])) success = true;
        }
        if (success) return false;
        return input;
    }

    run_function (name, args, pkg)
    {
        if (typeof args == undefined) args = "";

        if (pkg === 'ALL') {   // run from all packages
            let obj = this.find_function_by_name (name, true);
            if (obj) obj.exec(args, undefined);
            let pkgs = this._packages.list();
            for (var i = 0; i < pkgs.length; ++i) {
                let obj = this.find_function_by_name (name, true, pkgs[i].name);
                if (obj) obj.exec(args, pkgs[i].name);
            }
            return;
        }

        let obj = this.find_function_by_name (name, true, pkg);
        if (!obj) return;
        obj.exec(args, pkg);
    }

    handle_triggers(block, idx) {
        let nexus = this._nexus;
        let text = null;
        if (block[idx].no_triggers) return block;   // this is mostly for notifications
        if (block[idx].line) text = block[idx].parsed_line;
        if (text == null) return block;
        let txt = text.text();

        // Re-generate this list for every line - this means that enabling/disabling reflexes gets applied for the next line, not for the next block. Needed for multi-line to work correctly.
        let list = this.get_active_list('trigger');
        let s = nexus.settings();

        for (let id = 0; id < list.length; ++id) {
            let match = list[id].do_matching(txt);
            if (match == null) continue;

            if (s.echo_triggers)
                nexus.display_notice('Trigger: '+list[id].name, s.color_trigecho);

            let actions = new ActionList(nexus);
            actions.prepare ('trigger', list[id], match, block, idx, text);
            actions.execute (0);
        }

        this.match_waiting_actions(text, block, idx);
        return block;
    }



}



