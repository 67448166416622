
import React from 'react';

export class LoginBaseDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { page: this.props.showregister ? 1 : 0, login_email: this.props.acctemail || '', login_pass: this.props.acctpass || '', new_email: '', new_pass: '', new_pass2: '', remember: false, showpass: false };
    }

    toggleShowPass() {
        this.setState({showpass: !(this.state.showpass)});
    }

    onLogin() {
        this.props.onlogin(this.state.login_email, this.state.login_pass, this.state.remember);
    }

    onCreate() {
        this.props.onregister(this.state.new_email, this.state.new_pass, this.state.new_pass2);
    }

    onForgot() {
        this.props.onforgot(this.state.forgot_email);
    }

    showLogin() {
        this.setState({page: 0});
    }
    
    showCreation() {
        this.setState({page: 1});
    }

    showForgot() {
        this.setState({page: 2});
    }

    dialogCaption() {
        if (this.state.page === 1) return 'CREATE NEXUS ACCOUNT';
        if (this.state.page === 2) return 'FORGOT PASSWORD';
        return 'NEXUS LOGIN';
    }

    
}
