
// the Help page - not much here

import React from 'react';

import { FormSection } from './settingcomps'

export class SettingsHelp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    render() {
        let res = [];
        
        let el = (<p key='help1'>Please refer to the <a href="https://nexus.ironrealms.com/" target="_blank" rel="noopener noreferrer">Nexus wiki</a> for more information.</p>);
        res.push (this.formSection ('Online Help', el, 'help'));
        el = (<p key='help4'>The <b>Import/Export Settings</b> button allows you to save your settings locally to your computer, or to load them back.</p>);
        res.push (this.formSection ('Importing/Exporting Settings', el, 'importexport'));
        
        return (<div>{res}</div>);
    }
}
