
// This encapsulates a socket connecting to the game. Right now it's a websocket.

export class Socket {
    constructor(host, port, dir, encrypted)
    {
        this.host = host;
        this.port = port;
        this.dir = dir;
        this.use_wss = encrypted;
        this.ws = null;

        // handlers, caller needs to set them accordingly
        this.onconnect = null;
        this.onclose = null;
        this.ondata = null;  // takes a string param
        this.onerror = null;
    }

    connect() {
        if (this.ws) this.ws.close();

        var t = this;

        var addr = ((this.use_wss === true) ? 'wss' : 'ws') + '://';
        addr += this.host + ':' + this.port + this.dir;

        this.ws = new WebSocket(addr, 'binary');
        this.ws.binaryType = 'arraybuffer';
        this.ws.onopen = function(e) { t.onconnect(); };
        this.ws.onclose = function(e) { t.ws = null; if (t.onclose) t.onclose(); };
        this.ws.onmessage = function(e) { t.handle_read(e, t.ondata); };
        this.ws.onerror = function(e) { t.onerror(e); };
    }

    close() {
        this.ws.close();
    }

    connected() {
        if (this.ws && (this.ws.readyState === 1)) return true;  // 1 = OPEN
        return false;
    }

    send(s) {
        if (!this.connected()) return;
        // convert the string to an arraybuffer, to preserve special chars (don't want them UTF8-d)
        var arry = s.split('');
        for (var i = 0; i < arry.length; ++i)
            arry[i] = arry[i].charCodeAt(0);

        arry = new Uint8Array(arry);
        this.ws.send(arry);
    }

    handle_read(msgevent, handler)
    {
        var arry = msgevent.data;
        arry = new Uint8Array(arry);  // convert to types array
        var s = '';
        for (var i = 0; i < arry.length; ++i)
            s += String.fromCharCode(arry[i]);

        if (handler) handler(s);
    }

    has_pending_data() {
        if (!this.ws) return 0;
        return this.ws.bufferedAmount;
    }
}


