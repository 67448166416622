 
// Reflexes page - individual action editors

import React from 'react';

import { FormSection } from './settingcomps'
import { ActionField } from './actionfield'
import { Util } from '../../base/util.js'
import { ActionList } from '../../reflexes/actions.js'


// base class for action editors -and- widgets - edits one action, or a portion of one
class ActionEditorBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = { action: props.action };
        this.values = {};
        this.conditions = {};
        this.parent = null;  // this is for value/condition widgets
    }

    // update state from the attrib list
    componentDidMount() {
        let list_el = this.props.list_el;
        let aid = this.props.aid;
        list_el.editors[aid] = this;
    }

    componentDidUpdate(prevprops) {
        let list_el = prevprops.list_el;
        let aid = prevprops.aid;
        delete list_el.editors[aid];

        list_el = this.props.list_el;
        aid = this.props.aid;
        list_el.editors[aid] = this;

        if (this.props.action !== this.state.action) {
            this.values = {};  // need to reset values and conditions, otherwise they retain the old actions
            this.conditions = {};
            this.setState({ action: this.props.action });
        }
    }

    componentWillUnmount() {
        let list_el = this.props.list_el;
        let aid = this.props.aid;
        delete list_el.editors[aid];
    }

    // list of attributes that this action supports - overridden by child classes
    attribList() {
        return [];
    }

    // returns one of 'string', 'select', 'int', 'bool', 'color', 'value' - overridden by child classes
    attribType(attrib) {
        return 'string';
    }

    // overridden by child classes as needed
    attribChoices(attrib) {
        return [];
    }

    // overridden by child classes as needed
    attribChoiceCaption(attrib, choice) {
        return choice;
    }

    // overridden by child classes as needed
    attribValueVars(attrib) {
        return [ 'type', 'value', 'Type', 'Value' ];
    }

    // overridden by child classes as needed
    attribConditionVar(attrib) {
        return 'condition';
    }

    // overridden by child classes
    attribCaption(attrib) {
        return Util.ucfirst (attrib);
    }

    // overridden by child classes
    attribTooltip(attrib) {
        return null;
    }

    // overridden by child classes
    attribGrow(attrib) {
        return 0;
    }

    attribStyle(attrib) {
        return null;
    }

    getValueWidget(attrib) {
        if (!this.values[attrib]) {
            let val = new ValueWidget(this.props, this);
            let vars = this.attribValueVars(attrib);
            val.set(vars[0], vars[1], vars[2], vars[3]);
            val.reflextype = this.props.reflextype;
            this.values[attrib] = val;
        }
        return this.values[attrib];
    }

    getConditionWidget(attrib) {
        if (!this.conditions[attrib]) {
            let val = new ConditionWidget(this.props, this);
            let cvar = this.attribConditionVar(attrib);
            val.set(cvar);
            val.reflextype = this.props.reflextype;
            this.conditions[attrib] = val;
        }
        return this.conditions[attrib];
    }

    topParent() {
        let comp = this;
        while (comp.parent) comp = comp.parent;  // find the topmost parent - these can be nested
        return comp;
    }

    // Did we modify this action?
    isModified() {
        let comp = this.topParent();
        let action = comp.state.action;
        let orig = comp.props.orig_action;

        if (!orig) return true;
        if (action.type !== orig.type()) return true;
        let attrs = this.attribList();
        for (let id = 0; id < attrs.length; ++id) {
            let aname = attrs[id];
            if (this.attribType(aname) === 'value') {
                if (this.getValueWidget(aname).isModified()) return true;
            }
            if (this.attribType(aname) === 'condition') {
                if (this.getConditionWidget(aname).isModified()) return true;
            }
            if (orig[aname] !== action[aname]) return true;
        }
        return false;
    }

    updateField(name, val) {
        let type = this.attribType(name);
        if (val === undefined) return;
        if (val === null) return;
        if ((type === 'color') && (!val)) return;  // colors must be defined

        if (type === 'int') {
            val = parseInt(val);
            if (isNaN(val)) return;
        }

        let action = this.state.action;
        action[name] = val;
        let comp = this.topParent();
        comp.setState({ action: action });
    }

    // Should the field be hidden right now? Used to toggle elements that only apply when certain options are chosen.
    fieldHidden(name) {
        return false;
    }

    renderField(name) {
        if (this.fieldHidden(name)) return null;

        let caption = this.attribCaption(name);
        let tooltip = this.attribTooltip(name);
        let type = this.attribType(name);
        let grow = this.attribGrow(name);
        let style = this.attribStyle(name);

        let tips = this.props.nexus.platform().tooltips_shown();
        if (!tips) tooltip = null;
        let action = this.state.action;
        let val = action[name];
        let nex = this.props.nexus;

        let inputProps = {};
        if (type === 'value') inputProps['valueWidget'] = this.getValueWidget(name);
        if (type === 'condition') inputProps['conditionWidget'] = this.getConditionWidget(name);
        if (type === 'select') {
            let choices = this.attribChoices(name);
            let opts = [];
            for (let idx = 0; idx < choices.length; ++idx) {
                let ch = choices[idx];
                let entry = { choice: ch, caption: this.attribChoiceCaption(name, ch) };
                opts.push (entry);
            }
            inputProps['choices'] = opts;
        }

        return (<ActionField key={'action-'+name} name={name} nexus={nex} caption={caption} style={style} tooltip={tooltip} type={type} grow={grow} value={val} updateField={(name,val)=>this.updateField(name,val)} {...inputProps} />);
    }
    
    renderFields() {
        let attrs = this.attribList();
        let res = [];
        for (let id = 0; id < attrs.length; ++id) {
            let aname = attrs[id];
            res.push (this.renderField (aname));
        }
        return res;
    }

    render() {
        return this.renderFields();
    }
}

// this is a react component, but it's not actually mounted as one
class ValueWidget extends ActionEditorBase {
    constructor(props, parent) {
        super(props);
        this.set('type', 'value', 'Type', 'Value');
        this.reflextype = null;
        this.parent = parent;
    }

    set(tvar, vvar, tcaption, vcaption) {
        this.typeVar = tvar;
        this.valueVar = vvar;
        this.typeCaption = tcaption;
        this.valueCaption = vcaption;
    }

    attribList() {
        return [ this.typeVar, this.valueVar ];
    }

    attribType(attrib) {
        if (attrib === this.typeVar) return 'select';
        return 'string';
    }

    attribCaption(attrib) {
        if (attrib === this.typeVar) return this.typeCaption;
        if (attrib === this.valueVar) return this.valueCaption;
        return attrib;
    }

    attribTooltip(attrib) {
        if (attrib === this.typeVar) return 'The type of the value.';
        if (attrib === this.valueVar) return 'The value to use, depending on the type: value = enter the value directly; variable = enter the variable name; target = nothing needed; captured text = its number.';
        return null;
    }

    attribChoices(attrib) {
        if (attrib === this.typeVar) {
            let choices = ['value', 'variable', 'target'];
            if (this.reflextype === 'trigger')
                choices.push ('capture');
            return choices;
        }
        return [];
    }

    attribChoiceCaption(attrib, choice) {
        if (attrib === this.typeVar) {
            if (choice === 'value') return 'Fixed value';
            if (choice === 'variable') return 'Variable';
            if (choice === 'target') return 'Current target';
            if (choice === 'capture') return 'Captured text';
        }
        return choice;
    }

}

// this is also a react component that is not actually mounted as one
class ConditionWidget extends ActionEditorBase {
    constructor(props, parent) {
        super(props);
        this.parent = parent;
        this.valueWidget1 = new ValueWidget(props, parent);
        this.valueWidget2 = new ValueWidget(props, parent);
        this.set ('condition');
    }
    
    set(condvar) {
        this.condVar = condvar;
        this.valueWidget1.set (this.condVar + '-type1', this.condVar + '-val1', 'Type 1', 'Value 1');
        this.valueWidget2.set (this.condVar + '-type2', this.condVar + '-val2', 'Type 2', 'Value 2');
    }

    attribValueVars(attrib) {
        if (attrib === this.condVar + '1') return [ this.condVar + '-type1', this.condVar + '-val1', 'Type 1', 'Value 1' ];
        if (attrib === this.condVar + '2') return [ this.condVar + '-type2', this.condVar + '-val2', 'Type 2', 'Value 2' ];
        return [];
    }

    attribList() {
        let res = [ ];
        let suffixes = [ '1', '-mod', '-op', '2', '-cs' ];
        for (let idx = 0; idx < suffixes.length; ++idx)
            res.push (this.condVar + suffixes[idx]);
        return res;
    }

    attribType(attrib) {
        if (attrib === this.condVar + '1') return 'value';
        if (attrib === this.condVar + '2') return 'value';
        if (attrib === this.condVar + '-op') return 'select';
        if (attrib === this.condVar + '-mod') return 'select';
        if (attrib === this.condVar + '-cs') return 'bool';
        return 'string';
    }

    attribCaption(attrib) {
        if (attrib === this.condVar + '-op') return '';
        if (attrib === this.condVar + '-mod') return '';
        if (attrib === this.condVar + '-cs') return 'Match case';
        return attrib;
    }

    attribTooltip(attrib) {
        if (attrib === this.condVar + '-op') return null;
        if (attrib === this.condVar + '-mod') return null;
        if (attrib === this.condVar + '-cs') return 'If checked, uppercase and lowercase letters are considered different.';
        return null;
    }

    attribStyle(attrib) {
        if (attrib === this.condVar + '-mod') return { minWidth: 'auto' };
        if (attrib === this.condVar + '-op') return { minWidth: 'auto' };
        return null;
    }

    attribChoices(attrib) {
        if (attrib.endsWith('1')) return this.valueWidget1.attribChoices(attrib);
        if (attrib.endsWith('2')) return this.valueWidget2.attribChoices(attrib);

        if (attrib === this.condVar + '-mod') return [ 'is', 'not' ];
        if (attrib === this.condVar + '-op') return [ 'eq', 'greater', 'smaller', 'starts', 'ends' ];

        if (attrib === this.typeVar) {
            let choices = ['value', 'variable', 'target', 'capture'];
            return choices;
        }
        return [];
    }

    attribChoiceCaption(attrib, choice) {
        if (attrib.endsWith('1')) return this.valueWidget1.attribChoiceCaption(attrib);
        if (attrib.endsWith('2')) return this.valueWidget2.attribChoiceCaption(attrib);
        
        if (attrib === this.condVar + '-mod') {
            if (choice === 'is') return 'is';
            if (choice === 'not') return 'is not';
        }
        if (attrib === this.condVar + '-op') {
            if (choice === 'eq') return '=';
            if (choice === 'greater') return '>';
            if (choice === 'smaller') return '<';
            if (choice === 'starts') return 'starts with';
            if (choice === 'ends') return 'ends with';
        }
        return choice;
    }

}


class ActionEditorCommand extends ActionEditorBase {
    attribList() {
        let type = this.props.reflextype;
        if (type === 'alias')
            return [ 'command', 'prefix_suffix' ];
        return [ 'command' ];
    }

    attribType(attrib) {
        if (attrib === 'prefix_suffix') return 'bool';
        return 'string';
    }

    attribCaption(attrib) {
        if (attrib === 'command') return 'Command';
        if (attrib === 'prefix_suffix') return 'Include prefix and suffix';
        return attrib;
    }

    attribGrow(attrib) {
        if (attrib === 'command') return true;
        return false;
    }

    attribTooltip(attrib) {
        if (attrib === 'command') return 'The command to send to the game.';
        if (attrib === 'prefix_suffix') return 'If checked, the command sent to the game will include any text that you enter before and after the matching text.';
        return null;
    }
}

class ActionEditorNotify extends ActionEditorBase {
    attribList() {
        return [ 'notice', 'notice_fg', 'notice_bg' ];
    }

    attribType(attrib) {
        if ((attrib === 'notice_fg') || (attrib === 'notice_bg')) return 'color';
        return 'string';
    }

    attribCaption(attrib) {
        if (attrib === 'notice') return 'Text';
        if (attrib === 'notice_fg') return 'FG';
        if (attrib === 'notice_bg') return 'BG';
        return attrib;
    }

    attribTooltip(attrib) {
        if (attrib === 'notice') return 'The text to display.';
        if (attrib === 'notice_fg') return 'Foreground color of the notice.';
        if (attrib === 'notice_bg') return 'Background color of the notice.';
        return null;
    }
}

class ActionEditorNotification extends ActionEditorBase {
    attribList() {
        return [ 'heading', 'text' ];
    }

    attribType(attrib) {
        return 'string';
    }

    attribCaption(attrib) {
        if (attrib === 'heading') return 'Heading';
        if (attrib === 'text') return 'Text';
        return attrib;
    }

    attribTooltip(attrib) {
        if (attrib === 'heading') return 'The heading of the notice.';
        if (attrib === 'text') return 'The text of the notice.';
        return null;
    }
}

class ActionEditorSound extends ActionEditorBase {
    attribList() {
        return [ 'sound' ];
    }

    attribType(attrib) {
        return 'select';
    }

    attribGrow(attrib) {
        if (attrib === 'sound') return true;
        return false;
    }

    attribCaption(attrib) {
        if (attrib === 'sound') return 'Sound to play';
        return attrib;
    }

    attribTooltip(attrib) {
        if (attrib === 'sound') return 'Select the sound to play';
        return null;
    }

    attribChoices(attrib) {
        let nex = this.props.nexus;
        let sounds = nex.ui().sounds();
        return sounds.sounds_list;
    }

}

class ActionEditorWait extends ActionEditorBase {
    attribList() {
        return [ 'seconds', 'milliseconds' ];
    }

    attribGrow(attrib) {
        if ((attrib === 'seconds') || (attrib === 'milliseconds')) return true;
        return false;
    }

    attribType(attrib) {
        return 'int';
    }

    attribCaption(attrib) {
        if (attrib === 'seconds') return 'Seconds';
        if (attrib === 'milliseconds') return 'Milliseconds';
        return attrib;
    }
}

class ActionEditorFunction extends ActionEditorBase {
    attribList() {
        return [ 'fn' ];
    }

    attribType(attrib) {
        return 'select';
    }

    attribCaption(attrib) {
        if (attrib === 'fn') return 'Function';
        return attrib;
    }

    attribGrow(attrib) {
        if (attrib === 'fn') return true;
        return false;
    }

    attribTooltip(attrib) {
        if (attrib === 'fn') return 'Select the function to execute.';
        return null;
    }

    attribChoices(attrib) {
        if (attrib !== 'fn') return [];
        let refs = this.props.reflexes;
        let pkgs = refs.packages();
        let selpackage = this.props.selpackage;

        let pkg = (selpackage === undefined) ? null : pkgs.get(selpackage);
        // get functions in the current package, including disabled ones
        let lst = [];
        refs.traverse(function(r) { if (r.type === 'function') lst.push(r.name); }, pkg, false);

        return lst;
    }
}

class ActionEditorScript extends ActionEditorBase {
    attribList() {
        return [ 'script' ];
    }

    attribType(attrib) {
        return 'codemirror';
    }

    attribCaption(attrib) {
        if (attrib === 'script') return 'Script';
        return attrib;
    }

    attribTooltip(attrib) {
        if (attrib === 'script') return 'Enter the script to execute.';
        return null;
    }
}

class ActionEditorVariable extends ActionEditorBase {
    attribList() {
        return [ 'varname', 'op', 'val' ];
    }

    attribType(attrib) {
        if (attrib === 'varname') return 'string';
        if (attrib === 'op') return 'select';
        if (attrib === 'val') return 'value';
    }

    attribChoices(attrib) {
        if (attrib !== 'op') return [];
        return ['set', 'del', 'add', 'sub', 'mul', 'div'];
    }

    // overridden by child classes as needed
    attribChoiceCaption(attrib, choice) {
        if (attrib !== 'op') return choice;
        if (choice === 'set') return 'Set to';
        if (choice === 'del') return 'Delete';
        if (choice === 'add') return 'Increase by';
        if (choice === 'sub') return 'Decrease by';
        if (choice === 'mul') return 'Multiply by';
        if (choice === 'div') return 'Divide by';
        return choice;
    }

    attribValueVars(attrib) {
        if (attrib !== 'val') return;
        return [ 'valtype', 'value', 'Variable type', 'Value' ];
    }

    attribCaption(attrib) {
        if (attrib === 'op') return 'Operation';
        if (attrib === 'varname') return 'Variable name';
        return attrib;
    }

    fieldHidden(name) {
        let action = this.state.action;
        if (name === 'val') {
            if (action['op'] !== 'del') return false;
            return true;
        }
        return false;
    }

    attribTooltip(attrib) {
        return null;
    }
}

class ActionEditorIf extends ActionEditorBase {
    attribList() {
        return [ 'cond', 'dothen', 'dothenlabel', 'doelse', 'doelselabel' ];
    }

    attribType(attrib) {
        if (attrib === 'cond') return 'condition';
        if (attrib === 'dothen') return 'select';
        if (attrib === 'doelse') return 'select';
        return 'string';
    }

    attribConditionVar(attrib) {
        return 'cond';
    }

    attribCaption(attrib) {
        if (attrib === 'dothen') return 'then';
        if (attrib === 'doelse') return 'else';
        if (attrib === 'dothenlabel') return 'Label';
        if (attrib === 'doelselabel') return 'Label';
        return attrib;
    }

    attribChoices(attrib) {
        if ((attrib === 'dothen') || (attrib === 'doelse')) return [ 'continue', 'stop', 'jump' ];
        return [];
    }

    // overridden by child classes as needed
    attribChoiceCaption(attrib, choice) {
        if ((attrib === 'dothen') || (attrib === 'doelse')) {
            if (choice === 'continue') return 'Keep going';
            if (choice === 'stop') return 'Stop';
            if (choice === 'jump') return 'Jump to label';
        }
        return choice;
    }

    fieldHidden(name) {
        let action = this.state.action;
        if (name === 'dothenlabel') {
            if (action['dothen'] === 'jump') return false;
            return true;
        }
        if (name === 'doelselabel') {
            if (action['doelse'] === 'jump') return false;
            return true;
        }
        return false;
    }

    attribTooltip(attrib) {
        return null;
    }
}

class ActionEditorRepeat extends ActionEditorBase {
    attribList() {
        return [ 'label', 'mode', 'counttype', 'cond' ];
    }

    attribType(attrib) {
        if (attrib === 'label') return 'string';
        if (attrib === 'mode') return 'select';
        if (attrib === 'counttype') return 'value';  // if mode==count
        if (attrib === 'cond') return 'condition';  // if mode==while
        return 'string';
    }

    attribValueVars(attrib) {
        if (attrib !== 'counttype') return;
        return [ 'counttype', 'count', 'Count type', 'This many times' ];
    }

    attribConditionVar(attrib) {
        return 'cond';
    }

    attribCaption(attrib) {
        if (attrib === 'label') return 'Repeat up to label';
        if (attrib === 'mode') return 'Mode';
        return attrib;
    }

    attribChoices(attrib) {
        if ((attrib === 'mode')) return [ 'count', 'while' ];
        return [];
    }

    // overridden by child classes as needed
    attribChoiceCaption(attrib, choice) {
        if ((attrib === 'mode')) {
            if (choice === 'count') return 'This many times';
            if (choice === 'while') return 'While';
        }
        return choice;
    }

    attribTooltip(attrib) {
        if (attrib === 'label') return 'Repeat actions between this element and the chosen label.';
        if (attrib === 'mode') return 'Choose between a fixed repeat count, or repeating while a condition matches.';
        return null;
    }

    fieldHidden(name) {
        let action = this.state.action;
        if (name === 'counttype') {
            if (action['mode'] === 'count') return false;
            return true;
        }
        if (name === 'cond') {
            if (action['mode'] === 'while') return false;
            return true;
        }
        return false;
    }

}

class ActionEditorGag extends ActionEditorBase {
}

class ActionEditorHighlight extends ActionEditorBase {
    attribList() {
        return [ 'highlight', 'highlight_backref', 'highlight_fg', 'highlight_bg' ];
    }

    attribType(attrib) {
        if ((attrib === 'highlight_fg') || (attrib === 'highlight_bg')) return 'color';
        if (attrib === 'highlight_backref') return 'int';
        if (attrib === 'highlight') return 'select';
        return 'string'
    }

    attribCaption(attrib) {
        if (attrib === 'highlight') return 'Highlight';
        if (attrib === 'highlight_backref') return 'Captured #';
        if (attrib === 'highlight_fg') return 'FG';
        if (attrib === 'highlight_bg') return 'BG';
        if (attrib === 'text') return 'Text';
        return attrib;
    }

    attribTooltip(attrib) {
        if (attrib === 'highlight') return 'Pick what to highlight.';
        if (attrib === 'highlight_backref') return 'If you are highlighting a captured text, enter its number here.';
        if (attrib === 'highlight_fg') return 'Foreground color of the highlighted text.';
        if (attrib === 'highlight_bg') return 'Background color of the highlighted text.';
        return null;
    }

    attribChoices(attrib) {
        if (attrib !== 'highlight') return [];
        return ['match', 'line', 'prefix', 'suffix', 'backref'];
    }

    attribChoiceCaption(attrib, choice) {
        if (attrib !== 'highlight') return choice;
        if (choice === 'match') return 'Match';
        if (choice === 'line') return 'Whole line';
        if (choice === 'prefix') return 'Prefix';
        if (choice === 'suffix') return 'Suffix';
        if (choice === 'backref') return 'Captured text';
        return choice;
    }
    
    fieldHidden(name) {
        let action = this.state.action;
        if (name === 'highlight_backref') {
            if (action['highlight'] === 'backref') return false;
            return true;
        }
        return false;
    }

}

class ActionEditorRewrite extends ActionEditorBase {
    attribList() {
        return [ 'rewrite', 'rewrite_backref', 'value', 'rewrite_colors', 'rewrite_fg', 'rewrite_bg' ];
    }

    attribType(attrib) {
        if (attrib === 'rewrite') return 'select';
        if (attrib === 'rewrite_backref') return 'int';
        if (attrib === 'value') return 'value';
        if (attrib === 'rewrite_colors') return 'bool';
        if (attrib === 'rewrite_fg') return 'color';
        if (attrib === 'rewrite_bg') return 'color';
        return attrib;
    }

    attribChoices(attrib) {
        if (attrib !== 'rewrite') return [];
        return ['match', 'line', 'prefix', 'suffix', 'backref'];
    }

    // overridden by child classes as needed
    attribChoiceCaption(attrib, choice) {
        if (attrib !== 'rewrite') return choice;
        if (choice === 'match') return 'Match';
        if (choice === 'line') return 'Whole line';
        if (choice === 'prefix') return 'Prefix';
        if (choice === 'suffix') return 'Suffix';
        if (choice === 'backref') return 'Captured text';
        return choice;
    }

    attribValueVars(attrib) {
        if (attrib !== 'value') return;
        return [ 'rewrite_text_type', 'rewrite_text', 'Rewrite type', 'Value' ];
    }

    attribCaption(attrib) {
        if (attrib === 'rewrite') return 'Rewrite';
        if (attrib === 'rewrite_backref') return 'Captured #';
        if (attrib === 'rewrite_colors') return 'And highlight';
        if (attrib === 'rewrite_fg') return 'FG';
        if (attrib === 'rewrite_bg') return 'BG';
        return attrib;
    }

    attribTooltip(attrib) {
        if (attrib === 'rewrite_colors') return 'Check this if you want to enable color highlighting.';
        return null;
    }
    
    fieldHidden(name) {
        let action = this.state.action;
        if (name === 'rewrite_backref') {
            if (action['rewrite'] === 'backref') return false;
            return true;
        }
        if ((name === 'rewrite_fg') || (name === 'rewrite_bg')) {
            if (action['rewrite_colors']) return false;
            return true;
        }
        return false;
    }

}

class ActionEditorLinkify extends ActionEditorBase {
    attribList() {
        return [ 'linkify', 'linkify_backref', 'text', 'command', 'linkify_fg' ];
    }

    attribType(attrib) {
        if (attrib === 'linkify') return 'select';
        if (attrib === 'linkify_backref') return 'int';
        if (attrib === 'text') return 'value';
        if (attrib === 'command') return 'value';
        if (attrib === 'linkify_fg') return 'color';
        return attrib;
    }

    attribChoices(attrib) {
        if (attrib !== 'linkify') return [];
        return ['match', 'line', 'prefix', 'suffix', 'backref'];
    }

    // overridden by child classes as needed
    attribChoiceCaption(attrib, choice) {
        if (attrib !== 'linkify') return choice;
        if (choice === 'match') return 'Match';
        if (choice === 'line') return 'Whole line';
        if (choice === 'prefix') return 'Prefix';
        if (choice === 'suffix') return 'Suffix';
        if (choice === 'backref') return 'Captured text';
        return choice;
    }

    attribValueVars(attrib) {
        if (attrib === 'text') return [ 'linkify_text_type', 'linkify_text', 'With text', 'Text' ];
        if (attrib === 'command') return [ 'linkify_command_type', 'linkify_command', 'Command type', 'Command' ];
        return [];
    }

    attribCaption(attrib) {
        if (attrib === 'linkify') return 'Linkify';
        if (attrib === 'linkify_backref') return 'Captured #';
        if (attrib === 'linkify_fg') return 'FG';
        return attrib;
    }

    attribTooltip(attrib) {
        return null;
    }
    
    fieldHidden(name) {
        let action = this.state.action;
        if (name === 'linkify_backref') {
            if (action['linkify'] === 'backref') return false;
            return true;
        }
        return false;
    }

}

class ActionEditorDisableMe extends ActionEditorBase {
}

class ActionEditorDisable extends ActionEditorBase {
    attribList() {
        return [ 'rtype', 'name' ];
    }

    attribType(attrib) {
        if (attrib === 'rtype') return 'select';
        return 'string'
    }

    attribCaption(attrib) {
        if (attrib === 'rtype') return 'Type';
        if (attrib === 'name') return 'Name';
        return attrib;
    }

    attribGrow(attrib) {
        if (attrib === 'name') return true;
        return false;
    }

    attribTooltip(attrib) {
        if (attrib === 'rtype') return 'Select the reflex type.';
        if (attrib === 'name') return 'Enter the name of the reflex that you want to disable.';
        return null;
    }

    attribChoices(attrib) {
        // TODO: updating this list automatically would be nice
        if (attrib !== 'rtype') return [];
        return ['group', 'alias', 'trigger', 'event', 'keybind'];
    }
}

class ActionEditorEnable extends ActionEditorBase {
    attribList() {
        return [ 'rtype', 'name' ];
    }

    attribType(attrib) {
        if (attrib === 'rtype') return 'select';
        return 'string'
    }

    attribGrow(attrib) {
        if (attrib === 'name') return true;
        return false;
    }

    attribCaption(attrib) {
        if (attrib === 'rtype') return 'Type';
        if (attrib === 'name') return 'Name';
        return attrib;
    }

    attribTooltip(attrib) {
        if (attrib === 'rtype') return 'Select the reflex type.';
        if (attrib === 'name') return 'Enter the name of the reflex that you want to enable.';
        return null;
    }

    attribChoices(attrib) {
        // TODO: updating this list automatically would be nice
        if (attrib !== 'rtype') return [];
        return ['group', 'alias', 'trigger', 'event', 'keybind'];
    }
}

class ActionEditorLabel extends ActionEditorBase {
    attribList() {
        return [ 'label' ];
    }

    attribType(attrib) {
        return 'string';
    }

    attribCaption(attrib) {
        if (attrib === 'label') return 'Label';
        return attrib;
    }

    attribGrow(attrib) {
        if (attrib === 'label') return true;
        return false;
    }

    attribTooltip(attrib) {
        if (attrib === 'label') return 'Enter the name of the label. You can then use the same name in a Jump action to shift execution to it.';
        return null;
    }
}

class ActionEditorGoto extends ActionEditorBase {
    attribList() {
        return [ 'label' ];
    }

    attribType(attrib) {
        return 'string';
    }

    attribGrow(attrib) {
        if (attrib === 'label') return true;
        return false;
    }

    attribCaption(attrib) {
        if (attrib === 'label') return 'Label';
        return attrib;
    }

    attribTooltip(attrib) {
        if (attrib === 'label') return 'Enter the name of the label. You can then use the same name in a Jump action to shift execution to it.';
        return null;
    }
}

class ActionEditorStop extends ActionEditorBase {
}


class ActionEditorWaitFor extends ActionEditorBase {
    attribList() {
        return [ 'matching', 'text', 'whole_words', 'case_sensitive', 'expire' ];
    }

    attribType(attrib) {
        if (attrib === 'text') return 'string';
        if (attrib === 'matching') return 'select';
        if (attrib === 'whole_words') return 'bool';
        if (attrib === 'case_sensitive') return 'bool';
        if (attrib === 'expire') return 'int';
        return 'string';
    }

    attribChoices(attrib) {
        if (attrib !== 'matching') return [];
        return ['substring', 'begins', 'exact', 'regexp'];
    }

    // overridden by child classes as needed
    attribChoiceCaption(attrib, choice) {
        if (attrib !== 'matching') return choice;
        if (choice === 'substring') return 'Contains';
        if (choice === 'begins') return 'Begins with';
        if (choice === 'exact') return 'Exact match';
        if (choice === 'regexp') return 'Regular expression';

        return choice;
    }

    attribCaption(attrib) {
        if (attrib === 'text') return 'Wait for text';
        if (attrib === 'matching') return 'Matching type';
        if (attrib === 'whole_words') return 'Whole words only';
        if (attrib === 'case_sensitive') return 'Match case';
        if (attrib === 'expire') return 'For seconds';
        return attrib;
    }

    attribTooltip(attrib) {
        return null;
    }
}

class ActionEditorButton extends ActionEditorBase {
    attribList() {
        return [ 'buttonid', 'buttonaction', 'label', 'command' ];
    }

    attribType(attrib) {
        if (attrib === 'buttonid') return 'select';
        if (attrib === 'buttonaction') return 'select';
        return 'string';
    }

    attribChoices(attrib) {
        if (attrib === 'buttonid') {
            let buttons = this.props.buttons;
            let res = [];
            for (let idx = 1; idx <= buttons.count; ++idx)
                res.push (idx);
            return res;
        }
        
        if (attrib === 'buttonaction') 
            return ['label', 'command', 'highlight', 'unhighlight', 'default'];
        return [];
    }

    // overridden by child classes as needed
    attribChoiceCaption(attrib, choice) {
        if (attrib === 'buttonaction')  {
            if (choice === 'label') return 'Change label';
            if (choice === 'command') return 'Change command';
            if (choice === 'highlight') return 'Highlight';
            if (choice === 'unhighlight') return 'Unhighlight';
            if (choice === 'default') return 'Reset to default';
        }
        if (attrib === 'buttonid') {
            let buttons = this.props.buttons;
            return buttons.text(choice);
        }
        return choice;
    }

    attribCaption(attrib) {
        if (attrib === 'buttonid') return 'Button';
        if (attrib === 'buttonaction') return 'Action';
        if (attrib === 'label') return 'Button label';
        if (attrib === 'command') return 'Button command';
        return attrib;
    }

    attribTooltip(attrib) {
        return null;
    }
    
    fieldHidden(name) {
        let action = this.state.action;
        if (name === 'label') {
            if (action['buttonaction'] === 'label') return false;
            return true;
        }
        if (name === 'command') {
            if (action['buttonaction'] === 'command') return false;
            return true;
        }
        return false;
    }
    
}



// renders a list of all the editors
export class ActionEditorList_Base extends React.Component {
    constructor(props) {
        super(props);
        this.editors = {};  // editor elements
        this.state = { newactiontype: 'command', info: 1 };
    }

    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    // update state from the attrib list
    componentDidMount() {
        let parent_el = this.props.parent_el;
        parent_el.actions_el = this;
    }

    componentWillUnmount() {
        let parent_el = this.props.parent_el;
        parent_el.actions_el = null;
    }

    actions_for_element(type) {
        let res = [];
        res.push('command');
        res.push('notify');
        res.push('notification');
        res.push('sound');
        res.push('wait');
        res.push('waitfor');
        if (type === 'trigger') {
            res.push('gag');
            res.push('highlight');
            res.push('rewrite');
            res.push('linkify');
        }
        res.push('variable');
        res.push('if');
        res.push('repeat');
        res.push('label');
        res.push('goto');
        res.push('stop');
        res.push('button');
        res.push('disableme');
        res.push('disable');
        res.push('enable');
        res.push('function');
        res.push('script');
        return res;
    }

    action_type_caption(type) {
        if (type === 'command') return 'Send command';
        if (type === 'notify') return 'Show notice';
        if (type === 'notification') return 'Notification';
        if (type === 'sound') return 'Play sound';
        if (type === 'wait') return 'Wait';
        if (type === 'waitfor') return 'Wait for';
        if (type === 'gag') return 'Hide line';
        if (type === 'highlight') return 'Highlight';
        if (type === 'rewrite') return 'Rewrite';
        if (type === 'linkify') return 'Linkify';
        if (type === 'variable') return 'Modify variable';
        if (type === 'if') return 'If';
        if (type === 'repeat') return 'Repeat';
        if (type === 'label') return 'Label';
        if (type === 'goto') return 'Jump to label';
        if (type === 'stop') return 'Stop';
        if (type === 'button') return 'Modify a button';
        if (type === 'disableme') return 'Disable this reflex';
        if (type === 'disable') return 'Disable reflex';
        if (type === 'enable') return 'Enable reflex';
        if (type === 'function') return 'Call function';
        if (type === 'script') return 'Execute script';
        return type;
    }

    render_action(a, orig, aid, rtype) {
        let type = a.type;
        let selpackage = this.props.selpackage;
        let nex = this.props.nexus;
        let reflexes = nex.reflexes();
        let buttons = nex.ui().buttons();

        let inputProps = {};
        inputProps['key'] = 'action-'+aid;
        inputProps['nexus'] = nex;
        inputProps['list_el'] = this;
        inputProps['aid'] = aid;
        inputProps['action'] = a;
        inputProps['orig_action'] = orig;
        inputProps['reflextype'] = rtype;
        
        if (type === 'command') return (<ActionEditorCommand {...inputProps} />);
        if (type === 'notify') return (<ActionEditorNotify {...inputProps} />);
        if (type === 'notification') return (<ActionEditorNotification {...inputProps} />);
        if (type === 'sound') return (<ActionEditorSound {...inputProps} />);
        if (type === 'wait') return (<ActionEditorWait {...inputProps} />);
        if (type === 'waitfor') return (<ActionEditorWaitFor {...inputProps} />);
        if (rtype === 'trigger') {
            if (type === 'gag') return (<ActionEditorGag {...inputProps} />);
            if (type === 'highlight') return (<ActionEditorHighlight {...inputProps} />);
            if (type === 'rewrite') return (<ActionEditorRewrite {...inputProps} />);
            if (type === 'linkify') return (<ActionEditorLinkify {...inputProps} />);
        }
        if (type === 'variable') return (<ActionEditorVariable {...inputProps} />);
        if (type === 'if') return (<ActionEditorIf {...inputProps} />);
        if (type === 'repeat') return (<ActionEditorRepeat {...inputProps} />);
        if (type === 'label') return (<ActionEditorLabel {...inputProps} />);
        if (type === 'goto') return (<ActionEditorGoto {...inputProps} />);
        if (type === 'stop') return (<ActionEditorStop {...inputProps} />);
        if (type === 'button') return (<ActionEditorButton buttons={buttons} {...inputProps} />);
        if (type === 'disableme') return (<ActionEditorDisableMe {...inputProps} />);
        if (type === 'disable') return (<ActionEditorDisable {...inputProps} />);
        if (type === 'enable') return (<ActionEditorEnable {...inputProps} />);
        if (type === 'function') return (<ActionEditorFunction reflexes={reflexes} selpackage={selpackage} {...inputProps} />);
        if (type === 'script') return (<ActionEditorScript {...inputProps} />);
        return null;
    }

    newAction(atype) {
        if (!atype) return;
        let reflex = this.props.reflex;
        let actions = reflex.actions;
        let nex = this.props.nexus;

        let a_obj = ActionList.makeAction(atype, nex);
        let a = a_obj.encode();  // we store the encoded objects here
        actions.push (a);

        this.setState({info:1});  // info isn't actually used, we just set it to trigger re-render
    }

    moveAction(origIdx, newIdx) {
        let reflex = this.props.reflex;
        let actions = reflex.actions;
        const [item] = actions.splice(origIdx, 1);
        actions.splice(newIdx, 0, item);

        this.setState({info:1});  // info isn't actually used, we just set it to trigger re-render
    }

    moveActionUp(idx) {
        if (idx <= 0) return;
        this.moveAction(idx, idx - 1);
    }
    
    moveActionDown(idx) {
        let reflex = this.props.reflex;
        let actions = reflex.actions;
        if (idx >= actions.length - 1) return;
        this.moveAction(idx, idx + 1);
    }

    removeAction(idx) {
        let reflex = this.props.reflex;
        let actions = reflex.actions;
        actions.splice(idx, 1);
        
        this.setState({info:1});  // info isn't actually used, we just set it to trigger re-render
    }

    cloneAction(idx) {
        let reflex = this.props.reflex;
        let actions = reflex.actions;
        let nex = this.props.nexus;

        let a = actions[idx];
        let newa = ActionList.makeAction(a.type, nex);
        newa.apply (a);
        actions.push (newa.encode());

        this.setState({info:1});  // info isn't actually used, we just set it to trigger re-render
    }

    onDragEnd(res) {
        if (!res.destination) return;
        let startIdx = res.source.index;
        let endIdx = res.destination.index;
        this.moveAction (startIdx, endIdx);
    }

    isModified() {
        let reflex = this.props.reflex;
        let actions = reflex.actions;
        if (!actions) actions = [];
        for (let aid = 0; aid < actions.length; ++aid) {
            let ed = this.editors[aid];
            if (ed && ed.isModified()) return true;
        }
        return false;
    }

    render() {
        return null;
    }

}
