
// one field in the action editor (text field, select, etc)

import React from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { ChromePicker } from 'react-color';

import { JSEditor } from './jseditor'


// props: name, caption, tooltip, type, value
export class ActionField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
        this.ref = React.createRef();
    }
    
    openColor(open) {
        this.setState({open:open});
    }

    render() {
        let name = this.props.name;
        let caption = this.props.caption;
        let tooltip = this.props.tooltip;
        let type = this.props.type;
        let val = this.props.value;
        let style = this.props.style;
        let grow = this.props.grow || 0;
        let nex = this.props.nexus;
        if ((val === undefined) || (val === null)) val = '';

        let key = 'field-'+name;
        let s;
        let className = grow ? 'growAction' : 'fixedAction';

        if ((type === 'string') || (type === 'int')) {
            if (type === 'int') val = ''+val;  // convert numbers to strings
            s = (<TextField key={key} style={style} className={className} InputLabelProps={{className:className}} label={caption} value={val} onChange={(e)=>this.props.updateField(name, e.target.value)} />);
        } else if (type === 'multiline') {  // currently unused, but keeping here in case we want something that's not a script
            s = (<TextField key={key} InputLabelProps={{className:className}} multiline rows={8} label={caption} value={val} onChange={(e)=>this.props.updateField(name, e.target.value)} />);
        } else if (type === 'codemirror') {
            s = (<JSEditor keyval={key} nex={nex} val={val} onChange={(value)=>this.props.updateField(name, value)} />);
            s = (<FormControlLabel className={className} style={{flexGrow:1}} labelPlacement='top' key={key} control={s} label={caption} />);
        } else if (type === 'bool') {
            s = (<Checkbox checked={val} onChange={(e)=>this.props.updateField(name, e.target.checked)} />);
            s = (<FormControlLabel className={className} key={key} control={s} label={caption} />);
        } else if (type === 'color') {
            let open = this.state.open;
            let picker = (<ChromePicker color={val} onChange={(color,e)=>this.props.updateField(name, color.hex) } disableAlpha={true} />);
            let anchorOrigin={ vertical: 'bottom', horizontal: 'center' };
            let transformOrigin = { vertical: 'top', horizontal: 'center' };
            let popup = (<Popover style={{zIndex:6300}} key={key+'_popup'} open={open} anchorEl={this.ref.current} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} onClose={()=>this.openColor(false)}>{picker}</Popover>);
            let bstyle = { backgroundColor: val };
            s = (<Button className={className} key={key} ref={this.ref} style={bstyle} onClick={()=>{if (!open)this.openColor(!open);} }><span style={{mixBlendMode:'difference'}}>{caption}</span>{popup}</Button>);
        } else if (type === 'select') {
            let opts = [];
            let choices = this.props.choices;
            for (let idx = 0; idx < choices.length; ++idx) {
                let ch = choices[idx];
                opts.push((<MenuItem value={ch.choice}>{ch.caption}</MenuItem>));
            }
            s = (<TextField key={key} value={val} style={style} className={className} InputLabelProps={{className:className}} label={caption} SelectProps={{ MenuProps: { style: {zIndex:5500} } }} onChange={(e)=>this.props.updateField(name, e.target.value)} select>{opts}</TextField>);
        } else if (type === 'value') {
            let w = this.props.valueWidget;
            s = w.render();
            s = (<span key={key} className={className} style={{display:'flex',flexDirection:'column'}}>{s}</span>);
        } else if (type === 'condition') {
            let w = this.props.conditionWidget;
            s = w.render();
            s = (<span key={key}  style={{display:'flex',flexDirection:'row',alignItems:'center'}} className={className}>{s}</span>);
        }

        if (tooltip) s = (<Tooltip key={key} title={tooltip}>{s}</Tooltip>);
        return s;
    }
}

 
