import React from 'react';

import { FormSection } from './settingcomps'
import { Util } from '../../base/util.js'


export class SettingsTabs_Base extends React.Component {
    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    newTab(name) {
        let layout = this.props.layout;
        if (!name.length) return;
        let obj = layout.get_tab_object(name);
        if (obj) {
            this.props.platform.alert('A tab of that name already exists.');
            return;
        }
        
        layout.register_custom_tab(name);
        this.setState({'newtab': ''});
    }

    tabRename(name, newname) {
        let layout = this.props.layout;
        let ctabs = layout.custom_tabs();
        console.log(ctabs[name]);
        if (ctabs[name] === undefined) return;
        if (ctabs[newname] !== undefined) return;   // already exists

        layout.rename_custom_tab(name, newname);
    }
    
    tabRemove(name) {
        let layout = this.props.layout;
        this.props.platform.confirm('Really Remove?', 'Do you really want to remove the ' + Util.ucfirst(name) + ' tab?', () => {
            layout.unregister_custom_tab(name);
            this.setState({'newtab': ''});   // triggers a screen update
        });
    }

    render() {
        return null;
    }
}

 
 
