 
// Reflexes page - this is the big one

import React from 'react';
import { createPortal } from 'react-dom';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { Util } from '../../base/util.js'
import { ActionEditorList } from './actions';
import { ReflexEvent } from '../../reflexes/events.js'
import { JSEditor } from './jseditor'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';

import { ReflexEditor_Base, SettingsReflexes_Base } from './reflexes_base'


class ReflexEditor extends ReflexEditor_Base {

    renderField(rtype, name) {
        let nexus = this.props.nexus;
        let reflexEdit = this.props.reflexEdit;

        let tips = nexus.platform().tooltips_shown();

        let caption = this.attribCaption(rtype, name);
        let tooltip = this.attribTooltip(rtype, name);
        let val = reflexEdit[name];
        if ((val === undefined) || (val === null)) val = '';

        let key = 'field-'+name;
        let type = this.attribType(rtype, name);
        let s;

        if ((type === 'string') || (type === 'int')) {
            if (type === 'int') val = ''+val;  // convert numbers to strings
            s = (<TextField key={key} label={caption} style={{width:'100%'}}        value={val} onChange={(e)=>this.updateField(rtype, name, e.target.value)} />);
        } else if (type === 'multiline') {  // currently unused, but keeping here in case we want something that's not a script
            s = (<TextField key={key} multiline rows={8} label={caption} value={val} onChange={(e)=>this.updateField(rtype, name, e.target.value)} />);
        } else if (type === 'codemirror') {
            s = (<JSEditor keyval={key} nex={nexus} val={val} onChange={(value)=>this.updateField(rtype, name, value)} />);
            let label = (<span>{caption}</span>); // <FormControlLabel key={key+'-label'} labelPlacement='top' style={{width:'100%'}} label={caption} />);
            s = (<div key={key} style={{height:'370px', flexGrow:1}}>{label}{s}</div>);
        } else if (type === 'bool') {
            s = (<Checkbox checked={val} onChange={(e)=>this.updateField(rtype, name, e.target.checked)} />);
            s = (<FormControlLabel key={key} control={s} label={caption} />);
        } else if (type === 'select') {
            let choices = this.attribChoices(rtype, name);
            let opts = [];
            for (let idx = 0; idx < choices.length; ++idx) {
                let ch = choices[idx];
                let caption = this.attribChoiceCaption(rtype, name, ch);
                opts.push((<MenuItem key={'select-'+name+'-'+ch} value={ch}>{caption}</MenuItem>));
            }
            s = (<TextField value={val} key={key} label={caption} SelectProps={{ MenuProps: { style: {zIndex:5500} } }} onChange={(e)=>this.updateField(rtype, name, e.target.value)} select>{opts}</TextField>);
//            s = (<FormControlLabel key={key} control={s} label={caption} />);
        }

        if (tips && tooltip) s = (<Tooltip key={key} title={tooltip} PopperProps={{disablePortal:true}}>{s}</Tooltip>);
        return s;
    }

    renderGroup(fields) {
        return (this.formSection(null, (<div>{fields['name']}</div>), 'group_name'));
    }

    renderAlias(fields) {
        let match = (<div key='match' style={{display:'flex',flexDirection:'row', alignItems:'center'}}>{fields['matching']}<div style={{flexGrow:1,marginLeft:'10px'}}>{fields['text']}</div></div>);
        let options = (<div key='options' style={{display:'flex', flexDirection:'row', alignItems: 'end'}}><div style={{flexGrow:1}}>{fields['whole_words']}</div><div style={{flexGrow:1}}>{fields['case_sensitive']}</div><div style={{flexGrow:1}}>{fields['name']}</div></div>);
        let rows = [ match, options ];
        let s1 = this.formSection (null, rows, 'alias_setup');
        return s1;
    }

    renderTrigger(fields) {
        let match = (<div key='match' style={{display:'flex',flexDirection:'row', alignItems:'center'}}>{fields['matching']}<div style={{flexGrow:1,marginLeft:'10px'}}>{fields['text']}</div></div>);
        let options = (<div key='options' style={{display:'flex', flexDirection:'row', alignItems: 'end'}}><div style={{flexGrow:1}}>{fields['whole_words']}</div><div style={{flexGrow:1}}>{fields['case_sensitive']}</div><div style={{flexGrow:1}}>{fields['name']}</div></div>);
        let rows = [ match, options ];
        let s1 = this.formSection (null, rows, 'trigger_setup');
        return s1;
    }

    onShortcutButton() {
        if (this.state.grabbing) {
            this.setState({grabbing: false});
            return;
        }

        let nexus = this.props.nexus;
        nexus.platform().keygrabber = this;
        this.setState({grabbing: true});
    }
    
    onKeyGrab(key, isAlt, isCtrl, isShift) {
        if ((key == 16) || (key == 17) || (key == 18)) return;   // shift/ctrl/alt
        let nexus = this.props.nexus;
        nexus.platform().keygrabber = null;
        console.log(key);

        let r = this.props.reflexEdit;
        r['key_alt'] = isAlt;
        r['key_ctrl'] = isCtrl;
        r['key_shift'] = isShift;
        r['key'] = key;
        this.setState({ reflexEdit: r, grabbing: false });
    }

    renderKeybind(fields) {
        let el = this.props.reflexEdit;
        let text = this.props.parent_el.reflex_visible_name(el);

        let keybind = (<TextField style={{flexGrow:1}} label='Shortcut' value={text} InputProps={{ readOnly: true }} />);
        let btxt = 'Select the shortcut';
        if (this.state.grabbing) btxt = 'Press the desired shortcut ...';
        let btn = (<Button key='btn_select' variant='contained' onClick={ ()=>this.onShortcutButton() }>{btxt}</Button>);
        return (this.formSection (undefined, (<div style={{display:'flex', flexDirection:'row'}}>{keybind}{btn}</div>), 'keybind_shortcut'));
    }

    renderEvent(fields) {
        let types = ReflexEvent.event_types();
        let opts = [];
        for (let i = 0; i < types.length; ++i) {
            let evtype = types[i];
            let subtypes = ReflexEvent.event_subtypes(evtype);
            for (let j = 0; j < subtypes.length; ++j) {
                let evsubtype = subtypes[j];
                let ch = evtype + ' - ' + evsubtype;
                let caption = ReflexEvent.event_heading(evtype, evsubtype);
                opts.push((<MenuItem value={ch}>{caption}</MenuItem>));
            }
        }

        let el = this.props.reflexEdit;
        let val = el.evtype + ' - ' + el.evsubtype;

        let label = 'Event Type';
        let s = (<TextField value={val} label={label} SelectProps={{ MenuProps: { style: {zIndex:5500} } }} onChange={(e)=>this.updateEvent(e.target.value)} select>{opts}</TextField>);

        s = this.formSection (undefined, s, 'event_type');
        return s;
    }

    renderFunction(fields) {
        let fname = (<div key='function-name'>{fields['name']}</div>);
        let fcode = (<div key='function-code'>{fields['code']}</div>);
        let sections = [ this.formSection(undefined, fname, 'function_name'), this.formSection(undefined, fcode, 'function_code') ];
        return sections;
    }

    renderEnabled() {
        let reflex = this.props.reflexEdit;
        let orig_reflex = this.props.reflex;
        let enabled = reflex.enabled;
        let orig_enabled = orig_reflex.enabled;
        let enstyle = { backgroundColor: orig_enabled ? 'transparent' : 'red', marginBottom: '5px' };
        let el_enabled = (<Checkbox checked={enabled} onChange={(e)=>this.setEnabled(e.target.checked)} />);
        el_enabled = (<FormControlLabel control={el_enabled} label={'Enabled'} />);
        el_enabled = (<span key='el_enabled' style={enstyle}>{el_enabled}</span>);
        return el_enabled;
    }

    onActionAdded() {
        let nex = this.props.nexus;
        let el = nex.platform().get_element_by_id('reflexEditorRightPanel');
        if (el) nex.platform().set_timeout(()=>{el.scrollTop = el.scrollHeight;}, 100);
    }

    renderTopIcon(key, icon, iconStyle, iconColor, callback, tooltip) {
        let nexus = this.props.nexus;
        let tips = nexus.platform().tooltips_shown();
        let st = { marginLeft:'8px', marginRight:'8px', display: 'inline-block', fontSize: '30px', cursor: 'pointer' };
        
        let res = (<span key={key} onClick={ callback }><FontAwesomeIcon icon={[iconStyle, icon]} color={iconColor} style={st} /></span>);
        if (tips && tooltip) res = (<Tooltip key={key} title={tooltip} PopperProps={{disablePortal:true}}>{res}</Tooltip>);
        return res;
    }

    render() {
        let reflex = this.props.reflexEdit;
        let nexus = this.props.nexus;

        let el_enabled = this.renderEnabled();
        let eltype = (<span style={{paddingLeft:'20px'}}>{'Reflex Type: ' + Util.ucfirst (reflex.type)}</span>);
        let opts = this.renderOptions();
        //opts = (<div>{this.formSection (null /*Util.ucfirst (reflex.type) + ' Options'*/, opts, reflex.type+'_opts')}</div>);

        let actions = null;
        if (this.allowsActions (reflex.type)) {
            let orig_reflex = this.props.reflex;
            let selpackage = this.props.selpackage;
            actions = (<ActionEditorList parent_el={this} reflex={reflex} orig_reflex={orig_reflex} nexus={nexus} selpackage={selpackage} onActionAdded={()=>this.onActionAdded()} />);
        }
        // save/restore buttons
        let btn_save = this.renderTopIcon('btn_save', 'floppy-disk', 'fas', '#00ff00', ()=>this.save(), 'Save Changes');
        let btn_revert = this.renderTopIcon('btn_revert', 'rotate', 'fas', '#ffff80', ()=>this.revert(), 'Revert Changes');
        let btn_duplicate = this.renderTopIcon('btn_duplicate', 'clone', 'fa', '#ffffff', ()=>this.duplicate(), 'Duplicate '+Util.ucfirst (reflex.type));
        let btn_delete = this.renderTopIcon('btn_delete', 'trash', 'fas', '#ff0000', ()=>this.deleteReflex(), 'Delete '+Util.ucfirst (reflex.type));
        let buttons = [ btn_save, btn_revert, btn_duplicate, btn_delete ];
        buttons = (<div style={{display:'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', marginBottom:'5px'}}>{buttons}</div>);
        if (this.props.extrasRef.current) buttons = createPortal(buttons, this.props.extrasRef.current);

        let toprow = (<div style={{width:'100%'}}>{el_enabled}{eltype}</div>);
        let inner = (<div style={{flexGrow:1}}><div key='opts'>{opts}</div><div key='actions'>{actions}</div></div>);
        return (<div style={{display:'flex', flexDirection:'column', height: '100%', width: '100%'}}>{toprow}{buttons}{inner}</div>);
    }

}



export class SettingsReflexes extends SettingsReflexes_Base {
    constructor(props) {
        super(props);
        this.newref = React.createRef();
        this.addref = React.createRef();
    }

    tree_data_image(type) {
        return (<img alt='' src={'/images/reflexes/' + type + '.png'} />);
    }

    generate_tree_data(el) {
        var res = {};
        res.key = el.id;
        res.element = el;
        res.title = Util.escape_html(this.reflex_visible_name(el));
//        res.activated = (el == selected_reflex);
        if (!el.enabled) { res.className = 'reflex_inactive'; res.style = { color: 'grey' } }

        if (el.type !== "group") {
            // this is an element
            res.isLeaf = true;
            res.title = '(' + el.type + ') ' + res.title;
            res.icon = this.tree_data_image (el.type);
            return res;
        }

        res.icon = this.tree_data_image ('group');

        res.children = [];
        for (var idx = 0; idx < el.items.length; ++idx) {
            res.children.push(this.generate_tree_data(el.items[idx]));
        }

        return res;
    }

    filterNode(node) {
        let filter = this.state.filter;
        if (!filter.length) return false;
        let el = node.element;
        if (!el) return false;
        return this.filterElement(el);
    }

    renderTree(rootEl) {
        let treedata = this.generate_tree_data(rootEl);
        treedata = [ treedata ];

        let tree = (<Tree
                key='reflexes_tree'
                checkable
                selectedKeys={this.state.selection}
                checkedKeys={this.state.checked}
                expandedKeys={this.state.expanded}
                treeData={treedata}
                onSelect={(keys,info)=>this.onSelect(keys)}
                onCheck={(keys,info)=>this.onCheck(keys)}
                onExpand={(keys,info)=>this.onExpand(keys)}
                draggable={true} 
                allowDrop={(i) => this.allowDrop(i)}
                onDrop={(i)=>this.onDrop(i)}
                filterTreeNode={(node)=>this.filterNode(node)}
        />);
        return tree;
    }

    renderReflex(reflex, reflex_edit) {
        let nex = this.props.nexus;

        let pkg = undefined;
        if (this.state.package >= 0)
        {
            pkg = this.current_root_reflex();
            pkg = pkg.name;
        }

        return (<ReflexEditor reflex={reflex} reflexEdit={reflex_edit} nexus={nex} extrasRef={this.props.extrasRef} parent_el={this} onRevert={()=>this.onRevert()} onDuplicate={()=>this.onDuplicate()} onDelete={()=>this.onDelete()} selpackage={pkg} />);
    }

    renderFilter() {
        return (<TextField key={'reflex_filter'} label='Filter' value={this.state.filter} onChange={(e)=>this.setState({filter:e.target.value})} />);
    }

    renderPackageSelector() {
        let reflexes = this.props.reflexes;
        let packages = reflexes.packages();
        let pkglist = packages.list();

        let opts = [];
        opts.push ((<MenuItem value={-1} key={'pkg-none'}>{'--- global reflexes ---'}</MenuItem>));
        for (let idx = 0; idx < pkglist.length; ++idx) {
            let pkg = pkglist[idx];
            opts.push((<MenuItem key={'pkg-'+idx} value={idx}>{pkg.name}</MenuItem>));
        }
        let label = 'Reflex Packages';
        let s = (<TextField value={this.state.package} label={label} SelectProps={{ MenuProps: { style: {zIndex:5500} } }} onChange={(e)=>this.onPackageChange(e.target.value)} select>{opts}</TextField>);

        return s;
    }

    renderNewReflex() {
        let popper = null;
        if (this.state.newmenu) {
            let rtypes = [ 'group', 'alias', 'keybind', 'trigger', 'event', 'function' ];
            let actlist = [];
            for (let idx = 0; idx < rtypes.length; ++idx) {
                let rtype = rtypes[idx];
                let txt = 'Add a new ' + rtype;
                actlist.push ((<ListItem button key={'newact-'+rtype} onClick={() => this.createNewReflex(rtype)}><ListItemText>{txt}</ListItemText></ListItem>));
            }
            popper = (<Popover style={{zIndex:5350}} open={this.state.newmenu} onClose={()=>this.setState({newmenu:false})} anchorEl={() => this.newref.current} key={'newmenu'} ><Paper><List dense disablePadding={true} key="items-buttons" aria-label='New Reflex'>{actlist}</List></Paper></Popover>);
        }
        let btn = (<Button className='greenButton lightGreen' style={{width:'100%'}} ref={this.newref} onClick={()=>this.btnNew()}>Add new...</Button>);
        let res = (<div style={{marginTop:'15px'}}>{btn}{popper}</div>);
        return res;
    }

    btnAdd() {
        let sel = !this.state.addmenu;
        this.setState({addmenu: sel});
    }
    
    renderAddToPackage() {
        let popper = null;
        
        if (this.state.addmenu) {
            let reflexes = this.props.reflexes;
            let packages = reflexes.packages();
            let pkglist = packages.list();
            let opts = [];
            if (this.state.package !== -1)
                opts.push ((<MenuItem value={-1} key={'pkg-none'} onClick={() => this.onAddToPackage(-1)}>{'--- global reflexes ---'}</MenuItem>));
            for (let idx = 0; idx < pkglist.length; ++idx) {
                if (this.state.package === idx) continue;   // don't list the current package
                let pkg = pkglist[idx];
                opts.push((<MenuItem key={'pkg-'+idx} value={idx} onClick={() => { this.onAddToPackage(idx); this.btnAdd(); } }>{pkg.name}</MenuItem>));
            }

            popper = (<Popover style={{zIndex:5350}} open={this.state.addmenu} onClose={()=>this.setState({addmenu:false})} anchorEl={() => this.addref.current} key={'addmenu'} ><Paper><List dense disablePadding={true} key="items-buttons" aria-label='Add to package'>{opts}</List></Paper></Popover>);
        }
        let btn_add = (<Button key='btn_add_to_package' ref={this.addref} onClick={ ()=>this.btnAdd() }>Add to package</Button>);
        let res = (<div style={{display:'inline'}}>{btn_add}{popper}</div>);
        return res;
        
    }

    renderPackageButtons() {
        if (this.state.checked.length === 0) return null;
        
        let btn_create = (<Button key='btn_export_selected' variant='contained' onClick={ ()=>this.onExportSelected() }>Export selected</Button>);
        let btn_add = this.renderAddToPackage();
        return (<div style={{align:'center'}}>{btn_create}{btn_add}</div>);
    }

    render() {
        let rootEl = this.current_root_reflex();

        let selector = this.renderPackageSelector();
        let newreflex = this.renderNewReflex();
        let filter = this.renderFilter();
        let tree = this.renderTree(rootEl);
        tree = (<div style={{overflow:'scroll', flex:1}}>{tree}</div>);
        let packageButtons = this.renderPackageButtons();

        let reflex = this.state.reflex;
        let reflex_edit = this.state.reflex_edit;  // the copy of the reflex that's being updated
        let content = reflex ? this.renderReflex(reflex, reflex_edit) : null;

        let leftStyle = { width: '300px', height: '100%', display: 'flex', flexDirection: 'column', marginRight: '20px' };
        let rightStyle = { flexGrow: 1, overflowY: 'auto' };
        let res = (<div style={{display:'flex', flexDirection:'row', flex: 1, height: '100%'}}><div style={leftStyle}>{selector}{newreflex}{filter}{tree}{packageButtons}</div><div id={'reflexEditorRightPanel'} style={rightStyle}>{content}</div></div>);
        return res;
    }
}
