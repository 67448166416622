
// This class handles communication with the server-side Nexus API. It manages accounts and character lists.

export class ServerAPI {
    constructor(nexus) {
        this.__nexus = nexus;
        this.__api = nexus._api;
        this.__email = null;
        this.__sess = null;
        this.__loggedin = false;
        this.__aid = null;
        this.__anonymous = false;
        this.__game = null;
        this.__name = null;
    }

    __login_check(reject) {
        if (!this.__loggedin) {
            reject('You need to log in first.');
            return false;
        }
        return true;
    }

    aid() {
        if (!this.__loggedin) return null;
        return this.__aid;
    }

    email() {
        return this.__email;
    }

    set_email(email) {
        this.__email = email;
    }

    add_account(email, pass) {
        const handler = (resolve, reject) => {
            let data = { 'email' : email, 'pass' : pass };
            let p = this.__request ('accountadd', data);
            p.then((res) => {
                resolve(res.aid);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }
    
    login(email, pass) {
        const handler = (resolve, reject) => {
            let data = { 'email' : email, 'pass' : pass };
            let p = this.__request ('login', data);
            p.then((res) => {
                this.__aid = res.aid;
                this.__email = res.email;
                this.__sess = res.session;  // this is stored on a cookie as well
                this.__loggedin = true;
                resolve(res.aid);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    anon_login(game, name, password) {
        const handler = (resolve, reject) => {
            let data = { 'game' : game, 'name' : name, 'pass' : password };
            let p = this.__request ('anonlogin', data);
            p.then((res) => {
                this.__aid = null;
                this.__game = game;
                this.__name = name;
                this.__anonymous = true;
                this.__sess = res.session;  // this is stored on a cookie as well
                this.__loggedin = true;
                resolve(res.aid);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    on_disconnect() {
        this.__anonymous = false;
        this.__game = null;
        this.__name = null;
    }

    logout() {
        this.__sess = null;
        this.__loggedin = false;
        this.__email = null;
        // this doesn't actually clear the login cookie, but that should be fine
    }

    change_account(curpass, email, pass) {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = { 'curpass' : curpass };
            if (pass) data['pass'] = pass;
            if (email) data['email'] = email;
            let p = this.__request ('accountchange', data);
            p.then((res) => {
                resolve(true);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    delete_account(curpass) {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = { 'curpass' : curpass };
            let p = this.__request ('accountdelete', data);
            p.then((res) => {
                this.logout();
                resolve(true);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    forgot_password(email) {
        const handler = (resolve, reject) => {
            let data = { 'email' : email };
            let p = this.__request ('forgotpassword', data);
            p.then((res) => {
                resolve(true);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }


    // character list

    chars() {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = {};
            let p = this.__request ('characters', data);
            p.then((res) => {
                resolve(res);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }
    
    char_exists(name, gameID) {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = { 'name' : name, 'game' : gameID };
            let p = this.__request ('charexists', data);
            p.then((res) => {
                if (!parseInt(res.exists)) resolve(false);
                resolve(res);    // cid, name, password, game
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    char_add(name, gameID, pass, no_exist_check=false) {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = { 'name' : name, 'pass' : pass, 'game' : gameID };
            if (no_exist_check) data['no_exist_check'] = 1;
            let p = this.__request ('charadd', data);
            p.then((res) => {
                resolve(true);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }
    
    char_remove(cid) {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = { 'cid' : cid };
            let p = this.__request ('charremove', data);
            p.then((res) => {
                resolve(true);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }
    
    char_change(cid, name, gameID, pass) {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = { 'cid' : cid, 'name' : name, 'pass' : pass, 'game' : gameID };
            let p = this.__request ('charchange', data);
            p.then((res) => {
                resolve(true);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    
    // game list

    games() {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = {};
            let p = this.__request ('games', data);
            p.then((res) => {
                resolve(res);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    game_exists(name, server, port) {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = { 'name' : name, 'server' : server, 'port' : port };
            let p = this.__request ('gameexists', data);
            p.then((res) => {
                if (!parseInt(res.exists)) resolve(false);
                resolve(res);    // cid, name, password, game
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    game_add(name, server, port) {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = { 'name' : name, 'server' : server, 'port' : port };
            let p = this.__request ('gameadd', data);
            p.then((res) => {
                resolve(true);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    game_remove(gid) {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = { 'gid' : gid };
            let p = this.__request ('gameremove', data);
            p.then((res) => {
                resolve(true);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    game_change(gid, name, server, port) {
        const handler = (resolve, reject) => {
            if (!this.__login_check(reject)) return;
            let data = { 'gid' : gid, 'name' : name, 'server' : server, 'port' : port };
            let p = this.__request ('gamechange', data);
            p.then((res) => {
                resolve(true);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }


    // Requests related to settings loading and saving

    save_system(name, gameID, sys, password=null) {
        const handler = (resolve, reject) => {
            if ((!password) && (!this.__anonymous) && (!this.__login_check(reject))) return;
            let data = { 'name' : name, 'game' : gameID, 'savefile' : sys };
            if (password !== null) data['password'] = password;
            let p = this.__request ('savesput', data);
            p.then((res) => {
                resolve(true);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    load_system(name, gameID, age=null, password=null) {
        const handler = (resolve, reject) => {
            if ((!password) && (!this.__anonymous) && (!this.__login_check(reject))) return;
            let data = { 'name' : name, 'game' : gameID };
            if (age !== null) data['age'] = age;
            if (password !== null) data['password'] = password;
            let p = this.__request ('savesget', data);
            p.then((res) => {
                resolve(res.data);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }

    // available ages
    system_available(name, gameID, password=null) {
        const handler = (resolve, reject) => {
            if ((!password) && (!this.__anonymous) && (!this.__login_check(reject))) return;
            let data = { 'name' : name, 'game' : gameID };
            if (password !== null) data['password'] = password;
            let p = this.__request ('saveslist', data);
            p.then((res) => {
                resolve(res.list);
            }).catch((err) => { reject(err); })
        }
        return new Promise (handler);
    }
    
    
    __request(type, params) {
        
        const reqHandler = (resolve, reject) => {
            let fetchpars = {};
            fetchpars['method'] = 'POST';
            fetchpars['mode'] = 'cors';
            fetchpars['credentials'] = 'same-origin';  // this enables cookies
            let formData = new FormData();
            for (const pname in params)
                formData.append (pname.toString(), params[pname].toString());
            if (this.__sess) formData.append ('auth', this.__sess);
            
            fetchpars['body'] = formData;

            fetch(this.__api + '?act=' + type, fetchpars).then((result) => {
                if (!result.ok) {
                    let error = result.statusText;
                    if (!error) error = 'Something went wrong with the API, please report this or try later.';
                    reject(error);
                    return;
                }
                result.json().then((result) => {
                    if (result.error) {
                        if ((result.error === 'not_logged_in') && this.__loggedin) {
                            // We are logged in, but the API thinks that we are not ...
                            reject ('The login status is out of sync - this is a bug, please report it.');
                            return;
                        }
                        if (result.desc) reject(result.desc);
                        else reject (result.error);
                        return;
                    }

                    // we got our data
                    resolve(result);
                }).catch((error) => {
                    if (!error) error = 'Something went wrong with the JSON, please report this or try later.';
                    reject(error);
                });
            }).catch((error) => {
                if (!error) error = 'An unknown network error happened, please report this or try later.';
                reject(error);
            });
        };
        return new Promise (reqHandler);
    }
}

