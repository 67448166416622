
export class ClientSettings {
    constructor(gameinfo) {
        this._gameinfo = gameinfo;
        this.reset();
        this.init_setting_list();
    }

    gameinfo() {
        return this._gameinfo;
    }

    set_gameinfo(gi) {
        this._gameinfo = gi;
        this.init_setting_list();
    }

    init_setting_list() {
        
        this.settings_keys = [ 
            'reverted', 
            'custom_colors', 
            'custom_colors_reverted', 
            'play_music', 
            'echo_input', 
            'echo_target', 
            'clear_input', 
            'extra_break',
            'show_timestamps', 
            'show_scroll_timestamps', 
            'show_item_vnums', 
            'gag_comm', 
            'popups_help', 
            'show_last_prompt',
            'show_prompts', 
            'show_rdescs',
            'show_gauges',
            'show_exact_gauges',
            'show_buttons',
            'show_balances',
            'show_avatar',
            'tooltips_enabled', 
            'reveal_map',
            'copy_on_mouseup', 
            'echo_gmcp', 
            'echo_triggers', 
            'allow_button_keys', 
            'notifications_enabled',
            'color_inputecho', 
            'color_targetecho', 
            'color_gmcpecho', 
            'color_trigecho', 
            'font_stack', 
            'font_size', 
            'stack_delimiter', 
            'movement_compass',
            'movement_compass_web',
            'compass_left',
            'css_style',
            'scrollback_msg_limit',
            'scrollback_height'
        ];

        if (this._gameinfo && this._gameinfo.game_settings_keys) {
            var gkeys = this._gameinfo.game_settings_keys();
            for (var idx = 0; idx < gkeys.length; ++idx)
                this.settings_keys.push (gkeys[idx]);
        }
    }

    reset() {
        let ours = this._gameinfo ? this._gameinfo.is_ire_game() : false;
        let thirdparty_gmcp = false;
        if (this._gameinfo && this._gameinfo.using_gmcp && this._gameinfo.using_gmcp()) thirdparty_gmcp = true;
        this.reverted = false;
        this.custom_colors = [];
        this.custom_colors_reverted = [];
        this.play_music = false;
        this.echo_input = false;
        this.echo_target = false;
        this.clear_input = false;
        this.extra_break = true;
        this.show_timestamps = false;
        this.show_scroll_timestamps = false;
        this.show_item_vnums = false;
        this.gag_comm = false;
        this.popups_help = true;
        this.show_last_prompt = ours ? false : true;
        this.show_prompts = ours ? false : true;
        this.show_rdescs = true;
        this.show_gauges = ours || thirdparty_gmcp;
        this.show_exact_gauges = false;
        this.show_buttons = true;
        this.show_balances = ours;
        this.show_avatar = ours;
        this.tooltips_enabled = true;
        this.reveal_map = false;
        this.copy_on_mouseup = false;
        this.echo_gmcp = false;
        this.echo_triggers = false;
        this.allow_button_keys = true;
        this.notifications_enabled = false;
        this.movement_compass = ours || thirdparty_gmcp;
        this.movement_compass_web = false;
        this.compass_left = false;

        this.color_inputecho = '#00aaaa';
        this.color_targetecho = '#0000ff';
        this.color_gmcpecho = '#777';
        this.color_trigecho = '#ff0000';

        this.font_stack = 'Nunito Sans';
        this.font_size = '13px';
        this.stack_delimiter = '|';
        this.css_style = 'standard';

        this.scrollback_msg_limit = 1000;
        this.scrollback_height = 25;

        // these are not saved with settings
        this.autosave = true;
        this.debug_log_raw = false;

        if (this._gameinfo && this._gameinfo.game_settings_keys) {
            var gkeys = this._gameinfo.game_settings_keys();
            for (var idx = 0; idx < gkeys.length; ++idx)
                this[gkeys[idx]] = this._gameinfo.game_settings_default (gkeys[idx]);
        }

    }

    // used to load saved data
    apply (data) {
        this.reset();
        for (let idx = 0; idx < this.settings_keys.length; ++idx) {
            let key = this.settings_keys[idx];
            if (typeof data[key] != 'undefined')
                this[key] = data[key];
        }
    }

    // used when saving data - result will be JSON-encoded later
    encode() {
        var res = {};
        for (var idx = 0; idx < this.settings_keys.length; ++idx) {
            var key = this.settings_keys[idx];
            if (typeof this[key] != 'undefined')
                res[key] = this[key];
        }
        return res;
    }

    // various settings-related functions
    font_stacks() {
        return [
            {name: 'Nunito Sans', stack:"NunitoSans", mobilename: 'NunitoSans10pt-Regular'},
            {name: 'Arimo', stack: 'Arimo', mobilename: 'Arimo-Regular'},
            {name: 'Roboto', stack:"Roboto", mobilename: 'Roboto-Regular'},
            {name: 'Open Sans', stack:"OpenSans", mobilename: 'OpenSans-Regular'},
            {name: 'Playfair', stack:"Playfair", mobilename: 'Playfair144pt-Regular'},
            {name: 'EB Garamond', stack: "EBGaramond", mobilename: 'EBGaramond-Regular'},
            {name: 'Roboto Mono', stack: "'RobotoMono', monospace", mobilename: 'RobotoMono-Regular'},
            {name: 'Monospace (Traditional)', stack: "'LiberationMono', monospace", mobilename: 'Monospace'}
        ];
    }
    
    get_font_family(mobile=false) {
        var fstacks = this.font_stacks();
        for (var i in fstacks)
            if (fstacks[i].name === this.font_stack)
                return mobile ? fstacks[i].mobilename : fstacks[i].stack;
        return mobile ? fstacks[0].mobilename : fstacks[0].stack;
    }

    calc_fontsize() {
        let fs = this.font_size;
        if (fs.replace) fs = fs.replace('px','');
        return parseInt(fs) + 'px';
    }

    ansi_color_default(color) {
        if (this.reverted) {
            if (color === 0) return '#eeeeee';
            if (color === 1) return '#800000';
            if (color === 2) return '#00b300';
            if (color === 3) return '#808000';
            if (color === 4) return '#000070';
            if (color === 5) return '#800080';
            if (color === 6) return '#008080';
            if (color === 7) return '#303030';

            if (color === 8) return '#464646';
            if (color === 9) return '#700000';
            if (color === 10) return '#007000';
            if (color === 11) return '#707000';
            if (color === 12) return '#000070';
            if (color === 13) return '#700070';
            if (color === 14) return '#007070';
            if (color === 15) return '#333333';
        }

        if (color === 0) return '#000000';
        if (color === 1) return '#800000';
        if (color === 2) return '#00b300';
        if (color === 3) return '#808000';
        if (color === 4) return '#0000a0';
        if (color === 5) return '#800080';
        if (color === 6) return '#008080';
        if (color === 7) return '#aaaaaa';

        if (color === 8) return '#464646';
        if (color === 9) return '#ff0000';
        if (color === 10) return '#00ff00';
        if (color === 11) return '#ffff00';
        if (color === 12) return '#0000ff';
        if (color === 13) return '#ff00ff';
        if (color === 14) return '#00ffff';
        if (color === 15) return '#ffffff';

        return undefined;        
    }

    ansi_color_code(color)
    {
        if ((color < 0) || (color > 15)) return undefined;
        if (this.reverted && this.custom_colors_reverted[color]) return this.custom_colors_reverted[color];
        if ((!this.reverted) && this.custom_colors[color]) return this.custom_colors[color];

        return this.ansi_color_default(color);
    }

    set_ansi_color_code(color, reverted, code)
    {
        if ((color < 0) || (color > 15)) return;
        if (reverted) this.custom_colors_reverted[color] = code;
        else this.custom_colors[color] = code;
    }

    // color is from 0 to 15, or 16-255 for the 256-color support
    get_ansi_color(color, is_bg)
    {
        if (is_bg && (color === 0)) return '';
        if (color < 16) return this.ansi_color_code(color);

        // 256 color, values taken from http://www.mudpedia.org/mediawiki/index.php/Xterm_256_colors
        let c256 = ['00', '5f', '87', 'af', 'd7', 'ff'];
        if (this.reverted) c256 = ['ff', 'd7', 'af', '87', '5f', '00'];
        if ((color > 15) && (color < 232)) {
            color -= 16;
            let b = color % 6;
            color = Math.floor(color / 6);
            let g = color % 6;
            let r = Math.floor(color / 6);
            return '#' + c256[r] + c256[g] + c256[b];
        }
        if ((color >= 232) && (color <= 255)) {
            let c = color - 232;
            if (this.reverted) c = 255 - color;
            let g = Math.floor(c * 255 / 23).toString(16);
            if (g.length === 1) g = '0' + g;
            return '#' + g + g + g;
        }

        return '#aaaaaa';
    }

    get_ansi_color_name(id)
    {
        if (id === 0) return 'Black';
        if (id === 1) return 'Dark Red';
        if (id === 2) return 'Dark Green';
        if (id === 3) return 'Dark Yellow';
        if (id === 4) return 'Dark Blue';
        if (id === 5) return 'Dark Magenta';
        if (id === 6) return 'Dark Cyan';
        if (id === 7) return 'Gray';
        if (id === 8) return 'Dark Gray';
        if (id === 9) return 'Bright Red';
        if (id === 10) return 'Bright Green';
        if (id === 11) return 'Bright Yellow';
        if (id === 12) return 'Bright Blue';
        if (id === 13) return 'Bright Magenta';
        if (id === 14) return 'Bright Cyan';
        if (id === 15) return 'White';
        return '';
    }

    // this ensures that we don't needlessly use black backgrounds
    convert_bgcolor(bg) {
        if (bg === null) return null;
        if (bg === 'black') return null;
        if ((bg === '#000') || (bg === '#000000')) return null;
        return bg;
    }

    css_styles() {
        return [
            {name: 'Standard', style: "standard"},
            {name: 'Minimal', style: "minimal"},
            {name: 'Space', style: "space"}
        ];
    }


}

