
// button parameters are: text, commands, script, highlight, target_picker

import React from 'react'
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

export class Buttons extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
        this._clicktimer = null;
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.props.nexus.platform().buttonsComponent = this;
    }

    componentWillUnmount() {
        this.props.nexus.platform().buttonsComponent = null;
    }

    buttonMouseDown(button, i) {
        let timer = this.props.nexus.platform().set_timeout (() => {
            if (this.timer === timer) // only if the timer is still valid
                this.execute (i, true, false);
            // cleanup
            this.buttonMouseUp(button, i);
        }, 1000);
        this.timer = timer;
    }
    
    buttonMouseUp(button, i) {
        if (this.timer) this.props.nexus.platform().clear_timeout (this.timer);
        this.timer = null;
    }

    render() {
        let b = this.props.buttons;
        b.set_defaults (this.props.gmcp.Buttons);
        let vertical = this.props.vertical;

        let list = [];
        for (let i = 1; i <= b.count; ++i) {
            let button = b.get (i);
            // text, commands, script, target_picker, highlight
            let txt = b.text(i);
            let ismenu = (this.state.menuid && (this.state.menuid === i));
            // TODO: support long-clicks that force-open the menu
            let bst = {width: '100%', overflow: 'hidden'};
            if (button.highlight) { bst['backgroundColor'] = '#dddddd'; bst['color'] = '#000000'; }
            let divst = vertical ? {gridRow: i+'/'+(i+1)} : {gridColumn: i+'/'+(i+1)};
            divst['padding'] = '5px 3px 2px 3px';
            let btn = (<div key={'button'+i} style={divst}><Button style={bst} ref={ismenu ? this.ref : null} variant="outlined" size="small" onClick={() => { this.execute (i, false, false) }} onMouseDown={ () => this.buttonMouseDown(button, i) } onMouseUp={() => this.buttonMouseUp(button, i) } >{txt}</Button></div>);
            list.push (btn);
            if (ismenu) {
                let menuitems = [];
                for (let idx = 0; idx < this.state.targets.length; ++idx) {
                    let t = this.state.targets[idx];
                    menuitems.push ((<ListItem button key={t.id} onClick={() => this.menuClick (button, i, t.id)}><ListItemText>{t.text}</ListItemText></ListItem>));
                }

                let popper = (<Popper style={{zIndex:2}} open={true} anchorEl={() => this.ref.current} key='buttonmenu'><Paper><List dense disablePadding={true} key="items-buttons" aria-label="Button menu">{menuitems}</List></Paper></Popper>);
                list.push (popper);
            }
        }

        let mainst = vertical ? {gridTemplateRows: 'repeat('+b.count+', minmax(0, 1fr))'} : {gridTemplateColumns: 'repeat('+b.count+', minmax(0, 1fr))'};
        mainst['display'] = 'grid';
        return (<div key="buttons" id="buttons" style={mainst}>{list}</div>);
    }

    menuClick(b, id, tgid) {
        this.props.dh.set_current_target (tgid, true);
        this.setState ({ menuid : null });
        this.execute (b.id, false, true);
    }

    execute(id, show_menu, from_menu) {
        let t = this;
        let btns = this.props.buttons;
        let b = btns.get (id);

        // if the menu is already open, close it
        if (this.state.menuid && (this.state.menuid === id)) {
            this.setState ({ menuid : null });
            if (!from_menu) return;  // and if this wasn't a menu click, we're now done
        }
        
        if (!b) return;

        var need_menu = true;
        if (!b.target_picker) need_menu = false;
        var cmds = b.commands;
        if (cmds.indexOf('@tar') < 0) need_menu = false;
        if (this.props.dh.current_target_is_player()) need_menu = false;   // bypass mob validity checks for a player target

        // let's find the desired targets
        // mobs only for now, may want to add players later maybe?
        var targets = this.props.dh.get_item_list('room', 'm', 'x');

        let cur = this.props.dh.current_target();
        if (need_menu && (cur !== undefined)) {
            let curtg = parseInt(cur);
            if (isNaN(curtg)) {
                need_menu = false;
                show_menu = false;
            } else {
                cur = curtg;
                // check if our current target remains valid
                var tgvalid = false;
                for (var i = 0; i < targets.length; ++i)
                    if (targets[i].id === cur)
                        tgvalid = true;
                // but not if we want the menu
                if (show_menu) tgvalid = false;

                if (tgvalid) need_menu = false;
            }
        }

    // disable this, it causes players to attack loyals and such when only one is there ... revisit this once we add some "loyal" flag to GMCP
    //    if (need_menu && (targets.length == 1)) {
    //        // if there is only one target, use that
    //        dh.set_current_target(targets[0].id, true);
    //        need_menu = false;
    //    }
        if (need_menu && (!show_menu)) {
            // If we don't have a target and don't want a menu, we tell them to use TAB to pick
            if (targets.length)
                this.props.onnotice("You haven't selected any target. Please use the TAB key to do so.", 'red');
            else
                this.props.onnotice("There are no suitable targets here.", 'red');
            return;
        }

        if (targets.length === 0) need_menu = false;   // nothing to show in the menu

        if (!need_menu) {
            this.do_execute(b, id);
            return;
        }

        // We need to show the menu.
        this.setState ({menuid: id, targets: targets});
    }

    do_execute(b, id) {
        if (b === undefined) return;

        var cmds = b.commands;
        var script = b.script;

        if (cmds && cmds.length) {
            var clist = cmds.split("\n");
            for (var c = 0; c < clist.length; ++c) {
                var cmd = clist[c];
                if ((!cmd) || (!cmd.length)) continue;
                this.props.oncommand (cmd, false);
            }
        }

        if (script && script.length)
            this.props.onscript (script, 'Button Script ' + id);
    }

}

