
import { Tab } from './tab.js'

import React from 'react'

export class TabCustom extends Tab {
    constructor(props) {
        super(props);
    }

    id() {
        return this.props.tabname;
    }
    
    render() {
        return (<div dangerouslySetInnerHTML={{__html:this.props.contents}} />);
    }        
}
 
 
 
