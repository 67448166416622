
import React from 'react'

import { Menu, Item, Submenu, useContextMenu } from 'react-contexify';
import "react-contexify/dist/ReactContexify.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as FlexLayout from "flexlayout-react";
import '../../node_modules/flexlayout-react/style/dark.css'

import { Gauges } from './gauges'
import { Buttons } from './buttons'
import { Balances } from './balances'
import { InputLine } from './inputline'
import { Avatar } from './avatar'

// layouting components
export class LayoutHBox extends React.Component {
    renderChildren() {
        return this.props.children;
    }

    render() {
        let style = { display: 'flex', flexDirection: 'row', overflow: 'hidden' };
        if (this.props.style) {
            for (let sname in this.props.style)
                style[sname] = this.props.style[sname];
        }
        let className = '';
        if (this.props.className) className = this.props.className;
        return (<div className={className} style={style}>{this.renderChildren()}</div>);
    }
}

export class LayoutVBox extends React.Component {
    render() {
        let style = { display: 'flex', flexDirection: 'column', overflow: 'hidden' };
        let position = this.props.position;
        if (position) {
            if (position === 'left') {
                style['width'] = '20%';
                style['minWidth'] = '20%';
                style['flexShrink'] = '0';
            }
            if (position === 'right') {
                style['width'] = '20%';
                style['minWidth'] = '20%';
                style['flexShrink'] = '0';
            }
            if (position === 'center') style['flexGrow'] = '1';
        }
        if (this.props.style) {
            for (let sname in this.props.style)
                style[sname] = this.props.style[sname];
        }
        let className = '';
        if (this.props.className) className = this.props.className;
        return (<div className={className} style={style}>{this.props.children}</div>);
    }
}

export class GamePanel extends LayoutHBox {
    constructor(props) {
        super(props);
        let t = this;
        this.state = { };
        this.clickHandler = ((e,id) => {
            e.event.preventDefault();
            if (id === 'copy') id = 'copy ' + t.selectedText;
            this.props.onMenuClick(id)
        });
        this.layoutRef = React.createRef();
        this.menuID = 'main-menu';
        this.menu = useContextMenu({ id: this.menuID });
    }

    renderFlexItem(node) {
        return this.props.manager.get_tab_object (node.getComponent(), this.props.gmcp);
    }

    renderFlexIcon(node) {
        let layout = this.props.manager;
        let tabid = node.getComponent();
        let icon = node.getIcon();
        if (!icon) icon = 'file';   // avoid crashes if this is wrong
        let buffer = layout.buffer_for_tab (tabid);
        let sett = this.props.nexus.settings();
        let color = sett.reverted ? 'black' : 'white';
        let color2;
        if (!sett.reverted) {
            let c1 = layout.get_tab_icon_color (tabid);
            if (c1 && c1.length) color = c1;
            color2 = layout.get_tab_icon_color_2 (tabid);
        }

        if (buffer && buffer.unread) {
            
            let nodeset = node.getParent();
            let visible = node.isVisible();
            if (visible) buffer.unread = false;   // if this is the currently shown tab, flag it as read
            else {
                icon = 'message-exclamation';  // otherwise show the unread icon there
                color = '#ffffff';
                color2 = '#2cc968';
            }
        }
        let st = {};
        if (color2) {
            st['color'] = color;
            st['--fa-primary-color'] = color;
            st['--fa-secondary-color'] = color2;
            st['--fa-secondary-opacity'] = 1.0;
        }
        let fsize = parseInt(sett.calc_fontsize());
        let size = Math.floor(18 * fsize / 13);
        st['fontSize'] = size + 'px';
        let iconel = (<span><FontAwesomeIcon icon={['fad', icon]} size={size} color={color} style={st} /></span>);
        return iconel;
    }

    onAction(action) {
        if (action.type == 'FlexLayout_SelectTab') {
            let id = action.data.tabNode;
        }
        return action;
    }

    renderFlexLayout() {
        this.props.manager.flexLayout = this;
        try {
            this.model = FlexLayout.Model.fromJson(this.props.model);
        } catch (e) {
            this.props.manager.use_default_tabs();
            this.props.nexus.platform().alert('Your layout did not render correctly, resetting to the default one.');
            return '';
        }

        let sett = this.props.nexus.settings();
        let fsize = parseInt(sett.calc_fontsize());
        let fontSize = Math.floor(18 * fsize / 13);
        return (<FlexLayout.Layout model={this.model} ref={this.layoutRef} factory={(node) => this.renderFlexItem(node)} iconFactory={(node) => this.renderFlexIcon(node)} onModelChange={(model)=>this.onModelChange(model)} onAction={(action)=>this.onAction(action)} font={{size: fontSize+'px'}}/>);
    }

    addTab(tab, locId) {
        if (!this.layoutRef.current) return;
        try {
            // Does this location currently exist?
            let added = this.props.manager.restore_loc_in_model(locId, tab);
            if (!added) this.layoutRef.current.addTabToTabSet(locId, tab);
        } catch (e) {};
    }
    
    removeTab(tabId) {
        if (!this.model) return;
        this.model.doAction(FlexLayout.Actions.deleteTab(tabId));
    }
    
    moveTab(tabId, locId) {
        if (!this.model) return;
        this.model.doAction(FlexLayout.Actions.moveNode(tabId, locId, null, -1));
    }

    onModelChange(model) {
        this.props.onModelChange(model.toJson());
        return model;
    }

    renderItem(caption, clickid) {
        return (<Item key={'menuitem-'+clickid} closeOnClick={true} onClick={(e)=>this.clickHandler(e,clickid)}>{caption}</Item>);
    }

    renderMainMenu() {
        let t = this;
        let layout = this.props.manager;
        let items = [];

        items.push (this.renderItem('Copy selection', 'copy'));
        items.push (this.renderItem('Toggle fullscreen', 'fullscreen'));

        let tabitems = [];
        let locs = layout.tab_locations();
        for (let idx = 0; idx < locs.length; ++idx) {
            let loc = locs[idx];
            let tabs = layout.default_tabs_at_location(loc);
            for (let idx2 = 0; idx2 < tabs.length; ++idx2) {
                let tab = tabs[idx2];
                if (tab === 'output_main') continue;
                let caption = layout.get_tab_caption(tab);
                if (layout.tab_is_active (tab))
                    tabitems.push (this.renderItem('Disable ' + caption, 'close_tab '+tab));
                else
                    tabitems.push (this.renderItem('Enable ' + caption, 'open_tab '+tab));
            }
        }

        let menu_tabs = (<Submenu key={'menuitem_subtabs'} hoverDelay={1} label='Tabs ...'>{tabitems}</Submenu>);
        items.push (menu_tabs);

        let chatitems = [];
        let lst = this.props.channels;
        for (let i = 0; i < lst.length; ++i) {
            let name = lst[i].name;
            let caption = lst[i].caption;
            if (layout.channel_active (name))
                chatitems.push (this.renderItem('Close ' + caption, 'close_channel '+name));
            else
                chatitems.push (this.renderItem('Open ' + caption, 'open_channel '+name+' '+caption+' '+lst[i].command));
        }
        let menu_channels = (<Submenu key={'menuitem_subchannels'} label='Channels ...'>{chatitems}</Submenu>);
        items.push (menu_channels);

        let menu = (<Menu id={this.menuID} onVisibilityChange={(shown) => t.onMenuShown(shown)}>{items}</Menu>);
        return menu;
    }

    renderMenus() {
        let res = [];
        res.push(this.renderMainMenu());
        return res;
    }

    renderChildren() {
        return this.renderFlexLayout();
    }

    handleContextMenu(e) {
        if (e.target.nodeName.toLowerCase() == 'textarea') return;  // do not trigger on the input line
        this.menu.show({event:e});
    }

    onMenuShown(shown) {
        if (!shown) return;
        let t = this;
        // This remembers the selection at the time when the menu is open. Needed for "Copy selection" to work - without this it gets cleared prior to the handler firing.
        let platform = this.props.nexus.platform();
        t.selectedText = platform.get_selection();
    }

    render() {
        let res = super.render();
        let menus = this.renderMenus();
        res = (<div onContextMenu={(e)=>this.handleContextMenu(e)}>{res}{menus}</div>);
        return res;
    }

}


export class LayoutBottom extends React.Component {
    render() {
        let sett = this.props.settings;
        let bals = this.props.balances;
        let fontSize = parseInt(sett.calc_fontsize());
        let avatar_size = Math.floor(72 * fontSize / 13);
        let col1 = avatar_size + 'px';
        let col2 = (bals.length*25*fontSize/13)+'px'
        let col3 = 'auto';

        // Hide some columns depending on what is shown.
        if (!sett.show_avatar) col1 = '0px';
        if (!sett.show_balances) col2 = '0px';
        
        let style = {flex:0, gridTemplateColumns:col1 + ' ' + col2 + ' ' + col3};
        if (this.props.style) {
            for (let sname in this.props.style)
                style[sname] = this.props.style[sname];
        }
        let nex = this.props.nexus;

        let gi = nex.gameinfo();
        let gauges = sett.show_gauges ? (<Gauges vitals={this.props.gmcp.Vitals} gauges={this.props.gauges} settings={sett} variables={nex.variables()} exact={sett.show_exact_gauges} tooltips={this.props.tooltips} alter_gauge_var={this.props.alter_gauge_var} gameinfo={gi} />) : null;
        let buttons = sett.show_buttons ? (<Buttons gmcp={this.props.gmcp} buttons={this.props.buttons} nexus={nex} dh={this.props.dh} oncommand={this.props.oncommand} onscript={this.props.onscript} onnotice={this.props.onnotice} />) : null;
        let balances = sett.show_balances ? (<Balances vitals={this.props.gmcp.Vitals} balances={bals} tooltips={this.props.tooltips} settings={sett} />) : null;
        let avatar = sett.show_avatar ? (<Avatar gmcp={this.props.gmcp} settings={sett} gameinfo={gi} dh={this.props.dh} platform={nex.platform()} size={avatar_size} />) : null;
        let inputline = (<InputLine settings={this.props.settings} nexus={nex} onsubmit={this.props.oncommand} on_feature={(feat) => this.props.on_feature(feat)} singleline={this.props.singleline} manager={this.props.manager} lbuffer={this.props.lbuffer} />);
        let cells = [];
        let balances_row = '1';
        let buttons_col = '2 / 4'
        if (sett.show_balances && (!sett.show_gauges)) {
            balances_row = 2;
            buttons_col = '3';
        }
        if (balances) cells.push ((<div key='gridbal' style={{gridRow:balances_row, gridColumn:'2'}}>{balances}</div>));
        if (gauges) cells.push ((<div key='gridgauges' style={{gridRow:'1', gridColumn:'3'}}>{gauges}</div>));
        if(avatar) cells.push ((<div key='gridavatar' style={{gridRow:'1 / 3', gridColumn:'1'}}>{avatar}</div>));
        if (buttons) cells.push ((<div key='gridbutton' style={{gridRow:'2', gridColumn:buttons_col}}>{buttons}</div>));
        cells.push ((<div key='gridinput' style={{gridRow:'3', gridColumn:'1 / 4', display: 'block', marginBottom: '3px'}}>{inputline}</div>));
        return (<div className='bottom_view' style={style}>{cells}</div>);
    }
}

export class LayoutCenter extends React.Component {
    render() {
        let style = { display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: 1, height: '100%' };
        if (this.props.style) {
            for (let sname in this.props.style)
                style[sname] = this.props.style[sname];
        }
        let className = '';
        if (this.props.className) className = this.props.className;
        let main = (<div style={{flex:1, height:'50%'}}>{this.props.main}</div>);
        return (<div className={className} style={style}>{main}{this.props.bottom}</div>);
    }
}

export class LayoutMain extends React.Component {
    render() {
        // this has a height of 100%-520px, it will automatically grow to fill in the available space below
        let component = this.props.children;
        if (!component) return null;
        return (<div className='simpleTab' style={{flexGrow: 1, height: 'calc(100% - 520px)'}}>{component}</div>);
    }
}
