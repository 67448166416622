import React from 'react';

import { FormSection } from './settingcomps'


export class SettingsVariables_Base extends React.Component {
    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    varChange(name, val) {
        if (!name.length) return;
        if (val.length) {
            let nval = parseInt(val);  // store numbers
            if (!isNaN(nval)) val = nval;
        }
        this.props.onVarChange(name, val);
        // TODO scroll to it?
    }

    varRemove(name) {
        this.props.onVarRemove(name);
    }

    onFilter(filter) {
        this.setState({filter:filter});
    }
    

    render() {
        return null;
    }
}

 
 
