
// settings - the "general" and "advanced" pages
// keeping both here because they use very similar functionality

import React from 'react';

import { SettingsGeneral_Base, SettingsAdvanced_Base } from './general_base'

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';


export class SettingsGeneral extends SettingsGeneral_Base {
    renderSelectSetting(key, vals, cur, label, tooltip) {
        let opts = [];
        for (let i = 0; i < vals.length; i++) {
            opts.push ((<MenuItem key={'menu-'+i} value={vals[i].value}>{vals[i].text}</MenuItem>));
        };
        let tips = this.props.platform.tooltips_shown();
        let select = (<TextField key={key} value={cur} label={label} SelectProps={{ MenuProps: { style: {zIndex:5500} } }} onChange={(e)=>this.props.onChange (key, e.target.value)} select>{opts}</TextField>);
        // disablePortal fixes tooltips when the component is in a separate window
        if (tips && tooltip) select = (<Tooltip key={key} title={tooltip} PopperProps={{disablePortal:true}}>{select}</Tooltip>);
        return (<div key={key}>{select}</div>);
    }

    render() {
        let elements = [], outputs = [], ifaces = [];

        // elements
        elements.push (this.renderBoolSetting('tooltips_enabled', 'Tooltips', 'If checked, information about gauges and windows will be displayed when hovering over an element.'));
        elements.push (this.renderBoolSetting('movement_compass_web', 'Compass (web/desktop)', 'Enable this to display the clickable movement compass on the web/desktop version.'));
        elements.push (this.renderBoolSetting('movement_compass', 'Compass (mobile)', 'Enable this to display a movement compass on the mobile version.'));
        elements.push (this.renderBoolSetting('show_gauges', 'Gauges', 'If enabled, gauges for health and other attributes will be displayed below the main output.'));
        elements.push (this.renderBoolSetting('show_exact_gauges', 'Exact Gauges', 'If enabled, all gauges will show exact numbers instead of percentages.'));
        elements.push (this.renderBoolSetting('show_buttons', 'Buttons', 'If enabled, configurable buttons will be displayed below the main output.'));
        elements.push (this.renderBoolSetting('show_balances', 'Balances', 'If enabled, a small widget showing character balances will be displayed below the main output.'));
        elements.push (this.renderBoolSetting('show_avatar', 'Avatar', 'If enabled, the character avatar will be displayed below the main output.'));
        elements.push (this.renderBoolSetting('notifications_enabled', 'Notifications', 'If checked, you will receive desktop notifications when receiving communication and the client is not your active window.'));
        elements.push (this.renderBoolSetting('extra_break', 'Extra Padding', 'If checked, additional blank space will be added between all paragraphs of received text in the main output window.'));
        elements.push (this.renderBoolSetting('show_item_vnums', 'Item Numbers', 'If checked, unique item numbers will be displayed in room and inventory windows.'));
        elements.push (this.renderBoolSetting('show_rdescs', 'Room Descs', 'If checked, verbose room descriptions will be shown in the room window.'));
        elements.push (this.renderBoolSetting('popups_help', 'Help Popups', 'If checked, all help files will display in a separate popup window instead of the main output window.'));
        elements.push (this.renderBoolSetting('show_timestamps', 'Timestamps', 'If checked, each line received in the main output window will have a timestamp next to it.'));
        elements.push (this.renderBoolSetting('show_scroll_timestamps', 'Scrollback Timestamps', 'If checked, each line in the main output window scrollback will have a timestamp next to it.'));
        elements.push (this.renderBoolSetting('show_prompts', 'Prompt Lines', 'If checked, all command prompts (health, mana, etc) will be shown in the main output window.'));
        elements.push (this.renderBoolSetting('show_last_prompt', 'Last Prompt', 'If checked, the last received prompt line will be displayed in the main output window.'));
        elements.push (this.renderBoolSetting('reveal_map', 'Reveal Map', 'If checked, the full map will be revealed, not only the explored parts.'));
        
        elements = (<div key='settings_elements' style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))'}}>{elements}</div>);

        outputs.push (this.renderSettingWithColor('echo_input', 'Echo commands I enter to the output window', 'If checked, any command entered in the command line will also appear in the main output window.', 'color_inputecho', 'Choose color'));
        outputs.push (this.renderSettingWithColor('echo_target', 'Echo target change', 'If checked, when a player or mobile target changes, it will be displayed in the main output window.', 'color_targetecho', 'Choose color'));
        outputs.push (this.renderBoolSetting('gag_comm', 'Hide all chat messages in the main window if they are shown in another tab', 'If checked, all chat text will be hidden in the main output window if it is displayed in the communication window.'));
        let fonts = [ this.renderFontSetting(), this.renderFontSizeSetting() ];
        fonts = (<div key='fonts' style={{display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))'}}>{fonts}</div>);
        outputs.push (fonts);

        // interface
        ifaces.push (this.renderBoolSetting('clear_input', 'Clear input bar after sending', 'If checked, the command line will be set blank after entering a command.'));
        ifaces.push (this.renderBoolSetting('copy_on_mouseup', 'Automatically copy selected text', 'If checked, selecting a text in the output window will automatically copy it to the clipboard.'));
        ifaces.push (this.renderBoolSetting('play_music', 'Play background music', 'If checked, the client will play background music.'));
        ifaces.push (this.renderBoolSetting('compass_left', 'Compass on the left', 'If checked and the compass is enabled, it will be shown on the left side.'));

        // game-specific - only supporting boolean settings at this time
        let gamesettings = [];
        let gi = this.props.gameinfo;
        if (gi.game_settings_keys) {
            let gkeys = gi.game_settings_keys();
            if (gkeys && gkeys.length) {
                for (let i = 0; i < gkeys.length; ++i) {
                    let caption = gi.game_settings_caption(gkeys[i]);
                    if (!caption) continue;
                    let tooltip = gi.game_settings_tooltip(gkeys[i]);
                    gamesettings.push (this.renderBoolSetting(gkeys[i], caption, tooltip));
                }
            }
        }

        let groups = [];
        groups.push (this.formSection ('Display Elements', elements, 'section_elements'));
        groups.push (this.formSection ('Output', outputs, 'section_output'));
        groups.push (this.formSection ('Interface', ifaces, 'section_interface'));
        if (gamesettings.length)
            groups.push (this.formSection ('Game-specific', gamesettings, 'section_game'));
        
        return groups;
    }
}

export class SettingsAdvanced extends SettingsAdvanced_Base {
    render() {
        let sett = this.props.settings;
        let tips = this.props.platform.tooltips_shown();
        let advanced = [];

        let s = (<TextField key='stack_delimiter' label='Command Stack Delimiter' value={''+sett.stack_delimiter} onChange={(e)=>this.setDelimiter(e.target.value)} size='small' />);
        if (tips) s = (<Tooltip key='stack_delimiter' title='Used to separate multiple commands sent at one time' PopperProps={{disablePortal:true}}>{s}</Tooltip>);
        s = (<div key='stack_delimiter'>{s}</div>);
        advanced.push (s);
        
        advanced.push (this.renderBoolSetting('debug_log_raw', 'Log raw output to console', null));
        advanced.push (this.renderSettingWithColor('echo_gmcp', 'Echo all GMCP messages (for debugging)', 'If checked, GMCP output will be shown in the main output window. This is very spammy and should only be used for debugging purposes.', 'color_gmcpecho', 'Choose color'));
        advanced.push (this.renderSettingWithColor('echo_triggers', 'Echo trigger execution (for debugging)', 'If checked, trigger execution will be shown in the main output window. This is very spammy and should only be used for debugging purposes.', 'color_trigecho', 'Choose color'));
        return this.formSection ('Advanced', advanced, 'section_advanced');

    }    
}

