
import React from 'react';

import { SettingsGeneral, SettingsAdvanced } from '../settings/general'
import { SettingsColors } from '../settings/colors'
import { SettingsButtons } from '../settings/buttons'
import { SettingsHelp } from '../settings/help'
import { SettingsVariables } from '../settings/variables'
import { SettingsReflexes } from '../settings/reflexes'
import { SettingsPackages } from '../settings/packages'
import { SettingsTabs } from '../settings/tabs'
import { SettingsGauges } from '../settings/gauges'
import { SettingsImport } from '../settings/import'
import { SettingsFeedback } from '../settings/feedback'

export class SettingsBaseDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { gameinfo: this.props.gameinfo, open: this.props.open, opentab: -1 };
        this.tabs = ['general', 'colors', 'buttons', 'packages', 'reflexes', 'variables', 'tabs', 'gauges', 'import', 'advanced', 'feedback', 'help'];
    }

    componentDidMount() {
        let nex = this.props.nexus;
        nex.settings_dlg = this;
    }
    
    componentWillUnmount() {
        let nex = this.props.nexus;
        nex.settings_dlg = null;
    }

    onBack() {
        if (this.state.optntab < 0) {
            this.props.onCancel();
            return;
        }
        this.setState({ opentab: -1 });
    }

    tabName(id) {
        if (id === 'general') return 'Display Options';
        if (id === 'colors') return 'Colors';
        if (id === 'buttons') return 'Buttons';
        if (id === 'packages') return 'Reflex Packages';
        if (id === 'reflexes') return 'Reflexes';
        if (id === 'variables') return 'Variables';
        if (id === 'import') return 'Import / Export';
        if (id === 'advanced') return 'Advanced';
        if (id === 'feedback') return 'Feedback';
        if (id === 'tabs') return 'Custom Tabs';
        if (id === 'gauges') return 'Gauges';
        if (id === 'help') return 'Help';
        return id;
    }

    handleTabChange(val) {
        this.setState({opentab:val});
    }

    changeSetting(setting, value) {
        let nex = this.props.nexus;
        let sett = nex.settings();
        sett[setting] = value;
        nex.apply_settings();  // this will also update our tabs
    }

    changeColor(num, value) {
        let nex = this.props.nexus;
        let sett = nex.settings();
        sett.set_ansi_color_code(num, sett.reverted, value);
        nex.apply_settings();  // this will also update our tabs
    }

    changeVariable(v, value) {
        let nex = this.props.nexus;
        nex.variables().set(v, value);
    }

    removeVariable(v) {
        let nex = this.props.nexus;
        nex.variables().del(v);
    }

    onSave() {
        let nex = this.props.nexus;
        nex.save_system();
    }

    renderTab(tabid, extrasRef) {
        let nex = this.props.nexus;
        let platform = nex.platform();
        if (tabid === 'general') return (<SettingsGeneral key='settings_general' onChange={(sett,val)=>this.changeSetting(sett,val)} platform={platform} gameinfo={nex.gameinfo()} settings={nex.settings()} />);
        if (tabid === 'advanced') return (<SettingsAdvanced key='settings_advanced' onChange={(sett,val)=>this.changeSetting(sett,val)} platform={platform} settings={nex.settings()} />);
        if (tabid === 'colors') return (<SettingsColors key='settings_colors' onChange={(sett,val)=>this.changeSetting(sett,val)} onColorChange={(num,val)=>this.changeColor(num,val)} platform={platform} settings={nex.settings()} />);
        if (tabid === 'buttons') return (<SettingsButtons key='settings_buttons' onChange={(sett,val)=>this.changeSetting(sett,val)} buttons={nex.ui().buttons()} platform={platform} settings={nex.settings()} />);
        if (tabid === 'packages') return (<SettingsPackages key='settings_packages' nexus={nex} platform={platform} settings={nex.settings()} packages={nex.packages()} />);
        if (tabid === 'reflexes') return (<SettingsReflexes key='settings_reflexes' nexus={nex} reflexes={nex.reflexes()} extrasRef={extrasRef} packages={nex.packages()} />);
        if (tabid === 'variables') return (<SettingsVariables key='settings_variables' vars={nex.variables()} onVarChange={(v,val)=>this.changeVariable(v,val)} onVarRemove={(v)=>this.removeVariable(v)} platform={platform} />);
        if (tabid === 'tabs') return (<SettingsTabs key='settings_tabs' nexus={nex} layout={nex.ui().layout()} platform={platform} />);
        if (tabid === 'gauges') return (<SettingsGauges key='settings_gauges' nexus={nex} gauges={nex.ui().gauges()} layout={nex.ui().layout()} platform={platform} />);
        if (tabid === 'import') return (<SettingsImport key='settings_import' platform={platform} nexus={nex} settings={nex.settings()} />);
        if (tabid === 'feedback') return (<SettingsFeedback key='settings_feedback' platform={platform} nexus={nex} />);
        if (tabid === 'help') return (<SettingsHelp key='settings_help' />);
        return null;  // shouldn't happen
    }
    
}

