
// TODO abstract away ValueWidget/ConditionWidget specifics
// TODO need to merge the settings stuff with this

import { ReflexTrigger } from './triggers.js'

// Used to execute a list of actions. This is a transient object, *not* a per-object action storage (see reflexes.js for that)
export class ActionList {

    constructor(nexus) {
        this._nexus = nexus;
        this.reflexes = nexus.reflexes();
    }

    static makeAction(type, nexus) {
        if (type === 'command') return new ActionCommand(nexus);
        if (type === 'notify') return new ActionNotify(nexus);
        if (type === 'notification') return new ActionDesktopNotification(nexus);
        if (type === 'sound') return new ActionSound(nexus);
        if (type === 'wait') return new ActionWait(nexus);
        if (type === 'function') return new ActionFunction(nexus);
        if (type === 'script') return new ActionScript(nexus);
        if (type === 'variable') return new ActionVariable(nexus);
        if (type === 'if') return new ActionIf(nexus);
        if (type === 'repeat') return new ActionRepeat(nexus);
        if (type === 'gag') return new ActionGag(nexus);
        if (type === 'highlight') return new ActionHighlight(nexus);
        if (type === 'rewrite') return new ActionRewrite(nexus);
        if (type === 'linkify') return new ActionLinkify(nexus);
        if (type === 'disableme') return new ActionDisableMe(nexus);
        if (type === 'disable') return new ActionDisable(nexus);
        if (type === 'enable') return new ActionEnable(nexus);
        if (type === 'label') return new ActionLabel(nexus);
        if (type === 'goto') return new ActionGoto(nexus);
        if (type === 'stop') return new ActionStop(nexus);
        if (type === 'waitfor') return new ActionWaitFor(nexus);
        if (type === 'button') return new ActionButton(nexus);
        return undefined;
    }

    // state is internal state, should be empty unless this is called from a wait-style action
    prepare(type, reflex, match, block, idx, text, state)
    {
        this.type = type;
        this.reflex = reflex;
        this.match = match;
        this.block = block;
        this.idx = idx;
        this.text = text;
        this.variables = this._nexus.variables();

        if (!state) state = {};   // internal state
        this.state = state;

        var list = [];
        if (reflex.actions) {
            for (var i = 0; i < reflex.actions.length; ++i)
                list.push(reflex.actions[i]);
        }
        this.actions = list;

        // this preserves local variables ... probably need some kind of an "execution context" variable instead of this
        if (state.variables && (!match.variables)) {
            match.variables = state.variables;
            delete state.variables;
        }

        var pkg = this.reflexes.determine_package_for_reflex(reflex);
        pkg = pkg ? pkg.name : undefined;
        match.current_package = pkg;
    }

    execute(startat)
    {
        if (!this.actions) return;
        if (!this._nexus.datahandler().is_connected()) return;   // this is called by a timer, and we may have disconnected since
        if (!startat) startat = 0;

        this._nexus.current_actions = this;

        var loops = 0;
        for (var i = startat; i < this.actions.length; ++i) {
            var a = this.actions[i];
            this.aid = i;

            try {
                this._nexus.current_action = a;
                var res = a.execute (this);
            } catch(err) {
                this._nexus.display_notice("Error while executing an action.", 'red');
                console.log(err);
            }
            this._nexus.current_action = undefined;

            // if the action requested that we stop, we stop
            if (!res) continue;
            if (res.abort) break;
            if (res.label) {
                var newlabel = this.locate_label(res.label);
                // a bit hacky, this changes the loop variable to the one before we want, so that the '++i' gives us the appropriate one
                if (newlabel >= 0) {
                    i = newlabel - 1;
                    loops++;
                }
                else this._nexus.display_notice("There is no label called '" + res.label + "'.", 'red');
            }
            if ((res.jump != null) && (res.jump >= 0) && (res.jump < this.actions.length))
            {
                i = res.jump - 1;
                loops++;
            }

            if (loops > 2000) {
                this._nexus.display_notice("Looped more than 2000 times -- potential endless loop, aborting.", 'red');
                break;
            }
        }
    }

    expand_text(text, match) {
        var res = match.do_replacements(text, false, this.variables);
        res = this.variables.expand(res);
        return res;
    }

    locate_label (label) {
        if (!this.actions) return -1;
        for (var i = 0; i < this.actions.length; ++i) {
            var a = this.actions[i];

            if (a.type() !== 'label') continue;
            // TODO: case insensitive maybe?
            if (a.label === label) return i;
        }
        return -1;
    }


}



// *** base class for the various actions ***

class Action {
    constructor(nexus) {
        this._enabled = true;
        this._nexus = nexus;
    }

    enabled() {
        return this._enabled;
    }

    setEnabled(en) {
        this._enabled = en;
    }

    encode() {
        var e = {};
        e.type = this.type();
        e.enabled = this._enabled;
        return e;
    }
    
    apply(e) {
        if (e.enabled !== undefined)
            this._enabled = e.enabled;
    }

    // execute the action, alist is an ActionList instance holding context data
    execute(alist) {
        // nothing here
    }

    matches(str) {
        return false;
    }

    // TODO - we may need to make a separate class or something, but for now this'll do

    // This function complements the ValueWidget in settings.
    get_value(typevar, namevar, match)
    {
        var type = this[typevar];
        var text = this[namevar];

        if (!type) type = 'value';
        if (type === 'value') return text;
        if (type === 'variable') {
            // local variable from the matching?
            // TODO: support matching variables, too!!!
            // TODO: do local variables properly maybe.
            if (text[0] === '@') text = text.substr(1);
            if (match.variables && match.variables[text])
                return match.variables[text];
            // Nope. Global variable then.
            return this._nexus.variables().get(text);
        }
        if (type === 'target') return this._nexus.datahandler().current_target();
        if (type === 'capture') {
            if ((!match) || (!match.backrefs)) return null;
            var idx = parseInt(text);
            if (isNaN(idx) || (idx < 0) || (idx >= match.backrefs.length)) return null;
            return match.backrefs[idx];
        }
        return null;
    }

    // This function complements the ConditionWidget in settings.
    check_condition(condname, match)
    {
        var value1 = this.get_value(condname + '-type1', condname + '-val1', match);
        var value2 = this.get_value(condname + '-type2', condname + '-val2', match);
        if (value1 == null) value1 = '';
        if (value2 == null) value2 = '';

        var op = this[condname + '-op'];
        var cs = this[condname + '-cs'];
        var mod = this[condname + '-mod'];  // 'not' to negate the result
        // If we want case insensitive, convert strings to lowercase.
        if (!cs) {  // not case-sensitive, convert strings to lowercase
            if (typeof value1 === 'string') value1 = value1.toLowerCase();
            if (typeof value2 === 'string') value2 = value2.toLowerCase();
        }
        // Convert numbers represented as strings into actual numbers.
        if (isFinite(value1)) value1 = parseFloat(value1);
        if (isFinite(value2)) value2 = parseFloat(value2);

        var res = false;
        if (op === 'eq')
            res = (value1 === value2);
        else if (op === 'greater')
            res = (value1 > value2);
        else if (op === 'smaller')
            res = (value1 < value2);
        else if ((op === 'starts') || (op === 'ends')) {
            value1 = value1.toString();
            value2 = value2.toString();
            if (value1.length < value2.length) res = false;
            else
            {
                if (op === 'starts') res = (value1.substr(0, value2.length) === value2);
                else res = (value1.substr(-1 * value2.length, value2.length) === value2);
            }
        }

        if (mod === 'not') res = !res;
        return res;
    }

    init_value(typevar) {
        this[typevar] = 'value';
    }

    encode_value(e, typevar, namevar) {
        e[typevar] = this[typevar];
        e[namevar] = this[namevar];
        return e;
    }
    
    apply_value(e, typevar, namevar) {
        this[typevar] = e[typevar];
        this[namevar] = e[namevar];
    }

    init_condition(condname) {
        this.init_value (condname + '-type1');
        this.init_value (condname + '-type2');
        this[condname + '-op'] = 'eq';
        this[condname + '-mod'] = 'is';
        this[condname + '-cs'] = false;
    }

    encode_condition(e, condname) {
        this.encode_value(e, condname + '-type1', condname + '-val1');
        this.encode_value(e, condname + '-type2', condname + '-val2');
        e[condname + '-op'] = this[condname + '-op'];
        e[condname + '-cs'] = this[condname + '-cs'];
        e[condname + '-mod'] = this[condname + '-mod'];
    }
    
    apply_condition(e, condname) {
        this.apply_value(e, condname + '-type1', condname + '-val1');
        this.apply_value(e, condname + '-type2', condname + '-val2');
        this[condname + '-op'] = e[condname + '-op'];
        this[condname + '-cs'] = e[condname + '-cs'];
        this[condname + '-mod'] = e[condname + '-mod'];
    }


}


// *** ACTION IMPLEMENTATIONS ***

class ActionCommand extends Action {
    constructor(nexus) {
        super(nexus);
        this.command = '';
        this.prefix_suffix = false;  // for aliases only
    }

    type() { return 'command'; }

    encode() {
        var e = super.encode();
        e.command = this.command;
        e.prefix_suffix = this.prefix_suffix;
        return e;
    }
    
    matches(str) {
        if (this.command && this.command.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.command = e.command;
        this.prefix_suffix = e.prefix_suffix;
    }

    execute(alist) {
        var cmd = alist.match.do_replacements (this.command, this.prefix_suffix, this._nexus.variables());
        if (cmd && cmd.length) this._nexus.send_commands(cmd);
        return {};
    }
}

class ActionNotify extends Action {
    constructor(nexus) {
        super(nexus);
        this.notice = '';
        this.notice_fg = '#ff0000';
        this.notice_bg = '#000000';
    }

    type() { return 'notify'; }

    encode() {
        var e = super.encode();
        e.notice = this.notice;
        e.notice_fg = this.notice_fg;
        e.notice_bg = this.notice_bg;
        return e;
    }
    
    matches(str) {
        if (this.notice && this.notice.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.notice = e.notice;
        this.notice_fg = e.notice_fg;
        this.notice_bg = e.notice_bg;
    }
    
    execute(alist) {
        var fg = this.notice_fg;
        var bg = this.notice_bg;
        var notice = alist.expand_text(this.notice, alist.match);
        this._nexus.display_notice(notice, fg, bg);
        return {};
    }
}

class ActionDesktopNotification extends Action {
    constructor(nexus) {
        super(nexus);
        this.heading = '';
        this.text = '';
    }

    type() { return 'notification'; }

    encode() {
        var e = super.encode();
        e.heading = this.heading;
        e.text = this.text;
        return e;
    }

    matches(str) {
        if (this.heading && this.heading.toLowerCase().indexOf(str) >= 0) return true;
        if (this.text && this.text.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }


    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.heading = e.heading;
        this.text = e.text;
    }
    
    execute(alist) {
        var heading = alist.expand_text(this.heading, alist.match);
        var text = alist.expand_text(this.text, alist.match);
        this._nexus.ui().notifications().notify(heading, text);
        return {};
    }
}

class ActionSound extends Action {
    constructor(nexus) {
        super(nexus);
        this.sound = '';
    }

    type() { return 'sound'; }

    encode() {
        var e = super.encode();
        e.sound = this.sound;
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.sound = e.sound;
    }
    
    execute(alist) {
        var sound = this.sound;
        if (!sound) return;
        this._nexus.ui().sounds().play_sound ('sfx/' + sound, false, false);
        return {};
    }    
}

class ActionWait extends Action {
    constructor(nexus) {
        super(nexus);
        this.seconds = 0;
        this.milliseconds = 0;
    }

    type() { return 'wait'; }

    encode() {
        var e = super.encode();
        e.seconds = this.seconds;
        e.milliseconds = this.milliseconds;
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.seconds = e.seconds;
        this.milliseconds = e.milliseconds;
    }
    
    execute(alist) {
        if (this.seconds === "") this.seconds = 0;
        if (this.milliseconds === "") this.milliseconds = 0;
        var time = parseInt(this.seconds) * 1000 + parseInt(this.milliseconds);
        if (time && (time > 0)) {
            // schedule the wait, and remove the block info - we can't alter texts after a delay
            alist.block = undefined;
            alist.text = undefined;
            alist.idx = undefined;
            this._nexus.platform().set_timeout(function() {
                alist.execute(alist.aid + 1);
            }, time);
            // and tell the caller to not continue with the actions for now
            return { 'abort' : true };
        }
        return {};
    }
}

class ActionFunction extends Action {
    constructor(nexus) {
        super(nexus);
        this.fn = '';
    }

    type() { return 'function'; }

    encode() {
        var e = super.encode();
        e.fn = this.fn;
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.fn = e.fn;
    }

    matches(str) {
        if (this.fn && this.fn.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    execute(alist) {
        var fn = this.fn;
        if (fn && fn.length)
            alist.reflexes.run_function(fn, alist.match, alist.match.current_package);
        return {};
    }
}

class ActionScript extends Action {
    constructor(nexus) {
        super(nexus);
        this.script = '//Enter the script here';
    }

    type() { return 'script'; }

    encode() {
        var e = super.encode();
        e.script = this.script;
        return e;
    }

    matches(str) {
        if (this.script && this.script.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.script = e.script;
    }
    
    execute(alist) {
        this._nexus.exec_script(this.script, alist.match.backrefs, alist.match.current_package, alist.type + ' Script  [' + alist.reflex.name + ']');
        return {};
    }
}

class ActionVariable extends Action {
    constructor(nexus) {
        super(nexus);

        this.varname = '';
        this.valtype = '';
        this.value = '';
        this.op = 'set';
        this.init_value ('valtype');
    }

    type() { return 'variable'; }

    encode() {
        var e = super.encode();
        e.varname = this.varname;
        this.encode_value (e, 'valtype', 'value');
        e.op = this.op;
        return e;
    }

    matches(str) {
        if (this.varname && this.varname.toLowerCase().indexOf(str) >= 0) return true;
        if (this.value && this.value.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.apply_value (e, 'valtype', 'value');
        this.varname = e.varname;
        this.op = e.op;
    }
    
    execute(alist) {
        var name = this.varname;
        var value = this.get_value('valtype', 'value', alist.match);
        var vars = this._nexus.variables();
        var op = this.op;
        if (op === 'add')
            vars.inc(name, value);
        else if (op === 'sub')
            vars.dec(name, value);
        else if (op === 'mul')
            vars.mul(name, value);
        else if (op === 'div')
            vars.div(name, value);
        else if (op === 'set')
            vars.set(name, value);
        else if (op === 'del')
            vars.del(name);
        return {};
    }
}

class ActionIf extends Action {
    constructor(nexus) {
        super(nexus);

        this.dothen = 'continue';
        this.dothenlabel = '';
        this.doelse = 'stop';
        this.doelselabel = '';
        this.init_condition ('cond');
    }

    type() { return 'if'; }

    encode() {
        var e = super.encode();
        this.encode_condition (e, 'cond');
        e.dothen = this.dothen;
        e.dothenlabel = this.dothenlabel;
        e.doelse = this.doelse;
        e.doelselabel = this.doelselabel;
        return e;
    }

    matches(str) {
        if (this.dothenlabel && this.dothenlabel.toLowerCase().indexOf(str) >= 0) return true;
        if (this.doelselabel && this.doelselabel.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.apply_condition (e, 'cond');
        this.dothen = e.dothen;
        this.dothenlabel = e.dothenlabel;
        this.doelse = e.doelse;
        this.doelselabel = e.doelselabel;
    }
    
    execute(alist) {
        var matches = this.check_condition('cond', alist.match);
        var action = matches ? this.dothen : this.doelse;
        var label = matches ? this.dothenlabel : this.doelselabel;
        var res = {};
        if (action === 'continue') {
            // nothing needs to be done
        }
        if (action === 'stop') {
            res.abort = true;
        }
        else if (action === 'jump') {
            res.label = label;
        }
        return res;
    }
}

class ActionRepeat extends Action {
    constructor(nexus) {
        super(nexus);
        this.label = '';
        this.mode = 'count';
        this.init_value ('counttype');
        this.init_condition ('cond');
    }

    type() { return 'repeat'; }

    encode() {
        var e = super.encode();
        this.encode_value (e, 'counttype', 'count');
        this.encode_condition (e, 'cond');
        e.label = this.label;
        e.mode = this.mode;
        return e;
    }

    matches(str) {
        if (this.label && this.label.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.apply_value (e, 'counttype', 'count');
        this.apply_condition (e, 'cond');
        this.label = e.label;
        this.mode = e.mode;
    }
    
    execute(alist) {
        // this stores the repeat count on the actions variable. We do not use a stack, so overlapping actions may lead to undefined behaviour (especially as we also have goto)

        var res = {};

        var label = this.label;
        var labelid = alist.locate_label (label);
        if (labelid < 0) {
            this._nexus.display_notice("There is no label called '" + label + "'.", 'red');
            return {};
        }
        if (labelid <= alist.aid) {
            this._nexus.display_notice("Label '" + label + "' positioned before the repeat action using it.", 'red');
            return {};
        }

        var mode = this.mode;
        if (mode === 'while') {
            var matches = this.check_condition('cond', alist.match);
            if (matches) {
                // match - we keep going and instruct the label to send control back to us
                alist.state['jump'+labelid] = alist.aid;
            } else {
                delete alist.state['jump'+labelid];
                res.jump = labelid;
            }
        } else {
            var counter = alist.state['repeats'+alist.aid];
            if (!counter) counter = parseInt(this.get_value('counttype', 'count', alist.match));
            if (isNaN(counter)) counter = 0;
            if (counter <= 0) {  // shouldn't repeat at all
                delete alist.state['repeats'+alist.aid];
                delete alist.state['jump'+labelid];
                res.jump = labelid;
            } else {
                counter--;
                alist.state['repeats'+alist.aid] = counter;
                if (counter)
                    alist.state['jump'+labelid] = alist.aid;
                else
                    delete alist.state['jump'+labelid];   // last iteration
            }
        }

        return res;
    }
}

class ActionGag extends Action {

    type() { return 'gag'; }

    encode() {
        var e = super.encode();
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
    }
    
    execute(alist) {
        if (alist.block)
            alist.block[alist.idx].gag = true;
        return {};
    }
}

class ActionHighlight extends Action {
    constructor(nexus) {
        super(nexus);

        this.highlight_fg = '#ffff00';
        this.highlight_bg = '#000000';
        this.highlight = 'match';
        this.highlight_backref = '';
    }

    type() { return 'highlight'; }

    encode() {
        var e = super.encode();
        e.highlight_fg = this.highlight_fg;
        e.highlight_bg = this.highlight_bg;
        e.highlight = this.highlight;
        e.highlight_backref = this.highlight_backref;
        return e;
    }

    matches(str) {
        if (this.highlight && this.highlight.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.highlight_fg = e.highlight_fg;
        this.highlight_bg = e.highlight_bg;
        this.highlight = e.highlight;
        this.highlight_backref = e.highlight_backref;
    }
    
    execute(alist) {
        if (!alist.block) return {};

        var fg = this.highlight_fg;
        var bg = this.highlight_bg;
        bg = this._nexus.settings().convert_bgcolor(bg);
        var type = this.highlight;
        var backref = this.highlight_backref;
        var pos = alist.match.get_position(type, backref);
        if (pos != null) alist.text.colorize(pos.start, pos.start + pos.length, fg, bg);
        return {};
    }
}

class ActionRewrite extends Action {
    constructor(nexus) {
        super(nexus);
        this.rewrite = '';
        this.rewrite_backref = '';
        this.rewrite_text_type = '';
        this.rewrite_text = '';
        this.rewrite_colors = '';
        this.rewrite_fg = '#ffff00';
        this.rewrite_bg = '#000000';
        this.init_value ('rewrite_text_type');

    }

    type() { return 'rewrite'; }

    encode() {
        var e = super.encode();
        this.encode_value (e, 'rewrite_text_type', 'rewrite_text');
        e.rewrite = this.rewrite;
        e.rewrite_backref = this.rewrite_backref;
        e.rewrite_colors = this.rewrite_colors;
        e.rewrite_fg = this.rewrite_fg;
        e.rewrite_bg = this.rewrite_bg;
        return e;
    }

    matches(str) {
        if (this.rewrite && this.rewrite.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.apply_value (e, 'rewrite_text_type', 'rewrite_text');
        this.rewrite = e.rewrite;
        this.rewrite_backref = e.rewrite_backref;
        this.rewrite_colors = e.rewrite_colors;
        this.rewrite_fg = e.rewrite_fg;
        this.rewrite_bg = e.rewrite_bg;
    }
    
    execute(alist) {
        if (!alist.block) return {};

        var txt = this.get_value('rewrite_text_type', 'rewrite_text', alist);
        var fg, bg;
        if (this.rewrite_colors) {
            fg = this.rewrite_fg;
            bg = this.rewrite_bg;
            bg = this._nexus.settings().convert_bgcolor(bg);
        }
        var type = this.rewrite;
        var backref = this.rewrite_backref;
        var pos = alist.match.get_position(type, backref);
        if ((pos != null) && (txt != null)) {
            alist.text.replace(pos.start, pos.start + pos.length, txt, fg, bg);
            // okay, now we also need to alter the matches
            alist.match.update_positions(pos, txt, type);
        }
        return {};
    }
}

class ActionLinkify extends Action {
    constructor(nexus) {
        super(nexus);

        this.linkify = '';
        this.linkify_backref = '';
        this.linkify_text_type = '';
        this.linkify_text = '';
        this.linkify_command_type = '';
        this.linkify_command = '';
        this.linkify_color = '#ffffff';
        this.init_value ('linkify_text_type');
        this.init_value ('linkify_command_type');
    }

    type() { return 'linkify'; }

    encode() {
        var e = super.encode();
        this.encode_value (e, 'linkify_text_type', 'linkify_text');
        this.encode_value (e, 'linkify_command_type', 'linkify_command');
        e.linkify = this.linkify;
        e.linkify_backref = this.linkify_backref;
        e.linkify_color = this.linkify_color;
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.apply_value (e, 'linkify_text_type', 'linkify_text');
        this.apply_value (e, 'linkify_command_type', 'linkify_command');
        this.linkify = e.linkify;
        this.linkify_backref = e.linkify_backref;
        this.linkify_color = e.linkify_color;
    }
    
    execute(alist) {
        if (!alist.block) return {};

        var text = this.get_value('linkify_text_type', 'linkify_text', alist);
        var command = this.get_value('linkify_command_type', 'linkify_command', alist);
        var color = this.linkify_color;
        var type = this.linkify;
        var backref = this.linkify_backref;
        var pos = alist.match.get_position(type, backref);

        alist.text.linkify(pos.start, pos.start + pos.length, color, command, text, this._nexus.settings().reverted);
        if (text || command) alist.match.update_positions(pos, text ? text : command, type);
        return {};
    }
}

class ActionDisableMe extends Action {

    type() { return 'disableme'; }

    encode() {
        var e = super.encode();
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
    }
   

    execute(alist) {
        alist.reflexes.disable_reflex(alist.reflex);
        return {};
    }
}

class ActionDisable extends Action {
    constructor(nexus) {
        super(nexus);
        this.rtype = '';
        this.name = '';
    }

    type() { return 'disable'; }

    encode() {
        var e = super.encode();
        e.name = this.name;
        e.rtype = this.rtype;
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.name = e.name;
        this.rtype = e.rtype;
    }

    execute(alist) {
        if (!this.rtype) return;
        let pkg = alist.match.current_package;
        let el = alist.reflexes.find_by_name(this.rtype, this.name, true, false, pkg);
        if (!el) {
            this._nexus.display_notice('Disable reflex: unable to find any ' + this.rtype + ' called "' + this.name + '" in ' + (pkg?pkg:'global reflexes'), 'red');
            return {};
        }
        alist.reflexes.disable_reflex(el);
        return {};
    }
}

class ActionEnable extends Action {

    type() { return 'enable'; }

    encode() {
        var e = super.encode();
        e.name = this.name;
        e.rtype = this.rtype;
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.name = e.name;
        this.rtype = e.rtype;
    }

    execute(alist) {
        let pkg = alist.match.current_package;
        let el = alist.reflexes.find_by_name(this.rtype, this.name, true, false, pkg);
        if (!el) {
            this._nexus.display_notice('Enable reflex: unable to find any ' + this.rtype + ' called "' + this.name + '" in ' + (pkg?pkg:'global reflexes'), 'red');
            return {};
        }
        alist.reflexes.enable_reflex(el);
        return {};
    }
}

class ActionLabel extends Action {

    type() { return 'label'; }

    encode() {
        var e = super.encode();
        e.label = this.label;
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.label = e.label;
    }

    matches(str) {
        if (this.label && this.label.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    execute(alist) {
        var res = {};
        // another action may have requested a jump when this label is reached (the 'repeat' one, most notably)
        var jumpto = alist.state['jump'+alist.aid];
        if (jumpto != null) res.jump = jumpto;
        delete alist.state['jump'+alist.aid];
        return res;
    }
}

class ActionGoto extends Action {
    constructor(nexus) {
        super(nexus);
        this.label = '';
    }

    type() { return 'goto'; }

    encode() {
        var e = super.encode();
        e.label = this.label;
        return e;
    }

    matches(str) {
        if (this.label && this.label.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.label = e.label;
    }

    execute(alist) {
        return { 'label' : this.label };
    }
}

class ActionStop extends Action {

    type() { return 'stop'; }

    encode() {
        var e = super.encode();
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
    }

    execute(alist) {
        return { 'abort' : true };
    }
}

class ActionWaitFor extends Action {
    constructor(nexus) {
        super(nexus);
        this.text = '';
        this.matching = 'substring';
        this.whole_words = false;
        this.case_sensitive = false;
        this.expire = 0;
    }

    type() { return 'waitfor'; }

    matches(str) {
        if (this.text && this.text.toLowerCase().indexOf(str) >= 0) return true;
        return false;
    }

    encode() {
        var e = super.encode();
        e.text = this.text;
        e.matching = this.matching;
        e.whole_words = this.whole_words;
        e.case_sensitive = this.case_sensitive;
        e.expire = this.expire;
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.text = e.text;
        this.matching = e.matching;
        this.whole_words = e.whole_words;
        this.case_sensitive = e.case_sensitive;
        this.expire = e.expire;
    }

    execute(alist) {
        var wa = new ReflexTrigger('waitfor', this._nexus);
        wa.text = this.text;
        wa.matching = this.matching;
        wa.whole_words = this.whole_words;
        wa.case_sensitive = this.case_sensitive;
        // How long should we keep looking?
        var expire = this.expire || 10;
        wa.expire = Date.now() + expire * 1000;
        wa.reflex = alist.reflex;
        wa.idx = alist.aid + 1;
        wa.state = alist.state;
        if (alist.match.variables) alist.state.variables = alist.match.variables;

        alist.reflexes.add_waiting_action (wa);
        return { 'abort' : true };
    }
}

class ActionButton extends Action {
    type() { return 'button'; }

    encode() {
        var e = super.encode();
        e.buttonid = this.buttonid;
        e.buttonaction = this.buttonaction;
        e.label = this.label;
        e.command = this.command;
        return e;
    }
    
    apply(e) {
        if (e['type'] !== this.type()) return;  // sanity check
        super.apply (e);
        this.buttonid = e.buttonid;
        this.buttonaction = e.buttonaction;
        this.label = e.label;
        this.command = e.command;
    }

    execute(alist) {
        var buttons = this._nexus.ui().buttons();
        var num = this.buttonid;
        var act = this.buttonaction;
        var b = buttons.get (num);
        if (b && (num >= 1) && (num <= buttons.count)) {
            if (act === 'label') {
                buttons.set (num, b.commands, b.script, this.label, b.target_picker);
            }
            else if (act === 'command') {
                buttons.set (num, this.command, b.script, b.text, b.target_picker);
            }
            else if (act === 'highlight') {
                if (!b.highlight) buttons.highlight_button (num, true);
            }
            else if (act === 'unhighlight') {
                if (b.highlight) buttons.highlight_button (num, false);
            }
            else if (act === 'default') {
                buttons.clear_button (num);
            }
        }
        return {};
    }
}
