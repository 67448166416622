
import { Tab } from './tab.js'

import React from 'react'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export class TabWho extends Tab {
    constructor(props) {
        super(props);
        this.state = { 'channel' : '' };
    }

    id() {
        return 'who';
    }
    
    render() {
        var wlist = this.props.gmcp.WhoList;
        var sel = this.state.channel;

        var players = [];
        let est = {marginRight:'3px'};
        let gst = {marginRight:'3px'};
        if (!this.props.sett.reverted) {
            est['color'] = '#8d9fbf';
            est['--fa-primary-color'] = '#8d9fbf';
            est['--fa-secondary-color'] = '#ffffff';
            est['--fa-secondary-opacity'] = 1.0;
            gst['color'] = '#ffffff';
            gst['--fa-primary-color'] = '#ffffff';
            gst['--fa-secondary-color'] = '#8d9fbf';
            gst['--fa-secondary-opacity'] = 1.0;
        }


        // build the list of channels, as well as the players listening to the currently selected one
        var chanlist = [];
        for (let i = 0; i < wlist.length; ++i)
        {
            var chans = wlist[i].channels;
            if (!chans) chans = [];
            let ours = false;
            if (!sel.length) ours = true;
            for (let j = 0; j < chans.length; ++j)
            {
                if (sel.length && sel === chans[j]) ours = true;
                if (chanlist.indexOf(chans[j]) === -1)
                    chanlist.push(chans[j])
            }
            let name = wlist[i].name;
            let keyname = 'player-'+name;
            let eicon = (<FontAwesomeIcon key={keyname+'-icon'} icon={['fad', 'person-simple']} style={est} />);
            if (ours) players.push (<ListItem button dense key={keyname} onClick={() => this.props.onclick(name) }>{eicon}<ListItemText primary={name} /></ListItem>);
        }

        chanlist.sort();

        var channels = [];
        let issel = (sel === '');
        let gicon = (<FontAwesomeIcon key={'all-icon'} icon={['fad', 'people-group']} style={gst} />);
        channels.push (<ListItem button dense selected={issel} key='channels-all' onClick={() => this.setChannel ("")} >{gicon}<ListItemText primary="All Players" /></ListItem>);
        for (let i = 0; i < chanlist.length; ++i) {
            let name = chanlist[i];
            let keyname = 'channel-'+name;
            issel = (name === sel);
            gicon = (<FontAwesomeIcon key={keyname+'-icon'} icon={['fad', 'people-group']} style={gst} />);
            channels.push (<ListItem button dense selected={issel} key={keyname} onClick={() => this.setChannel (name) }>{gicon}<ListItemText primary={name} /></ListItem>);
        }

        // finally, we compose the actual interface
        return (
            <Box style={this.props.style}>
                <List component="nav" aria-label="Channels">
                    {channels}
                </List>
                <List component="nav" aria-label="Player List">
                    {players}
                </List>
            </Box>
        );
    }        

    setChannel(ch) {
        this.setState ({'channel' : ch});
    }

}
 
 
