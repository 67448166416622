
// Feedback page

import React from 'react';

import { Util } from '../../base/util.js'

import { FormSection } from './settingcomps'

export class SettingsFeedback_Base extends React.Component {
    constructor(props) {
        super(props);
        this.state = { name: '', email: '', feedback: '', thank: false };
    }

    formSection(name, content, key=null) {
        return (<FormSection key={key} keyname={key} name={name} content={content} />);
    }

    feedbackError() {
        if (this.state.name.length < 3) return 'Please provide your name.';
        if (this.state.email === '') return 'Please provide your e-mail.';
        if (!Util.valid_email (this.state.email)) return 'Please enter a valid e-mail address.';
        if (this.state.feedback.length < 20) return 'Please provide your feedback.';
        return null;
    }
    
    sendFeedback()
    {
        let nex = this.props.nexus;
        let platform = this.props.platform;
        let err = this.feedbackError();
        if (err) {
            platform.alert ('Feedback', err);
            return;
        }

        let formData = new FormData();
        formData.append ('fb_game', nex.gameinfo().game_short());
        formData.append ('fb_character', nex.datahandler().GMCP.Character.name);
        formData.append ('fb_name', this.state.name);
        formData.append ('fb_email', this.state.email);
        formData.append ('fb_feedback', this.state.feedback);
        formData.append ('fb_verify', '6f78ss92wse');
        let req = {
            method: 'POST',
            body: formData
        };
        
        fetch(nex.gameinfo().script_url()+'send_feedback.php', req).then(response => { 
            this.setState({thank: true});
        });
    }

    render() {
        return null;
    }
}

 
 
 
