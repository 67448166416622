

// this generates HTML in a string, may want to switch that to DOM elements
export class TextFormatter {
    constructor(params, allow_mxp) {
        this.prefix = '';
        this.suffix = '';
        this.allow_mxp = allow_mxp;

        this._re1 = /</g;
        this._re2 = />/g;
        this._re3 = /&/g;
        this._urlRe = /((https?|ftp|file):\/\/[-A-Z0-9+&@#*'()/%?=~_|!:,.;]*[-A-Z0-9+&@#*'()/%=~_|])/igm;
        this._helpRe = /\bHELP\b ((?:[A-Z][A-Z\s]+|\d+(?:\.\d+)?)+)/g;
        this._nonprint = /[\x00-\x08\x0E-\x1F\x80-\xFF]/gm;
    }

    formatBase (chunk) {
        return '';
    }

    formatText (chunk) {
        let res = chunk.text();
        // Remove rogue non-printables
        res = res.replace(this._nonprint, "");
        // escape HTML chars
        if (!this.allow_mxp) res = res/*.replace(this._re3, "&amp;")*/.replace(this._re1, "&lt;").replace(this._re2, "&gt;");
        // doing this here means that links with a color change in the middle won't get expanded, which is fine
        // links / help
        res = res.replace(this._urlRe, function (match, $1, $2, offset, original) {
            return "<a href='" + $1.replace(/'/g, "%27") + "' class='url_link' target='_blank'>" + $1 + "</a>";
        });

        res = res.replace(this._helpRe, function (match, $1) {return "<a class=\"mxp_send\" href=\"#\" rel=\"HELP " + $1 + "\">HELP " + $1 +"</a>"})

        return res;
    }

    formatColor (chunk) {
        let res = '';
        if (chunk.fg() === 'reset') {
            res = this.suffix;
            this.suffix = '';
            return res;
        }

        let style = '';
        if (chunk.fg() != null) style += 'color: ' + chunk.fg() + '; ';
        if (chunk.bg() != null) style += 'background-color: ' + chunk.bg() + ' ';
        if (style === '') return '';
        // close previous color tags
        // IMPORTANT: if we use span suffix tags for anything else, this will fail!
        // Disabling this, it's causing weird problems
//        while (this.suffix.substr(0, 7) === '</span>') {
//            res += '</span>';
//            this.suffix = this.suffix.substr(7);
//        }
        this.suffix += '</span>';
        return res + '<span style="' + style + '">';
    }

    formatLink(chunk) {
        let res = '<a class="mxp_send' + (chunk.prompt() ? ' mxp_prompt' : '') + '"';
        res += ' href="#" rel="' + chunk.commands() + '"';
        if (chunk.hint()) res += ' title="' + chunk.hint() + '"';
        if (chunk.color()) res += ' style="color: ' + chunk.color() + '"';
        res += '>' + chunk.text() + '</a>';
        return res;
    }

    format(chunks) {
        let res = '';
        for (let i = 0; i < chunks.length; ++i)
            res += chunks[i].formatted(this);
        return this.prefix + res + this.suffix;
    }
}

 
