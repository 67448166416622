import React from 'react'

import { DraggableCore}  from 'react-draggable'

export class NexusDraggable extends React.Component {
    constructor(props) {
        super(props);
        let pos = this.props.position;
        this.x = 0;
        this.y = 0;
        this.startX = 0;
        this.startY = 0;
        // the offset determines the shift of each successive window, so that they do not all start at the same location
        this.offset = this.props.offsetId * 30;
        if (isNaN(this.offset)) this.offset = 0;
        let ww = this.props.platform.window_width();
        let wh = this.props.platform.window_height();
        let maxOffset = ww / 2;
        if (maxOffset > wh / 2) maxOffset = wh / 2;
        while (this.offset > maxOffset) this.offset -= maxOffset;
        if (pos) {
            this.x = pos.x;
            this.y = pos.y;
        }
        this.ref = React.createRef();
    }

    onStart(e, data) {
        let el = data.node;
        this.startX = data.x - el.offsetLeft;
        this.startY = data.y - el.offsetTop;
    }

    onDrag(e, data) {
        let content = this.ref.current;
        let w = content.clientWidth;

        this.x = data.x - this.startX;
        this.y = data.y - this.startY;

        // clip to window
        let el = data.node;
        let ww = this.props.platform.window_width();
        let wh = this.props.platform.window_height();
        if (this.x < 0) this.x = 0;
        if (this.y < 0) this.y = 0;
        if (this.x > ww - w/2) this.x = ww - w/2;
        if (this.y > wh - 40) this.y = wh - 40;

        el.style.left = this.x+'px';
        el.style.top = this.y+'px';
        if (this.props.positionChanged) this.props.positionChanged(this.x, this.y);
        e.stopPropagation();
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        let el = this.ref.current;

        // first, let's center if requested
        let w = el.clientWidth;
        let h = el.clientHeight;
        let ww = this.props.platform.window_width();
        let wh = this.props.platform.window_height();
        if (this.x === 'center') {
            this.x = (ww - w) / 2 + this.offset;
            el.style.left = this.x + 'px';
        }
        if (this.y === 'center') {
            this.y = (wh - h) / 2 + this.offset;
            el.style.top = this.y + 'px';
        }

        if (!this.width) this.width = w;
        if (!this.height) this.height = h;

        // Did the size change? Need to update X/Y accordingly if so.
        if (this.width !== w) {
            this.x += (this.width - w) / 2;
            el.style.left = this.x + 'px';
            this.width = w;
        }
        if (this.height !== h) {
            this.y += (this.height - h) / 2;
            el.style.top = this.y + 'px';
            this.height = h;
        }
    }

    render() {
        let component = this.props.children;
        if (!component) return null;
        let st = { position: 'fixed', left: this.x + 'px', top: this.y + 'px' };
        return (<DraggableCore onStart={(e,data)=>this.onStart(e,data)} onDrag={(e,data)=>this.onDrag(e,data)} {...this.props} ><div ref={this.ref} style={st}>{component}</div></DraggableCore>);
    }
}
 
