
import { Util } from '../base/util.js'
import { Socket } from '../base/socket.js'
import { LineParser } from '../base/line.js'
import { BlockParser } from '../base/lines.js'

// This handles data coming from a socket and dispatches it to the various callbacks. Likewise, it sends data to the socket.
// It also handles telnet codes, GMCP splitting, and so on.

export class DataHandler {
    constructor(nexus) {
        this._nexus = nexus;
        this._settings = nexus.settings();
        this._socket = undefined;
        this.gmcp_regex = /\xFF\xFA\xC9([^\xFF]+)\xFF\xF0/;
        this.telnet_regex = /\xFF[\xFB|\xFC|\xFD|\xFE][\x00-\xFF]/gm;
        this.telnet_options = [];

        let t = this;
        this.block_parser = new BlockParser (nexus);
        this.block_parser.on_special_display = function(type, lines, params) {  //passthru routine
            t.on_special_display (type, lines, params);
        };
        this.on_block = function(block){};
        this.on_rawdata = function(s){};
        this.on_special_display = function(type, lines, params){};
        this.on_gmcp_feature = function(feature, params){};
        this.on_connected = function(){};
        this.on_disconnected = function(){};
        this.on_gmcp_updated = function(){};
        this.on_gmcp_enabled = null;

        // Telnet codes
        this.TELNET_IS = String.fromCharCode(0);         // \x00
        this.TELNET_SEND = String.fromCharCode(1);       // \x01
        this.TELNET_ECHO = String.fromCharCode(1);       // \x01
        this.TELNET_ETX = String.fromCharCode(3);        // \x03
        this.TELNET_EOT = String.fromCharCode(4);        // \x04
        this.TELNET_TTYPE = String.fromCharCode(24);     // \x18
        this.TELNET_EOR1 = String.fromCharCode(25);      // \x19
        this.TELNET_MSDP = String.fromCharCode(69);      // \x45
        this.TELNET_MSSP = String.fromCharCode(70);      // \x46
        this.TELNET_MCCP2 = String.fromCharCode(86);	// \x56
        this.TELNET_MCCP = String.fromCharCode(90);      // \x5A
        this.TELNET_MXP = String.fromCharCode(91);       // \x5B
        this.TELNET_ZMP = String.fromCharCode(93);       // \x5D

        this.TELNET_EOR = String.fromCharCode(239);      // \xEF
        this.TELNET_SE = String.fromCharCode(240);       // \xF0
        this.TELNET_GA = String.fromCharCode(249);	// \xF9
        this.TELNET_SB = String.fromCharCode(250);       // \xFA

        this.TELNET_WILL = String.fromCharCode(251);     // \xFB
        this.TELNET_WONT = String.fromCharCode(252);     // \xFC
        this.TELNET_DO = String.fromCharCode(253);       // \xFD
        this.TELNET_DONT = String.fromCharCode(254);     // \xFE
        this.TELNET_IAC = String.fromCharCode(255);      // \xFF

        this.TELNET_ATCP = String.fromCharCode(200);	// \xC8
        this.TELNET_GMCP = String.fromCharCode(201);     // \xC9

        this.TELNET_ESC = String.fromCharCode(27);       // \x1B
        
        this.reset();
    }

    reset() {
        this.buffer = '';
        this.last_send = 0;
        this.disconnect_reason = '';
        this.gmcp_enabled = false;
        this.mxp_enabled = false;
        this.supports_iacga = false;
        this.telnet_options = [];
        this.connect_error = null;

        this.GMCP = {
            "Character":null,
            "Vitals":null,
            "StatusVars":null,
            "Status":{},
            "PingTime": -1,
            "PingStart": null,
            "CharStats": [],
            "RoomPlayers": [],
            "Afflictions": {},
            "Defences": {},
            "DefencesList": {},
            "Items": { 'room' : [], 'inv' : [] },
            "ChannelList":[],
            "SkillGroups":[],
            "SkillInfo":{},   // temporary data for expanded skills
            "NewsSections":{},
            "NewsList":{},   // temporary data for currently shown news lists
            "WhoList":[],
            "Rift": {},
            "TaskList":{},
            "Time":{},
            "Location": { 'roomname' : '', 'roomexits' : [], 'areaname' : '', 'x' : 0, 'y' : 0, 'z' : 0, 'b' : 0, 'areaid' : null, 'ohmap' : false },
            "Target": { "ID" : null, "Text": null, "IsPlayer": null, "HP": null },
            "Buttons": {},
        };
        this.on_gmcp_updated();
        this.need_gmcp_update = false;
    }

    set_socket(s) {
        this._socket = s;
    }

    socket() {
        return this._socket;
    }

    is_connected() {
        if (!this._socket) return false;
        return this._socket.connected();
    }

    settings() {
        return this._settings;
    }

    connect(use_secondary = false) {
        let t = this;
        let use_encryption = true;
        let wshost = t._nexus.gameinfo().server_name(use_secondary);
        let wsport = t._nexus.gameinfo().server_port(use_encryption, use_secondary);
        let wsdir = t._nexus.gameinfo().websock_dir();
        if (use_secondary) wsdir = '/';
        let socket = new Socket(wshost, wsport, wsdir, use_encryption);
        this._socket = socket;

        // If we are using the primary address, this flag indicates that should the connection fail, we'll try the secondary
        // one next. As soon as we successfully connect, the flag gets cleared. The flag also gets cleared after we kickoff
        // the secondary connection, to avoid looping.
        let try_secondary = !use_secondary;

        socket.ondata = function(data) {
            t.read_data (data);
        };
        socket.onclose = function(e) {
            t.handle_disconnected();
        }
        socket.onconnect = function(e) {
            try_secondary = false;
            t.handle_connected();
        };
        socket.onerror = function(e) {
            if (try_secondary) {
                try_secondary = false;
                socket.onclose = null;  // so that the onclose handler doesn't fire
                t.connect(true);
                return;
            }
            t._nexus.display_notice(e, 'red');
            t.connect_error = e;
        };

        socket.connect();
    }

    handle_connected() {
        let t = this;

        if (t.interval_players) t._nexus.platform().clear_interval (this.interval_players);
        t.interval_players = t._nexus.platform().set_interval(function () {
            if (!t.is_connected()) return;
            t.send_GMCP('Comm.Channel.Players', '');
        }, 30000);
        t._nexus.platform().set_timeout (function() {
            t.send_GMCP('Comm.Channel.Players', '');
            t.send_GMCP('Char.Items.Inv', '');
        }, 2000);
        
        if (t.interval_ping) t._nexus.platform().clear_interval (this.interval_ping);
        t.interval_ping = t._nexus.platform().set_interval(function () {
            if (!t.is_connected()) return;
            t.ping();
        }, 5000);

        t.reset();
        let gi = this._nexus.gameinfo();
        if (gi.is_ire_game()) t.mxp_enabled = true;   // IRE games use MXP by default
        if (gi.set_using_gmcp) gi.set_using_gmcp(false);

        t.on_connected();
    }

    handle_disconnected() {
        let t = this;
        if (t.interval_ping) t._nexus.platform().clear_interval (t.interval_ping);
        if (t.interval_players) t._nexus.platform().clear_interval (t.interval_players);

        // process the remaining data, if any
        // this is important to get the GMCP disconnect reason, amongst other things
        this.read_data('', true);

        let gi = this._nexus.gameinfo();
        if (gi.set_using_gmcp) gi.set_using_gmcp(false);

        t.on_disconnected();

        t.reset();
        t._socket = null;
    }

    read_data(data, force_process=false) {
        let t = this;
        let loops = 0;
        // Run multiple iterations, in case we get more blocks at once.
        // But cap it at 100, so that we don't spin endlessly if there's a bug somewhere.
        while (loops < 100) {
            loops++;

            let str = this.handle_telnet_read(data, force_process);
            data = '';
            if (str === false) break;

            let lines = this.telnet_split(str);
            // this also handles special display blocks
            lines = this.block_parser.parse_lines (lines);
            if (lines === false) break;

            this.on_block (lines);
            if (!lines.length) break;
        }

        // Propagate the GMCP change and update the interface.
        // This also starts a 150ms timer. If another gmcp update comes within that window, we do not apply it right away, but instead wait and collect all the updates,
        // doing them all at once, This substantially improves performance when lots of text are coming in at once.
        if (t.need_gmcp_update && (!t.gmcp_update_cooldown)) {
            let nex = t._nexus;
            // report that the GMCP object was updated (so that react objects can update in turn)
            t.on_gmcp_updated();
            t.need_gmcp_update = false;

            t.gmcp_update_cooldown = nex.platform().set_timeout(() => {
                t.gmcp_update_cooldown = null;
                if (t.need_gmcp_update) t.on_gmcp_updated();
                t.need_gmcp_update = false;
            }, 150);
        }

    }

    handle_telnet_read(data, force_process=false)
    {
        let t = this;
        let nex = t._nexus;

        this.buffer += data;
        this.buffer = this.handle_negotiation (this.buffer);
        // More data arrived? Clear the prompt timer.
        if (data.length && t.prompt_timer) {
            nex.platform().clear_timeout (t.prompt_timer);
            delete t.prompt_timer;
        }

        // TODO!
        // This is not perfect, as it can split legitimate GMCP payloads if they contain the special chars. But it'll do.
        // TODO - full telnet parser is maybe needed, especially as we're now supporting the 3rdparty games ...
        let idx = this.buffer.indexOf("\xFF\xF9");   // IAC GA
        if (idx >= 0) {
            t.supports_iacga = true;  // Remember that we have received an IAC-GA sequence.
            let res = this.buffer.substr(0, idx + 2);
            this.buffer = this.buffer.substr(idx + 2);
            this.on_rawdata (res);
            return res;
        }

        let l = this.buffer.length;
        // IAC SB / IAC SE (for standalone GMCP replies/notices)
        if (((this.buffer[0] + this.buffer[1]) === "\xFF\xFA") && ((this.buffer[l-2] + this.buffer[l-1]) === "\xFF\xF0"))
        {
            let res = this.buffer;
            this.buffer = "";
            this.on_rawdata (res);
            return res;
        }

        // non-IRE game without IAC-GA support?
        let gi = this._nexus.gameinfo();
        if ((!gi.is_ire_game()) && (!this.supports_iacga)) {
            // Grab everything up to the last newline and process it.
            idx = this.buffer.lastIndexOf('\n');
            if (idx >= 0) {
                let res = this.buffer.substr(0, idx + 1);
                this.buffer = this.buffer.substr(idx + 1);
                this.on_rawdata (res);
                return res;
            }
            // If we have anything left, it's either a prompt or a line fragment.
            if (this.buffer.length) {
                // So we'll wait 0.2s to see if the rest comes, if not, we'll treat it as a prompt.
                t.prompt_timer = nex.platform().set_timeout (() => { t.read_data('', true) }, 200);
            }
        }

        if (force_process && t.prompt_timer) {
            nex.platform().clear_timeout (t.prompt_timer);
            delete t.prompt_timer;

            let res = this.buffer;
            this.buffer = "";
            this.on_rawdata (res);
            return res;
        }
    
        return false;
    }

    // split a block into lines, the prompt, and GMCP markers for fixed width, help, etc
    telnet_split(msg) {
        if (typeof msg == "undefined" || msg === undefined)
            return false;

        // need to normalize linebreaks
        msg = msg.replace(/\r\n/gm, "\n");
        msg = msg.replace(/\r/gm, "\n");

        // split GMCP chunks
        let gmcp_split = [];
        while (true) {
            let gmcp_matches = this.gmcp_regex.exec(msg)
            if (!gmcp_matches) break;

            let idx = gmcp_matches.index;
            if (idx > 0) {
                let chunk = {};
                chunk.text = msg.substr(0, idx);
                gmcp_split.push(chunk);
            }

            let gmcp_method = gmcp_matches[1].trim();
            let gmcp_args = '';
            let gidx = gmcp_method.indexOf(' ');
            if (gidx >= 0) {
                gmcp_args = gmcp_method.substr(gidx + 1);
                gmcp_method = gmcp_method.substr(0, gidx);
            }
            let gchunk = this.handle_GMCP(gmcp_method, gmcp_args);
            if (gchunk) gmcp_split.push(gchunk);
            
            msg = msg.substr(idx + gmcp_matches[0].length);
        }
        if (msg.length) {
            let chunk = {};
            chunk.text = msg;
            gmcp_split.push(chunk);
        }

        // split text chunks into lines
        // we know that we have either one full text block including a prompt, or no blocks at all
        let txt = '';
        let lines = [];
        let idx;
        for (let i = 0; i < gmcp_split.length; ++i) {
            if (!gmcp_split[i].text) {
                lines.push(gmcp_split[i]);
                continue;
            }
            txt += gmcp_split[i].text;
            do {
                idx = txt.indexOf("\n");
                if (idx >= 0) {
                    let chunk = {};
                    chunk.line = txt.substr(0, idx);
                    chunk.is_prompt = false;
                    chunk.timestamp_ms = Util.get_time(true);
                    chunk.timestamp = Util.get_time(false);
                    txt = txt.substr(idx + 1);
                    lines.push(chunk);
                }
            } while (idx >= 0);
        }
        idx = txt.indexOf("\xFF\xF9");   // IAC GA
        let prompt_line = '';
        if (idx >= 0) {
            prompt_line = txt.substr(0, idx);
            txt = txt.substr(idx + 2);
        } else {
            prompt_line = txt;
            txt = '';
        }
        if (prompt_line.length) {
            let chunk = {};
            chunk.line = prompt_line;
            chunk.is_prompt = true;
            chunk.timestamp_ms = Util.get_time(true);
            chunk.timestamp = Util.get_time(false);
            lines.push(chunk);
        }
        if (txt.length) {
            // This shouldn't happen, as it means that we have a problem with the logic somewhere.
            this._nexus.platform().log_raw('Unhandled text bit: "' + txt + '" of size ' + txt.length);
        }
        return lines;
    }

    handle_feature_on(nfeature, ntype) {
        // Already on? Nothing to do.
        let t = this;
        if (this.telnet_options[nfeature] === true) return;
        let reply_type_yes = (ntype === this.TELNET_WILL) ? this.TELNET_DO : this.TELNET_WILL;
        let reply_type_no = (ntype === this.TELNET_WILL) ? this.TELNET_DONT : this.TELNET_WONT;

        if (nfeature === this.TELNET_GMCP)
        {
            this.gmcp_enabled = true;
            this.telnet_options[nfeature] = true;

            // Also send what we WILL do //
            this._socket.send(this.TELNET_IAC + reply_type_yes + nfeature);
            this.send_GMCP("Core.Hello", {"Client": this._nexus.client_name(), "Version":this._nexus.client_version()+(this._nexus.platform().real_mobile()?'mobile':'')});
            let supports = [];
            let gi = this._nexus.gameinfo();
            if (gi.is_ire_game()) {
                supports = ["Char 1", "Char.Login 1", "Char.Skills 1", "Char.Items 1", "Comm.Channel 1", "IRE.Rift 1", "IRE.FileStore 1", "Room 1", "IRE.Composer 1", "Redirect 2", "IRE.Display 3", "IRE.News 1", "IRE.Tasks 1", "IRE.Sound 1", "IRE.Misc 1", "IRE.Time 1", "IRE.Target 1"];
            } else {
                supports = ["Char 1", "Char.Skills 1", "Char.Items 1", "Comm.Channel 1", "Room 1", "Redirect 2"];
                gi.set_using_gmcp(true);
                this._nexus.ui().layout().game_changed();
                this._nexus.set_default_settings();
            }
            this.send_GMCP("Core.Supports.Set", supports);

            // also send termtype here
            // IAC WILL TTYPE; IAC SB TTYPE IS IRE-NEXUS IAC SE
            this.telnet_options[t.TELNET_TTYPE] = true;
            this._socket.send(this.TELNET_IAC + this.TELNET_WILL + this.TELNET_TTYPE);
            this._socket.send(this.TELNET_IAC + this.TELNET_SB + this.TELNET_TTYPE + this.TELNET_IS + this._nexus.client_name() + this.TELNET_IAC + this.TELNET_SE);

            if (typeof this.on_gmcp_enabled === "function")
            {
                this._nexus.platform().set_timeout(function() { t.on_gmcp_enabled(this); t.on_gmcp_enabled = null; }, 0);
            }
            return;
        }
        if (nfeature === this.TELNET_MXP) {
            this.mxp_enabled = true;
            this.telnet_options[nfeature] = true;
        }

        // We do not support this.
        this._socket.send(this.TELNET_IAC + reply_type_no + nfeature);
    }

    handle_feature_off(nfeature, ntype) {
        // Already off? Nothing to do.
        if (this.telnet_options[nfeature] === false) return;
        
        let reply_type_no = (ntype === this.TELNET_WILL) ? this.TELNET_DONT : this.TELNET_WONT;
        this.telnet_options[nfeature] = false;
        // And acknowledge.
        this._socket.send(this.TELNET_IAC + reply_type_no + nfeature);
    }

    // Telnet negotiation //
    handle_negotiation(data)
    {
        //this._nexus.platform().log_raw(data);
        let matches;
        while ((matches = this.telnet_regex.exec(data)) !== null)
        {
            let ntype = matches[0][1];
            let nfeature = matches[0][2];

            if (this._socket && ((ntype === this.TELNET_WILL) || (ntype === this.TELNET_DO)))
                this.handle_feature_on(nfeature, ntype);
            if (this._socket && ((ntype === this.TELNET_WONT) || (ntype === this.TELNET_DONT)))
                this.handle_feature_off(nfeature, ntype);
        }
        return data.replace(this.telnet_regex, "");
    }

    send_command(command) {
        if (!this._socket) return;
        this._socket.send(command + "\r\n");
        this.last_send = Util.timestamp();
    }

    
    // *******************************************************************************************************************
    // ***                                                     MXP                                                     ***
    // *******************************************************************************************************************

    send_mxp_message(message)
    {
        if (!this._socket) return;
        this._socket.send("\x1B[1z" + message + "\n");
        this.last_send = Util.timestamp();
    }

    send_mxp_info()
    {
        this.send_mxp_message ("<version mxp=1.0 client="+this._nexus.client_name()+" version="+this._nexus.client_version()+(this._nexus.platform().real_mobile()?'mobile':'')+" >");
    }

    send_mxp_supports()
    {
        this.send_mxp_message ("<supports +send +color>");
    }
    
    
    // *******************************************************************************************************************
    // ***                                                     GMCP                                                    ***
    // *******************************************************************************************************************

    send_GMCP(message_type, message)
    {
        if (!this._socket) return;
        if (!this.gmcp_enabled) return;

        let smessage = JSON.stringify(message);
        this.on_gmcp_feature ('gmcp-send', { 'type': message_type, 'message' : smessage });

        this._socket.send("\xFF\xFA\xC9" + message_type + " " + smessage + "\xFF\xF0");
        this.last_send = Util.timestamp();
    }
    
    ping() {
        if (!this.gmcp_enabled) return;
        this.GMCP.PingStart = Util.timestamp();
        this.send_GMCP("Core.Ping", (this.GMCP.PingTime > 0) ? this.GMCP.PingTime : '');
    }
    
    // Recursively find an item in the room/inv lists
    find_item(item_id, list, delete_it=false) {
        let id = parseInt(item_id);
        let res;
        if (list) {
            for (let idx = 0; idx < list.length; ++idx) {
                if (list[idx].id === id)  // found our item
                {
                    res = list[idx];
                    if (delete_it) list.splice(idx, 1);
                    return res;
                }
                // item has children?
                if (list[idx].items && list[idx].items.length)
                {
                    res = this.find_item(id, list[idx].items, delete_it);
                    if (res) return res;
                }
            }
            return undefined;  // not found here
        }

        if (this.GMCP.Items.room) res = this.find_item(id, this.GMCP.Items.room, delete_it);
        if ((!res) && this.GMCP.Items.inv) res = this.find_item(id, this.GMCP.Items.inv, delete_it);
        return res;
    }

    add_items(items, loc, replace) {
        // find the parent to add to
        let parent, parentid;
        if ((loc === 'room') || (loc === 'inv')) {
            parent = this.GMCP.Items[loc];
            parentid = loc;
        }
        else if (loc.substr(0, 3) === 'rep') {
            let id = parseInt(loc.substr (3));
            parent = this.find_item(id);
            if (parent) {
                parentid = id;
                if (!parent.items) parent.items = [];
                parent = parent.items;
            }
        }
        if (!parent) return false;  // nowhere to add this item to
        if (replace) parent.length = 0;  // remove existing items if requested
        for (let idx = 0; idx < items.length; ++idx) {
            items[idx].parentid = parentid;
            items[idx].id = parseInt(items[idx].id);

            // the item name may contain ANSI sequences, we need to strip those
            // (TODO or maybe render them?)
            let parser = new LineParser(this._settings, undefined);
            let line = parser.parse_line(items[idx].name);
            items[idx].name = line.text();

            // check if the item already exists
            let found = false;
            for (let ii = 0; ii < parent.length; ++ii) {
                if (items[idx].id !== parent[ii].id) continue;
                // found our item
                parent[ii] = items[idx];
                found = true;
                break;
            }

            if (!found) parent.push (items[idx]);
        }
        return true;
    }

    item_section(loc, attr)
    {
        if (attr == null) attr = "";

        if (loc === 'room')
        {
            if (attr.length && (attr.indexOf ('m') >= 0) && (attr.indexOf ('d') < 0))
                return 'mobs';
            return 'items';
        }

        if (loc === 'inv')
        {
            if ((attr.indexOf ('l') >= 0) || (attr.indexOf ('L') >= 0))    // Wielded
                return 'wielded';
            if (attr.indexOf ('w') >= 0)  // Worn
                return 'worn';
            return 'items';
        }

        if (loc === 'players') {
            return 'players';
        }

        if (loc.substr (0, 3) === 'rep') {
            return loc;
        }

        return '';
    }

    get_item_list(loc, attr, skip_attr=null) {
        let section = this.item_section(loc, attr);
        let items = this.GMCP.Items[loc];
        if (!items || (!items.length)) return [];
        let res = [];
        for (let idx = 0; idx < items.length; ++idx) {
            let e = items[idx];
            if (this.item_section (loc, e.attrib) !== section) continue;
            
            if (skip_attr && skip_attr.length && e.attrib && (e.attrib.indexOf(skip_attr) >= 0))
                continue;
            res.push (e);
        }
        return res;
    }
    
    // returns [ groupname, grouppos, position, task ]
    find_task(tasktype, taskid) {
        let types = [ 'tasks', 'quests', 'achievements' ];
        for (let tt = 0; tt < types.length; ++tt) {
            let type = types[tt];
            let tasks = this.GMCP.TaskList[type];
            if (tasktype.toLowerCase().indexOf(type) < 0) continue;

            let origtask = tasks['items'][taskid];
            if (!origtask) return null;  // no such task

            let groupname = origtask.group.toLowerCase();
            let grouppos = -1;
            for (let g = 0; g < tasks['groups'].length; ++g) {
                let tgroup = tasks['groups'][g];
                let gname = tgroup['name'].toLowerCase();
                if (gname !== groupname) continue;
                grouppos = g;
                // got the group, find the index
                for (let idx = 0; idx < tgroup['items'].length; ++idx)
                    if (tgroup['items'][idx].id === taskid)
                        return [ groupname, grouppos, idx, tgroup['items'][idx] ];
                    
                return null;  // not found - shouldn't happen
            }
            return null;
        }
        return null;
    }
                    
    current_gender () {
        return (this.GMCP.Status.gender && (this.GMCP.Status.gender.toLowerCase() === 'female')) ? 'female' : 'male';
    }

    current_target()
    {
        return this.GMCP.Target['ID'];
    }

    current_target_is_player()
    {
        return this.GMCP.Target['IsPlayer'];
    }

    clear_current_target_info() {
        this.GMCP.Target['Text'] = null;
        this.GMCP.Target['HP'] = null;
        this.GMCP['TargetIsPlayer'] = false;
        this.GMCP.Target['hpperc'] = null;
        this.GMCP['IsPlayer'] = false;   // compat
    }

    set_current_target_info(desc, hp, is_player)
    {
        if (desc !== undefined) {
            this.GMCP.Target['Text'] = desc;
            this.GMCP.Target['short_desc'] = desc;   // compat
        }
        if (hp !== undefined) {
            this.GMCP.Target['HP'] = hp;
            this.GMCP.Target['hpperc'] = hp;
        }
        this.GMCP['TargetIsPlayer'] = is_player;
        this.GMCP.Target['IsPlayer'] = is_player;   // compat
    }

    current_target_visible() {
        let res = this.GMCP.Target['Text'];
        if (!res) res = this.GMCP.Target['ID'];
        if (this.GMCP.Target['HP'] && this.GMCP.Target['HP'].length) res += ' (' + this.GMCP.Target['HP'] + ')';
        if ((!res) || (!res.length)) res = 'None';
        return res;
    }

    set_current_target(tg, notify_game)
    {
        if (typeof (tg) === 'number') tg = tg.toString();
        let diff = (this.GMCP.Target['ID'] !== tg);

        this.GMCP.Target['ID'] = tg;
        this.GMCP.Target['id'] = tg;   // compat
        if (notify_game) this.send_GMCP("IRE.Target.Set", (tg !== undefined) ? tg : 0);

        let newName;
        if (!diff) return;

        this.clear_current_target_info(undefined, undefined, undefined);
        let found = false;
        let targets = this.get_item_list('room', 'm');
        for (let i = 0; i < targets.length; ++i) {
            if (targets[i].id !== tg) continue;
            newName = targets[i].text;
            this.set_current_target_info(newName, undefined, false);
            found = true;
            break;
        }
        if (!found) {
            targets = this.get_item_list('players');
            for (let i = 0; i < targets.length; ++i) {
                if (targets[i].id.toLowerCase() !== tg.toLowerCase()) continue;
                newName = targets[i].text;
                this.set_current_target_info(newName, undefined, true);
                break;
            }
        }

        this.on_gmcp_feature ('target-changed', { 'target' : tg, 'name' : newName });
    }

    
    // main GMCP processing routine for GMCP messages
    // Returns an object to insert into the text stream if needed (for fixed width, channels, etc)
    handle_GMCP(gmcp_method, gmcp_args)
    {
        let gmcp_fire_event = false;
        let gmcp_event_param = '';
        let need_relayout = false;

        if (gmcp_args.length === 0) gmcp_args = "\"\"";  // because JSON can't handle an empty string
        gmcp_args = JSON.parse(gmcp_args);

        switch (gmcp_method) {
        case "Core.Goodbye":
        {
            this.disconnect_reason = gmcp_args;
            break;
        }

        case "Core.Ping":
        {
            if (this.GMCP.PingStart)
                this.GMCP.PingTime = Util.timestamp() - this.GMCP.PingStart;
            this.GMCP.PingStart = null;
            need_relayout = true;
            break;
        }

        case "Char.Name":
        {
            this.GMCP.Character = gmcp_args;
            this.on_gmcp_feature ('charname', gmcp_args);
            break;
        }

        case "Char.StatusVars":
        {
            this.GMCP.StatusVars = gmcp_args;
            need_relayout = true;
            break;
        }

        case "Char.Status":
        {
            // the data can be partial, so don't replace what we have
            if (this.GMCP.Status == null) this.GMCP.Status = {};
            let s = gmcp_args;
            for (let v in s)
                this.GMCP.Status[v] = s[v];
            need_relayout = true;
            break;
        }

        case "Char.Vitals":
        {
            this.GMCP.Vitals = gmcp_args;
            // put all the info to variables
            for (let v in gmcp_args)
            {
                if (v === 'charstats')
                    this.GMCP.CharStats = gmcp_args.charstats;
                else
                    this._nexus.variables().set('my_'+v, gmcp_args[v]);
            }
            
            need_relayout = true;
            gmcp_fire_event = true;
            gmcp_event_param = '';

            break;
        }

        case "Char.Skills.Groups": {
            this.GMCP.SkillGroups = gmcp_args;
            need_relayout = true;
            break;
        }
        case "Char.Skills.List": {
            this.GMCP.SkillInfo.List = gmcp_args;
            need_relayout = true;
            break;
        }
        case "Char.Skills.Info": {
            this.GMCP.SkillInfo.Desc = gmcp_args;
            this.on_gmcp_feature ('skill-info', gmcp_args);
            break;
        }

        case "IRE.News.Sections": {
            for (let idx = 0; idx < gmcp_args.length; ++idx) {
                gmcp_args[idx].count = parseInt(gmcp_args[idx].count);
                gmcp_args[idx].lastread = parseInt(gmcp_args[idx].lastread);
            }
            this.GMCP.NewsSections = gmcp_args;
            need_relayout = true;
            break;
        }
        case "IRE.News.Articles": {
            this.GMCP.NewsList.List = gmcp_args;
            this.GMCP.NewsList.Section = this.GMCP.NewsList.RequestedSection;
            this.GMCP.NewsList.FromID = this.GMCP.NewsList.RequestedFromID;
            this.GMCP.NewsList.ToID = this.GMCP.NewsList.RequestedToID;
            this.GMCP.NewsList.RequestedSection = null;
            this.GMCP.NewsList.RequestedFromID = null;
            this.GMCP.NewsList.RequestedToID = null;
            need_relayout = true;
            break;
        }
        case "IRE.News.Article": {
            this.GMCP.NewsList.Article = gmcp_args;
            this.on_gmcp_feature ('news-article', gmcp_args);
            break;
        }

        case "Char.Afflictions.List": {
            this.GMCP.Afflictions = {};
            for (let i = 0; i < gmcp_args.length; ++i) {
                let aff = gmcp_args[i];  // this has keys: name, cure, desc
                this.GMCP.Afflictions[aff.name] = aff;
            }
            need_relayout = true;
            break;
        }

        case "Char.Afflictions.Add": {
            let aff = gmcp_args;
            this.GMCP.Afflictions[aff.name] = aff;
            need_relayout = true;
            gmcp_fire_event = true;
            gmcp_event_param = aff;
            break;
        }

        case "Char.Afflictions.Remove": {
            for (let i = 0; i < gmcp_args.length; ++i)
                delete this.GMCP.Afflictions[gmcp_args[i]];
            need_relayout = true;
            gmcp_fire_event = true;
            gmcp_event_param = gmcp_args;
            break;
        }

        case "Char.Defences.InfoList": {
            this.GMCP.DefencesList = {};
            for (let i = 0; i < gmcp_args.length; ++i) {
                let def = gmcp_args[i];  // this has keys: name, desc, icon, color ...
                def['important'] = parseInt(def['important']);
                this.GMCP.DefencesList[def.name] = def;
            }
            need_relayout = true;
            break;
        }

        case "Char.Defences.List": {
            this.GMCP.Defences = {};
            for (let i = 0; i < gmcp_args.length; ++i) {
                let def = gmcp_args[i];  // this has keys: name, desc
                this.GMCP.Defences[def.name] = def;
            }
            need_relayout = true;
            break;
        }

        case "Char.Defences.Add": {
            let def = gmcp_args;
            this.GMCP.Defences[def.name] = def;
            need_relayout = true;
            gmcp_fire_event = true;
            gmcp_event_param = def;
            break;
        }

        case "Char.Defences.Remove": {
            for (let i = 0; i < gmcp_args.length; ++i)
                delete this.GMCP.Defences[gmcp_args[i]];
            need_relayout = true;
            gmcp_fire_event = true;
            gmcp_event_param = gmcp_args;
            break;
        }

        case "Room.AddPlayer":
        {
            let name = gmcp_args.name;
            let lname = name.toLowerCase();
            if (lname !== this.GMCP.Character.name.toLowerCase())
            {
                // remove the existing name, if any
                for (let idx = 0; idx < this.GMCP.RoomPlayers.length; ++idx) {
                    if (this.GMCP.RoomPlayers[idx].name.toLowerCase() !== lname) continue;
                    this.GMCP.RoomPlayers.splice(idx, 1);
                    break;
                }

                let e = { 'name': name, 'fullname': gmcp_args.fullname };
                this.GMCP.RoomPlayers.push (e);

                need_relayout = true;
                gmcp_fire_event = true;
                gmcp_event_param = name;
            }
            break;
        }

        case "Room.RemovePlayer":
        {
            let name = gmcp_args;
            let lname = name.toLowerCase();
            for (let idx = 0; idx < this.GMCP.RoomPlayers.length; ++idx) {
                if (this.GMCP.RoomPlayers[idx].name.toLowerCase() !== lname) continue;
                this.GMCP.RoomPlayers.splice(idx, 1);
                break;
            }
            need_relayout = true;
            gmcp_fire_event = true;
            gmcp_event_param = gmcp_args;
            break;
        }

        case "Room.Players":
        {
            let p = [];
            for (let i in gmcp_args) {
                let name = gmcp_args[i].name;
                if (name.toLowerCase() === this.GMCP.Character.name.toLowerCase()) continue;

                let e = { 'name': name, 'fullname': gmcp_args[i].fullname };
                p.push (e);
            }

            this.GMCP.RoomPlayers = p;
            need_relayout = true;
            break;
        }

        case "Char.Items.Add":
        case "Char.Items.Update":
        {
            let item = gmcp_args.item;
            let loc = gmcp_args.location;

            // find the existing item - we'll do the update action if it exists
            let prev = this.find_item(item.id, undefined, true);

            let items = [ item ];
            if (!this.add_items (items, loc, false)) return;
            need_relayout = true;
            break;
        }

        case "Char.Items.Remove":
        {
            let item_id;
            if (typeof gmcp_args.item.id != "undefined")
                item_id = gmcp_args.item.id;
            else
                item_id = gmcp_args.item;
            let loc = gmcp_args.location;
            let loclist = undefined;
            if (loc === 'inv') loclist = this.GMCP.Items.inv;
            else if (loc === 'room') loclist = this.GMCP.Items.room;
            let item = this.find_item(item_id, loclist, true);  // this removes the item
            if (!item) break;
            need_relayout = true;
            break;
        }

        case "Char.Items.List":
        {
            let loc = gmcp_args.location;
            let items = gmcp_args.items;
            if (!this.add_items (items, loc, true)) return;

            need_relayout = true;
            break;
        }

        case "IRE.Time.List":
        {
            this.GMCP.Time = {};
            for (let i in gmcp_args)
                this.GMCP.Time[i] = gmcp_args[i];
            need_relayout = true;
            break;
        }

        case "IRE.Time.Update":
        {
            for (let i in gmcp_args)
                this.GMCP.Time[i] = gmcp_args[i];
            need_relayout = true;
            break;
        }

        case "Redirect.Content":
        {
            let name = gmcp_args.name;
            if (!name) name = 'Information';
            let type = gmcp_args.type;
            if (!type) type = 'text';
            let windowid = gmcp_args.windowid;
            if (!windowid) windowid = type;
            let width = parseInt(gmcp_args.width);
            if (isNaN(width)) width = 0;
            let height = parseInt(gmcp_args.height);
            if (isNaN(height)) height = 0;
            let content = gmcp_args.content;

            this.on_gmcp_feature ('content-window', { 'id' : windowid, 'name' : name, 'type' : type, 'content' : content, 'width' : width, 'height' : height });
            break;
        }
        case "Redirect.Content.Close":
        {
            let windowid = gmcp_args;
            if (!windowid) return;
            this.on_gmcp_feature ('content-window-close', { 'id' : windowid });
            break;
        }

        case "IRE.Display.Help":
        {
            if (this._settings.popups_help === true) {
                let res = {};
                res.display = 'help';
                res.start = (gmcp_args === "start");
                return res;
            }
            break;
        }

        case "IRE.Display.Window":
        {
            if (this._settings.popups_help === true) {
                let res = {};
                res.display = 'window';
                res.start = (parseInt(gmcp_args.start) === 1);
                res.cmd = gmcp_args.cmd;
                return res;
            }
            break;
        }

        case "IRE.Display.Ohmap":
        {
            if (this._nexus.ui().layout().tab_is_active('map')) {
                let res = {};
                res.display = 'ohmap';
                res.start = (gmcp_args === "start");
                return res;
            }
            break;
        }

        case "IRE.Display.FixedFont":
        {
            let res = {};
            res.display = 'fixed';
            res.start = (gmcp_args === "start");
            return res;
        }

        case "IRE.Display.AutoFill":
        {
            let hl = (gmcp_args.highlight && (gmcp_args.highlight === true || gmcp_args.highlight === "true"));
            this.on_gmcp_feature ('input-command', { 'command' : gmcp_args.command, 'highlight' : hl });
            break;
        }

        case "IRE.Display.HidePopup":
        {
            this.on_gmcp_feature ('popup-hide', { 'id' : gmcp_args.id });
            break;
        }

        case "IRE.Display.HideAllPopups":
        {
            this.on_gmcp_feature ('popup-hide', { 'id' : 'all' });
            break;
        }

        case "IRE.Display.Popup":
        {
            this.on_gmcp_feature ('popup', { 'id' : gmcp_args.id, 'element' : gmcp_args.element, 'src' : gmcp_args.src, 'content' : gmcp_args.text, 'options' : gmcp_args.options, 'commands' : gmcp_args.commands, 'allow_noshow' : gmcp_args.allow_noshow });
            break;
        }

        case "IRE.Display.ButtonActions":
        {
            this.GMCP.Buttons = gmcp_args;
            need_relayout = true;
            break;
        }

        case "Comm.Channel.Start":
        {
            let res = {};
            res.channel = gmcp_args;
            res.start = true;
            return res;
        }
        case "Comm.Channel.End":
        {
            let res = {};
            res.channel = gmcp_args;
            res.start = false;
            return res;
        }

        case "Comm.Channel.Text":
        {
            let channel = gmcp_args.channel;
            let text = gmcp_args.text;
            let parser = new LineParser(this._settings, undefined);
            let line = parser.parse_line(text);
            this.on_gmcp_feature ('channel-text', { 'line' : line, 'channel' : channel, 'talker' : gmcp_args.talker, 'raw' : text });
            break;
        }

        case "Comm.Channel.List":
        {
            // array, each element has attributes 'name', 'command', 'caption'
            this.GMCP.ChannelList = gmcp_args;
            need_relayout = true;
            break;
        }

        case "Comm.Channel.Players":
        {
            let who = gmcp_args;

            who.sort(function (a,b) {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            });
            this.GMCP.WhoList = who;
            need_relayout = true;
            break;
        }

        case "IRE.Rift.Change":
        {
            let name = gmcp_args.name;
            if (gmcp_args.amount)
                this.GMCP.Rift[name] = { amount: gmcp_args.amount, desc: gmcp_args.desc };
            else
                delete this.GMCP.Rift[name];

            need_relayout = true;
            break;
        }

        case "IRE.Rift.List":
        {
            this.GMCP.Rift = {};
            for (let i in gmcp_args) {
                let name = gmcp_args[i].name;
                this.GMCP.Rift[name] = { amount: gmcp_args[i].amount, desc: gmcp_args[i].desc };
            }
            
            need_relayout = true;
            break;
        }

        case "IRE.FileStore.Content":
        {
            this.on_gmcp_feature ('file-content', gmcp_args);
            break;
        }

        case "IRE.FileStore.List":
        {
            this.on_gmcp_feature ('file-list', gmcp_args);
            break;
        }

        case "IRE.Composer.Edit":
        {
            this.on_gmcp_feature ('edit-text', { 'title' : gmcp_args.title, 'text' : gmcp_args.text });
            break;
        }

        case "IRE.Tasks.List":
        {
            this.GMCP.TaskList = {};

            let types = [ 'tasks', 'quests', 'achievements' ];
            for (let tt = 0; tt < types.length; ++tt) {
                let type = types[tt];
                let type_single = type.substr(0, type.length-1);
                let items = {};

                let groups = {};
                let grouporder = [];   // groups in the order in which they were encountered
                // the "Active" group always exists on the top (only shown if we have such tasks)
                grouporder.push("Active");
                // Similarly, the Completed one always exists at the bottom
                let lastgroups = [];
                lastgroups.push("Completed");
                for (let i = 0; i < gmcp_args.length; ++i)
                {
                    let mytype = gmcp_args[i].type.toLowerCase();
                    if ((mytype !== type) && (mytype != type_single)) continue;

                    items[gmcp_args[i].id] = gmcp_args[i];

                    let group = gmcp_args[i].group;
                    if (!groups[group]) groups[group] = [];

                    groups[group].push(gmcp_args[i]);
                    if ((grouporder.indexOf(group) < 0) && (lastgroups.indexOf(group) < 0))
                        grouporder.push(group);
                }

                for (let g = 0; g < lastgroups.length; ++g)
                    grouporder.push(lastgroups[g]);

                let tdata = {};
                tdata['groups'] = [];
                tdata['items'] = items;

                for (let g = 0; g < grouporder.length; ++g) {
                    let group = grouporder[g];
                    if ((groups[group] === undefined) || (groups[group].length === 0)) continue;
                    let grp = {};
                    grp['name'] = group;
                    grp['items'] = groups[group];
                    tdata['groups'].push (grp);
                }

                this.GMCP.TaskList[type] = tdata;
            }
            need_relayout = true;
            break;
        }

        case "IRE.Tasks.Update":
        {
            for (let i = 0; i < gmcp_args.length; ++i)
            {
                let task = gmcp_args[i];
                let type = task.type;
                let tid = task.id;
                let tpos = this.find_task (type, tid);
                if (!tpos) return;    // we haven't found this task - let's just ignore it .. down the line we may want to add it to the list
                
                let groupname = tpos[0];
                let grouppos = tpos[1];
                let position = tpos[2];
                let tasks = this.GMCP.TaskList[type];
                tasks['items'][tid] = task;
                let newgrouppos = grouppos;
                
                if (groupname.toLowerCase() === task.group.toLowerCase())  // still in the same group
                {
                    tasks['groups'][grouppos]['items'][position] = task;
                } else {
                    // need to find the new group
                    let newgroup = -1;
                    for (let grp = 0; grp < tasks['groups']; ++grp) {
                        if (tasks['groups'][grp]['name'].toLowerCase() === task.group.toLowerCase()) // got the new group
                        {
                            newgroup = grp;
                            break;
                        }
                    }
                    if (newgroup >= 0) {
                        tasks['groups'][grouppos]['items'].splice(position, 1);
                        tasks['groups'][newgroup]['items'].push (task);
                        newgrouppos = newgroup;
                    } else 
                    {
                        // We did not find the new group - let's just keep the task where it is, the next full update will sort it out.
                        tasks['groups'][grouppos]['items'][position] = task;
                    }
                }
            }
            need_relayout = true;
            break;
        }
        
        case "Room.Info":
        {
            let prev = this.GMCP.Location;
            let loc = {};
            loc['roomname'] = gmcp_args.name;
            loc['roomexits'] = gmcp_args.exits;
            loc['id'] = gmcp_args.num;
            loc['desc'] = gmcp_args.desc;
            loc['ohmap'] = gmcp_args.ohmap;
            loc['name'] = gmcp_args.name;   // compat
            loc['exits'] = gmcp_args.exits;  // compat
            loc['num'] = gmcp_args.num;   // compat
            // gmcp_args.background and gmcp_args.linecolor are currently not used

            let coords = gmcp_args.coords.split(/,/g);
            let coords_okay = false;
            let area_id = undefined;
            let x = undefined;
            let y = undefined;
            let z = undefined;
            let building = undefined;

            if (coords && coords.length >= 4) {
                area_id = parseInt(coords[0]);
                x = coords[1];
                y = coords[2];
                z = coords[3];
                x = (x === '?') ? null : parseInt(x);
                y = (y === '?') ? null : parseInt(y);
                z = (z === '?') ? null : parseInt(z);
                building = (coords.length >= 5) ? parseInt(coords[4]) : 0;

                if (Util.is_number(area_id)) coords_okay = true;
            }
            // also include the supplied area name, in case the coords cannot be parsed
            loc['areaname'] = gmcp_args.area;
            loc['area'] = gmcp_args.area;   // compat

            loc['x'] = x;
            loc['y'] = y;
            loc['z'] = z;
            loc['b'] = building;
            loc['areaid'] = area_id;
            if (gmcp_args.explored) {
                // convert this into a mapping
                let explore = {};
                for (let idx = 0; idx < gmcp_args.explored.length; ++idx)
                    explore[gmcp_args.explored[idx]] = true;
                if (!this.GMCP.Explored) this.GMCP.Explored = {};
                if (area_id) this.GMCP.Explored[area_id] = explore;
            }
            if (area_id && this.GMCP.Explored[area_id]) {
                this.GMCP.Explored[area_id][loc.id] = true;   // The game only updates us when the area changes, so let's do this here.
                loc['explored'] = this.GMCP.Explored[area_id];
                loc.exploreChanged = true;
            }
            if (gmcp_args.specials) {
                // We got a list of special exits.
                let specials = {};
                for (let idx = 0; idx < gmcp_args.specials.length; ++idx)
                {
                    let s = gmcp_args.specials[idx];
                    let room = parseInt(s.room);
                    let e = { room: room, exit: s.exit, target: s.target };
                    if (!specials[room]) specials[room] = [];
                    specials[room].push (e);
                }
                this.GMCP.SpecialExits = specials;
            }

            this.GMCP.Location = loc;

            need_relayout = true;
            gmcp_fire_event = true;
            gmcp_event_param = loc['id'];
            break;
        }

        case "IRE.Sound.Preload":
        {
            this.on_gmcp_feature ('sound-preload', { 'name' : gmcp_args.name });
            break;
        }

        case "IRE.Sound.Play":
        {
            let fadein = false;
            let fadeout = false;
            let loop = false;

            if (typeof gmcp_args.fadein_csec != "undefined")
                fadein = gmcp_args.fadein_csec * 1000; // GMCP provides in seconds, sound lib needs milliseconds //

            if (typeof gmcp_args.fadeout_csec != "undefined")
                fadeout = gmcp_args.fadeout_csec * 1000;

            this.on_gmcp_feature ('sound-play', { 'name' : gmcp_args.name, 'fadein' : fadein, 'fadeout' : fadeout });
            break;
        }

        case "IRE.Sound.Stop":
        {
            let fadeout = false;
            if (typeof gmcp_args.fadeout_csec != "undefined")
                fadeout = gmcp_args.fadeout_csec * 1000;

            this.on_gmcp_feature ('sound-stop', { 'name' : gmcp_args.name, 'fadeout' : fadeout });
            break;
        }

        case "IRE.Sound.StopAll":
        {
            let fadeout = false;
            if (typeof gmcp_args.fadeout_csec != "undefined")
                fadeout = gmcp_args.fadeout_csec * 1000;

            this.on_gmcp_feature ('sound-stop', { 'name' : 'ALL', 'fadeout' : fadeout });
            break;
        }

        case "IRE.Target.Set":
        {
            let target = gmcp_args;
            let ntarget = parseInt(target);
            if (!isNaN(ntarget)) target = ntarget;
            this.set_current_target(target, false);

            need_relayout = true;
            gmcp_fire_event = true;
            gmcp_event_param = target;
            break;
        }

        case "IRE.Target.Request":
        {
            let tg = this.current_target();
            this.send_GMCP("IRE.Target.Set", (tg !== undefined) ? tg : 0);
            break;
        }

        case "IRE.Target.Info":
        {
            let tg = parseInt(gmcp_args.id);
            let is_player = (tg === -1);
            let cur = this.current_target();
            tg = tg.toString();
            if ((!is_player) && (tg !== cur)) return;   // nothing if the target has since changed - eliminates race conds. Bypassed for player targets.
            let desc = gmcp_args.short_desc;
            let hp = is_player ? undefined : gmcp_args.hpperc;
            this.set_current_target_info(desc, hp, is_player);
            need_relayout = true;
            break;
        }

        // used to upload the avatar
        case "IRE.Misc.OneTimePassword":
        {
            if (this._onetime_callback) this._onetime_callback (gmcp_args);
            delete this._onetime_callback;
            break;
        }
        
        case "IRE.Misc.CharCreate":
        {
            if (gmcp_args.ok) {
                // This will establish the anonymous login, if needed.
                this.on_gmcp_feature ('charname', gmcp_args);
            } else {
                this.disconnect_reason = gmcp_args.error;
                this._socket.close();
                return;
            }
            break;
        }
        default:   // nothing
            break;
        }

        // Remember that we need to report the GMCP update. We don't do this right away so as not to needlessly update multiple times per block.
        if (need_relayout) this.need_gmcp_update = true;

        this.on_gmcp_feature ('gmcp', { 'method' : gmcp_method, 'args' : gmcp_args, 'fire_event' : gmcp_fire_event, 'event_param' : gmcp_event_param });
    }
    
    gmcp_popup_dontshow(id) {
        this.send_GMCP("IRE.Display.NoShowPopup", id);
    }

    request_old_system(age)
    {
        let file = "html5-reflexes";
        if (age) file += "-" + age;
        this.send_GMCP("IRE.FileStore.Request", {"request" : "get " + file + " raw"});
    }

    // not used anymore
    request_file_list()
    {
        this.send_GMCP("IRE.FileStore.Request", {"request" : "list"});
    }

    request_onetime_password(callback) {
        let t = this;
        t._onetime_callback = callback;
        t.send_GMCP("IRE.Misc.OneTimePassword","");
    }
    
    request_skill(skillname, name)
    {
        var params = { 'group' : skillname };
        if (name) {
            params['name'] = name;
            this.GMCP.SkillInfo.Desc = null;
        } else
            this.GMCP.SkillInfo.List = null;
        this.send_GMCP("Char.Skills.Get", params);
    }

    // We get this automatically on login, but just in case ...
    request_news_sections() {
        this.send_GMCP("IRE.News.Sections", '');
    }

    request_news(section, id) {
        let params = { 'section' : section, 'id' : id };
        this.GMCP.NewsList.Article = null;
        this.send_GMCP("IRE.News.Get", params);
    }

    request_news_section(section, fromID, toID) {
        let params = { 'section' : section, 'from' : fromID, 'to' : toID };
        this.GMCP.NewsList.RequestedSection = section;
        this.GMCP.NewsList.RequestedFromID = fromID;
        this.GMCP.NewsList.RequestedToID = toID;
        this.send_GMCP("IRE.News.List", params);
    }

    // called when pressing TAB, advances to the next target
    tab_target(players) {
        let targets;
        if (players)
            targets = this.get_item_list('players');
        else
            targets = this.get_item_list('room', 'm', 'x');
        if (!targets.length) {
            this._nexus.display_notice("There are no suitable targets here.", 'red');
            return;
        }
        if (targets.length === 1) {
            this.set_current_target(targets[0].id, true);
            return;
        }
        let curidx = -1;
        for (let i = 0; i < targets.length; ++i) {
            if (targets[i].id === parseInt(this.current_target())) {
                curidx = i;
                break;
            }
        }
        curidx += 1;  // advance to the next target. If we haven't found our current one, we move to the first one.
        if (curidx >= targets.length) curidx = 0;
        this.set_current_target(targets[curidx].id, true);
    }

}
