// movement compass

import { Util } from '../base/util.js'

import React from 'react'

export class Compass extends React.Component {
    render() {
        let exits = this.props.exits;
        if (!exits) exits = {};
        var dirs = Util.directions();

        let buttons = [];
        for (var i = 0; i < dirs.length; ++i)
        {
            let dir = dirs[i];
            if (dir === 'i') dir = 'in';
            if (dir === 'o') dir = 'out';
            let enabled = exits[dir] ? true : false;
            let pic = this._render_button(dir, enabled);
            buttons.push (pic);
        }
        let style = this.props.style;
        if (!style) style = {};
        return (<div style={style} className="movement_buttons">{buttons}</div>);
    }
    
    _render_button(dir, enabled) {
        let classes = ['movement_button', 'movement_button_' + dir];
        if (dir !== 'l') classes.push ('movement_button_dir');
        if (enabled) classes.push ('movement_enabled');

        let fulldir = Util.get_full_direction_name(dir);
        if (enabled) fulldir += '2';
        let src = '/images/compass/' + fulldir + '.png';

        let cmd = dir;
        if (cmd === 'i') cmd = 'in';
        if (cmd === 'o') cmd = 'out';
        if (cmd === 'l') cmd = 'look';
        let fn = enabled ? (() => this.props.oncommand(cmd)) : null;
        return (<img alt='' key={'compass_'+cmd} className={classes.join(' ')} style={{pointerEvents:'all'}} src={src} onClick={fn} />);
    }
}
