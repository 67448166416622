// the output displaying component

import { Tab } from './tab.js'
import { Compass } from '../compass.js'

import React from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VList } from "virtua";

class OutputLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let line = this.props.line;

        this.lastScrollback = this.props.scrollbackMode;
        this.lastSettingsId = this.props.settingsId;
        this.isLastPrompt = this.props.isLastPrompt;

        // show a timestamp?
        let show_ts = false;
        if (this.props.settings.show_timestamps) show_ts = true;
        if (this.props.scrollbackMode && this.props.settings.show_scroll_timestamps)
            show_ts = true;

        let timestamp = '';
        if (show_ts) {
            let cl = 'timestamp mono no_out';
            timestamp = line.timestamp_ms;
            timestamp = timestamp ? "<span class=\"" + cl + "\">" + timestamp + "&nbsp;</span>" : '';
        }

        let txt = line.parsed_line;
        let lineData = txt ? txt.formatted() : '';
        if (line.html_line) lineData = line.html_text;
        lineData = timestamp + lineData;
        let lineclass = 'textline ' + (line.monospace ? 'mono' : '');
        // prompt?
        let shown = true;
        if (line.is_prompt) {
            lineclass = 'prompt ' + lineclass;
            if (this.props.channel) return null;   // no prompts in channels
            shown = false;
            if (this.props.settings.show_prompts) shown = true;
            if (this.props.settings.show_last_prompt && this.props.isLastPrompt) shown = true;
        }

        let lines = [];
        if (shown) lines.push ((<div aria-live='polite' className={lineclass} dangerouslySetInnerHTML={{__html: lineData }} onClick={(e) => this.props.onClick(e, line.id)}></div>));

        let mxpMenu = this.props.mxpMenu;
        if (mxpMenu) {
            let commands = mxpMenu['commands'];
            let entries = [];
            for (let i = 0; i < commands.length; ++i) {
                let cmd = commands[i];
                entries.push ((<MenuItem onClick = { (e) => (mxpMenu['onclick'])(e,cmd) }>{cmd}</MenuItem>));
            }
            let menu = (<Menu id="mxpMenu" open={true} anchorEl={mxpMenu['anchor']} onClose={ mxpMenu['onclose'] }>{entries}</Menu>);
            lines.push (menu);
        }

        if (this.props.extraBreak) lines.push((<br key={'break'} />));
        if (!lines.length) return null;
        let res = (<div style={this.props.style} key={this.props.passKey}>{lines}</div>);
        return res;
    }
}

export class TabOutput extends Tab {
    constructor(props) {
        super(props);
        this.state = { 'scrollbackMode' : false, 'mxpKey' : null };
        props.buffer.output = this;

        // define lambdas here to prevent their re-creation on every render
        this.scrollUpFn = (()=>this.scrollback_up());
        this.scrollDownFn = (()=>this.scrollback_down());
        this.onScrollFn = ((e) => this.onScroll(e));
        this.onScrollEndFn = ((e) => this.onScrollEnd(e));
        this.onScrollUpFn = ((e) => this.onScrollMouseUp(e));
        this.lineClickFn = ((e, lineKey) => this.mxpLink(e, lineKey));
        this.onHideScrollFn = (()=>this.hide_scrollback());

        this.output = React.createRef();
        this.scrollback = React.createRef();
        this.currentScroll = 0;
        this.scrolling = false;

        this.lastLine = 0;
        this.extraLines = {};
        this.rowRenderer = (_, i) => { return this.renderLine(i)};
    }

    componentDidMount() {
        if ((!this.props.channel) && this.props.nexus) {
            this.props.nexus.platform().outputRef = this.output;
            this.props.nexus.platform().outputComponent = this;
        }

        this.scrollToEnd();
        if (this.scrollback.current) this.scrollback.current.scrollToIndex(this.lastLineCount - 1, { align: "end" });
        this.scrollToBottom();
    }

    componentWillUnmount() {
        if ((!this.props.channel) && this.props.nexus) {
            this.props.nexus.platform().outputRef = null;
            this.props.nexus.platform().outputComponent = null;
        }
    }

    id() {
        if (!this.props.channel) return 'output_main';
        return 'channel_' + this.props.channel;
    }

    componentDidUpdate() {
        if (this.scrollback.current) this.scrollback.current.scrollToIndex(this.lastLineCount - 1, { align: "end" });

        if (this.state.scrollbackMode) return;
        this.scrollToBottom();
    }

    updated() {
        let t = this;
        if (this.state.scrollbackMode) return;
        t.scrollToBottom();
        this.setState({'date' : (new Date()).getTime()});   // forces an update
    }

    scrollTo(pos) {
        if (pos < 0) pos = 0;
        if (this.output.current) this.output.current.scrollTo(pos);
    }

    scrollToEnd() {
        if (this.output.current) this.output.current.scrollToIndex(this.lastLineCount - 1, { align: "end" });
    }

    show_scrollback() {
        if (this.state.scrollbackMode) return;
        //if (this.updating) return;
        this.scrollTo(this.currentScroll - 20);
        this.setState ({'scrollbackMode' : true});
    }

    hide_scrollback() {
        this.scrollToEnd();

        if (!this.state.scrollbackMode) return;
        this.setState ({'scrollbackMode' : false});
    }

    scrollBy(by) {
        let to = this.currentScroll + by;
        this.scrollTo(to);
    }

    scrollback_up() {
        let nex = this.props.nexus;
        nex.platform().scroll_resize(5);
    }

    scrollback_down() {
        let nex = this.props.nexus;
        nex.platform().scroll_resize(-5);
    }

    viewportHeight() {
        let el = this.output.current;
        if (!el) return 0;
        return el.viewportSize;
    }

    onScroll(cur) {
        let limit = 1;
        if (this.state.scrollbackMode) limit = 5;  // this makes the client "snap" out of scrollback when scrolling close enough

        if (cur < 0) cur = 0;   // initial connection sometimes has this negative and then scrollback opens when it should not
        this.scrolling = true;
        let hide = false;
        let el = this.output.current;
        this.currentScroll = cur;
        if (el && (cur + el.viewportSize + limit >= el.scrollSize)) hide = true;   // seems there are some imperfections in rounding on chrome, so giving this some leeway
        if (this.newData) hide = true;
        if (hide)
            this.hide_scrollback();
        else
            this.show_scrollback();
    }

    onScrollEnd() {
        this.scrolling = false;
    }

    onScrollMouseUp(e) {
        if (this.props.settings.copy_on_mouseup) document.execCommand ("Copy")
    }

    scrollToBottom() {
        this.hide_scrollback();
    }

    renderLine(i) {
        let t = this;
        let buffer = this.props.buffer;
        let sett = this.props.settings;
        let nex = this.props.nexus;

        let line = buffer.get_line_by_idx(i);

        let lineKey = line.id;
        let isLastPrompt = (this.last_prompt === i);
        let extraBreak = (sett.extra_break && line.is_prompt && this.extraLines[i] && (!isLastPrompt));
        let mxpMenu = null;
        if (this.state.mxpKey === lineKey) {
            mxpMenu = { 'commands' : this.state.mxpMenu, 'anchor' : this.state.mxpAnchor, 'onclick' : ((e,cmd) => t.mxpMenuCommand(cmd)), 'onclose' : ((e) => t.mxpMenuClose()) };
        }

        let renderLine = (<OutputLine line={line} onClick={this.lineClickFn} settings={sett} channel={this.props.channel} key={lineKey} passKey={lineKey} scrollbackMode={this.state.scrollbackMode} settingsId={nex.settingsId} isLastPrompt={isLastPrompt} extraBreak={extraBreak} style={null} mxpMenu={mxpMenu} /> );
        return renderLine;
    }

    render() {
        let t = this;
        // individual output lines
        let lines = [];
        let buffer = this.props.buffer;
        let sett = this.props.settings;
        let nex = this.props.nexus;
        this.buffer = buffer;
        this.settings = sett;
        let lineCount = buffer.count();

        // find the last prompt
        this.last_prompt = -1;
        for (let i = lineCount - 1; i >= 0; --i) {
            let line = buffer.get_line_by_idx(i);
            if (line.is_prompt) {
                this.last_prompt = i;
                break;
            }
        }

        // figure out extra lines
        let lines_since_prompt = 0;
        for (let i = this.lastLine + 1; i < lineCount; ++i)
        {
            let line = buffer.get_line_by_idx(i);
            if (line.is_prompt) {
                if (lines_since_prompt > 0) this.extraLines[i] = true;
                lines_since_prompt = 0;
            }
            else lines_since_prompt++;
        }

        let bgcolor = sett.reverted ? '#ffffff' : '#000000';
        let cleft = sett.compass_left;
        let outputStyle = {overflowX: 'hidden', overflowY: 'scroll', backgroundColor: bgcolor, paddingLeft: '1px' };
        let scrollStyle = { overflow: 'hidden', backgroundColor: bgcolor, display: this.state.scrollbackMode ? 'block' : 'none', pointerEvents: 'none', width: '100%', height: '100%' };

        let output = (<VList key='output' ref={this.output} style={outputStyle} onScroll={this.onScrollFn} onScrollEnd={this.onScrollEndFn} className="h100 output output_container" overscan={10} >{Array.from({length:lineCount}).map(this.rowRenderer)}</VList>);

        let hidestyle = {'gridRowStart':3,'gridRowEnd':4,'gridColumnStart':2,'gridColumnEnd':3,right:0,bottom:44,display:'block',height:'40px',width:'150px',marginLeft:'auto',position:'relative',pointerEvents: 'all',textAlign:'right'};
        let scrollbtns = null;
        if (this.state.scrollbackMode) {
            let hidebtn = (<Button variant='contained' onClick={this.onHideScrollFn} style={{width:'80px'}}>Hide</Button>);
            let up = (<FontAwesomeIcon icon={['fad', 'arrow-up-from-bracket']} />);
            let down = (<FontAwesomeIcon icon={['fad', 'arrow-down-to-bracket']} />);
            let updownstyle = {width:'32px',minWidth:'32px',paddingLeft:0,paddingRight:0,height:'36px'};
            let upbtn = (<Button variant='contained' onClick={this.scrollUpFn} style={updownstyle}>{up}</Button>);
            let downbtn = (<Button variant='contained' onClick={this.scrollDownFn} style={updownstyle}>{down}</Button>);
            scrollbtns = (<div style={hidestyle}>{upbtn}{downbtn}{hidebtn}</div>);;
        }
        let scrollback = (<VList key='wrapper' ref={this.scrollback} style={scrollStyle} className="h100 output output_scrollback">{Array.from({length:lineCount}).map(this.rowRenderer)}</VList>);

        let compassHeight = '0px';
        let compass = '';
        if (this.props.nexus.ui().want_compass() && (!this.props.channel))  // compass is for the main display only
        {
            let exits = this.props.gmcp.Location.roomexits;
            compass = (<Compass style={{'gridRowStart':1,'gridRowEnd':2,'gridColumnStart':cleft?1:2,'gridColumnEnd':cleft?2:3}} exits={exits} oncommand={(cmd) => this.props.oncommand(cmd, false)} />);
            compassHeight = '150px';
        }

        let scrollbackHeight = sett.scrollback_height + '%';
        let upperStyle = {'display':'grid', width:'calc(100% - 15px)', height:'100%', 'gridTemplateRows' : compassHeight+' auto '+scrollbackHeight, 'gridTemplateColumns' : cleft?'150px auto':'auto 150px'};
        let upperGridStyle = {'gridRowStart':3,'gridRowEnd':4,'gridColumnStart':1,'gridColumnEnd':3,position:'relative'};
        let overlayStyle = {position:'absolute', width:'100%', height:'100%', left:0, top:0, pointerEvents : 'none', zIndex : 1};
        let upper = (<div style={upperStyle} ><div style={upperGridStyle} >{scrollback}</div>{compass}{scrollbtns}</div>);
        let overlay = (<div style={overlayStyle}>{upper}</div>);

        // This ensures that we scroll to bottom on new content, but only if we aren't scrolled up
        if (this.output.current && (!this.state.scrollbackMode)) {
            this.scrollToBottom();
            
            // For 100ms after receiving new data, all scrolling shifts to the bottom and other events are ignored.
            // Solves problems with chrome's smooth scrolling throwing us into scrollback.
            if (this.dataTimer)
                nex.platform().clear_timeout(this.dataTimer);
            this.dataTimer = nex.platform().set_timeout (() => { t.newData = false; t.dataTimer = null; }, 100);
            t.newData = true;
        }
            
        this.lastLineCount = lineCount;
        if (this.scrollback.current) this.scrollback.current.scrollToIndex(this.lastLineCount - 1, { align: "end" });

        let style = { position:'relative' };
        // fonts
        let font = sett.get_font_family();
        let fontsize = sett.font_size;
        style.fontFamily = font;
        style.fontSize = fontsize;

        return (<div key="mainoutput" className="h100" onMouseDown={(e)=>this.onMouseDown(e)} onMouseUp={(e)=>this.onMouseUp(e)} style={style}>{output}{overlay}</div>);
    }

    onMouseDown(e) {
        let t = this;
        if (e.button !== 0) return;
        t.cx = e.clientX;
        t.cy = e.clientY;
    }
    
    onMouseUp(e) {
        let t = this;
        t.cx = -1;
        t.cy = -1;
    }

    mxpLink (e, lineKey)
    {
        let link = e.target.closest('a');
        if(!link) return;
        if (link.classList.contains ('url_link')) return;   // this is a regular link

        e.preventDefault();

        let command = link.rel;
        if (!command.length) return;

        let isprompt = link.classList.contains ('mxp_prompt');
        let split = command.split(/\|/g);
        if (split.length === 1)
        {
            this.props.oncommand (command, isprompt);
            return;
        }

        this.setState ({mxpMenu : split, mxpKey : lineKey, mxpAnchor: link});
    }

    mxpMenuCommand(cmd) {
        this.props.oncommand (cmd, false);
        this.mxpMenuClose();
    }
    
    mxpMenuClose() {
        this.setState ({mxpMenu : null, mxpKey : null});
    }


}

