
export class PasswordsBase {
    constructor(nexus) {
        this.nexus = nexus;
    }

    // Turn the IDs into a storage key.
    genkey(gid, cname, type) {
        if (gid && cname) {  // this is for a character
            return 'char-'+gid+'-'+cname.toLowerCase();
        }
        // Otherwise it's the login name + password
        return 'login-' + type;
    }

    fetch(gid, cname, type) {
        let t = this;
        const handler = (resolve, reject) => {
            let key = t.genkey(gid, cname, type);
            t.do_fetch(key, resolve, reject);
        };
        return new Promise (handler);
    }

    clear(gid, cname, type) {
        return this.store(gid, cname, type, null);
    }

    store(gid, cname, type, password) {
        let t = this;
        const handler = (resolve, reject) => {
            let key = t.genkey(gid, cname, type);
            t.do_store(key, password, resolve, reject);
        };
        return new Promise (handler);
    }

    
    // These need to be overridden
    do_fetch(key, resolve, reject) { reject(null); }
    do_store(key, password, resolve, reject) { reject(null); }

};
