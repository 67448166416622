
import { Tab } from './tab.js'

export class TabAffDef_Base extends Tab {

    constructor(props) {
        super(props);
    }

    game() { 
        return this.props.gameinfo.game_short();
    }


    aff_shown(aff)
    {
        return true;
    }

    def_categories() {
        let gmcp = this.props.gmcp;
        let definfo = gmcp.DefencesList;
        let cats = [];
        for (let defname in definfo) {
            if (!definfo.hasOwnProperty(defname)) continue;
            let cat = definfo[defname]['category'];
            if (!cats.includes(cat))
                cats.push(cat);
        }
        return cats;
    }

    shown_defs_in_category(category) {
        let gmcp = this.props.gmcp;
        let definfo = gmcp.DefencesList;
        let active_defs = gmcp.Defences;

        let list = {};
        let count = 0;
        for (let defname in definfo) {
            if (!definfo.hasOwnProperty(defname)) continue;
            let d = definfo[defname];
            let cat = d['category'];
            if (cat != category) continue;
            let active = active_defs[defname] ? true : false;
            if (active || d['important']) {
                list[defname] = d;
                count++;
            }
        }
        if (!count) list = null;
        return list;
    }

}
