import { TabNews_Base } from './news_base'

import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class TabNews extends TabNews_Base {

    constructor(props) {
        super(props);
    }

    renderSection(s) {
        let t = this;
        let gmcp = this.props.gmcp;
        let data = gmcp.NewsList;
        if (data.Section != s) return null;   // Not our data.
        let lst = data.List;
        let fromID = data.FromID;
        let toID = data.ToID;

        let bst = { 'flex' : 1 };
        let buttons = [];
        let btnBack = (<Button key='btnBack' variant='contained' style={bst} onClick={()=>t.backClick()}>Back</Button>);
        let btnPrev = (<Button key='btnPrev' variant='contained' style={bst} onClick={()=>t.previousClick()}>Previous</Button>);
        let btnNext = (<Button key='btnNext' variant='contained' style={bst} onClick={()=>t.nextClick()}>Next</Button>);
        let btnLast = (<Button key='btnLast' variant='contained' style={bst} onClick={()=>t.lastClick()}>Last</Button>);
        buttons.push(btnBack);
        buttons.push(btnPrev);
        buttons.push(btnNext);
        buttons.push(btnLast);

        let entries = [];
        for (let i = lst.length - 1; i >= 0; --i) {
            let id = fromID + i;
            let date = lst[i].date;
            let author = lst[i].author;
            let receiver = lst[i].receiver;
            let title = lst[i].title;

            let icon_name = 'circle-caret-right';
            let icon = (<FontAwesomeIcon icon={['fad', icon_name]} style={{height:'20px', width:'20px', paddingRight:'5px'}} />);

            let text = '(' + id + '): ' + title;
            let keyname = 'newsarticle-'+id;
            let li = (<ListItem dense key={keyname} button style={{cursor:'pointer'}} onClick={() => this.entryClick(id)}>{icon}<ListItemText primary={text} className='highlightText' /></ListItem>)
            entries.push (li);
        }
        

        return (
            <Box style={this.props.style}>
                <div style={{display: 'flex', flexDirection: 'row'}}>{buttons}</div>
                <h4>{s + ' News: ' + fromID + '-' + toID}</h4>
                <List component="nav" aria-label="News Entries">
                    {entries}
                </List>
            </Box>
        );        
    }

    render() {
        let gmcp = this.props.gmcp;
        let sections = gmcp.NewsSections;
        if (!sections.length) return (<div>There are no news.</div>);

        if (this.state.selsection) {  // We have a section.
            let res = this.renderSection(this.state.selsection);
            if (res) return res;            
        }

        let entries = [];
        for (let i = 0; i < sections.length; ++i) {
            let name = sections[i].name;
            let count = sections[i].count;
            let lastread = sections[i].lastread;

            let icon_name = 'circle-caret-right';
            let icon = (<FontAwesomeIcon icon={['fad', icon_name]} style={{fontSize:'1.4em', paddingRight:'5px'}} />);

            let text = name + ' (' + lastread + '/' + count + ')';
            let keyname = 'newsgroup-'+name+'-'+i;
            let li = (<ListItem dense key={keyname} button style={{cursor:'pointer'}} onClick={() => this.sectionClick(name)}>{icon}<ListItemText primary={text} className='highlightText' /></ListItem>)
            entries.push (li);
        }
        
        return (
            <Box style={this.props.style}>
                <List component="nav" aria-label="News Sections">
                    {entries}
                </List>
            </Box>
        );
    }
    
}
 
