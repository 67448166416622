// the avatar component

import { AvatarBase } from './avatar_base'

import React from 'react'
import Popper from '@mui/material/Popper';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material';

import { TabPanel } from './tabpanel';

import CancelIcon from '@mui/icons-material/Cancel';

export class RealAvatar extends AvatarBase {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    renderAvatar() {
        let src = this.state.avatar;
        if (!src) src = '/images/sample_avatar.jpg';
        let style = { height: '100%', width: '100%', border: '1px #555 solid', borderRadius: '3px', cursor: 'pointer' };
        return (<img style={style} alt='avatar' src={src} onClick={()=>this.toggleDialog()} />);
    }

    renderTabPick() {
        let gi = this.props.gameinfo;
        let lst = [];
        for (let id = 1; id <= gi.avatar_count(); ++id) {
            let aid = id;
            let src = 'https://www.' + gi.server_name() + '/local/nexus_data/avatars/avatar' + aid + '.jpg';
            let img = (<img className='avatar_choose_one' alt='pick' key={'avatar-'+aid} src={src} onClick={()=>this.avatarPick(aid)} />);
            lst.push (img);
        }
        let choices = (<div id='avatar_choices'>{lst}</div>);
        return choices;
    }


    renderTabUpload() {
        let uploader = this.getUploader();
        return (<div id='avatar_tab_upload'>{uploader}</div>);
    }

    renderDialogContent() {
        let tablist = [];
        tablist.push ((<Tab key={'tab_1'} label='Pick' id={'simple-tab-1'} />));
        tablist.push ((<Tab key={'tab_2'} label='Upload' id={'simple-tab-2'} />));
        let tabpanels = [];
        let tab1 = this.renderTabPick ();
        let tab2 = this.renderTabUpload ();
        tabpanels.push ((<TabPanel key={'tabpanel_1'} value={this.state.opentab} index={0}>{tab1}</TabPanel>));
        tabpanels.push ((<TabPanel key={'tabpanel_2'} value={this.state.opentab} index={1}>{tab2}</TabPanel>));
        let res = [
            (<AppBar key='appbar' position="static" color="primary">
                <Tabs value={this.state.opentab} onChange={(ev, val) => this.handleTabChange(val)} aria-label="tabs">
                    {tablist}
                </Tabs>
            </AppBar>),
            (<div key='panel' className='dialogPanel'>{tabpanels}</div>)
        ];
        return res;
    }

    renderDialog() {
        if (!this.state.dialog) return null;

        // TODO: pull this out to some shared dialog class?
        let theme = this.props.theme;
        let h = 400;
        let w = 610;
        let fontsize = this.props.settings.calc_fontsize();
        let style = {backgroundColor: theme.palette.background.default, width: w+'px', height: h+'px', position:'relative', fontSize:fontsize};
        let hstyle = {};
        let cstyle = { position:'absolute', top:0, right:0 };
        let instyle = { display: 'flex', flexDirection: 'column', backgroundColor: theme.palette.background.default};

        let closeButton = (<IconButton aria-label="close" size='small' style={cstyle} onClick={() => this.toggleDialog()} ><CancelIcon /></IconButton>);
        let content = this.renderDialogContent();
        content = (<div className='avatarContent nexusDialogContent' style={instyle}>{content}</div>);
        content = (<div style={style} className='avatarDialog nexusDialog'><div style={hstyle} className='handle avatarHeader nexusDialogHeader'>Select a New Avatar{closeButton}</div>{content}</div>);
        let dlg = (<Popper aria-labelledby="avatar-dialog" anchorEl={this.ref.current} open={this.state.dialog} placement='top-end' >{content}</Popper>);
        return dlg;

    }

    render() {
        let avatar = this.renderAvatar();
        let dialog = this.renderDialog();
        let sz = this.props.size;
        let style = { width: sz, height: sz, marginBottom: '3px' };
        return (<div ref={this.ref} style={style}>{avatar}{dialog}</div>);
    }
    
}

export function Avatar(props) {
  const theme = useTheme();
  return <RealAvatar {...props} theme={theme} />;
}
