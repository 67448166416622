import { Util } from '../base/util.js'

export class NameGen {
  static generate() {
    var vv = ["a", "ae", "ai", "au", "e", "ea", "ei", "eo", "i", "ia", "io", "o", "oa", "oi", "ou", "u", "ua", "ui"];
    var vv2 = ["acia", "ada", "adi", "adra", "aeda", "aede", "aedra", "aera", "aeri", "aeta", "aida", "aira", "ala", "ali", "alla", "ama", "amo", "ana", "ani", "anna", "anne", "ano", "ara", "ari", "aro", "asa", "asi", "aso", "assa", "asse", "asso", "atta", "ava", "aya", "ayo", "eda", "edda", "edo", "edra", "ela", "eli", "ella", "elo", "emma", "ena", "enna", "era", "eri", "ero", "essa", "esse", "esso", "etta", "etto", "eva", "eve", "iana", "iano", "iara", "iaro", "idra", "illa", "illi", "ima", "ina", "indi", "ino", "ira", "iri", "iro", "isa", "issa", "isse", "itta", "itte", "itto", "odra", "oma", "orda", "ori", "osa", "osse", "otta", "ulla", "ullo", "una", "une", "uno", "ura", "uri", "uro"];
    var vc = ["ac", "ad", "aed", "ael", "aen", "aer", "aes", "aet", "aev", "af", "ag", "aid", "aig", "ail", "ain", "air", "ais", "aiv", "al", "all", "am", "an", "ann", "ar", "arn", "as", "at", "aun", "aur", "aus", "aut", "auv", "av", "eac", "ead", "eal", "ean", "ear", "eas", "eat", "ec", "ed", "eic", "eid", "eil", "ein", "eir", "eis", "eit", "el", "ell", "en", "eol", "eon", "eor", "eos", "eot", "er", "es", "est", "et", "iac", "iad", "ian", "iar", "ias", "iat", "ic", "id", "iel", "ien", "ier", "ies", "iet", "ig", "il", "im", "in", "iod", "iol", "ion", "ior", "ios", "ir", "is", "iun", "ius", "oc", "od", "ol", "oll", "on", "orn", "os", "oud", "oul", "oun", "ourn", "ous", "ov", "uan", "uil", "uin", "ul", "un", "ur", "us", "yal", "yan", "yar", "yel", "yen", "yil", "yin", "yir"];
    var cc = ["b", "ban", "bar", "ben", "ber", "bin", "bir", "bon", "bor", "bur", "byn", "c", "cal", "call", "can", "cas", "cass", "cat", "cel", "cen", "ces", "cet", "cian", "ciel", "cien", "cil", "cill", "cin", "cis", "col", "coll", "con", "cos", "coss", "cot", "cull", "cus", "cut", "cyl", "cyll", "cyn", "d", "dal", "dan", "dar", "del", "den", "der", "des", "diar", "diel", "dil", "din", "dir", "dol", "don", "dor", "dos", "dul", "dun", "dur", "dyn", "dys", "f", "fal", "fan", "far", "fel", "fen", "fer", "fil", "fin", "fir", "fon", "for", "fur", "fyr", "j", "jac", "jad", "jak", "jal", "jan", "jas", "jass", "jec", "jed", "jel", "jen", "jes", "jess", "jic", "jid", "jil", "jin", "jis", "joc", "jod", "jol", "jon", "jos", "joss", "l", "laen", "lan", "lar", "las", "len", "ler", "les", "lial", "lian", "liel", "lien", "lin", "liol", "lion", "lir", "lis", "lon", "lor", "los", "lus", "lyn", "lynn", "lyr", "lys", "m", "mal", "man", "mar", "mel", "men", "mer", "mil", "mill", "min", "mir", "mor", "n", "nael", "nald", "nan", "nel", "nian", "niel", "nin", "nor", "r", "ral", "ram", "ran", "ras", "rel", "ren", "rial", "rian", "riel", "rien", "rin", "ris", "rit", "roh", "rom", "ron", "ros", "ruel", "ryn", "rys", "s", "san", "sel", "sen", "shan", "shann", "shen", "shir", "sian", "sil", "sin", "sios", "sis", "sit", "skan", "skar", "sold", "sor", "stan", "stel", "sten", "styn", "sur", "syl", "sylv", "syr", "t", "tan", "tar", "tas", "ten", "ter", "tes", "tian", "tias", "tien", "tin", "tir", "tis", "trin", "tris", "trix", "tryn", "trys", "tyn", "tys", "v", "van", "vas", "vian", "vias", "vin", "vir", "von", "vyn", "vyr", "wil", "win", "wyn", "yal", "yan", "yar", "yel", "yen", "yil", "yin", "yir"];
    var cv = ["ba", "bai", "bao", "be", "bea", "bei", "bi", "bia", "bla", "ble", "bli", "blo", "bo", "boa", "bra", "bre", "brea", "bri", "bria", "bro", "bru", "brua", "bry", "bu", "by", "ca", "cae", "cai", "cao", "ce", "cei", "cha", "chai", "che", "chi", "chia", "cho", "chri", "ci", "cia", "cie", "cio", "cle", "clea", "cleo", "clia", "clio", "co", "cra", "cre", "crea", "cri", "cria", "crio", "cu", "cy", "da", "dae", "dai", "dau", "de", "dea", "dei", "di", "dia", "dio", "dra", "dre", "dri", "dro", "dru", "du", "dwa", "dwi", "dwo", "dy", "dya", "fa", "fae", "fai", "fe", "fei", "fi", "fia", "fio", "fla", "fli", "flo", "fra", "fri", "fru", "fu", "fy", "ga", "gae", "gai", "ge", "gei", "gi", "gia", "gio", "gla", "gle", "gli", "glo", "gra", "gre", "gri", "gwe", "gwi", "gwy", "ha", "hai", "he", "hei", "ho", "hwi", "hy", "ja", "jae", "jai", "je", "ji", "jo", "ju", "ka", "kae", "kai", "ke", "kei", "ki", "ko", "kri", "kry", "ku", "kui", "ky", "kya", "la", "lae", "lai", "le", "lei", "li", "lia", "lo", "ly", "lya", "ma", "mae", "mai", "me", "mee", "mei", "mi", "mia", "mio", "mo", "mu", "my", "na", "nae", "nai", "ne", "ni", "nia", "nio", "no", "nu", "ny", "pa", "pae", "pai", "pao", "pe", "pei", "pha", "phe", "phi", "pho", "phy", "pi", "pia", "pie", "py", "qua", "que", "qui", "quia", "quo", "ra", "rae", "rai", "re", "rei", "rhi", "rhi", "rhy", "ri", "ria", "ro", "rou", "ru", "ry", "sa", "sae", "sai", "se", "sei", "sey", "sha", "shae", "shi", "sho", "shu", "si", "sio", "ska", "ske", "sko", "sla", "slai", "so", "sou", "sri", "su", "sva", "sve", "svo", "sy", "sya", "ta", "tae", "tai", "te", "tei", "ti", "tia", "to", "tra", "tre", "tri", "tro", "tsa", "tsi", "twi", "ty", "va", "vae", "vai", "ve", "vi", "vo", "vu", "vy", "wa", "wai", "wi", "wo", "wri", "wu", "wy", "y", "ya", "ye", "yi", "yo", "ysa", "yu", "za", "zae", "zai", "ze", "zei", "zi", "zia", "zo", "zu", "zy"];
    var cv2 = ["bella", "beta", "betta", "calla", "calli", "cara", "cari", "caro", "cella", "cello", "cena", "chelle", "cina", "dali", "dana", "dara", "dari", "dela", "della", "deva", "devi", "diana", "fabi", "fila", "filo", "fina", "gani", "glori", "hali", "halla", "hana", "hani", "heli", "helia", "helio", "jana", "jane", "jani", "jenna", "kala", "kara", "kesi", "kyla", "kyri", "lasse", "lili", "lina", "line", "lira", "mari", "meli", "meri", "mira", "miri", "rani", "rielle", "rina", "rine", "rissa", "sani", "senna", "stelle", "stiana", "synne", "tani", "tiano", "tina", "tli", "tra", "tynne", "vina", "vivi", "vona", "vynne", "wenna", "wynne"];
    var cx = ["lden", "ldor", "ldra", "lnar", "lni", "lnor", "lnys", "ndar", "ndara", "ndir", "ndira", "ndor", "ndora", "ndra", "ndri", "nifer", "nna", "nnan", "nnis", "rnan", "rni", "rnis", "rnon", "rnys", "rra", "rran", "rris", "ssa", "ssan", "tlina", "ttan", "ttas"];

    var name;
    switch (Math.floor((Math.random() * 17) + 1)) {
        case 1:
            name = Util.random_element(vv);
            name += Util.random_element(cc);
            break;
        case 2:
            name = Util.random_element(vv);
            name += Util.random_element(cv);
            break;
        case 3:
            name = Util.random_element(vv);
            name += Util.random_element(cv2);
            break;
        case 4:
            name = Util.random_element(vv);
            name += Util.random_element(cv);
            break;
        case 5:
            name = Util.random_element(vc);
            name += Util.random_element(vv);
            break;
        case 6:
            name = Util.random_element(cc);
            name += Util.random_element(vv);
            break;
        case 7:
            name = Util.random_element(vc);
            name += Util.random_element(vc);
            break;
        case 8:
            name = Util.random_element(vc);
            name += Util.random_element(vv2);
            break;
        case 9:
            name = Util.random_element(cc);
            name += Util.random_element(vc);
            break;
        case 10:
            name = Util.random_element(cc);
            name += Util.random_element(vv2);
            break;
        case 11:
            name = Util.random_element(cc);
            name += Util.random_element(cv);
            break;
        case 12:
            name = Util.random_element(cv);
            name += Util.random_element(cv2);
            break;
        case 13:
            name = Util.random_element(cv);
            name += Util.random_element(cx);
            break;
        case 14:
            name = Util.random_element(cv);
            name += Util.random_element(cv);
            break;
        case 15:
            name = Util.random_element(vv2);
            name += Util.random_element(cv2);
            break;
        case 16:
            name = Util.random_element(vv2);
            name += Util.random_element(cx);
            break;
        case 17:
            name = Util.random_element(cv2);
            name += Util.random_element(cx);
            break;
        default:
            break;
    }

    return Util.ucfirst(name);
  }
}

