
import { Reflex } from './reflex.js'
import { ActionList } from './actions.js'

export class ReflexAlias extends Reflex {
    constructor(name, nexus) {
        super(name, nexus);
        this.type = 'alias';
        this.text = '';
        this.matching = 'begins';
        this.whole_words = true;
        this.case_sensitive = true;
        this.prefix_suffix = true;
        this.actions = [];
    }

    encode() {
        var res = super.encode();
        res.text = this.text;
        res.matching = this.matching;
        res.whole_words = this.whole_words;
        res.case_sensitive = this.case_sensitive;
        res.prefix_suffix = this.prefix_suffix;
        return res;
    }

    apply (e, reflexes) {
        if (e['type'] !== this.type) return;  // sanity check
        super.apply (e, reflexes);
        this.text = e.text;
        this.matching = e.matching;
        this.whole_words = e.whole_words;
        this.case_sensitive = e.case_sensitive;
        this.prefix_suffix = e.prefix_suffix;
    }

    duplicate() {
        var res = new ReflexAlias(this.name, this._nexus);
        res.text = this.text;
        res.matching = this.matching;
        res.whole_words = this.whole_words;
        res.case_sensitive = this.case_sensitive;
        res.prefix_suffix = this.prefix_suffix;
        res.actions = this.copy_actions();
        return res;
    }

    matches(txt) {
        if ((!txt) || (txt.length === 0)) return true;

        txt = txt.toLowerCase();

        if (this.text && this.text.toLowerCase().indexOf(txt) >= 0) return true;

        return super.matches(txt);
    }

    do_matching(input) {
        return super.do_matching(input, this.text, this.matching, this.whole_words, this.case_sensitive);
    }

    static alias_commands = undefined;

    static handle_aliases (input, nexus)
    {
        var inner_loop = false;
        if (ReflexAlias.alias_commands !== undefined) inner_loop = true;  // are we expanding an alias inside another alias?

        if (!inner_loop) ReflexAlias.alias_commands = [];

        var list = nexus.reflexes().get_active_list('alias');
        for (var id = 0; id < list.length; ++id) {
            var match = list[id].do_matching(input);
            if (match == null) continue;
            var actions = new ActionList (nexus);
            actions.prepare ('alias', list[id], match);
            actions.execute(0);
            if (inner_loop) return [ ];  // if we are expanding aliases from aliases, the result is in alias_commands; return nothing here
            var res = ReflexAlias.alias_commands;
            ReflexAlias.alias_commands = undefined;
            return res;
        }
        if (inner_loop) {
            ReflexAlias.alias_commands.push(input);
            return [ ];   // again, if we are expanding alias in an alias, we update alias_commands
        }

        ReflexAlias.alias_commands = undefined;
        return [ input ] ;
    }


}




