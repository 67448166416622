
export class Notifications {

    constructor(settings, platform) {
        this._settings = settings;
        this._platform = platform;
        this.focused = true;

        // How long should the notification appear on the screen (in milliseconds), 0 = permanent
        this.notification_time = 5000;

        // Comms default set to 'show' will show people/channels unless on ignore list.
        //       default set to 'ignore' will only show people/channels on show list.
        //       default set to 'off' will disable all comms
        this.defaultcomm = 'show';

        // These are unused currently.
        // List of people that are always ignored. Always use initial caps
        this.ignore_person_list = [];
        // List of channels that are ignored unless person is in always show list. All lowercase.
        this.ignore_channel_list = [];
        // List of people to include even if channel otherwise ignored. Initial caps.
        this.show_person_list = [];
        // List of channels to show if default is set to ignore.
        // If default is set to 'show' this will show even people on the ignore_person_list if on this channel
        this.show_channel_list = [];
    }

    set_focused(focused) {
        this.focused = focused;
    }

    handle_channel_text(channel, text, talker) {
/*
        var ignore = true;
        if (channel.indexOf(" ") >= 0) channel = channel.substr(0, channel.indexOf(" "));
        if ((this.defaultcomm == 'show') || (this.defaultcomm == 'ignore')) {
            ignore = false;
            if (this.ignore_person_list.indexOf(talker) >= 0) ignore = true;
            if (this.ignore_channel_list.indexOf(channel) >=0) ignore = true;
            if (this.show_person_list.indexOf(talker) >= 0) ignore = false;
            if (this.show_channel_list.indexOf(channel) >=0) ignore = false;
            if (this.defaultcomm = 'ignore') ignore = !ignore;
        }
*/
//        if (ignore) return;
        this.notify (talker + " on " + channel, text);
    }

    _do_notify(heading, text) {
//        if (text) text = '<div>' + text + '</div>';
        var options = {
            body: text,
        }
        var timeout = this.notification_time;
        var notification = new Notification (heading, options);
        if (timeout) this._platform.set_timeout(notification.close.bind(notification), timeout);
    }

    notify(heading, text) {
        if (!this._settings.notifications_enabled) return;
        if (this.focused) return;

        if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            this._do_notify (heading, text);
        }

        // Otherwise, ask the user for permission
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    this._do_notify (heading, text);
                }
            });
        }
    }

}

