
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Menu, Item, useContextMenu } from 'react-contexify';
import "react-contexify/dist/ReactContexify.css";

import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'

import { Util } from '../base/util.js'

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';

// need gmcp, on_feature, fullscreen and gameinfo from props
export class StatusBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { stop : false, logging : false, showVolume: false };
        this.volumeRef = React.createRef();
        library.add (faBolt);
        
        this.loggingMenuID = 'logging-menu';
        this.loggingMenu = useContextMenu({ id: this.loggingMenuID });
    }

    onVolumeChange(vol) {
        this.props.onVolumeChange(vol);
    }

    volume() {
        return this.props.platform.get_volume();
    }

    pingIcon() {
        let gmcp = this.props.gmcp;
        if (gmcp.PingTime && (gmcp.PingTime >= 0)) {
            if (gmcp.PingTime < 50) return 'signal-strong';
            else if (gmcp.PingTime < 200) return 'signal-good';
            else if (gmcp.PingTime < 500) return 'signal-fair';
            else return 'signal-weak';
        }
        return 'signal-slash';
    }

    pingIconColor() {
        let gmcp = this.props.gmcp;
        if (gmcp.PingTime && (gmcp.PingTime >= 0)) {
            if (gmcp.PingTime < 50) return '#00ff00';
            else if (gmcp.PingTime < 200) return '#ffff00';
            else if (gmcp.PingTime < 500) return '#ff0000';
            else return '#ff0000';
        }
        return '#ff0000';
    }

    pingIconColor2() {
        let gmcp = this.props.gmcp;
        if (gmcp.PingTime && (gmcp.PingTime >= 0)) {
            if (gmcp.PingTime < 50) return '#b3b6b7';
            else if (gmcp.PingTime < 200) return '#b3b6b7';
            else if (gmcp.PingTime < 500) return '#b3b6b7';
            else return '#b3b6b7';
        }
        return '#b3b6b7';
    }

    volumeIcon() {
        let vol = this.volume();
        if (vol >= 60) return 'volume-high';
        if (vol > 0) return 'volume-low';
        return 'volume-off';
    }

    statusIcon(type, icon, handler, tip, off=false, className='', color=null, color2=null) {
        let gi = this.props.gameinfo;
        if (gi.statusbar_hide_field && gi.statusbar_hide_field(type)) return null;  // games can hide some fields
        let tips = this.props.tooltips;
        if (off) {
            if (className) className += ' ';
            className += 'off';
        }
        let sett = this.props.settings;
        let fontSize = parseInt(sett.calc_fontsize());
        let sz = Math.floor(25 * fontSize / 13);
        let reverted = sett.reverted;
        let span_st = { fontSize: sz+'px', marginTop: 'auto', marginBottom: 'auto', display: 'inline-block' };
        let st = {};
        if (!reverted) {
            if (color && color.length) {
                st['color'] = color;
                st['--fa-primary-color'] = color;
            }
            if (color2) {
                st['--fa-secondary-color'] = color2;
                st['--fa-secondary-opacity'] = 1.0;
            }
        }
        let group = 'fad';
        if (icon === 'bolt') group = 'fas';
        let res = (<span id={'status_'+type} style={span_st}><FontAwesomeIcon style={st} icon={[group, icon]} id={'icon_'+type} onClick={handler} className={className} /></span>);
        if (tips) res = (<Tooltip key={'icon_'+type} title={tip}>{res}</Tooltip>);
        return res;
    }

    statusVar(type, icon, content, tip, color=null, color2=null) {
        let gi = this.props.gameinfo;
        if (gi.statusbar_hide_field && gi.statusbar_hide_field(type)) return null;  // games can hide some fields
        let tips = this.props.tooltips;
        let st = { marginRight: '3px' };
        let reverted = this.props.settings.reverted;
        if (!reverted) {
            if (color && color.length) {
                st['color'] = color;
                st['--fa-primary-color'] = color;
            }
            if (color2) {
                st['--fa-secondary-color'] = color2;
                st['--fa-secondary-opacity'] = 1.0;
            }
        }
        let div_st = {};
        if (type === 'target') {
            div_st['flex'] = 1;
            div_st['maxWidth'] = '500px';
        }
        let el = (<div id={'status-'+type} style={div_st} className="status-el"><FontAwesomeIcon icon={['fad', icon]} style={st} />{content}</div>);
        if (tips) el = (<Tooltip key={'status_'+type} title={tip}>{el}</Tooltip>);
        return el;
    }

    renderMenuItem(caption, clickid) {
        return (<Item key={'menuitem-'+clickid} closeOnClick={true} onClick={(e)=>this.clickHandler(e,clickid)}>{caption}</Item>);
    }

    renderLoggingMenu() {
        let t = this;
        let items = [];

        let log = this.props.log;
        if (log.active())
            items.push (this.renderMenuItem('Stop logging', 'logging-stop'));
        else
            items.push (this.renderMenuItem('Start logging', 'logging-start'));
        items.push (this.renderMenuItem('Save buffer', 'logging-download'));

        let menu = (<Menu id={this.loggingMenuID}>{items}</Menu>);
        return menu;
    }

    renderMenus() {
        let res = [];
        res.push(this.renderLoggingMenu());
        return res;
    }

    handleContextMenu(e) {
        let menu = null;
        let el = e.target.closest('#status_logging');
        if (el) menu = this.loggingMenu;

        if (!menu) {
            e.preventDefault();
            return;
        }
        this.activeMenu = menu;
        menu.show({event:e});
    }

    clickHandler(e,id) {
        e.event.preventDefault();
        this.props.onMenuClick(id);
        if (this.activeMenu) this.activeMenu.hideAll();
    }

    render() {
        let gmcp = this.props.gmcp;
        let gi = this.props.gameinfo;

        let el;
        let statuses = [];
        let tips = this.props.tooltips;
        let left = [];
        el = this.statusIcon('help', 'circle-question', () => this.props.on_feature('help'), 'Click here to read documentation on Nexus');
        if (el) left.push(el);
        let el_left = (<div key="left_icons" id="left_icons" style={{flexGrow: 0, display:'flex',flexDirection:'row'}} >{left}</div>);
        statuses.push (el_left);
        statuses.push(<div key="sep1" className="separator" style={{flexBasis: '5px'}} />);

        let vars = [];
        el = this.statusVar('level', 'books', gmcp.Status.level, 'Level', '#ad8348', '#e1c398');
        if (el) vars.push (el);
        el = this.statusVar('gold', 'treasure-chest', gmcp.Status.gold, Util.ucfirst (gi.money()), '#ffd700', '#a05213');
        if (el) vars.push (el);
        el = this.statusVar('bank', 'landmark', gmcp.Status.bank, Util.ucfirst (gi.money()) + ' in Bank', '#e7b82c', '#e58334');
        if (el) vars.push (el);
        el = this.statusVar('ping', this.pingIcon(), gmcp.PingTime, 'Ping Time', this.pingIconColor(), this.pingIconColor2());
        if (el) vars.push (el);
        el = this.statusVar('target', 'bullseye-arrow', this.props.dh.current_target_visible(), 'Current Target (TAB to change)', null, '#ff0000');
        if (el) vars.push (el);
        statuses.push ((<div key="status_vars" id="status_vars" style={{display:'flex',flexDirection:'row'}}>{vars}</div>));

        let extras = [];
        let msgs = gmcp.Status.unread_msgs;
        if (!msgs) msgs = 0;
        let msg_tooltip = 'No Unread Messages';
        if (msgs > 0) msg_tooltip = msgs + ' Unread Message' + ((msgs===1)?'':'s') + ' - HELP MESSAGES';
        el = this.statusIcon('messages', 'envelope-open-text', () => this.props.on_feature('msgs'), msg_tooltip, false, 'statusMessages', '#c8c8c8', (msgs > 0) ? '#00ff00' : '#ffffff');
        extras.push (el);

        let news = gmcp.Status.unread_news;
        if (!news) news = 0;
        let news_tooltip = 'No Unread News';
        if (news > 0)  news_tooltip = news + ' Unread News - HELP NEWS';
        el = this.statusIcon('news', 'newspaper', () => this.props.on_feature('news'), news_tooltip, false, 'statusNews', (news > 0) ? '#427242' : '#c8c8c8', (news > 0) ? '#00ff00' : '#ffffff' );
        extras.push (el);
        let el_extras = (<div key="extra_icons" id="extra_icons" style={{flexGrow: 1, textAlign: 'right'}} >{extras}</div>);
        statuses.push (el_extras);
        statuses.push(<div key="sep2" className="separator" style={{flexBasis: '5px'}} />);

        let sett = this.props.settings;
        let fontSize = parseInt(sett.calc_fontsize());
        let mult = fontSize / 13.0;

        let daytype = 'day';
        let daytitle = '';
        let daypos = 0;
        if (gmcp.Time && (gmcp.Time.daynight !== undefined))
        {
            var daynight = parseInt(gmcp.Time.daynight);
            if (isNaN(daynight)) daynight = 0;
            var dn = daynight;
            dn = (dn === 100) ? 100 : dn % 100;
            if (daynight <= 100) {   // day
                daytype = 'day';
                if (dn === 0) daytitle = 'It is dawn.';
                else if (dn < 28) daytitle = 'It is early morning.';
                else if (dn < 50) daytitle = 'It is mid-morning.';
                else if (dn === 50) daytitle = 'It is exactly noon.';
                else if (dn < 72) daytitle = 'It is early afternoon.';
                else if (dn < 91) daytitle = 'It is late afternoon.';
                else daytitle = 'It is dusk.';
            } else {
                daytype = 'night';
                if (dn < 16) daytitle = 'Dusk has overtaken the light.';
                else if (dn < 50) daytitle = 'It is deep night, before midnight.';
                else if (dn === 50) daytitle = 'It is deepest midnight.';
                else if (dn < 83) daytitle = 'It is middle of the night.';
                else daytitle = 'It is late night, approaching dawn.';
            }
            daypos = dn;
        }
        let st1 = { width: (80*mult)+'px' };
        let st2 = { height: (32*mult)+'px', width: (77*mult)+'px', borderRadius: (11*mult)+'px' };
        let el_daynight = (<div className="daynight-icon" style={{left:daypos+'%'}} ></div>);
        let el_daytime = (<div className={'daytime-' + daytype} id="status-daytime" style={st1}><div className="daynight-wrap"style={st2}>{el_daynight}</div></div>);
        if (tips) el_daytime = (<Tooltip key="status_daytime" title={daytitle}>{el_daytime}</Tooltip>);
        statuses.push (el_daytime);
        statuses.push(<div key="sep3" className="separator" style={{flexBasis: '5px'}} />);

        let icon_fullscreen = this.statusIcon('fullscreen', 'expand-wide', () => this.props.on_feature('fullscreen'), 'Fullscreen Mode', !this.props.fullscreen);
        let icon_logging = this.statusIcon('logging', 'square-pen', () => this.onLogging(), 'Click to toggle logging, right click for more', false, '', '#ffffff', this.props.log.active() ? '#00ff00' : '#707070');
        let icon_fullstop = this.statusIcon('fullstop', 'bolt', () => this.onFullStop(), 'Click to stop all function and trigger execution', this.state.stop);

        let volumeSt = {};
        let volumeGroup = 'fad';
        let vol = this.volume();
        let reverted = this.props.settings.reverted;
        if (!reverted) {
            if (vol > 0) {
                volumeSt['color'] = '#00ff00';
                volumeSt['--fa-primary-color'] = '#00ff00';
                volumeSt['--fa-secondary-color'] = '#ffffff';
                volumeSt['--fa-secondary-opacity'] = 1.0;
            } else {
                volumeGroup = 'fas';
                volumeSt['color'] = '#707070';
            }
        }
        let sz = Math.floor(25 * mult);
        let vol_st = { fontSize: sz+'px', display: 'inline-block', marginTop: 'auto', marginBottom: 'auto' };
        let icon_volume = (<span style={vol_st} ref={this.volumeRef}><FontAwesomeIcon icon={[volumeGroup, this.volumeIcon()]} id='volume' style={volumeSt} onClick={(e) => this.onVolume(e)} /></span>);
        if (tips) icon_volume = (<Tooltip key='icon_volume' title='Volume'>{icon_volume}</Tooltip>);

        let icon_settings = this.statusIcon('settings', 'gear', () => this.props.on_feature('settings'), 'Settings');
        let icon_reset_ui = this.statusIcon('reset_ui', 'arrows-rotate', () => this.props.on_feature('reset'), 'Resets UI - If you\'ve reconfigured the UI and things have gone pear-shaped, click it to reset your UI back to its original state.');
        let icons = [];
        icons.push (icon_fullscreen);
        icons.push (icon_logging);
        icons.push (icon_fullstop);
        icons.push (icon_volume);
        icons.push (icon_settings);
        icons.push (icon_reset_ui);

        // volume control
        if (this.state.showVolume) {
            let vol = this.volume();
            let selector = <Slider orientation='vertical' style={{height:'150px'}} value={vol} onChange={(e,v)=>this.onVolumeChange(v)} step={1} min={0} max={100} />;
            let volcontrol = (<Popper style={{zIndex:20000}} open={true} anchorEl={()=>this.volumeRef.current} key='volumesel'><Paper>{selector}</Paper></Popper>);
            volcontrol = (<ClickAwayListener onClickAway={()=>this.setState({showVolume:false})}>{volcontrol}</ClickAwayListener>);
            icons.push (volcontrol);
        }

        let el_icons = (<div key="status_icons" id="status_icons" style={{display:'flex', flexDirection:'row'}}>{icons}</div>);
        statuses.push (el_icons);

        let menus = this.renderMenus();

        return (<div id="footer" style={{height:(35*mult)+'px'}} onContextMenu={(e)=>this.handleContextMenu(e)}>{statuses}{menus}</div>);
    }
    
    onFullStop() {
        if (this.state.stop) {
            this.props.on_feature ('exec-start');
            this.setState ({stop:false});
        } else {
            this.props.on_feature ('exec-stop');
            this.setState ({stop:true});
        }
    }

    onLogging() {
        if (this.props.log.active())
            this.props.on_feature ('logging-stop');
        else
            this.props.on_feature ('logging-start');
    }

    onVolume(e) {
        e.stopPropagation();
        if (this.state.showVolume)
            this.setState({showVolume:false});
        else
            this.setState({showVolume:true});
    }        

}
